import { createSelector } from 'reselect';

const getScene = (state) => state.pages.editor.scene;
const getConfiguration = (state) => state.sceneConfiguration.activeLink;

const getSceneData = createSelector([getScene, getConfiguration], (scene, configuration) => {
    const { name, description, tags, notes, id, latestVersionId, publishedVersionId } = scene;
    const { sku, skuVersion, fixedAttributesSelection: attributes } = configuration;

    return {
        id,
        name,
        description,
        tags,
        notes,
        latestVersionId,
        publishedVersionId,
        sku,
        skuVersion,
        attributes,
    };
});

export default getSceneData;
