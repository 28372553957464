import { createSlice } from '@reduxjs/toolkit';

import { calculateCanvasScale } from 'src/util/misc';
import { selectLayer } from 'src/pages/editor/components/layer/slice';
import { PointType } from 'src/types';

const ZOOM_SPEED = 0.3;
const MAX_CANVAS_SCALE = 3;
const MIN_CANVAS_SCALE = 0.8;
export const CONSTRAINED_BLEED_TYPE = 'bleed';
export const CONSTRAINED_TRIM_TYPE = 'trim';

type CanvasSliceStateType = {
    isAddRulerEnabled: boolean;
    aspectRatioConstrainedType: string;
    scale: number;
    width: number | undefined;
    height: number | undefined;
    offset: PointType;
    latestRequestCount: number;
    successRequestCount: number;
    errorRequestCount: number;
    renderedDocument: {
        image?: string;
        fileName?: string;
        reference?: string;
    };
    uploadingDocumentImage?: boolean;
    url?: string;
};

const initialState: CanvasSliceStateType = {
    isAddRulerEnabled: false,
    aspectRatioConstrainedType: '',
    scale: 1,
    width: undefined,
    height: undefined,
    offset: { x: 0, y: 0 },
    latestRequestCount: 0,
    successRequestCount: 0,
    errorRequestCount: -1,
    renderedDocument: {
        image: undefined,
        fileName: undefined,
        reference: undefined,
    },
};

const slice = createSlice({
    name: 'canvas',

    initialState,

    reducers: {
        toggleAddMode: (state) => {
            state.isAddRulerEnabled = !state.isAddRulerEnabled;
        },
        setAspectRatioConstraint: (state, { payload }) => {
            state.aspectRatioConstrainedType = payload;
        },
        zoomCanvas: (state, { payload }) => {
            const { scroll, image, container, mouseX = 0.5, mouseY = 0.5 } = payload;
            const { offset, scale } = state;
            const containerRect = container.getBoundingClientRect();

            const updatedCanvasScale = scale + scroll * ZOOM_SPEED;
            const newScale = Math.max(Math.min(updatedCanvasScale, MAX_CANVAS_SCALE), MIN_CANVAS_SCALE);

            const finalScale = calculateCanvasScale(scale, container, image);
            const newFinalScale = calculateCanvasScale(newScale, container, image);

            const imageWidth = (image && image.width) || 1;
            const imageHeight = (image && image.height) || 1;

            const oldWidth = imageWidth * finalScale;
            const newWidth = imageWidth * newFinalScale;
            const deltaX = oldWidth - newWidth;

            const oldHeight = imageHeight * finalScale;
            const newHeight = imageHeight * newFinalScale;
            const deltaY = oldHeight - newHeight;

            const maxX = containerRect.width / 4;
            const maxY = containerRect.height / 4;
            const minX = -(newWidth - containerRect.width * (2 / 3));
            const minY = -(newHeight - containerRect.height * (2 / 3));
            let x = deltaX * mouseX;
            let y = deltaY * mouseY;
            x = Math.min(Math.max(minX, offset.x + x), maxX);
            y = Math.min(Math.max(minY, offset.y + y), maxY);

            state.scale = newScale;
            state.offset = { x, y };
        },
        centerCanvas: (state, { payload }) => {
            const { container, image } = payload;
            const finalScale = calculateCanvasScale(1, container, image);
            const x = (container.clientWidth - image.width * finalScale) / 2;
            const y = (container.clientHeight - image.height * finalScale) / 2;
            state.scale = 1;
            state.offset = { x, y };
        },
        moveCanvas: (state, { payload }) => {
            state.offset = payload;
        },
        updateDocumentReference: (state, { payload }) => {
            state.renderedDocument = { reference: payload };
        },
        uploadDocumentImage: (state) => {
            state.uploadingDocumentImage = true;
        },
        uploadDocumentImageSuccess: (state, { payload }) => {
            state.renderedDocument = {
                image: payload.url,
                fileName: payload.fileName,
            };
            state.uploadingDocumentImage = false;
        },
        uploadDocumentImageFailed: (state) => {
            state.uploadingDocumentImage = false;
        },
        removeDocumentImage: (state) => {
            state.renderedDocument = {};
        },
        renderCanvas: (state, { payload }) => {
            state.latestRequestCount = payload;
        },
        renderCanvasSuccess: (state, { payload }) => {
            state.url = payload.url;
            state.width = payload.width;
            state.height = payload.height;
            state.successRequestCount = payload.requestCount;
        },
        renderCanvasFailed: (state, { payload }) => {
            state.errorRequestCount = payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(selectLayer, (state) => {
            state.isAddRulerEnabled = false;
        });
    },
});

export const reducer = slice.reducer;

export const toggleAddMode = slice.actions.toggleAddMode;
export const setAspectRatioConstraint = slice.actions.setAspectRatioConstraint;
export const zoomCanvas = slice.actions.zoomCanvas;
export const centerCanvas = slice.actions.centerCanvas;
export const moveCanvas = slice.actions.moveCanvas;
export const updateDocumentReference = slice.actions.updateDocumentReference;
export const uploadDocumentImage = slice.actions.uploadDocumentImage;
export const uploadDocumentImageSuccess = slice.actions.uploadDocumentImageSuccess;
export const uploadDocumentImageFailed = slice.actions.uploadDocumentImageFailed;
export const removeDocumentImage = slice.actions.removeDocumentImage;
export const renderCanvas = slice.actions.renderCanvas;
export const renderCanvasSuccess = slice.actions.renderCanvasSuccess;
export const renderCanvasFailed = slice.actions.renderCanvasFailed;
