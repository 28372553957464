import { Accordion } from '@cimpress/react-components';
import React, { PureComponent } from 'react';
import Engraving from './engraving';
import MapPoints from '../../documents/transforms/mapPoints';

interface Props {
    id: string;
    isEngraving: boolean;
    colorEngraving: string;
    toggleEngraving: (id: string) => void;
    changeEngravingColor: (data: { id: string; color: string }) => void;
}

interface State {
    isOpen: boolean;
}

export default class AdvancedView extends PureComponent<Props, State> {
    state: State = {
        isOpen: false,
    };

    handleClick = (_: any, isOpen: boolean) => {
        this.setState({ isOpen });
    };

    render() {
        const { id, isEngraving, toggleEngraving, changeEngravingColor, colorEngraving } = this.props;

        return (
            <div className='advanced__tools'>
                <Accordion
                    variant='minimal'
                    title={this.state.isOpen ? 'Hide Advanced' : 'Show Advanced'}
                    className='advanced__tools__content'
                    onHeaderClick={this.handleClick}>
                    <Engraving
                        id={id}
                        isEngraving={isEngraving}
                        toggleEngraving={toggleEngraving}
                        changeEngravingColor={changeEngravingColor}
                        colorEngraving={colorEngraving}
                    />
                    <MapPoints id={id} />
                </Accordion>
            </div>
        );
    }
}
