import auth from 'src/auth';

interface BackgroundRemoveInput {
    url: string;
    signal?: AbortSignal;
}

interface BackgroundRemoveSuccess {
    success: true;
    data: {
        output: {
            url: string;
        };
    };
}

interface BackgroundRemoveError {
    success: false;
    errorMessage: string;
}

type BackgroundRemoveResult = BackgroundRemoveSuccess | BackgroundRemoveError;

export interface BackgroundResponse {
    id: string;
    status: string;
    output: Output;
    input: Input;
}
export interface Output {
    info: Info;
    url: string;
    options?: null;
    cropBoundingBox: CropBoundingBox;
}
export interface Info {
    inputWidth: number;
    inputHeight: number;
    remarks: string;
    outputWidth: number;
    outputHeight: number;
    classificationType: string;
}
export interface CropBoundingBox {
    x: number;
    y: number;
    width: number;
    height: number;
}
export interface Input {
    url: string;
    classificationType: string;
}

export async function backgroundRemove({ url, signal }: BackgroundRemoveInput): Promise<BackgroundRemoveResult> {
    const response = await fetch('https://imagemind.ipa.cimpress.io/v2/imagemind/backgroundremove', {
        method: 'POST',
        body: JSON.stringify({
            input: {
                url,
                classificationType: 'photo',
            },
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.getAccessToken()}`,
        },
        signal,
    });

    if (!response.ok) {
        return { success: false, errorMessage: response.statusText };
    }

    try {
        const data: BackgroundResponse = await response.json();
        const isTransparent = data.output.info?.remarks?.includes('Background not removed');
        return isTransparent
            ? {
                  success: false,
                  errorMessage: 'Input image has a Transparency, those type of images are not supported',
              }
            : {
                  success: true,
                  data,
              };
    } catch (error: unknown) {
        return {
            success: false,
            errorMessage: (error as Error).message,
        };
    }
}

interface GenerateImagesInput {
    input: string;
    width: number;
    height: number;
    signal?: AbortSignal;
}
export interface GenerateImagesOutput {
    id: string;
    status: string;
    output: ImagesOutput;
    input: SourceInput;
}

export interface ImagesOutput {
    info: ImageInfo;
    options: Option[];
}

export interface ImageInfo {
    outputWidth: number;
    outputHeight: number;
}

export interface Option {
    url: string;
}

export interface SourceInput {
    prompt: string;
    seed: number;
    optionCount: number;
    iterationCount: number;
    width: number;
    height: number;
    presetStyle: any;
    cfgScale: number;
    base64EncodeImage: boolean;
}

export async function generateImages({
    input,
    width = 1024,
    height = 1024,
    signal,
}: GenerateImagesInput): Promise<Option[] | BackgroundRemoveError> {
    const response = await fetch('https://imagemind.ipa.cimpress.io/v2/imagemind/imagify', {
        method: 'POST',
        body: JSON.stringify({
            input: {
                prompt: input,
                seed: 0,
                optionCount: 4,
                iterationCount: 20,
                width,
                height,
                presetStyle: null,
                cfgScale: 12,
                base64EncodeImage: false,
            },
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.getAccessToken()}`,
        },
        signal,
    });

    if (!response.ok) {
        return { success: false, errorMessage: response.statusText };
    }

    try {
        const data: GenerateImagesOutput = await response.json();
        return data.output.options;
    } catch (error: unknown) {
        return {
            success: false,
            errorMessage: (error as Error).message,
        };
    }
}
