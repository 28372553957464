import React from 'react';
import PropTypes from 'prop-types';
import { Circle, Group } from 'react-konva';

const cursorStyles = ['nw-resize', 'ne-resize', 'se-resize', 'sw-resize'];

let localPoints = [];
export default function GrabPoints(props) {
    const { points, width, height, offset, color } = props;

    const onDragMove = (e, initialPosition, index) => {
        const deltaX = initialPosition.x - e.clientX;
        const deltaY = initialPosition.y - e.clientY;

        const selected = points[index];
        const newPoints = points.map((point) => {
            const newPoint = { ...point };

            // setting points to new coordinates
            if (point.x === selected.x) {
                // restrict it to be within width bounds
                newPoint.x = width ? Math.min(Math.max(offset, point.x - deltaX), width + offset) : point.x - deltaX;
            }
            if (point.y === selected.y) {
                // restrict it to be within height bounds
                newPoint.y = height ? Math.min(Math.max(offset, point.y - deltaY), height + offset) : point.y - deltaY;
            }
            return newPoint;
        });

        localPoints = [newPoints[0], newPoints[2]];

        props.onDragMove([newPoints[0], newPoints[2]]);
    };

    const onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        props.onDragEnd(localPoints);
    };

    const onDragStart = (e, index) => {
        const initialPosition = {
            x: e.evt.clientX,
            y: e.evt.clientY,
        };

        document.onmousemove = (evt) => onDragMove(evt, initialPosition, index);
        document.onmouseup = onDragEnd;

        localPoints = [];
        e.cancelBubble = true;
        props.onDragStart(initialPosition);
    };

    const cursorChange = (cursorStyle, id) => {
        document.body.style.cursor = cursorStyle;
        console.log(id);
    };

    return (
        <Group key={localPoints}>
            {points.map((point, index) => (
                <Circle
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    x={point.x}
                    y={point.y}
                    radius={10}
                    fill={color || 'black'}
                    stroke={'white'}
                    strokeWidth={1}
                    onMouseEnter={() => cursorChange(cursorStyles[index], index)}
                    onMouseLeave={() => cursorChange('default')}
                    onMouseDown={(e) => onDragStart(e, index)}
                />
            ))}
        </Group>
    );
}

GrabPoints.propTypes = {
    points: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })),
    onDragStart: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragEnd: PropTypes.func,
    offset: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};
