import React, { Component } from 'react';
import { Group } from 'react-konva';
import Grabber from '../../grabber';
import {
    distance,
    calculatePosition,
    angle as calculateAngle,
    toDegrees,
    mod,
    getWidthFromDiameterAndAngle,
} from 'src/util/math';
import type { KonvaEventObject } from 'konva/lib/Node';
import type { CylinderType } from 'src/types/transforms';

type Point = {
    x: number;
    y: number;
};

type Props = {
    container: HTMLElement;
    scaledCylinderWarp: Omit<CylinderType, 'id' | 'fullWrap'>;
    cylinderWarp: CylinderType;
    scale: number;
    onDragMove: (warp: CylinderType) => void;
    onDragEnd: () => void;
    cursorChange: (cursor: string) => void;
    offset: Point;
};

type State = {
    startCylinderWarp: CylinderType | null;
};

export default class RadiusGrabbers extends Component<Props, State> {
    state: State = {
        startCylinderWarp: null,
    };

    onDragStart = (e: KonvaEventObject<MouseEvent>, extent: boolean): void => {
        const { cylinderWarp } = this.props;

        this.setState({
            startCylinderWarp: cylinderWarp,
        });

        document.onmouseup = this.onDragEnd;
        document.onmousemove = (evt) => this.onDragMove(evt, extent);
        e.cancelBubble = true;
    };

    onDragMove = (e: MouseEvent, extent: boolean): void => {
        const { startCylinderWarp } = this.state;
        const { onDragMove, scale, container, offset } = this.props;
        const warp = JSON.parse(JSON.stringify(startCylinderWarp));

        const containerRect = container.getBoundingClientRect();
        const mousePosition = {
            x: (e.clientX - containerRect.left - offset.x) / scale,
            y: (e.clientY - containerRect.top - offset.y) / scale,
        };

        let angle = toDegrees(
            calculateAngle(warp.cylinderTop.center, calculatePosition(warp.cylinderTop, 0), mousePosition),
        );

        // rotate left or rotate right
        if (
            distance(mousePosition, calculatePosition(warp.cylinderTop, 270)) >
            distance(mousePosition, calculatePosition(warp.cylinderTop, 90))
        ) {
            angle = 360 - angle;
        }

        if (warp.decorationExtentDegreesBeta + angle < 0) {
            angle = 0;
        }

        if (extent) {
            if (angle < warp.referenceAngle) {
                warp.decorationExtentDegreesBeta = mod(angle + (360 - warp.decorationExtentDegreesAlpha), 360);
            } else {
                warp.decorationExtentDegreesBeta = mod(angle - warp.decorationExtentDegreesAlpha, 360);
            }
            const decoSize = getWidthFromDiameterAndAngle(warp.productDiameter, warp.decorationExtentDegreesBeta);
            warp.decorationAreaDimensions = { width: decoSize, height: decoSize };
        } else {
            warp.decorationExtentDegreesAlpha = angle;
        }

        onDragMove(warp);
    };

    onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();
    };

    render() {
        const { scaledCylinderWarp, cursorChange, cylinderWarp } = this.props;

        const referencePoint = calculatePosition(
            scaledCylinderWarp.cylinderTop,
            -scaledCylinderWarp.decorationExtentDegreesAlpha,
        );
        const extentPoint = calculatePosition(
            scaledCylinderWarp.cylinderTop,
            -(scaledCylinderWarp.decorationExtentDegreesAlpha + scaledCylinderWarp.decorationExtentDegreesBeta),
        );

        return (
            <Group>
                <Grabber
                    position={referencePoint}
                    cursorChange={cursorChange}
                    onDragStart={(e) => this.onDragStart(e, false)}
                    color='purple'
                />
                {!cylinderWarp.fullWrap && (
                    <Grabber
                        position={extentPoint}
                        cursorChange={cursorChange}
                        onDragStart={(e) => this.onDragStart(e, true)}
                        color='purple'
                    />
                )}
            </Group>
        );
    }
}
