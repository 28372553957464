// This is a simple component that turns on the navigate away warnings when there are unsaved changes (and turns it off otherwise)

import { isSceneModified } from 'src/selectors/pages/editor';
import { Store } from './storeManager';

function onChange(store: Store) {
    const sceneModified = isSceneModified(store.getState());

    const editorPage = window.location.pathname.includes('/editor');

    const editorModified = editorPage && sceneModified;

    window.onbeforeunload = editorModified ? () => true : null;
}

export default function navigationManager(store: Store) {
    store.subscribe(() => onChange(store));
}

export function turnOffNavigationWarning() {
    window.onbeforeunload = null;
}
