import type { RootState } from 'src/store';
import { connect } from 'react-redux';
import { isSceneModified, isSceneSavable, isSceneSaving, getSceneId } from 'src/selectors/pages/editor';
import { isMultiBackground } from 'src/selectors/layers';
import { saveDraft } from 'src/pages/editor/components/scene/slice';
import saveButton from './newVersion';
import getSceneVariation from '../../SceneVariation/selectors/getSceneVariation';

const mapStateToProps = (state: RootState) => {
    const isModified = isSceneModified(state);
    const sceneVariable = getSceneVariation(state);
    const isSceneVariableSavable = sceneVariable.scenePurposes.length > 0;

    return {
        text: isModified ? 'Save' : 'Saved',
        spinning: isSceneSaving(state),
        disabled:
            isMultiBackground(state) ||
            !isModified ||
            !isSceneSavable(state) ||
            !getSceneId(state) ||
            !isSceneVariableSavable,
    };
};

const saveContainer = connect(mapStateToProps, { saveAsNewVersion: () => saveDraft() })(saveButton);

export default saveContainer;
