import React, { Component } from 'react';
import { Stage, Rect, Layer } from 'react-konva';
import type Konva from 'konva';

import { logError } from 'src/loggingManager';
import Loading from 'src/components/Loading';
import { downloadImage } from 'src/util/upload';
import { SPACE } from 'src/util/keycodes';
import { calculateCanvasScale } from 'src/util/misc';
import { addOnDownListener, addOnUpListener, removeOnDownListener, removeOnUpListener } from 'src/keyboardManager';
import { Provider, ReactReduxContext } from 'react-redux';
import Warps from './warps';
import Header from './header';
import Rulers from '../rulers';
import transparentFillUrl from '../../../../../content/images/transparent.png';
import Cropping from '../SceneVariation/Cropping';
import type { CancelablePromise } from 'src/types/upload';
import type { KonvaEventObject } from 'konva/lib/Node';

interface Props {
    scale: number;
    offset: { x: number; y: number };
    image: { url?: string; width?: number; height?: number };
    errorRequestCount: number;
    successRequestCount: number;
    latestRequestCount: number;
    deselectLayer: () => void;
    moveCanvas: (offset: { x: number; y: number }) => void;
    zoomCanvas: (params: {
        scroll: number;
        image: any;
        container: HTMLElement;
        mouseX: number;
        mouseY: number;
    }) => void;
    centerCanvas: (params: { image: any; container: HTMLElement }) => void;
    isCroppingEnable: boolean;
}

interface State {
    transparentBackground: HTMLImageElement | null;
    spacePressed: boolean;
    offset: { x: number; y: number };
    clientX: number | null;
    clientY: number | null;
    startX: number | null;
    startY: number | null;
    dragging: boolean;
}

const WHEEL_CONST = 120;

export default class Canvas extends Component<Props, State> {
    cancelableRequests: CancelablePromise<HTMLImageElement>[] = [];
    container: HTMLDivElement | null = null;
    stage: Konva.Stage | null = null;

    state: State = {
        transparentBackground: null,
        spacePressed: false,
        offset: this.props.offset,
        clientX: null,
        clientY: null,
        startX: null,
        startY: null,
        dragging: false,
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (!prevState.dragging) {
            return { offset: nextProps.offset };
        }

        return null;
    }

    componentDidMount() {
        const mountPromise = downloadImage(transparentFillUrl);
        this.cancelableRequests.push(mountPromise);

        mountPromise
            .then((transparentBackground) => {
                this.cancelableRequests.splice(this.cancelableRequests.indexOf(mountPromise), 1);
                this.setState({ transparentBackground });
            })
            .catch((errorResponse) => {
                logError(`Download of canvas image failed (url=${transparentFillUrl}): ${errorResponse}`);
            });

        addOnDownListener(this.onKeyDown);
        addOnUpListener(this.onKeyUp);
    }

    componentDidUpdate(prevProps: Props) {
        const { image, centerCanvas } = this.props;
        if (image.url && !prevProps.image.url) {
            centerCanvas({ image, container: this.container! });
        }
    }

    componentWillUnmount() {
        this.cancelableRequests.forEach((p) => p.cancel?.());
        removeOnDownListener(this.onKeyDown);
        removeOnUpListener(this.onKeyUp);
    }

    onKeyDown = (e: KeyboardEvent) => {
        if (e.which === SPACE) {
            this.setState({ spacePressed: true });
        }
    };

    onKeyUp = (e: KeyboardEvent) => {
        if (e.which === SPACE) {
            this.setState({ spacePressed: false });
        }
    };

    onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        const { offset, spacePressed } = this.state;
        if (spacePressed) {
            this.setState({
                clientX: e.evt.clientX,
                clientY: e.evt.clientY,
                startX: offset.x,
                startY: offset.y,
                dragging: true,
            });
            document.onmousemove = this.onDragMove;
            document.onmouseup = this.onDragEnd;
            return;
        }
        this.props.deselectLayer();
    };

    onDragMove = (e: MouseEvent) => {
        const { image, scale } = this.props;
        const { clientX, clientY, startX, startY } = this.state;

        const containerRect = this.container!.getBoundingClientRect();

        const finalScale = calculateCanvasScale(scale, this.container!, image);

        let newX = startX! - (clientX! - e.clientX);
        let newY = startY! - (clientY! - e.clientY);
        const imageWidth = image.width! * finalScale;
        const imageHeight = image.height! * finalScale;
        const minX = -(imageWidth - containerRect.width * (2 / 3));
        const minY = -(imageHeight - containerRect.height * (2 / 3));
        const maxX = containerRect.width / 4;
        const maxY = containerRect.height / 4;
        newX = Math.min(Math.max(minX, newX), maxX);
        newY = Math.min(Math.max(minY, newY), maxY);

        this.setState({ offset: { x: newX, y: newY } });
    };

    onDragEnd = () => {
        const { moveCanvas } = this.props;
        const { offset } = this.state;
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.setState({ dragging: false });

        moveCanvas(offset);
    };

    onWheel = (e: WheelEvent) => {
        e.preventDefault();

        const { zoomCanvas, image } = this.props;
        const scroll = -e.deltaY / WHEEL_CONST;
        const containerRect = this.container!.getBoundingClientRect();
        const mouseX = 1 - (containerRect.width - (e.pageX - containerRect.left)) / containerRect.width;
        const mouseY = 1 - (containerRect.height - (e.pageY - containerRect.top)) / containerRect.height;
        zoomCanvas({ scroll, image, container: this.container!, mouseX, mouseY });
    };

    render() {
        const { scale, image, errorRequestCount, successRequestCount, latestRequestCount, isCroppingEnable } =
            this.props;
        const { transparentBackground, spacePressed, offset } = this.state;

        let canvas: JSX.Element | null = null;
        let backDrop: JSX.Element | null = null;
        if (image.width && image.height && transparentBackground && this.container) {
            const containerWidth = this.container.clientWidth;
            const containerHeight = this.container.clientHeight;
            const finalScale = calculateCanvasScale(scale, this.container, image);
            const cursorChange = (cursor: string) => (this.container!.style.cursor = cursor);

            backDrop = (
                <img
                    data-testid='scene-maker-editor-background-image'
                    className='scene-maker-editor__background'
                    src={image.url}
                    width={image.width * finalScale}
                    height={image.height * finalScale}
                    style={{ left: offset.x, top: offset.y }}
                />
            );

            // Redux workaround for React Konva: https://github.com/konvajs/react-konva/issues/311#issuecomment-536634446
            // This can be removed as soon as we upgrade React Konva to 18.2.2 or higher which requires React 18
            canvas = (
                <ReactReduxContext.Consumer>
                    {({ store }) => (
                        <Stage
                            ref={(ref) => (this.stage = ref)}
                            style={spacePressed ? { cursor: '-webkit-grab' } : {}}
                            className='scene-canvas'
                            width={containerWidth}
                            height={containerHeight}
                            onMouseDown={(e) => this.onMouseDown(e)}>
                            <Provider store={store}>
                                <Layer>
                                    <Rect width={containerWidth} height={containerHeight} />
                                </Layer>
                                <Warps
                                    container={this.container!}
                                    cursorChange={cursorChange}
                                    scale={finalScale}
                                    offset={offset}
                                />
                                <Rulers
                                    container={this.container}
                                    canvasWidth={containerWidth}
                                    canvasHeight={containerHeight}
                                    cursorChange={cursorChange}
                                    scale={finalScale}
                                    offset={offset}
                                />
                                {isCroppingEnable && (
                                    <Cropping
                                        canvasWidth={containerWidth}
                                        canvasHeight={containerHeight}
                                        offset={offset}
                                        scale={finalScale}
                                        image={image}
                                    />
                                )}
                            </Provider>
                        </Stage>
                    )}
                </ReactReduxContext.Consumer>
            );
            // The Rect in the above JSX is necessary for dragging the canvas around with the spacebar.
        }
        return (
            <div className='scene-maker-editor'>
                <Header image={image} container={this.container} />
                <div
                    ref={(ref) => {
                        this.container = ref;
                        if (this.container) {
                            this.container.addEventListener('wheel', this.onWheel, { passive: false });
                        }
                    }}
                    className='scene-maker-editor__canvas'>
                    {backDrop}
                    {canvas}
                    <Loading
                        show={errorRequestCount !== latestRequestCount && latestRequestCount > successRequestCount}
                    />
                </div>
            </div>
        );
    }
}
