export const RECTANGLE_WARP = 'RectangleWarp';
export const PERSPECTIVE_WARP = 'PerspectiveWarp';
export const CYLINDER_WARP = 'CylinderWarp';
export const SMOOTH_WARP = 'SmoothWarp';
export const TEXTURE_WARP = 'TextureWarp';

export type DocumentWarp =
    | typeof RECTANGLE_WARP
    | typeof PERSPECTIVE_WARP
    | typeof CYLINDER_WARP
    | typeof SMOOTH_WARP
    | typeof TEXTURE_WARP;

export const DOCUMENT_ID = 'Document';
