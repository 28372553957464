import auth from 'src/auth';

const identifyUser = () => {
    const { name, email, sub } = auth.getProfile();
    window.FS &&
        window.FS.identify(sub, {
            displayName: name,
            email,
        });
};

export default function identifyFullStoryUser() {
    const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];
    if (!LOCAL_DOMAINS.includes(window.location.hostname)) {
        // in case full story is already initialized we want to track the user
        identifyUser();

        // in the case that we are hitting this before full story is initialized, track the user when we are notified full story is ready
        window._fs_ready = identifyUser; // eslint-disable-line no-underscore-dangle
    }
}
