import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@cimpress/react-components';

const noteSection = (props) => {
    const { onChangeNotes, note } = props;

    return (
        <div className='information-panel__notes'>
            <TextField
                type='textarea'
                onChange={onChangeNotes}
                value={note}
                placeholder='Add notes'
                data-testid='editor-information-notes-input'
            />
        </div>
    );
};

noteSection.propTypes = {
    note: PropTypes.string.isRequired,
    onChangeNotes: PropTypes.func.isRequired,
};

export default noteSection;
