import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getDocumentById } from 'src/selectors/layers';
import texture from './texture';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const { warpType, width, height } = getDocumentById(state, ownProps.id);

    return {
        warpType,
        width,
        height,
    };
};

const textureContainer = connect(mapStateToProps, {})(texture);

export default textureContainer;
