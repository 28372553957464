import { connect } from 'react-redux';

import { getSurfaceProperties, isLinkSetupInvalid } from 'src/selectors/pages/editor';
import panels from './panels';
import getConfirmState from '../SceneVariation/selectors/getConfirmState';
import { setConfirm } from '../SceneVariation/slice';
import type { RootState } from 'src/store';

const mapStateToProps = (state: RootState) => {
    const { skuVersion = 0 } = getSurfaceProperties(state);
    return {
        invalidSkuSetup: isLinkSetupInvalid(state),
        confirm: getConfirmState(state),
        useV2Component: skuVersion > 0, // Flag to use the generic selector with new API's
    };
};

const panelsContainer = connect(mapStateToProps, {
    setConfirm,
})(panels);

export default panelsContainer;
