import { createSlice } from '@reduxjs/toolkit';

import { RULER } from 'src/pages/editor/components/layer/layerTypes';
import { layerToggleHide } from 'src/store/actions/actionTypes';
import { removeById } from 'src/util/state';

const WHITE = '#ffffff';
const BLACK = '#000000';

type RulerSliceState = Array<{
    id: string;
    point1: [number, number];
    point2: [number, number];
    segments: number;
    ticks: number[];
    color: string;
    hidden?: boolean;
}>;

const initialState: RulerSliceState = [];

const slice = createSlice({
    name: 'rulers',
    initialState,

    reducers: {
        addRuler: (state, { payload }) => {
            const { point1, point2, id } = payload;
            state.push({
                id,
                point1,
                point2,
                segments: 10,
                ticks: [],
                color: BLACK,
            });
        },
        removeRuler: removeById,
        toggleColor: (state, { payload }) => {
            const ruler = state.find((rule) => rule.id === payload);
            if (ruler) {
                ruler.color = ruler.color === WHITE ? BLACK : WHITE;
            }
        },
        updateSegments: (state, { payload }) => {
            const { id, segments } = payload;
            const ruler = state.find((rule) => rule.id === id);
            if (ruler) {
                ruler.segments = segments;
            }
        },
        updatePosition: (state, { payload }) => {
            const { id, point1, point2 } = payload;
            const ruler = state.find((rule) => rule.id === id);
            if (ruler) {
                if (point1) {
                    ruler.point1 = point1;
                }
                if (point2) {
                    ruler.point2 = point2;
                }
            }
        },
        addCustomTick: (state, { payload }) => {
            const ruler = state.find((rule) => rule.id === payload);
            if (ruler) {
                ruler.ticks.push(0);
            }
        },
        removeCustomTick: (state, { payload }) => {
            const { id, index } = payload;
            const ruler = state.find((rule) => rule.id === id);
            if (ruler && ruler.ticks.length > index) {
                ruler.ticks.splice(index, 1);
            }
        },
        updateCustomTick: (state, { payload }) => {
            const { id, index, value } = payload;
            const ruler = state.find((rule) => rule.id === id);
            if (ruler && ruler.ticks.length > index) {
                ruler.ticks[index] = value;
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase(layerToggleHide, (state, { payload }) => {
            const { id, type } = payload;
            if (type === RULER) {
                const ruler = state.find((doc) => doc.id === id);
                if (ruler) {
                    ruler.hidden = !ruler.hidden;
                }
            }
        });
    },
});

export const reducer = slice.reducer;

export const addRuler = slice.actions.addRuler;
export const removeRuler = slice.actions.removeRuler;
export const toggleColor = slice.actions.toggleColor;
export const updateSegments = slice.actions.updateSegments;
export const updatePosition = slice.actions.updatePosition;
export const addCustomTick = slice.actions.addCustomTick;
export const removeCustomTick = slice.actions.removeCustomTick;
export const updateCustomTick = slice.actions.updateCustomTick;
