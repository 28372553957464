import { htmlSpecialChars } from 'src/util/url';
import { toArray } from 'src/util/converter';
import idGeneratorAtor from 'src/util/idGeneratorAtor';
import { NORMAL, SCREEN } from 'src/models/blendingModes';
import { OVERLAY } from 'src/pages/editor/components/layer/layerTypes';
import { XMLTree_DIPComposite, XMLTree_Image } from 'src/types/xml';
import { Overlay } from 'src/pages/editor/components/overlays/slice';

const idGenerator = idGeneratorAtor('LoadedOverlay');

/* eslint-disable no-underscore-dangle */
export function buildOverlayObject(overlay: Overlay, parentSize: string, hideEnabled: boolean) {
    if (!overlay.url || (hideEnabled && overlay.hidden)) {
        return null;
    }

    let size = parentSize;
    if (overlay.width || overlay.height) {
        size = `${overlay.width},${overlay.height}`;
    }

    return {
        size,
        name: overlay.name,
        type: OVERLAY,
        mode: overlay.mode,
        depth: overlay.depth,
        src: htmlSpecialChars(overlay.url),
    };
}

export function xmlToOverlayObjects(xmlObject: XMLTree_DIPComposite) {
    let images = toArray(xmlObject.Image);
    // TODO: Here we are assuming any image with a mode different than NORMAL is an overlay, we can do this better
    images = images.filter((a: XMLTree_Image) => a._mode !== NORMAL);

    if (!images.length) {
        return [];
    }

    const overlays = images.map((image: XMLTree_Image) => {
        const id = idGenerator();
        return {
            mode: image._mode || SCREEN,
            url: image._src,
            name: image._name,
            uploading: false,
            id,
        };
    });

    return overlays;
}
