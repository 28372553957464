import React, { Component } from 'react';
import { Group, Line } from 'react-konva';
import Grabber from '../../grabber';
import type { KonvaEventObject } from 'konva/lib/Node';
import type { CylinderType } from 'src/types/transforms';

interface EllipseGrabbersProps {
    scaledCylinderWarp: Omit<CylinderType, 'id' | 'fullWrap'>;
    cylinderWarp: CylinderType;
    scale: number;
    onDragMove: (cylinderWarp: CylinderType) => void;
    onDragEnd: () => void;
    cursorChange: (cursor: string) => void;
}

interface EllipseGrabbersState {
    startCylinderWarp: CylinderType | null;
    clientX: number | null;
    clientY: number | null;
}

export default class EllipseGrabbers extends Component<EllipseGrabbersProps, EllipseGrabbersState> {
    state: EllipseGrabbersState = {
        startCylinderWarp: null,
        clientX: null,
        clientY: null,
    };

    onDragStart = (e: KonvaEventObject<MouseEvent>, top: boolean) => {
        const { cylinderWarp } = this.props;

        this.setState({
            startCylinderWarp: cylinderWarp,
            clientX: e.evt.clientX,
            clientY: e.evt.clientY,
        });

        document.onmouseup = this.onDragEnd;
        document.onmousemove = (evt) => this.onDragMove(evt, top);
    };

    onDragMove = (e: MouseEvent, top: boolean) => {
        const { clientX, clientY, startCylinderWarp } = this.state;
        const { onDragMove, scale } = this.props;
        const deltaX = (clientX! - e.clientX) / scale;
        const deltaY = (clientY! - e.clientY) / scale;
        const newCylinderWarp = JSON.parse(JSON.stringify(startCylinderWarp));
        if (top) {
            newCylinderWarp.cylinderTop.center.x -= deltaX;
            newCylinderWarp.cylinderTop.center.y -= deltaY;
        } else {
            newCylinderWarp.cylinderBottom.center.x -= deltaX;
            newCylinderWarp.cylinderBottom.center.y -= deltaY;
        }

        onDragMove(newCylinderWarp);
    };

    onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();
    };

    render() {
        const { scaledCylinderWarp, cursorChange } = this.props;

        const top = scaledCylinderWarp.cylinderTop.center;
        const bottom = scaledCylinderWarp.cylinderBottom.center;
        const center = {
            x: (top.x + bottom.x) / 2,
            y: (top.y + bottom.y) / 2,
        };
        const dottedLine = [top.x, top.y, center.x, center.y];

        return (
            <Group>
                <Line points={dottedLine} stroke='rgba(255, 255, 255, .3)' dash={[4, 2]} strokeWidth={1} />
                <Grabber position={top} cursorChange={cursorChange} onDragStart={(e) => this.onDragStart(e, true)} />
                <Grabber
                    position={bottom}
                    cursorChange={cursorChange}
                    onDragStart={(e) => this.onDragStart(e, false)}
                />
            </Group>
        );
    }
}
