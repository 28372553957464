import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Line, Group, Text } from 'react-konva';
import isEqual from 'lodash/isEqual';

import {
    getPointsOnLine,
    getPointOnLine,
    getNormalSlope,
    generateLineFromSlopeAndPoint,
    distance,
} from 'src/util/math';
import Grabber from '../canvas/grabber';

export default class Ruler extends Component {
    state = {
        startX: this.props.point2.x,
        startY: this.props.point2.y,
        clientX: 0,
        clientY: 0,
        point1OffsetX: 0,
        point2OffsetX: 0,
        point1OffsetY: 0,
        point2OffsetY: 0,
        draggingPoint: 2,
        translating: false,
    };

    // This is an expensive component. Add some optimization here.
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.translating || nextState.translating || this.props.drawing) {
            return true;
        }
        return ['point1', 'point2', 'segments', 'scale', 'offset', 'selected', 'color', 'ticks', 'hidden'].some(
            (propKey) => !isEqual(this.props[propKey], nextProps[propKey]),
        );
    }

    onTranslateStart = (e) => {
        const { point1, point2, scale, selected, selectRuler } = this.props;
        this.manageDragEvents(this.onTranslateMove, this.onTranslateEnd, true);
        const point1OffsetX = e.evt.clientX / scale - point1.x;
        const point2OffsetX = e.evt.clientX / scale - point2.x;
        const point1OffsetY = e.evt.clientY / scale - point1.y;
        const point2OffsetY = e.evt.clientY / scale - point2.y;
        this.setState({ translating: true, point1OffsetX, point2OffsetX, point1OffsetY, point2OffsetY });
        e.cancelBubble = true;

        if (!selected) {
            selectRuler();
        }
    };

    onTranslateMove = (e) => {
        const { updatePosition, scale } = this.props;
        const { point1OffsetX, point2OffsetX, point1OffsetY, point2OffsetY } = this.state;
        const point1 = { x: e.clientX / scale - point1OffsetX, y: e.clientY / scale - point1OffsetY };
        const point2 = { x: e.clientX / scale - point2OffsetX, y: e.clientY / scale - point2OffsetY };
        updatePosition({ point1, point2 });
    };

    onTranslateEnd = () => {
        this.manageDragEvents(this.onTranslateMove, this.onTranslateEnd);
        this.setState({ translating: false });
    };

    onDragStart = (index) => (e) => {
        this.manageDragEvents(this.onDragMove, this.onDragEnd, true);
        if (e) {
            const point = index === 1 ? 'point1' : 'point2';
            this.setState({
                draggingPoint: index,
                clientX: e.evt.clientX,
                clientY: e.evt.clientY,
                startX: this.props[point].x,
                startY: this.props[point].y,
            });
            e.cancelBubble = true;
        }
    };

    onDragMove = (e) => {
        const { updatePosition, scale } = this.props;
        const { draggingPoint, startX, startY } = this.state;
        let { clientX, clientY } = this.state;
        if (!clientX && !clientY) {
            clientX = e.clientX;
            clientY = e.clientY;
            this.setState({ clientX, clientY });
        }
        const point = draggingPoint === 1 ? 'point1' : 'point2';
        const newPoint = { x: startX - (clientX - e.clientX) / scale, y: startY - (clientY - e.clientY) / scale };
        updatePosition({ [point]: newPoint });
    };

    onDragEnd = () => {
        this.manageDragEvents(this.onDragMove, this.onDragEnd);
    };

    manageDragEvents = (move, end, add) => {
        let manageEventListener = document.removeEventListener;
        if (add) {
            manageEventListener = document.addEventListener;
        }
        manageEventListener('mousemove', move);
        manageEventListener('touchmove', move);
        manageEventListener('mouseup', end);
        manageEventListener('touchend', end);
    };

    render() {
        const {
            point1,
            point2,
            cursorChange,
            offset,
            scale,
            segments,
            selected,
            drawing,
            color,
            ticks = [],
            hidden,
        } = this.props;

        if (hidden) {
            return null;
        }

        const { translating } = this.state;
        const scaledPoint1 = { x: point1.x * scale + offset.x, y: point1.y * scale + offset.y };
        const scaledPoint2 = { x: point2.x * scale + offset.x, y: point2.y * scale + offset.y };
        const pointArray = [scaledPoint1.x, scaledPoint1.y, scaledPoint2.x, scaledPoint2.y];

        let totalTicks = segments - 1;
        let halfTicks = false;
        let tenthTicks = false;
        const spacePerSegment = distance(scaledPoint1, scaledPoint2) / segments;
        if (spacePerSegment > 30) {
            tenthTicks = true;
            totalTicks = segments * 10 - 1;
        } else if (spacePerSegment > 5) {
            halfTicks = true;
            totalTicks = segments * 2 - 1;
        }
        const regularTicks = getPointsOnLine(scaledPoint1, scaledPoint2, totalTicks);
        const normalSlope = getNormalSlope(scaledPoint1, scaledPoint2);

        const stroke = drawing ? 'red' : color;

        function drawTick(tick, key, length, tickColor) {
            const points = generateLineFromSlopeAndPoint(tick, normalSlope, length);
            return (
                <Line
                    key={key}
                    points={[points[0].x, points[0].y, points[1].x, points[1].y]}
                    stroke={tickColor}
                    strokeWidth={1}
                />
            );
        }

        return (
            <Group>
                <Line points={pointArray} stroke={stroke} strokeWidth={1} />
                <Line points={pointArray} stroke='transparent' strokeWidth={10} onMouseDown={this.onTranslateStart} />
                {regularTicks.map((point, index) => {
                    let length = 7;
                    if (halfTicks && index % 2 !== 1) {
                        length = 5;
                    } else if (tenthTicks && index % 10 === 4) {
                        length = 5;
                    } else if (tenthTicks && index % 10 !== 9) {
                        length = 3;
                    }
                    return drawTick(point, `reg-${index}`, length, stroke);
                })}
                {ticks.map((tick, index) => {
                    const point = getPointOnLine(scaledPoint1, scaledPoint2, tick / segments);
                    let labelPoint = { x: point.x + 10, y: point.y - 10 };
                    if (normalSlope < -0.5 || normalSlope > 1.5) {
                        labelPoint = { x: point.x - 5, y: point.y + 10 };
                    }
                    /* eslint-disable react/no-array-index-key */
                    return (
                        <Group key={`group-${index}`}>
                            {drawTick(point, `cust-${index}`, 9, 'red')}
                            <Text
                                x={labelPoint.x}
                                y={labelPoint.y}
                                text={`${tick}`}
                                fontSize={20}
                                fontFamily='Arial'
                                fill='red'
                                align='center'
                            />
                        </Group>
                    );
                    /* eslint-eable react/no-array-index-key */
                })}
                {!translating && selected && (
                    <Group>
                        <Grabber
                            position={scaledPoint1}
                            cursorChange={cursorChange}
                            onDragStart={this.onDragStart(1)}
                        />
                        <Grabber
                            position={scaledPoint2}
                            cursorChange={cursorChange}
                            onDragStart={this.onDragStart(2)}
                        />
                    </Group>
                )}
            </Group>
        );
    }
}

const rulerPoint = PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
}).isRequired;

Ruler.propTypes = {
    scale: PropTypes.number.isRequired,
    point1: rulerPoint,
    point2: rulerPoint,
    segments: PropTypes.number.isRequired,
    ticks: PropTypes.arrayOf(PropTypes.number),
    offset: rulerPoint,
    hidden: PropTypes.bool,
    selected: PropTypes.bool.isRequired,
    cursorChange: PropTypes.func.isRequired,
    updatePosition: PropTypes.func.isRequired,
    selectRuler: PropTypes.func.isRequired,
    drawing: PropTypes.bool,
    color: PropTypes.string,
};
