import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from '@cimpress/react-components';

import { RECTANGLE_TRANSFORM, PERSPECTIVE_TRANSFORM, SMOOTH_TRANSFORM } from 'src/models/transforms';

export default class TrimButton extends PureComponent {
    render() {
        const { id, surfaceData, transform, trimCallback } = this.props;
        const isEnable = !!surfaceData && !!surfaceData.trimArea && Object.keys(surfaceData.trimArea).length > 0;
        if (!surfaceData || !isEnable || !transform) {
            return null;
        }
        const onApplyTrim = () => {
            const trim = surfaceData.trimArea;
            const bleed = surfaceData.fullBleedArea;

            // Compute the trim values
            const left = (trim.x - bleed.x) / bleed.width;
            const top = (trim.y - bleed.y) / bleed.height;
            const right = 1.0 - (bleed.x + bleed.width - (trim.x + trim.width)) / bleed.width;
            const bottom = 1.0 - (bleed.y + bleed.height - (trim.y + trim.height)) / bleed.height;

            // Extract the attributes from the transform.
            const { points, ...data } = transform;

            // Create a new object keeping all the old attributes except for sourceX and sourceY
            const newTransform = { ...data, points: points.map((point) => ({ x: point.x, y: point.y })) };
            // Update the points based on the warp type.
            switch (transform.type) {
                case RECTANGLE_TRANSFORM:
                    Object.assign(newTransform.points[0], {
                        sourceX: Math.max(left, points[0].sourceX),
                        sourceY: Math.max(top, points[0].sourceY),
                    });
                    Object.assign(newTransform.points[1], {
                        sourceX: Math.min(right, points[1].sourceX),
                        sourceY: Math.min(bottom, points[1].sourceY),
                    });
                    break;
                case SMOOTH_TRANSFORM:
                    if (points[0].sourceX !== 0 && points[0].sourceY !== 0) {
                        for (let i = 0; i < points.length; i++) {
                            Object.assign(newTransform.points[i], {
                                sourceX: points[i].sourceX,
                                sourceY: points[i].sourceY,
                            });
                        }
                    } else {
                        for (let i = 0; i < points.length; i++) {
                            const updatedSourceX = left + points[i].sourceX * (right - left);
                            const updatedSourceY = top + points[i].sourceY * (bottom - top);

                            Object.assign(newTransform.points[i], { sourceX: updatedSourceX, sourceY: updatedSourceY });
                        }
                    }

                    break;
                case PERSPECTIVE_TRANSFORM:
                default:
                    Object.assign(newTransform.points[0], {
                        sourceX: Math.max(left, points[0].sourceX),
                        sourceY: Math.max(top, points[0].sourceY),
                    });
                    Object.assign(newTransform.points[1], {
                        sourceX: Math.min(right, points[1].sourceX),
                        sourceY: Math.max(top, points[1].sourceY),
                    });
                    Object.assign(newTransform.points[2], {
                        sourceX: Math.min(right, points[2].sourceX),
                        sourceY: Math.min(bottom, points[2].sourceY),
                    });
                    Object.assign(newTransform.points[3], {
                        sourceX: Math.max(left, points[3].sourceX),
                        sourceY: Math.min(bottom, points[3].sourceY),
                    });
            }

            trimCallback({ id, transforms: [newTransform] });
        };

        return (
            <Button className='product-trim-button' onClick={onApplyTrim}>
                Apply Product Trim
            </Button>
        );
    }
}

TrimButton.propTypes = {
    id: PropTypes.string,
    surfaceData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    trimCallback: PropTypes.func,
    transform: PropTypes.shape({
        type: PropTypes.string,
        points: PropTypes.arrayOf(
            PropTypes.shape({
                sourceX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                sourceY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            }),
        ),
    }),
};
