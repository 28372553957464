import type { RootState } from 'src/store';
import { connect } from 'react-redux';
import { isBulkMode } from 'src/selectors';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { isAddRulerEnabled } from 'src/selectors/layers';
import { getSceneName } from 'src/selectors/pages/editor';
import getUndoEvents from 'src/selectors/layers/getUndoEvents';
import getCanUndo from 'src/selectors/layers/getCanUndo';
import getCanRedo from 'src/selectors/layers/getCanRedo';
import getRedoEvents from 'src/selectors/layers/getRedoEvents';
import { toggleAddMode, zoomCanvas, centerCanvas } from '../slice';
import header from './header';

const mapStateToProps = (state: RootState) => ({
    name: isBulkMode(state) ? 'Bulk Mode' : getSceneName(state),
    addModeEnabled: isAddRulerEnabled(state),
    canUndo: getCanUndo(state),
    canRedo: getCanRedo(state),
    undoEvents: getUndoEvents(state),
    redoEvents: getRedoEvents(state),
});

const headerContainer = connect(mapStateToProps, (dispatch) => ({
    zoomCanvas: (payload: TODO) => dispatch(zoomCanvas(payload)),
    centerCanvas: (payload: TODO) => dispatch(centerCanvas(payload)),
    toggleAddRulerMode: () => dispatch(toggleAddMode()),
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo()),
}))(header);

export default headerContainer;
