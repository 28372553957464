import * as React from 'react';

interface IPSDImportItemProps {
    id: string;
    url: string;
    name: string;
    dimension: string;
    onClick: (id: string) => void;
    selected: boolean;
}

const PSDImportItem: React.FunctionComponent<IPSDImportItemProps> = (props) => {
    const isSelected = props.selected ? 'selected' : '';
    return (
        <div className={`import-psd-modal__item ${isSelected}`} onClick={() => props.onClick(props.id)}>
            <div className='import-psd-modal__item-check'></div>
            <div className='import-psd-modal__item-image'>
                <img src={props.url} alt={props.name} />
            </div>
            <div className='import-psd-modal__item-description'>
                <span>{props.name}</span>
                <span>{props.dimension}</span>
            </div>
        </div>
    );
};

export default PSDImportItem;
