import { createSelector } from 'reselect';
import { RootState } from 'src/store';

const getSceneSku = (state: RootState) =>
    state.pages.editor.surfaces.skuVersion
        ? `${state.pages.editor.surfaces.sku}-${state.pages.editor.surfaces.skuVersion}`
        : state.pages.editor.surfaces.sku;

const getSku = createSelector([getSceneSku], (sku) => sku);

export default getSku;
