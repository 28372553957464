import createCachedSelector from 're-reselect';

import getAllRulers from './getAllRulers';
import { RootState } from 'src/store';

const getId = (state: RootState, id: string) => id;

const getRulerById = createCachedSelector(
    [getAllRulers, getId],
    (rulers, id) => rulers.find((ruler) => ruler.id === id) || {},
)((state, rulerId) => rulerId);

export default getRulerById;
