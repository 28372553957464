import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { updateCylinder } from 'src/pages/editor/components/documents/slice';
import { getCylinderWarpById, getDocumentById } from 'src/selectors/layers';
import { DOCUMENT } from 'src/pages/editor/components/layer/layerTypes';
import { selectLayer } from 'src/pages/editor/components/layer/slice';
import CylinderWarp from './cylinderWarp';

const mapStateToProps = (state: RootState, ownProps: { documentId: string }) => ({
    cylinderWarp: getCylinderWarpById(state, ownProps.documentId),
    hidden: getDocumentById(state, ownProps.documentId).hidden,
});

const cylinderWarpContainer = connect(mapStateToProps, {
    updateCylinder,
    selectLayer: (documentId: string) => selectLayer({ id: documentId, type: DOCUMENT }),
})(CylinderWarp);

export default cylinderWarpContainer;
