import { connect } from 'react-redux';
import idGeneratorAtor from 'src/util/idGeneratorAtor';
import { uploadBackgrounds } from './slice';
import AddBackground from './addBackground';
import type { AppDispatch } from 'src/store';
import { setAIModalOpen } from 'src/slice';

const idGenerator = idGeneratorAtor('addBackground');

const mapStateToProps = () => ({
    className: 'add-layer',
    multiple: true,
    accept: 'image/png, image/jpeg, image/bmp, application/x-photoshop, application/octet-stream, image/vnd.adobe.photoshop',
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onUpload: (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files && files.length) {
            const newBackgrounds = Array.from(files).map((file) => ({ id: idGenerator(), file }));
            dispatch(uploadBackgrounds(newBackgrounds));
        }
    },
    onGenerate: () => dispatch(setAIModalOpen(true)),
});

const addBackgroundContainer = connect(mapStateToProps, mapDispatchToProps)(AddBackground);

export default addBackgroundContainer;
