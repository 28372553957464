import React, { PureComponent } from 'react';
import { Select } from '@cimpress/react-components';

import { orientationValues } from './orientations';

interface OrientationProps {
    activeOrientation?: string;
    id?: string;
    onSelect: (data: { id?: string; orientation: string }) => void;
}

export default class Orientation extends PureComponent<OrientationProps> {
    render() {
        const { activeOrientation, id, onSelect } = this.props;
        if (!activeOrientation) {
            return null;
        }

        return (
            <div className='orientation'>
                <Select
                    label='Orientation'
                    className='orientation__select'
                    options={orientationValues}
                    value={{ value: activeOrientation, label: activeOrientation }}
                    onChange={(orientation: { value: string }) => onSelect({ id, orientation: orientation.value })}
                    isClearable={false}
                    isSearchable={false}
                />
            </div>
        );
    }
}
