import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import { getDocumentById } from 'src/selectors/layers';
import uploadAndDeleteButton from 'src/components/UploadAndDeleteButton';
import { uploadTexture } from '../../documents/slice';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const selectedDocument = getDocumentById(state, ownProps.id);
    // @ts-expect-error -- missing `textureUrl` in type
    const uploaded = !!selectedDocument.textureUrl;
    const { textureName: fileName, textureUploading: uploading } = selectedDocument;

    return {
        testid: 'editor-scene-upload-texture-input',
        fileName,
        uploaded,
        uploading,
        hidden: false,
        label: uploaded ? 'Texture Mask' : 'Upload Texture',
        className: 'texture__upload-button',
        accept: 'image/png, image/jpeg, image/bmp',
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: { id: string }) => ({
    onUpload: (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            dispatch(uploadTexture({ file, id: ownProps.id }));
        }
    },
});

const textureButtonContainer = connect(mapStateToProps, mapDispatchToProps)(uploadAndDeleteButton);

export default textureButtonContainer;
