import React, { PureComponent } from 'react';
import { Toggle } from '@cimpress/react-components';

const WHITE = '#ffffff';

interface ColorProps {
    color: string;
    id: string;
    toggleColor: (id: string) => void;
}

export default class Color extends PureComponent<ColorProps> {
    render() {
        const { color, toggleColor, id } = this.props;
        return (
            <div className='ruler-color'>
                Light mode
                <Toggle on={color === WHITE} onClick={() => toggleColor(id)} />
            </div>
        );
    }
}
