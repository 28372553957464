import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { toggleFullWrap } from 'src/pages/editor/components/documents/slice';
import { getCylinderWarpById } from 'src/selectors/layers';
import fullWrap from './fullWrap';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const cylinder = getCylinderWarpById(state, ownProps.id);
    return {
        fullWrapEnabled: cylinder ? cylinder.fullWrap : false,
    };
};

const fullWrapContainer = connect(mapStateToProps, { onToggle: toggleFullWrap })(fullWrap);

export default fullWrapContainer;
