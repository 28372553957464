import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import { RECTANGLE_WARP } from 'src/models/documentWarps';
import { aspectRatioConstrainedType } from 'src/selectors/canvas';
import { getDocumentById } from 'src/selectors/layers';
import { getSurfaces } from 'src/selectors/pages/editor';
import {
    CONSTRAINED_BLEED_TYPE,
    CONSTRAINED_TRIM_TYPE,
    setAspectRatioConstraint,
} from 'src/pages/editor/components/canvas/slice';
import Constraints from './constraints';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const { page, warpType: documentWarpType } = getDocumentById(state, ownProps.id);
    // const documentWarpType = getDocumentById(state, ownProps.id).warpType;
    const surfaces = getSurfaces(state);
    const activeSurfaceData = surfaces.find((surface) => surface.index === page);
    const noSurfaces = !surfaces.length;

    const options = [{ label: 'None', value: '' }];

    if (activeSurfaceData && activeSurfaceData.fullBleedArea && activeSurfaceData.fullBleedArea.width) {
        options.push({ label: 'Bleed Aspect Ratio', value: CONSTRAINED_BLEED_TYPE });
    }
    if (activeSurfaceData && activeSurfaceData.trimArea && activeSurfaceData.trimArea.width) {
        options.push({ label: 'Trim Aspect Ratio', value: CONSTRAINED_TRIM_TYPE });
    }

    // Hide the constraint option if the warp isn't rectangular or we don't have surface data
    const hide = documentWarpType !== RECTANGLE_WARP || noSurfaces;

    return {
        currentValue: options.find((i) => i.value === aspectRatioConstrainedType(state)),
        hide,
        options,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onToggleAspectRatioConstraint: (item: TODO) => {
        dispatch(setAspectRatioConstraint(item.value));
    },
});

const constraintsContainer = connect(mapStateToProps, mapDispatchToProps)(Constraints);

export default constraintsContainer;
