import { createSlice } from '@reduxjs/toolkit';

import {
    createSceneSuccess,
    publishSceneSuccess,
    saveDraftSuccess,
    saveAndPublishSceneSuccess,
} from 'src/pages/editor/components/scene/slice';

type ScenesSliceState = Array<{
    id: string;
    versions: TODO;
    xml: TODO;
    deleting: boolean;
    deleted: boolean;
    restoring: boolean;
}>;

const initialState: ScenesSliceState = [];

function saveSuccess(state: ScenesSliceState, { payload }: TODO) {
    const { sceneId, ...data } = payload;
    const matchingScene = state.find((scene) => scene.id === sceneId);
    if (matchingScene) {
        Object.assign(matchingScene, data);
    }
}

const slice = createSlice({
    name: 'scenes',
    initialState,

    reducers: {
        addScene: (state, { payload }) => {
            const { id } = payload;
            const matchingScene = state.find((scene) => scene.id === id);
            if (matchingScene) {
                Object.assign(matchingScene, payload);
            } else {
                state.unshift(payload);
            }
        },
        addVersions: (state, { payload }) => {
            const versionsByIds = Array.isArray(payload) ? payload : [payload];
            versionsByIds.forEach((versionsById) => {
                const { sceneId, versions } = versionsById;
                const matchingScene = state.find((scene) => scene.id === sceneId);
                if (matchingScene) {
                    matchingScene.versions = versions;
                }
            });
        },
        addSceneXml: (state, { payload }) => {
            const { id, xml } = payload;
            const matchingScene = state.find((scene) => scene.id === id);
            if (matchingScene) {
                matchingScene.xml = xml;
            }
        },
        sceneUpdatedToServer: (state, { payload }) => {
            const { id } = payload;
            const matchingScene = state.find((scene) => scene.id === id);
            if (matchingScene) {
                Object.assign(matchingScene, payload);
            }
        },
        deleteScene: (state, { payload }) => {
            const matchingScene = state.find((scene) => scene.id === payload);
            if (matchingScene) {
                matchingScene.deleting = true;
            }
        },
        deleteSceneSuccess: (state, { payload }) => {
            const matchingScene = state.find((scene) => scene.id === payload);
            if (matchingScene) {
                matchingScene.deleted = true;
                matchingScene.deleting = false;
            }
        },
        deleteSceneFailed: (state, { payload }) => {
            const matchingScene = state.find((scene) => scene.id === payload);
            if (matchingScene) {
                matchingScene.deleting = false;
            }
        },
        restoreScene: (state, { payload }) => {
            const matchingScene = state.find((scene) => scene.id === payload);
            if (matchingScene) {
                matchingScene.restoring = true;
            }
        },
        restoreSceneSuccess: (state, { payload }) => {
            const matchingScene = state.find((scene) => scene.id === payload);
            if (matchingScene) {
                matchingScene.deleted = false;
                matchingScene.restoring = false;
            }
        },
        restoreSceneFailed: (state, { payload }) => {
            const matchingScene = state.find((scene) => scene.id === payload);
            if (matchingScene) {
                matchingScene.restoring = false;
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase(createSceneSuccess, saveSuccess);
        builder.addCase(publishSceneSuccess, saveSuccess);
        builder.addCase(saveDraftSuccess, saveSuccess);
        builder.addCase(saveAndPublishSceneSuccess, saveSuccess);
    },
});

export const reducer = slice.reducer;

export const addScene = slice.actions.addScene;
export const addVersions = slice.actions.addVersions;
export const addSceneXml = slice.actions.addSceneXml;
export const sceneUpdatedToServer = slice.actions.sceneUpdatedToServer;
export const deleteScene = slice.actions.deleteScene;
export const deleteSceneSuccess = slice.actions.deleteSceneSuccess;
export const deleteSceneFailed = slice.actions.deleteSceneFailed;
export const restoreScene = slice.actions.restoreScene;
export const restoreSceneSuccess = slice.actions.restoreSceneSuccess;
export const restoreSceneFailed = slice.actions.restoreSceneFailed;
