import type { RootState } from 'src/store';
import { connect } from 'react-redux';
import { getGenerationMode, isCreateSceneModalOpen } from 'src/selectors';
import { changeMode, setAIModalOpen } from 'src/slice';
import generateScene from './generateScene';

const mapStateToProps = (state: RootState) => ({
    showModal: isCreateSceneModalOpen(state),
    mode: getGenerationMode(state),
});

const generateSceneContainer = connect(mapStateToProps, {
    changeMode,
    onAIGeneration: setAIModalOpen,
})(generateScene);

export default generateSceneContainer;
