import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { RootState } from 'src/store';

const getAlerts = (state: RootState) => state.alerts;
export const getAllAlerts = createSelector([getAlerts], (alerts) => alerts);

const getName = (state: RootState, name: string) => name;
export const getAllAlertsWithName = createCachedSelector([getAllAlerts, getName], (alerts, name) =>
    alerts.filter((alert) => alert.name === name),
)((state, name) => name);
