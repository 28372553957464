import { connect } from 'react-redux';

import {
    getCanvasImage,
    getErrorRequestCount,
    getLatestRequestCount,
    getSuccessRequestCount,
    getCanvasScale,
    getCanvasOffset,
} from 'src/selectors/canvas';
import { moveCanvas, zoomCanvas, centerCanvas } from './slice';
import { deselectLayer } from '../layer/slice';
import canvas from './canvas';
import getCroppingEnable from '../SceneVariation/selectors/getCroppingEnable';
import type { RootState } from 'src/store';

const mapStateToProps = (state: RootState) => ({
    errorRequestCount: getErrorRequestCount(state),
    successRequestCount: getSuccessRequestCount(state),
    latestRequestCount: getLatestRequestCount(state),
    image: getCanvasImage(state),
    scale: getCanvasScale(state),
    offset: getCanvasOffset(state),
    isCroppingEnable: getCroppingEnable(state),
});

const canvasContainer = connect(mapStateToProps, { moveCanvas, zoomCanvas, deselectLayer, centerCanvas })(canvas);

export default canvasContainer;
