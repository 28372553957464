import React, { useEffect } from 'react';

import NumberInput from 'src/components/NumberInput';

type MapPoint = {
    x: number;
    y: number;
    sourceX: number;
    sourceY: number;
};

interface MapPointsProps {
    id?: string;
    transform?: {
        points: MapPoint[];
        id: string;
    };
    onSubmit: (data: { id?: string; transformId?: string; index: number; point: { [key: string]: number } }) => void;
    onSelectPoint: (data: { point: number; id?: string }) => void;
    selectedPoint?: number;
}

export default function MapPoints({ id, transform, onSubmit, onSelectPoint, selectedPoint }: MapPointsProps) {
    useEffect(() => {
        if (!selectedPoint) {
            onSelectPoint({ point: 1, id });
        }
    }, []);

    function submitChange(key: string, value: number, index: number) {
        onSubmit({ id, transformId: transform?.id, index, point: { [key]: value } });
    }

    function buildTab(point: MapPoint, index: number) {
        return {
            name: `Grip ${index + 1}`,
            block: (
                <div className='design-area-grips'>
                    <NumberInput
                        label='X (Screen)'
                        value={point.x}
                        onSubmit={(value) => submitChange('x', value, index)}
                    />
                    <NumberInput
                        label='Y (Screen)'
                        value={point.y}
                        onSubmit={(value) => submitChange('y', value, index)}
                    />
                    <NumberInput
                        label='X (Source)'
                        value={point.sourceX}
                        onSubmit={(value) => submitChange('sourceX', value, index)}
                    />
                    <NumberInput
                        label='Y (Source)'
                        value={point.sourceY}
                        onSubmit={(value) => submitChange('sourceY', value, index)}
                    />
                </div>
            ),
            href: '#',
        };
    }

    const tabs = transform?.points.map(buildTab);

    function renderGrip() {
        let gripBlock = <></>;

        if (tabs?.[selectedPoint! - 1]) {
            gripBlock = tabs[selectedPoint! - 1].block;
        }

        return (
            <div>
                <div className='design-area-position_grips_button-groups'>
                    {tabs?.map((tab, i) => (
                        <button
                            key={tab.name}
                            className={`${selectedPoint! - 1 === i ? 'design-area-position_grips_selected' : 'design-area-position_grips_buttons'}`}
                            onClick={() => {
                                onSelectPoint({ point: i + 1, id });
                                gripBlock = tab.block;
                            }}>
                            {tab.name}
                        </button>
                    ))}
                </div>
                {gripBlock}
            </div>
        );
    }

    return (
        <div className='design-area-position'>
            <div className='design-area-position__title'>Position your design area</div>
            {renderGrip()}
        </div>
    );
}
