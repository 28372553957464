import React, { PureComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Alert, { AlertProps } from './alert';

interface AlertListProps {
    alerts: Array<Omit<AlertProps, 'index'> & { id: number }>;
    onDismiss: (id: number) => void;
}

export default class AlertList extends PureComponent<AlertListProps> {
    render() {
        const { alerts, onDismiss } = this.props;
        return (
            <div className='alert-container'>
                <TransitionGroup>
                    {alerts.map((alert, index) => (
                        <CSSTransition key={alert.id} classNames='alert' timeout={500}>
                            <Alert onDismiss={() => onDismiss(alert.id)} index={index} {...alert} />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
        );
    }
}
