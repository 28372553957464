import * as React from 'react';
import { PureComponent } from 'react';
import { Tooltip, Select } from '@cimpress/react-components';

import { DOCUMENT } from 'src/pages/editor/components/layer/layerTypes';
import { documentModes, overlayModes } from 'src/models/blendingModes';

interface BlendingProps {
    activeMode?: string;
    id: string;
    type: string;
    onSelect: (id: string, type: string, mode: string) => void;
    isEngraving: boolean;
}

export default class Blending extends PureComponent<BlendingProps> {
    render() {
        const { activeMode, id, type, onSelect, isEngraving } = this.props;
        if (!activeMode) {
            return null;
        }

        const blendingModes = type === DOCUMENT ? documentModes : overlayModes;
        const tip =
            type === DOCUMENT
                ? `Normal is generally used for embroidery or single color. Multiply is generally used for any printed paper products.
                The multiply effect will remove white elements from the customer's design.`
                : 'Screen is an effect that shows highlights. Use overlay only if you know what you are doing.';

        const onSelectFunc = (mode: { value: string }) => onSelect(id, type, mode.value);

        return (
            <div className='blending'>
                {!isEngraving && (
                    <Tooltip
                        containerClassName='blending__tooltip-container'
                        className='blending__tooltip'
                        direction='right'
                        contents={tip}>
                        <Select
                            label='Blending Mode'
                            className='blending__select'
                            options={blendingModes}
                            value={{ value: activeMode, label: activeMode }}
                            onChange={onSelectFunc}
                            isClearable={false}
                            isSearchable={false}
                        />
                    </Tooltip>
                )}
            </div>
        );
    }
}
