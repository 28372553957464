import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getDocumentById, getOverlayById } from 'src/selectors/layers';
import { changeMode } from 'src/store/actions/layers';
import { DOCUMENT, BACKGROUND, OVERLAY, LayerType } from 'src/pages/editor/components/layer/layerTypes';
import blending from './blending';

function getLayerFromStore(state: RootState, id: string, type: LayerType) {
    switch (type) {
        case OVERLAY:
            return getOverlayById(state, id);
        case DOCUMENT:
        default:
            return getDocumentById(state, id);
    }
}

const mapStateToProps = (state: RootState, ownProps: { id: string; type: LayerType }) => {
    const { id, type } = ownProps;
    let activeMode;
    let isEngraving;
    if (id && type && type !== BACKGROUND) {
        const layer = getLayerFromStore(state, id, type);
        if (layer) {
            activeMode = layer.mode;
            // @ts-expect-error -- `isEngraving` doesn't exist on `Layer`
            isEngraving = layer.isEngraving;
        }
    }

    return {
        activeMode,
        isEngraving,
    };
};

const blendingContainer = connect(mapStateToProps, {
    onSelect: changeMode,
})(blending);

export default blendingContainer;
