import { createAction } from '@reduxjs/toolkit';
import { BlendingMode } from 'src/models/blendingModes';
import { LayerType } from 'src/pages/editor/components/layer/layerTypes';

export const RELOAD = 'reload';

// layers
export const LAYER_TOGGLE_HIDE = 'layer:toggleHide';
export const BLENDING_MODE_CHANGE = 'layer:changeMode';

// Documents Undo / Redo
export const DOCUMENTS_UNDO = '@@redux-undo/UNDO';
export const DOCUMENTS_REDO = '@@redux-undo/REDO';

export const ALL_DOCUMENTS = '@@all-documents@@';

export const layerToggleHide = createAction<{ id: string; type: LayerType }>(LAYER_TOGGLE_HIDE);
export const blendingModeChange = createAction<{ id: string; mode: BlendingMode; type: LayerType }>(
    BLENDING_MODE_CHANGE,
);
