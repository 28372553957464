import { createSelector } from 'reselect';
import { RootState } from 'src/store';

const getCanvas = (state: RootState) => state.pages.editor.canvas;

const getCanvasImage = createSelector([getCanvas], (canvas) => ({
    url: canvas.url,
    width: canvas.width,
    height: canvas.height,
}));

export default getCanvasImage;
