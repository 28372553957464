import React, { PureComponent } from 'react';

interface FileUploadProps {
    id?: string;
    testid?: string;
    name?: string;
    className?: string;
    accept?: string;
    disabled?: boolean;
    multiple?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onClick?: React.InputHTMLAttributes<HTMLInputElement>['onClick'];
}

export default class FileUpload extends PureComponent<FileUploadProps> {
    upload = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(e);
        // Clear the input after so it can be uploaded again (if they so choose);
        e.target.value = '';
    };

    render() {
        const {
            id,
            testid,
            name,
            className,
            disabled,
            onClick,
            accept = 'image/png, image/jpeg, image/bmp',
            multiple = false,
        } = this.props;
        return (
            <input
                id={id}
                data-testid={testid}
                name={name}
                className={`hidden-input ${className}`}
                type='file'
                onChange={this.upload}
                onClick={onClick}
                disabled={disabled}
                accept={accept}
                multiple={multiple}
            />
        );
    }
}
