/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelablePromise } from 'src/types/upload';
import { makePublicServiceRequest } from './servicesClient';
import auth from 'src/auth';

const TENANT = 'default';

const uploadsDomains: Record<string, string> = {
    'us-east': 'https://cluster-110.uploads.documents.cimpress.io',
    'eu-west': 'https://cluster-100.uploads.documents.cimpress.io',
    'ap-northeast': 'https://cluster-120.uploads.documents.cimpress.io',
    china: 'https://uploads.yinshida.com.cn',
    auto: 'https://uploads.documents.cimpress.io',
};

function getDomain(region = 'auto') {
    return uploadsDomains[region] || uploadsDomains.auto;
}

export function generateUploadsUrl(region = 'auto', uploadId = '', resizeParams?: Record<string, any>) {
    let url = `${getDomain(region)}/v1/uploads/${uploadId}?tenant=${TENANT}`;
    if (resizeParams) {
        url += `&transform=${encodeURIComponent(
            `[{"transform":"scaleDown","arguments":{x:${resizeParams.width},y:${resizeParams.height}}}]`,
        )}`;
    }
    return url;
}

function uploadScene(xml: string, region = 'auto') {
    const sceneXml = `<?xml version="1.0" encoding="utf-16"?>${xml}`;
    const formData = new FormData();
    const blob = new Blob([sceneXml], { type: 'text/xml' });
    formData.append('file', blob, 'index.xml');

    return makePublicServiceRequest({
        method: 'post',
        url: generateUploadsUrl(region),
        data: formData,
        header: {
            // Reset default `Content-Type` header to `undefined` to allow Axios to set the correct `Content-Type` header
            'Content-Type': undefined,
        },
    });
}

function uploadJSON(json: Record<string, any>, region = uploadsDomains.auto) {
    const formData = new FormData();
    const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
    formData.append('file', blob, 'document.json');

    return makePublicServiceRequest({
        method: 'post',
        url: generateUploadsUrl(region),
        data: formData,
        header: {
            // Reset default `Content-Type` header to `undefined` to allow Axios to set the correct `Content-Type` header
            'Content-Type': undefined,
        },
    });
}

// Returns the upload id
export function uploadSceneXml(xml: string, region = 'auto'): CancelablePromise<string> {
    const uploadPromise = uploadScene(xml, region);
    const { cancel } = uploadPromise;

    const processResponse: CancelablePromise<string> = uploadPromise.then(
        (uploadResponse) => uploadResponse.data[0].uploadId as string,
    );

    processResponse.cancel = cancel;

    return processResponse;
}

// Returns the upload id
export function uploadJSONFile(json: Record<string, any>) {
    const uploadPromise = uploadJSON(json);
    const { cancel } = uploadPromise;

    const processResponse: CancelablePromise<string> = uploadPromise.then(
        (uploadResponse) => uploadResponse.data[0].uploadId,
    );

    processResponse.cancel = cancel;

    return processResponse;
}

export function uploadImage(region = 'auto', file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return makePublicServiceRequest({
        method: 'post',
        url: generateUploadsUrl(region),
        data: formData,
        header: {
            // Reset default `Content-Type` header to `undefined` to allow Axios to set the correct `Content-Type` header
            'Content-Type': undefined,
        },
    });
}

export function getUrlById(uploadId: string) {
    return `${uploadsDomains.auto}/v1/uploads/${uploadId}`;
}

interface UploadImageByUrlInput {
    url: string;
    signal?: AbortSignal;
}

interface UploadImageByUrlSuccess {
    success: true;
    data: Array<{
        uploadId: string;
    }>;
}

interface UploadImageByUrlError {
    success: false;
}

type UploadImageByUrlResult = UploadImageByUrlSuccess | UploadImageByUrlError;

export async function uploadImageByUrl({ url, signal }: UploadImageByUrlInput): Promise<UploadImageByUrlResult> {
    const response = await fetch(`https://uploads.documents.cimpress.io/v1/uploads?tenant=default&url=${url}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${auth.getAccessToken()}` },
        signal,
    });

    if (!response.ok) {
        return {
            success: false,
        };
    }

    try {
        const data = await response.json();
        return {
            success: true,
            data,
        };
    } catch (error) {
        return {
            success: false,
        };
    }
}
