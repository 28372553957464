import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getSceneName, getSceneDescription } from 'src/selectors/pages/editor';
import { getAllBackgrounds } from 'src/selectors/layers';
import { updateName, updateDescription } from 'src/pages/editor/components/scene/slice';
import { isBulkMode } from 'src/selectors';
import properties from './properties';

const mapStateToProps = (state: RootState) => {
    const backgrounds = getAllBackgrounds(state);
    return {
        name: getSceneName(state),
        isBulkMode: isBulkMode(state),
        description: getSceneDescription(state),
        noBackgrounds: backgrounds.length < 1,
    };
};

const propertiesContainer = connect(mapStateToProps, {
    onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => updateName(e.target.value || ''),
    onChangeDescription: (e: React.ChangeEvent<HTMLInputElement>) => updateDescription(e.target.value || ''),
})(properties);

export default propertiesContainer;
