import React, { PureComponent } from 'react';
import { Checkbox } from '@cimpress/react-components';

interface AutomaskProps {
    id?: string;
    active: boolean;
    enable: boolean;
    toggle: (id: string) => void;
}

export default class Automask extends PureComponent<AutomaskProps> {
    handleToggle = () => {
        this.props.toggle(this.props.id!);
    };

    render() {
        const { active, enable } = this.props;

        if (!enable) return <></>;

        return (
            <div className='automask'>
                <Checkbox
                    type='secondary'
                    style={{ marginBottom: '24px' }}
                    checked={active}
                    onClick={this.handleToggle}
                    label='Use Auto Mask'
                />
            </div>
        );
    }
}
