import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import UnsavedChangesAlert from 'src/components/UnsavedChangesAlert';
import ResizeModal from './components/backgrounds/resizeModal';
import BackgroundRemovalContainer from './components/backgrounds/backgroundRemovalModal';
import Canvas from './components/canvas';
import Footer from './components/footer';
import GenerateScene from './components/generateScene';

import Panels from './components/panels';
import ShortcutsContainer from './components/shortcuts';
import ImportPSDModal from 'src/import/psd';
import AIGenerationContainer from './components/aiGeneration';

interface MatchParams {
    tenantId?: string;
    tenantType?: string;
    id?: string;
    versionId?: string;
}

interface AppProps extends RouteComponentProps<MatchParams> {
    loading?: boolean;
    isPSDOpen?: boolean;
    upToDate?: boolean;
    loadEditorPage: (params: { tenantType?: string; tenantId?: string; sceneId?: string; versionId?: string }) => void;
    setTenant: (tenant: { type: string; id: string }) => void;
}

export default class App extends PureComponent<AppProps> {
    componentDidMount() {
        const { loadEditorPage, match } = this.props;
        const { tenantType, tenantId, id, versionId } = match.params;
        if (tenantType && tenantId) {
            this.props.setTenant({
                type: tenantType,
                id: tenantId,
            });
        }
        loadEditorPage({ tenantType, tenantId, sceneId: id, versionId });
    }

    render() {
        const { isPSDOpen } = this.props;
        return (
            <div>
                <UnsavedChangesAlert />
                <ShortcutsContainer />
                {isPSDOpen && <ImportPSDModal />}
                <GenerateScene />
                <AIGenerationContainer />
                <ResizeModal />
                <BackgroundRemovalContainer />
                <div className='editor-container'>
                    <div className='split-view'>
                        <Panels />
                        <Canvas />
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
