import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getDocumentById } from 'src/selectors/layers';
import warp from './warp';

const mapStateToProps = (state: RootState, ownProps: { documentId: string }) => ({
    warpType: getDocumentById(state, ownProps.documentId).warpType,
});

const warpContainer = connect(mapStateToProps, {})(warp);

export default warpContainer;
