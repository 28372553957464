import React from 'react';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    OnDragEndResponder,
} from 'react-beautiful-dnd';

const DEFAULT_STYLES = {
    backgroundColor: 'transparent',
    itemBackgroundColor: 'transparent',
    backgroundColorDragging: 'rgb(222, 237, 242)',
    itemBackgroundColorDragging: '#c6dde3',
    itemBorderStyle: 'none',
    itemBorderStyleDragging: '1px solid #0088a9',
};

const getListStyle = (isDraggingOver: boolean, styles: Record<string, string>) => ({
    background: isDraggingOver ? styles.backgroundColorDragging : styles.backgroundColor,
});

const getItemStyle = (isDragging: boolean, styles: Record<string, string>, draggableStyle?: Record<string, any>) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none' as const,
    // change background colour if dragging
    background: isDragging ? styles.itemBackgroundColorDragging : styles.itemBackgroundColor,
    border: isDragging ? styles.itemBorderStyleDragging : styles.itemBorderStyle,

    // styles we need to apply on draggables
    ...draggableStyle,
});

interface DraggableListProps {
    items: React.ReactElement[];
    onDragEnd: OnDragEndResponder;
    id: string;
    styles?: {
        backgroundColor?: string;
        itemBackgroundColor?: string;
        backgroundColorDragging?: string;
        itemBackgroundColorDragging?: string;
        itemBorderStyle?: string;
        itemBorderStyleDragging?: string;
    };
}

export default function DraggableList({ items, id, onDragEnd, styles }: DraggableListProps) {
    const listStyles = Object.assign({}, DEFAULT_STYLES, styles);
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={id}>
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        data-rbd-dragging={snapshot.isDraggingOver}
                        style={getListStyle(snapshot.isDraggingOver, listStyles)}>
                        {items.map((item, index) => (
                            <Draggable key={item.key} draggableId={item.key!} index={index}>
                                {(prov, snap) => (
                                    <div
                                        ref={prov.innerRef}
                                        {...prov.draggableProps}
                                        {...prov.dragHandleProps}
                                        style={getItemStyle(snap.isDragging, listStyles, prov.draggableProps.style)}>
                                        {item}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

DraggableList.defaultProps = {
    onDragEnd: () => {},
};
