import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Spinner } from '@cimpress/react-components';
import { hideBackgroundRemovalModal, updateBackgroundRemovalModal } from './slice';
import { backgroundRemove } from 'src/services/imageMindClient';
import { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';
import { getSelectedBackgroundRemoval } from './selectors';
import { getBackgroundById } from 'src/selectors/layers';

// TODO: Change this to Redux/Saga way of working
type BackgroundRemovalTypes = {
    show: boolean;
    imageUrl: string;
    hide: () => void;
    onReplace: (newUrl: string) => void;
};

export function BackgroundRemovalModal({ hide, show, imageUrl, onReplace }: BackgroundRemovalTypes) {
    return (
        <Modal
            show={show}
            title='Background Removal'
            closeButton={true}
            onRequestHide={hide}
            footer={<Button onClick={hide}>Cancel</Button>}
            size='lg'>
            {show && <BackgroundRemovalModalContent imageUrl={imageUrl} hide={hide} onReplace={onReplace} />}
        </Modal>
    );
}

function BackgroundRemovalModalContent({ imageUrl, hide, onReplace }: Omit<BackgroundRemovalTypes, 'show'>) {
    const [backgroundRemovedUrl, setBackgroundRemovedUrl] = useState<string>();
    const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading');
    const [errorMsg, setErrorMsg] = useState<string>('');
    const isBusy = useRef(false);

    useEffect(() => {
        if (!imageUrl) return;
        if (isBusy.current) return;

        isBusy.current = true;

        const abortController = new AbortController();

        setStatus('loading');

        backgroundRemove({ url: imageUrl, signal: abortController.signal })
            .then((result) => {
                if (!result.success) {
                    setStatus('error');
                    setErrorMsg(result.errorMessage);
                    return;
                }

                setStatus('success');
                setBackgroundRemovedUrl(result.data.output.url);
            })
            .catch((error) => {
                setStatus('error');
                setErrorMsg(error.message);
                console.error(error);
            })
            .finally(() => {
                isBusy.current = false;
            });

        return () => {
            abortController.abort();
        };
    }, [imageUrl]);

    if (!imageUrl || status === 'error') {
        return (
            <div>
                <p>
                    An error occurred and we could not process your image. The reason is <i>{errorMsg}</i>
                </p>
            </div>
        );
    }

    return (
        <div className='background-removal'>
            {status === 'loading' && (
                <div className='background-removal-loading-container'>
                    <Spinner />
                </div>
            )}
            {status === 'success' && (
                <div className='background-removal-container'>
                    <div>
                        <h2>Original</h2>
                        <img src={imageUrl} className='background-removal-image' />
                        <Button onClick={hide} className='background-removal-button'>
                            Continue with original
                        </Button>
                    </div>
                    <div>
                        <h2>Background Removed</h2>
                        <img src={backgroundRemovedUrl} className='background-removal-image' />
                        {backgroundRemovedUrl && (
                            <Button
                                onClick={() => onReplace(backgroundRemovedUrl)}
                                className='background-removal-button'>
                                Continue with background removed
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state: RootState) => {
    const backgroundId = getSelectedBackgroundRemoval(state);
    const background = getBackgroundById(state, backgroundId || '0');
    const show = backgroundId != null;

    return {
        show,
        imageUrl: background?.url || '',
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    hide: () => dispatch(hideBackgroundRemovalModal()),
    onReplace: (backgroundRemovedUrl: string) => dispatch(updateBackgroundRemovalModal(backgroundRemovedUrl)),
});

const BackgroundRemovalContainer = connect(mapStateToProps, mapDispatchToProps)(BackgroundRemovalModal);

export default BackgroundRemovalContainer;
