import { connect } from 'react-redux';

import { getTags } from 'src/selectors/pages/editor';
import { addTag, removeTag } from 'src/pages/editor/components/scene/slice';
import getMerchantSettings from 'src/selectors/getSettings';
import sceneTags from './sceneTags';
import { RootState } from 'src/store';

const mapStateToProps = (state: RootState) => {
    const userSettings = getMerchantSettings(state);
    const tags = userSettings.tags.default || [];
    return {
        tags: getTags(state),
        predefinedTags: tags,
    };
};

const sceneTagsContainer = connect(mapStateToProps, {
    addTag,
    removeTag,
})(sceneTags);

export default sceneTagsContainer;
