import { PointType } from 'src/types/index';

export function mod(a: number, n: number) {
    return ((a % n) + n) % n;
}

/* Collision detection */
// x,y is the point to test
// cx, cy is circle center, and radius is circle radius
export function pointInCircle(x: number, y: number, cx: number, cy: number, radius: number) {
    const distancesquared = (x - cx) * (x - cx) + (y - cy) * (y - cy);
    return distancesquared <= radius * radius;
}

export function toRadians(degrees: number) {
    return degrees * (Math.PI / 180);
}

export function toDegrees(radians: number) {
    return radians * (180 / Math.PI);
}

// VECTOR MATH UTILITY
export function subtract(v1: PointType, v2: PointType) {
    return {
        x: v1.x - v2.x,
        y: v1.y - v2.y,
    };
}

export function divide(vector: PointType, n: number) {
    return {
        x: vector.x / n,
        y: vector.y / n,
    };
}

export function magnitude(vector: PointType) {
    return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
}

export function distance(p1: PointType, p2: PointType) {
    const deltaX = p1.x - p2.x;
    const deltaY = p1.y - p2.y;

    return Math.sqrt(deltaX ** 2 + deltaY ** 2);
}

// + <- mouse position
// |
// |_
// |*|______+  <- current position
//
//  we are calculating this (*) angle

// Use law of cosines to calculate angle
// Returns radians
export function angle(p1: PointType, p2: PointType, p3: PointType) {
    const p12 = distance(p1, p2);
    const p13 = distance(p1, p3);
    const p23 = distance(p2, p3);

    let inner = (p12 ** 2 + p13 ** 2 - p23 ** 2) / (2 * p12 * p13);
    // we can end up with floating point errors when
    // calculating the length. This can create a value
    // greater than 1 (e.g. 1.0000001) or less
    // than -1 (e.g. -1.000001) which is undefined for acos.
    if (inner > 1) {
        inner = 1;
    } else if (inner < -1) {
        inner = -1;
    }

    return Math.acos(inner);
}

// Cylinder Warp utility
export function calculatePosition(
    ellipse: { center: { x: number; y: number }; radiusX: number; radiusY: number; rotationDegrees: number },
    degrees: number,
) {
    let x = ellipse.center.x + ellipse.radiusX * Math.cos(toRadians(degrees));
    let y = ellipse.center.y + ellipse.radiusY * Math.sin(toRadians(degrees));

    // rotate the frame
    if (ellipse.rotationDegrees !== 0) {
        const radians = toRadians(ellipse.rotationDegrees);
        // translate to origin
        const x1 = x - ellipse.center.x;
        const y1 = y - ellipse.center.y;

        // rotate point
        const x2 = x1 * Math.cos(radians) - y1 * Math.sin(radians);
        const y2 = x1 * Math.sin(radians) + y1 * Math.cos(radians);

        // translate back
        x = x2 + ellipse.center.x;
        y = y2 + ellipse.center.y;
    }

    return {
        x,
        y,
    };
}

// returns x and y position of top left corner and width and height of rectangle
export function getBoundingRectangle(points: PointType[]) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    points.forEach((point) => {
        if (point.x < minX) {
            minX = point.x;
        }
        if (point.x > maxX) {
            maxX = point.x;
        }
        if (point.y < minY) {
            minY = point.y;
        }
        if (point.y > maxY) {
            maxY = point.y;
        }
    });
    return {
        left: minX,
        top: minY,
        width: maxX - minX,
        height: maxY - minY,
    };
}

// returns x and y position of top left corner and right bottom corner
export function getBoundingRectanglePosition(points: PointType[]) {
    const { left, top, width, height } = getBoundingRectangle(points);
    return {
        left,
        top,
        right: left + width,
        bottom: top + height,
    };
}

export function calculateUnrotatedWidthAndHeight(points: PointType[]) {
    const scaleFactor = 1.2;

    let corners;
    if (points.length > 4) {
        const { left, top, right, bottom } = getBoundingRectanglePosition(
            points.map((point) => ({ x: point.x, y: point.y })),
        );
        corners = [
            { x: left, y: top },
            { x: right, y: top },
            { x: right, y: bottom },
            { x: left, y: bottom },
        ];
    } else if (points.length > 2) {
        corners = points;
    } else {
        corners = [points[0], { x: points[1].x, y: points[0].y }, points[1], { x: points[0].x, y: points[1].y }];
    }

    const width1 = distance(corners[0], corners[1]);
    const width2 = distance(corners[2], corners[3]);
    const width = Math.max(width1, width2);

    const height1 = distance(corners[1], corners[2]);
    const height2 = distance(corners[3], corners[0]);
    const height = Math.max(height1, height2);

    return { width: width * scaleFactor, height: height * scaleFactor };
}

// This will return an array of points that are equidistant along the presented line
export function getPointsOnLine(point1: PointType, point2: PointType, numberOfPoints: number) {
    const pointsArray = [];
    for (let i = 1; i < numberOfPoints + 1; i++) {
        const x = point1.x + (point2.x - point1.x) * (i / (numberOfPoints + 1));
        const y = point1.y + (point2.y - point1.y) * (i / (numberOfPoints + 1));
        pointsArray.push({ x, y });
    }
    return pointsArray;
}

export function getNormalSlope(p1: PointType, p2: PointType) {
    if (p1.y - p2.y === 0) {
        return Infinity;
    }
    return -(p1.x - p2.x) / (p1.y - p2.y);
}

// This will generate a "line" (returning two points) in which "point" is the center, slope denotes the angle,
// and dist * 2 is the length of the new line.
// https://math.stackexchange.com/questions/175896/finding-a-point-along-a-line-a-certain-distance-away-from-another-point
export function generateLineFromSlopeAndPoint(point: PointType, slope: number, dist: number) {
    const x1 = point.x + dist / Math.sqrt(1 + slope ** 2);
    const x2 = point.x - dist / Math.sqrt(1 + slope ** 2);
    let y1 = point.y - dist;
    let y2 = point.y + dist;
    if (slope < 9999 && slope > -9999) {
        const b = point.y - slope * point.x;
        y1 = slope * x1 + b;
        y2 = slope * x2 + b;
    }
    return [
        { x: x1, y: y1 },
        { x: x2, y: y2 },
    ];
}

// This will return a point a given percentage distance from point 1 towards point 2
export function getPointOnLine(point1: PointType, point2: PointType, percentage: number) {
    const x = point1.x + (point2.x - point1.x) * percentage;
    const y = point1.y + (point2.y - point1.y) * percentage;
    return { x, y };
}

export function getAngleFromDiameterAndWidth(diameter: number, width: number) {
    return (width / (Math.PI * diameter)) * 360;
}

export function getWidthFromDiameterAndAngle(diameter: number, ang: number) {
    return (ang / 360) * Math.PI * diameter;
}

export function midpoint(point1: PointType, point2: PointType) {
    const midPointX = (point1.x + point2.x) / 2;
    const midPointY = (point1.y + point2.y) / 2;
    return {
        x: midPointX,
        y: midPointY,
    };
}

// translate points
export function translatePoints(points: PointType[], dx: number, dy: number) {
    return points.map((point) => {
        const translatedPoint = { ...point };
        translatedPoint.x = point.x + dx;
        translatedPoint.y = point.y + dy;

        return translatedPoint;
    });
}

// rotate points around the origin (0,0)
export function rotatePoints(points: PointType[], radians: number) {
    const cosine = Math.cos(radians);
    const sin = Math.sin(radians);

    return points.map((point) => {
        const translatedPoint = { ...point };
        translatedPoint.x = point.x * cosine - point.y * sin;
        translatedPoint.y = point.x * sin + point.y * cosine;

        return translatedPoint;
    });
}

export function rotatePointsAboutPoint(
    points: PointType[],
    radian: number,
    rotationPointX: number,
    rotationPointY: number,
) {
    let newPoints = translatePoints(points, -rotationPointX, -rotationPointY); // translate points to origin
    newPoints = rotatePoints(newPoints, radian); // rotate points about origin
    newPoints = translatePoints(newPoints, rotationPointX, rotationPointY); // translate points back

    return newPoints;
}

// find intersection of two lines given a point on each line and angle of rotation
export function intersectionOfTwoLines(alpha: number, point1: PointType, theta: number, point2: PointType) {
    const slope1 = Math.cos(alpha) / Math.sin(alpha);
    const slope2 = Math.cos(theta) / Math.sin(theta);

    const b1 = point1.y - slope1 * point1.x;
    const b2 = point2.y - slope2 * point2.x;

    // vertical line, slope is infinity or -infinity
    if (slope1 === Infinity || slope1 === -Infinity) {
        const y = slope2 * point1.x + b2;
        return { x: point1.x, y };
    }
    if (slope2 === Infinity || slope2 === -Infinity) {
        const y = slope1 * point2.x + b1;
        return { x: point2.x, y };
    }

    const x = (b2 - b1) / (slope1 - slope2);
    const y = slope1 * x + b1;

    return { x, y };
}
