import type { KonvaEventObject } from 'konva/lib/Node';
import React, { Component } from 'react';
import { Line } from 'react-konva';

type Point = {
    x: number;
    y: number;
};

interface Props {
    scaledPoints: Point[];
    points: Point[];
    selected: boolean;
    scale?: number;
    selectLayer: () => void;
    onDragMove: (points: Point[]) => void;
    onDragEnd: () => void;
}

interface State {
    clientX: number | null;
    clientY: number | null;
    startPoints: Point[] | null;
}

export default class Border extends Component<Props, State> {
    state: State = {
        clientX: null,
        clientY: null,
        startPoints: null,
    };

    onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        if (this.props.selected) {
            this.onDragStart(e);
        } else {
            this.props.selectLayer();
        }
    };

    onDragStart = (e: KonvaEventObject<MouseEvent>) => {
        const { points } = this.props;

        this.setState({
            clientX: e.evt.clientX,
            clientY: e.evt.clientY,
            startPoints: points,
        });

        document.onmousemove = this.onDragMove;
        document.onmouseup = this.onDragEnd;
    };

    onDragMove = (e: MouseEvent) => {
        const { clientX, clientY, startPoints } = this.state;
        const { onDragMove, scale = 1 } = this.props;
        const deltaX = (clientX! - e.clientX) / scale;
        const deltaY = (clientY! - e.clientY) / scale;
        const newPoints = startPoints!.map((point) => ({ x: point.x - deltaX, y: point.y - deltaY }));

        onDragMove(newPoints);
    };

    onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();
    };

    reversePoints = (pointsArray: number[]) => {
        const reverse: number[] = [];

        for (let i = pointsArray.length - 1; i >= 0; i -= 2) {
            reverse.push(pointsArray[i - 1]);
            reverse.push(pointsArray[i]);
        }

        return reverse;
    };

    renderLine(segment: number[], color: string, closed: boolean) {
        return (
            <Line
                key={segment.join(',')}
                points={segment}
                stroke={color}
                strokeWidth={1}
                closed={closed}
                onMouseDown={this.onMouseDown}
            />
        );
    }

    render() {
        const { selected, scaledPoints } = this.props;
        const pointArray = scaledPoints.reduce((acc, curr) => acc.concat([curr.x, curr.y]), [] as number[]);

        const color = selected ? 'red' : 'transparent';

        if (pointArray.length > 8) {
            const squareSize = Math.sqrt(pointArray.length / 2) * 2;

            // map out the horizontal lines and set horizontal border lines
            const horizontalLines: number[][] = [];

            for (let i = 0; i < pointArray.length; i += squareSize) {
                horizontalLines.push(pointArray.slice(i, i + squareSize));
            }

            // map out vertical lines and set vertical border lines
            const verticalLines: number[][] = [];
            for (let col = 0; col < squareSize; col += 2) {
                const line: number[] = [];

                for (let row = 0; row < squareSize / 2; row++) {
                    line.push(horizontalLines[row][col]);
                    line.push(horizontalLines[row][col + 1]);
                }

                verticalLines.push(line);
            }

            // render border lines as one line
            const border = [
                ...horizontalLines[0],
                ...verticalLines[verticalLines.length - 1],
                ...this.reversePoints(horizontalLines[horizontalLines.length - 1]),
                ...this.reversePoints(verticalLines[0]),
            ];

            const lineSegment: Array<JSX.Element | JSX.Element[]> = [];
            lineSegment.push(horizontalLines.map((segment) => this.renderLine(segment, color, false)));
            lineSegment.push(verticalLines.map((segment) => this.renderLine(segment, color, false)));
            lineSegment.push(this.renderLine(border, color, true));

            return lineSegment;
        }

        return this.renderLine(pointArray, color, true);
    }
}
