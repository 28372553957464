import React, { Component } from 'react';
import { Group } from 'react-konva';

import CylinderBorder from './cylinderBorder';
import MoveGrabbers from './moveGrabbers';
import RadiusGrabbers from './radiusGrabbers';
import ReferenceGrabbers from './referenceGrabbers';
import Rotation from './rotation';
import type { CylinderType } from 'src/types/transforms';

type Point = {
    x: number;
    y: number;
};

type CylinderWarpProps = {
    container: HTMLElement;
    documentId: string;
    cylinderWarp?: CylinderType;
    scale: number;
    hidden?: boolean;
    selected: boolean;
    cursorChange: (cursor: string) => void;
    selectLayer: (documentId: string) => void;
    offset: Point;
    updateCylinder: (params: { id: string; cylinder: any }) => void;
};

type CylinderWarpState = {
    cylinderWarp: CylinderType | undefined;
    dragging?: boolean;
};

export default class CylinderWarp extends Component<CylinderWarpProps, CylinderWarpState> {
    state: CylinderWarpState = {
        cylinderWarp: this.props.cylinderWarp,
    };

    onDragMove = (cylinderWarp: any) => {
        this.setState({ cylinderWarp, dragging: true });
    };

    onDragEnd = () => {
        const { documentId, updateCylinder } = this.props;
        updateCylinder({ id: documentId, cylinder: this.state.cylinderWarp });
        this.setState({ dragging: false });
    };

    static getDerivedStateFromProps(nextProps: CylinderWarpProps, prevState: CylinderWarpState) {
        if (!prevState.dragging) {
            return { cylinderWarp: nextProps.cylinderWarp };
        }
        return null;
    }

    render() {
        const { scale, offset, cursorChange, container, selected, documentId, selectLayer, hidden } = this.props;
        const { cylinderWarp } = this.state;
        if (!cylinderWarp || !cylinderWarp.cylinderTop || hidden) {
            return null;
        }

        const scaledCylinderWarp: Omit<CylinderType, 'id' | 'fullWrap'> = {
            cylinderTop: {
                center: {
                    x: cylinderWarp.cylinderTop.center.x * scale + offset.x,
                    y: cylinderWarp.cylinderTop.center.y * scale + offset.y,
                },
                radiusX: cylinderWarp.cylinderTop.radiusX * scale,
                radiusY: cylinderWarp.cylinderTop.radiusY * scale,
                rotationDegrees: cylinderWarp.cylinderTop.rotationDegrees,
            },
            cylinderBottom: {
                center: {
                    x: cylinderWarp.cylinderBottom.center.x * scale + offset.x,
                    y: cylinderWarp.cylinderBottom.center.y * scale + offset.y,
                },
                radiusX: cylinderWarp.cylinderBottom.radiusX * scale,
                radiusY: cylinderWarp.cylinderBottom.radiusY * scale,
                rotationDegrees: cylinderWarp.cylinderBottom.rotationDegrees,
            },
            decorationAreaDimensions: {
                width: cylinderWarp.decorationAreaDimensions.width * scale,
                height: cylinderWarp.decorationAreaDimensions.height * scale,
            },
            productDiameter: cylinderWarp.productDiameter * scale,
            decorationExtentDegreesAlpha: cylinderWarp.decorationExtentDegreesAlpha,
            decorationExtentDegreesBeta: cylinderWarp.decorationExtentDegreesBeta,
            isDecorationRotated: cylinderWarp.isDecorationRotated,
            imageDimensions: {
                width: cylinderWarp.imageDimensions.width * scale,
                height: cylinderWarp.imageDimensions.height * scale,
            },
        };

        return (
            <Group>
                <CylinderBorder
                    cylinderWarp={cylinderWarp}
                    scaledCylinderWarp={scaledCylinderWarp}
                    scale={scale}
                    onDragMove={this.onDragMove}
                    onDragEnd={this.onDragEnd}
                    selected={selected}
                    selectLayer={() => selectLayer(documentId)}
                />
                {selected && (
                    <Group>
                        <Rotation
                            cylinderWarp={cylinderWarp}
                            scaledCylinderWarp={scaledCylinderWarp}
                            scale={scale}
                            onDragMove={this.onDragMove}
                            onDragEnd={this.onDragEnd}
                            cursorChange={cursorChange}
                            container={container}
                            offset={offset}
                        />
                        <MoveGrabbers
                            cylinderWarp={cylinderWarp}
                            scaledCylinderWarp={scaledCylinderWarp}
                            scale={scale}
                            onDragMove={this.onDragMove}
                            onDragEnd={this.onDragEnd}
                            cursorChange={cursorChange}
                        />
                        <RadiusGrabbers
                            cylinderWarp={cylinderWarp}
                            scaledCylinderWarp={scaledCylinderWarp}
                            scale={scale}
                            onDragMove={this.onDragMove}
                            onDragEnd={this.onDragEnd}
                            cursorChange={cursorChange}
                            container={container}
                            offset={offset}
                        />
                        <ReferenceGrabbers
                            cylinderWarp={cylinderWarp}
                            scaledCylinderWarp={scaledCylinderWarp}
                            scale={scale}
                            onDragMove={this.onDragMove}
                            onDragEnd={this.onDragEnd}
                            cursorChange={cursorChange}
                            container={container}
                            offset={offset}
                        />
                    </Group>
                )}
            </Group>
        );
    }
}
