import React, { PureComponent, ReactNode } from 'react';

export interface AlertProps {
    index: number;
    message: ReactNode;
    type: string;
    dismissible?: boolean;
    timeout?: number;
    onDismiss?: () => void;
}

export default class Alert extends PureComponent<AlertProps> {
    componentDidMount() {
        const { timeout, onDismiss } = this.props;
        if (timeout && onDismiss) {
            window.setTimeout(onDismiss, timeout * 1000);
        }
    }

    render() {
        const { message, type, dismissible, index, onDismiss } = this.props;
        return (
            <div className={`alert alert-${type} alert--${index}${dismissible ? ' alert-dismissible' : ''}`}>
                {dismissible && (
                    <a className='close' type='button' onClick={onDismiss}>
                        &times;
                    </a>
                )}
                <div className='alert-message'>{message}</div>
            </div>
        );
    }
}
