import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getRulerById } from 'src/selectors/layers';
import { toggleColor } from 'src/pages/editor/components/rulers/slice';
import color from './color';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => ({
    // @ts-expect-error -- fix type for `getRulerById`
    color: getRulerById(state, ownProps.id).color,
});

const colorContainer = connect(mapStateToProps, { toggleColor })(color);

export default colorContainer;
