import React, { PureComponent, ChangeEvent } from 'react';
import { TextField } from '@cimpress/react-components';

interface SegmentsProps {
    segments: number;
    id: string;
    updateSegments: (data: { id: string; segments: number }) => void;
}

interface SegmentsState {
    currentValue: number;
    validValue: number;
    status?: 'error';
}

const isError = (value: number) => (!(Number.isFinite(value) && value >= 1 && value <= 100) ? 'error' : undefined);

export default class Segments extends PureComponent<SegmentsProps, SegmentsState> {
    state: SegmentsState = {
        currentValue: this.props.segments,
        validValue: this.props.segments,
        status: isError(this.props.segments),
    };

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        const error = isError(value);
        this.setState({ currentValue: value, status: error });
        if (!error) {
            const { updateSegments, id } = this.props;
            updateSegments({ id, segments: value });
        }
    };

    static getDerivedStateFromProps(nextProps: SegmentsProps, prevState: SegmentsState): Partial<SegmentsState> | null {
        if (nextProps.segments !== prevState.validValue) {
            return {
                currentValue: nextProps.segments,
                validValue: nextProps.segments,
                status: isError(nextProps.segments),
            };
        }
        return { status: isError(prevState.currentValue) };
    }

    render() {
        const { currentValue, status } = this.state;
        return (
            <div className='segments'>
                <TextField
                    className='segments__field'
                    label='Segments'
                    value={currentValue || (currentValue === 0 ? 0 : '')}
                    status={status}
                    onChange={this.onChange}
                    type='number'
                    onFocus={(e) => e.target.select()}
                />
            </div>
        );
    }
}
