import { createSelector } from 'reselect';

import { getCoamTenant } from 'src/selectors';
import getAllBackgrounds from 'src/selectors/layers/getAllBackgrounds';
import isScenePublishing from './isScenePublishing';
import isSceneSaving from './isSceneSaving';
import isSceneDuplicating from './isSceneDuplicating';
import isLinkSetupInvalid from './isLinkSetupInvalid';
import { RootState } from 'src/store';

const canvasData = (state: RootState) => state.pages.editor.canvas;

const isSceneSavable = createSelector(
    [
        isLinkSetupInvalid,
        getCoamTenant,
        getAllBackgrounds,
        isScenePublishing,
        isSceneSaving,
        isSceneDuplicating,
        canvasData,
    ],
    (invalidSku, tenant, backgrounds, publishing, saving, duplicating, renderData) => {
        const isAlreadySaving = saving || duplicating || publishing;
        const didRender = renderData.latestRequestCount === renderData.successRequestCount;
        const hasBackground = backgrounds.length > 0;
        return hasBackground && !!tenant.tenantId && !invalidSku && didRender && !isAlreadySaving;
    },
);

export default isSceneSavable;
