import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import moment from 'moment';

import SkuInput from './sku';

export default class Product extends PureComponent {
    render() {
        const { productName, lastModified, firstSurfaceWidth, firstSurfaceHeight, retrievingSurfaces, isReady } =
            this.props;
        return (
            <div className='product-data'>
                <p className='choose-sku__title'>Type in a product SKU:</p>
                <SkuInput />
                {productName && isReady && (
                    <div>
                        <div className='link__data'>
                            <div className='link__data-title'>Name:</div>
                            <div className='link__data-value'>{productName}</div>
                        </div>
                        <div className='link__data'>
                            <div className='link__data-title'>Last Modified:</div>
                            <div className='link__data-value'>{moment(lastModified).fromNow()}</div>
                        </div>
                        <div className='link__data'>
                            <div className='link__data-title'>Surface Dimensions:</div>
                            {!retrievingSurfaces ? (
                                <div className='link__data-value'>
                                    Width: {`${firstSurfaceWidth} cm `}
                                    <br />
                                    Height: {`${firstSurfaceHeight} cm `}
                                </div>
                            ) : (
                                <div className='link__data-value'>Loading surface data...</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Product.propTypes = {
    lastModified: PropTypes.string,
    productName: PropTypes.string,
    firstSurfaceHeight: PropTypes.number,
    firstSurfaceWidth: PropTypes.number,
    retrievingSurfaces: PropTypes.bool,
    isReady: PropTypes.bool,
};
