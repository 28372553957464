import { DOCUMENT_ID } from 'src/models/documentWarps';

import { ItemType } from 'src/types/index';

export default function idGeneratorAtor(prefix = 'ID') {
    let count = 0;
    return () => `${prefix} ${count++}`;
}

export function getDocumentNextId(allDocs: ItemType[]) {
    if (allDocs.length > 0) {
        const lastDoc = allDocs.slice(-1)[0];
        const curIteration = (((lastDoc.id as string) || '').split(' ') || ['']).slice(-1)[0];
        const nextId = parseInt(curIteration);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(nextId)) {
            return `${DOCUMENT_ID} ${nextId + 1}`;
        }
        // Increase the number in 10, to avoid any possible issue with current docs
        return `${DOCUMENT_ID} ${allDocs.length + 10}`;
    }
    return `${DOCUMENT_ID} 1`;
}

export function getTransformNextId(docId: string) {
    if (docId) {
        const nextId = docId.split(' ').slice(-1)[0];
        return `Transform ${nextId}`;
    }

    return 'Transform NoData';
}
