/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    generatePlacementTransform,
    generateTubeTransform,
    generatePerspectiveTransformWithPoints,
} from 'src/util/transformGenerators';
import { makePublicServiceRequest } from './servicesClient';
import {
    CylinderType,
    PerspectiveTransformType,
    PlacementTransformType,
    TubeTransformType,
} from 'src/types/transforms';

const MISC_ENDPOINT = `https://misc-rendering.documents.cimpress.io`;
const PREVIEW_ORCHESTRATION = `https://previews.cimpress.io`;

const generateInstructionsUri = (
    sku?: string,
    variables?: Record<string, any>,
    skuVersion?: number,
    image?: string,
) => {
    let temp =
        `${PREVIEW_ORCHESTRATION}/v2/sampledoc?sku=${encodeURIComponent(sku || '')}` +
        `${skuVersion && skuVersion >= 1 ? `&version=${skuVersion}` : ''}` +
        `${
            Object.keys(variables || {}).length ? `&skuVariables=${encodeURIComponent(JSON.stringify(variables))}` : ''
        }`;
    if (image) {
        temp = `${temp}&imageUris=${encodeURIComponent(image)}`;
    }

    return temp;
};

const renderingDomains: Record<string, string> = {
    'us-east': 'https://aws-us-east-1.rendering.documents.cimpress.io',
    'eu-west': 'https://aws-eu-west-1.rendering.documents.cimpress.io',
    'ap-northeast': 'https://aws-ap-northeast-1.rendering.documents.cimpress.io',
    china: 'https://origin-rendering.yinshida.com.cn',
    auto: `https://rendering.documents.cimpress.io`,
};

function getDomain(region = 'auto') {
    return renderingDomains[region] || renderingDomains.auto;
}

type GeneratePreviewParams = {
    url: string;
    size: number;
    useWidth: boolean;
    region?: string;
    instructionsUri: string;
};
function generatePreview({
    url,
    size = 500,
    useWidth = false,
    region = 'auto',
    instructionsUri = generateInstructionsUri(),
}: GeneratePreviewParams) {
    if (!url) {
        return undefined;
    }
    const instructions = instructionsUri ? `&instructions_uri=${encodeURIComponent(instructionsUri)}` : '';
    const scene = `scene=${encodeURIComponent(url)}`;
    const dimension = `${useWidth ? 'width' : 'height'}=${size}`;
    return `${getDomain(region)}/v1/cse/preview?${dimension}&${scene}&timepreview=1&format=webp${instructions}`;
}

type GeneratePreviewUsingSkuParams = {
    url: string;
    size: number;
    useWidth: boolean;
    region?: string;
    sku: string;
    skuVersion: number;
    variables: Record<string, any>;
};
function generatePreviewUsingSku({
    url,
    size,
    useWidth,
    region,
    sku,
    skuVersion,
    variables,
}: GeneratePreviewUsingSkuParams) {
    return generatePreview({
        url,
        size,
        useWidth,
        region,
        instructionsUri: generateInstructionsUri(sku, variables, skuVersion),
    });
}

type MapPoint = { targetX: number; targetY: number; sourceX: number; sourceY: number };
export type CylinderResponse = {
    documentSize: { width: number; height: number };
    perspectiveWarp: PerspectiveTransformType;
    tubeWarp: TubeTransformType;
    placementWarp?: PlacementTransformType;
};
function parseCylinderWarp(
    response: CylinderWarpSceneTube,
    _imageDimensionsWidth: number,
    _imageDimensionsHeight: number,
    _documentId: string,
    perspectiveId: string,
    cylinderId: string,
    placementId: string,
) {
    // Todo decorationAreaPoints are an empty array. Why?
    const { documentSize, perspectiveWarp, tubeWarp, placementWarp } = response;
    if (!documentSize || !perspectiveWarp || !tubeWarp) {
        return {};
    }

    const mapPoints = (point: MapPoint) => ({
        x: point.targetX,
        y: point.targetY,
        sourceX: point.sourceX,
        sourceY: point.sourceY,
    });

    // This is to organize the perspective mapPoints in a clockwise fashion.
    const [point00, point10, point01, point11] = perspectiveWarp.mapPoints.map(mapPoints);

    const result: CylinderResponse = {
        documentSize,
        perspectiveWarp: generatePerspectiveTransformWithPoints({
            points: [point00, point10, point01, point11],
            id: perspectiveId,
        }),
        tubeWarp: generateTubeTransform({
            width: tubeWarp.size.width,
            height: tubeWarp.size.height,
            lowShift: tubeWarp.lowShift,
            highShift: tubeWarp.highShift,
            points: tubeWarp.mapPoints.map(mapPoints),
            id: cylinderId,
        }),
    };

    // rotation of the document needs a placementWarp
    if (placementWarp) {
        result.placementWarp = generatePlacementTransform({
            width: placementWarp.size.width,
            height: placementWarp.size.height,
            rotate: placementWarp.rotation,
            x1: placementWarp.mapPoint.targetX,
            y1: placementWarp.mapPoint.targetY,
            id: placementId,
        });
    }

    return result;
}

type CylinderWarpSceneTube = {
    documentSize: { width: number; height: number };
    perspectiveWarp: {
        mapPoints: MapPoint[];
    };
    tubeWarp: {
        size: {
            width: number;
            height: number;
        };
        lowShift: number;
        highShift: number;
        mapPoints: MapPoint[];
    };
    placementWarp: {
        size: {
            width: number;
            height: number;
        };
        rotation: number;
        mapPoint: MapPoint;
    };
};
function cylinderWarp(
    parameters: CylinderType,
    documentId: string | undefined,
    perspectiveId: string | undefined,
    cylinderId: string | undefined,
    placementId: string | undefined,
) {
    return makePublicServiceRequest({
        method: 'post',
        url: `${MISC_ENDPOINT}/scene/warp/tube`,
        data: JSON.stringify(parameters),
    }).then((response) =>
        parseCylinderWarp(
            response.data as CylinderWarpSceneTube,
            parameters.imageDimensions.width,
            parameters.imageDimensions.height,
            documentId || '',
            perspectiveId || '',
            cylinderId || '',
            placementId || '',
        ),
    );
}

export {
    MISC_ENDPOINT,
    PREVIEW_ORCHESTRATION,
    cylinderWarp,
    generatePreview,
    generatePreviewUsingSku,
    generateInstructionsUri,
};
