import { createSelector } from 'reselect';
import getAllBackgrounds from './getAllBackgrounds';

const getSceneSize = createSelector([getAllBackgrounds], (backgrounds) => {
    const firstBackgroundWithSize = backgrounds.find((background) => background.width && background.height);

    if (!firstBackgroundWithSize) {
        return { width: 0, height: 0 };
    }
    return { width: firstBackgroundWithSize.width, height: firstBackgroundWithSize.height };
});

export default getSceneSize;
