import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import auth from 'src/auth';
import { connect } from 'react-redux';
import { difference, isEqual } from 'lodash';
import { getAllBackgrounds, getAllDocuments, getAllOverlays } from 'src/selectors/layers';
import { Tooltip, Button, Checkbox, Accordion, Select, TextField } from '@cimpress/react-components';
import GenericSelector from '@cimpress-technology/generic-selector';
import { ENTER } from 'src/util/keycodes';
import getMerchantSettings from 'src/selectors/getSettings';
import ProductVariables from './ProductVariables';
import SceneVariables from './SceneVariables';
import { MERCHANDISING, PRODUCT_VARIABLE_TYPES, PURPOSES_OPTIONS, SCENE_VARIABLE_TYPES } from './constants';
import { updateScenePurpose, updateFixedAttributesSelection, removeTag, setTags } from './slice';
import getSceneVariation from './selectors/getSceneVariation';
import PreviewScene from './PreviewScene';
import SceneTags from '../save/sceneTags/sceneTags';
import ViewOptions from './ViewOptions';

const AttributesSelection = (props) => {
    const assets = useMemo(
        () => [
            ...props.overlays,
            ...props.backgroundLayers,
            ...props.documentMasks
                .filter((each) => !!each.maskUrl)
                .map((each) => ({ id: each.maskName, name: each.maskName })),
        ],
        [props.overlays, props.documentMasks],
    );
    const [attributesTooltipOpen, setAttributesToolTipOpen] = useState(false);
    const [displayabledAttributes, setDisplayabledAttributes] = useState([]);
    const selectedAttributes = useMemo(
        () => Object.keys(props.fixedAttributesSelection),
        [props.fixedAttributesSelection],
    );
    const [interimSelections, setInterimSelections] = useState(selectedAttributes);
    const [currentSubpurpose, setCurrentSubPurpose] = useState('');
    const checkboxToggle = (attributeKey) => {
        const index = interimSelections.findIndex((each) => each === attributeKey);
        if (index >= 0) {
            setInterimSelections([...interimSelections.slice(0, index), ...interimSelections.slice(index + 1)]);
        } else {
            setInterimSelections([...interimSelections, attributeKey]);
        }
    };

    const closeAttributeSelector = () => {
        if (!isEqual(interimSelections, selectedAttributes)) {
            const updates = {};
            interimSelections.forEach((each) => {
                updates[each] = props.fixedAttributesSelection[each];
            });
            props.updateFixedAttributesSelection(updates);
        }
        setAttributesToolTipOpen(false);
    };

    const attributeConfigurations = useMemo(() => {
        const attributes = {};
        displayabledAttributes.forEach((key) => {
            attributes[key] = {
                initialSelection: props.fixedAttributesSelection[key],
                isHidden: !selectedAttributes.includes(key),
            };
        });
        return attributes;
    }, [selectedAttributes, displayabledAttributes, props.fixedAttributesSelection]);

    const remainingProductAttributes = useMemo(() => {
        const remainingAttributes = difference(displayabledAttributes, selectedAttributes);
        return remainingAttributes;
    }, [displayabledAttributes, selectedAttributes]);

    const onLoad = (state) => {
        const temp = state.attributes.filter((attr) => attr.isDisplayed).map((attri) => attri.key);
        setDisplayabledAttributes(temp);
    };

    const handleAttributeChanges = (changes) => {
        const updates = {};
        selectedAttributes.forEach((each) => {
            const attributeDetails = changes.attributes.find((eachAttribute) => eachAttribute.key === each);
            if (attributeDetails) {
                updates[each] = attributeDetails.resolvedValue;
            }
        });
        props.updateFixedAttributesSelection(updates);
    };

    const getAssetOptions = (optionsFor) => {
        let assetsAlreadyUsed = props.productConfigurations.filter(
            ({ variableOption }) => variableOption === PRODUCT_VARIABLE_TYPES.product,
        );
        assetsAlreadyUsed = assetsAlreadyUsed.filter((each) => !!each.layer).map((each) => each.layer.value);
        if (optionsFor === PRODUCT_VARIABLE_TYPES.product) {
            const sceneVariablesAssetsUsed = props.sceneVariables.reduce((acc, current) => {
                const assetChangeTypes = current.rules.filter(
                    (each) => each.type === SCENE_VARIABLE_TYPES['Asset Change'] && !!each.asset,
                );
                return acc.concat(assetChangeTypes.map((each) => each.asset.value));
            }, []);
            assetsAlreadyUsed = assetsAlreadyUsed.concat(sceneVariablesAssetsUsed);
        }

        return assets
            .filter((each) => !assetsAlreadyUsed.includes(each.id))
            .map((each) => ({
                value: each.id,
                label: each.name,
            }));
    };

    const updateCurrentSubpurpose = (event) => {
        setCurrentSubPurpose(event.target.value);
    };

    const addCustomSubpurposes = () => {
        const newSubpurposes = currentSubpurpose
            .split(',')
            .filter((tag) => tag)
            .map((tag) => tag.trim());
        props.updateScenePurpose([
            ...props.scenePurposes,
            ...newSubpurposes.map((each) => ({
                label: `${MERCHANDISING.label} ${each}`,
                value: `${MERCHANDISING.value}:${each}`,
            })),
        ]);
        setCurrentSubPurpose('');
    };

    const purposeOptions = useMemo(() => {
        const userSurpurposes = props.userSettings.merchandisingSubPurposes.map((i) => ({
            label: `${MERCHANDISING.label} ${i}`,
            value: `${MERCHANDISING.value}:${i}`,
        }));
        return [...PURPOSES_OPTIONS, ...userSurpurposes].filter(
            (opt) => !props.scenePurposes.find((item) => item.value === opt.value),
        );
    }, [props.scenePurposes, props.userSettings.merchandisingSubPurposes]);

    const productVariableAssetOptions = useMemo(
        () => getAssetOptions(PRODUCT_VARIABLE_TYPES.product),
        [props.productConfigurations, props.sceneVariables, assets],
    );

    const details = useMemo(
        () =>
            props.scenePurposes.length ? (
                <>
                    <ProductVariables
                        attributesOptions={remainingProductAttributes}
                        assetOptions={productVariableAssetOptions}
                        productDetails={props.productDetails}
                    />
                    <SceneVariables purposeOptions={props.scenePurposes} getAssetOptions={getAssetOptions} />
                    <PreviewScene />
                </>
            ) : (
                <></>
            ),
        [props.scenePurposes.length, props.productDetails, productVariableAssetOptions, remainingProductAttributes],
    );
    return (
        <>
            <Accordion title='Link Information' defaultOpen={true}>
                <div className='accordion-body-wrapper'>
                    <GenericSelector
                        authToken={auth.getAccessToken()}
                        productId={props.sku}
                        productVersion={props.skuVersion}
                        attributeConfigurations={attributeConfigurations}
                        errorAlertConfiguration={{ showErrorAlert: false }}
                        onLoad={onLoad}
                        isColorSwatch={true}
                        onChange={handleAttributeChanges}
                    />
                    <Tooltip
                        tooltipStyle={{ maxWidth: '800px' }}
                        direction='bottom'
                        show={attributesTooltipOpen}
                        containerClassName='variable-selector__tooltip'
                        className='variable-selector__tooltip'
                        variety='popover'
                        contents={
                            <div
                                style={{
                                    maxWidth: '500px',
                                    maxHeight: '500px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: '#292d33 #00000000',
                                }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {displayabledAttributes?.map((key) => (
                                        <Checkbox
                                            data-testid={`variable-selector_${key}`}
                                            key={key}
                                            label={key}
                                            style={{ width: '33%' }}
                                            checked={interimSelections.includes(key)}
                                            onChange={() => checkboxToggle(key)}
                                        />
                                    ))}
                                </div>
                                <Button
                                    data-testid='variable-selector_close_button'
                                    variant='primary'
                                    className='variables__confirm-button'
                                    onClick={closeAttributeSelector}>
                                    Ok
                                </Button>
                            </div>
                        }
                    />
                    <Button
                        data-testid='variable-selector_add_remove_button'
                        variant='link'
                        onClick={() => {
                            setAttributesToolTipOpen(!attributesTooltipOpen);
                        }}>
                        Add/Remove Attributes
                    </Button>
                    <div>
                        <hr style={{ borderTop: '1px solid #ccd5de' }} />
                        <h3 style={{ textAlign: 'center' }}>View Options</h3>
                        <ViewOptions />
                    </div>
                    <div>
                        <hr style={{ borderTop: '1px solid #ccd5de' }} />
                        <h3 style={{ textAlign: 'center' }}>Choose scene purposes</h3>
                        <div>
                            <Select
                                label='Select Purpose(s)'
                                isMulti={true}
                                value={props.scenePurposes}
                                options={purposeOptions}
                                onChange={(chosenOptions) =>
                                    props.updateScenePurpose((chosenOptions || []).map((each) => each))
                                }
                                menuPortalTarget={document.body}
                            />
                            <TextField
                                value={currentSubpurpose}
                                label='Add custom merchandising subpurposes'
                                onChange={updateCurrentSubpurpose}
                                onKeyDown={(e) => {
                                    if (e.keyCode === ENTER) {
                                        addCustomSubpurposes();
                                    }
                                }}
                                rightAddon={
                                    <Button onClick={addCustomSubpurposes} disabled={!currentSubpurpose}>
                                        Add
                                    </Button>
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <hr style={{ borderTop: '1px solid #ccd5de' }} />
                        <h3 style={{ textAlign: 'center' }}>Tags</h3>
                        <SceneTags
                            tags={props.tags}
                            predefinedTags={props.userSettings.tags.default}
                            addTag={props.addTag}
                            removeTag={props.removeTag}
                        />
                    </div>
                </div>
            </Accordion>
            {details}
        </>
    );
};

const assetShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

AttributesSelection.propTypes = {
    productDetails: PropTypes.shape({
        ruleSet: PropTypes.any,
    }),
    sku: PropTypes.string,
    skuVersion: PropTypes.number,
    overlays: PropTypes.arrayOf(assetShape).isRequired,
    documentMasks: PropTypes.arrayOf(assetShape).isRequired,
    backgroundLayers: PropTypes.arrayOf(assetShape).isRequired,
    scenePurposes: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    updateScenePurpose: PropTypes.func.isRequired,
    fixedAttributesSelection: PropTypes.object,
    updateFixedAttributesSelection: PropTypes.func.isRequired,
    productConfigurations: PropTypes.array,
    sceneVariables: PropTypes.array,
    userSettings: PropTypes.shape({
        merchandisingSubPurposes: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.shape({
            default: PropTypes.arrayOf(PropTypes.string),
            surfacePrefix: PropTypes.string,
        }),
    }),
    tags: PropTypes.arrayOf(PropTypes.string),
    addTag: PropTypes.func.isRequired,
    removeTag: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const backgroundLayers = getAllBackgrounds(state);
    const overlays = getAllOverlays(state);
    const documentMasks = getAllDocuments(state);
    const { fixedAttributesSelection, scenePurposes, productConfigurations, sceneVariables, tags } =
        getSceneVariation(state);
    const userSettings = getMerchantSettings(state);

    return {
        overlays,
        documentMasks,
        backgroundLayers,
        scenePurposes,
        fixedAttributesSelection,
        productConfigurations,
        sceneVariables,
        userSettings,
        tags,
    };
}

export default connect(mapStateToProps, {
    updateScenePurpose,
    updateFixedAttributesSelection,
    addTag: setTags,
    removeTag,
})(AttributesSelection);
