import React from 'react';
import { Tooltip } from '@cimpress/react-components';

interface TooltipWrapperProps {
    contents?: string;
    children?: React.ReactNode;
}

const TooltipWrapper = (props: TooltipWrapperProps) => {
    const { contents, children } = props;
    const show = contents ? undefined : false;
    return (
        <Tooltip show={show} {...props}>
            {children}
        </Tooltip>
    );
};

export default TooltipWrapper;
