import React, { Component } from 'react';
// @ts-expect-error -- convert module to TypeScript
import CTHeader from '@cimpress-technology/react-platform-header';
import { Link } from 'react-router-dom';
import { Loudspeaker, LoudspeakerIcon } from '@cimpress-technology/react-loudspeaker';

import auth from 'src/auth';
import CoamDropdown from 'src/components/services/coam';
import { turnOffNavigationWarning } from 'src/store/navigationManager';

interface Profile {
    email: string;
    name: string;
    picture: string;
    domain: string;
    sub: string;
}

const getProfile = (): Profile | Record<string, never> => {
    let profile = {};
    if (auth.isLoggedIn()) {
        const { email, name, picture, domain, sub } = auth.getProfile();
        profile = {
            email,
            name,
            picture,
            domain,
            sub,
        };
    }
    return profile;
};

const loudspeakerProps = {
    accessToken: auth.getAccessToken()!,
    channelIds: ['scene-maker'],
    title: 'Announcements',
};

interface HeaderProps {}

interface HeaderState {}

export default class Header extends Component<HeaderProps, HeaderState> {
    render() {
        return (
            <React.Fragment>
                <CTHeader
                    appTitle={
                        <Link className='header-title' to='/'>
                            Scene Maker
                        </Link>
                    }
                    appLinks={[
                        {
                            id: 'coamDropdown',
                            content: <CoamDropdown key='2' />,
                        },
                        {
                            id: 'contact',
                            content: <Link to='/contact'>Contact</Link>,
                        },
                        {
                            id: 'documentation',
                            content: (
                                <a
                                    href='https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/232489016/Scene+Maker'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <img
                                        className='colorOceanBase iconLg'
                                        src='https://static.ux.cimpress.io/assets/icons/book-open-4-l.svg'
                                    />
                                </a>
                            ),
                        },
                    ]}
                    profile={getProfile()}
                    onLogInClicked={() => auth.login()}
                    onLogOutClicked={() => {
                        turnOffNavigationWarning();
                        auth.logout('/');
                    }}
                    isLoggedIn={auth.isLoggedIn()}
                    notifications={<LoudspeakerIcon {...loudspeakerProps} />}
                    accessToken={auth.getAccessToken()}
                />
                <Loudspeaker {...loudspeakerProps} />
            </React.Fragment>
        );
    }
}
