import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Accordion } from '@cimpress/react-components';
import { getAllDocuments } from 'src/selectors/layers';
import { toSelectOption } from 'src/util/misc';
import EachProductVariable from './EachProductVariable';
import { updateConfiguration, removeConfiguration, addConfiguration } from './slice';
import { PRODUCT_VARIABLE_TYPES } from './constants';
import getSceneVariation from './selectors/getSceneVariation';

const ProductVariables = (props) => {
    const addNewProductConfiguration = (event) => {
        event.stopPropagation();
        props.addConfiguration();
    };
    const title = (
        <div className='layers-header'>
            <div className='layers-header__text'>Product Variables</div>
            <button onClick={addNewProductConfiguration}>
                <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/add-2-l.svg' />
            </button>
        </div>
    );
    const { productConfigurations } = props;

    const getDocumentOptions = (variableAttributeValue) => {
        let documentsUsed = productConfigurations.filter(
            ({ variableOption, variableAttribute }) =>
                variableOption === PRODUCT_VARIABLE_TYPES.blending && variableAttribute === variableAttributeValue,
        );
        documentsUsed = documentsUsed.filter((each) => !!each.document).map((each) => each.document);
        return toSelectOption(props.documents.map((each) => each.id).filter((each) => !documentsUsed.includes(each)));
    };

    return (
        <Accordion title={title} defaultOpen={false}>
            <div className='accordion-body-wrapper'>
                {productConfigurations.map((each, index) => (
                    <EachProductVariable
                        /* eslint-disable react/no-array-index-key */
                        key={index}
                        index={index}
                        configuration={each}
                        updateConfiguration={props.updateConfiguration}
                        removeConfiguration={props.removeConfiguration}
                        attributesOptions={props.attributesOptions}
                        assetOptions={props.assetOptions}
                        getDocumentOptions={getDocumentOptions}
                        productDetails={props.productDetails}
                    />
                ))}
            </div>
        </Accordion>
    );
};

const optionShape = PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
});

ProductVariables.propTypes = {
    productDetails: PropTypes.shape({
        ruleSet: PropTypes.any,
    }),
    attributesOptions: PropTypes.arrayOf(PropTypes.string),
    productConfigurations: PropTypes.arrayOf(
        PropTypes.shape({
            layer: optionShape,
            variableAttribute: PropTypes.string,
            variableOption: PropTypes.string,
            values: PropTypes.object,
        }),
    ),
    updateConfiguration: PropTypes.func.isRequired,
    removeConfiguration: PropTypes.func.isRequired,
    addConfiguration: PropTypes.func.isRequired,
    documents: PropTypes.arrayOf(PropTypes.any),
    assetOptions: PropTypes.arrayOf(optionShape),
};

function mapStateToProps(state) {
    const { productConfigurations } = getSceneVariation(state);
    const documents = getAllDocuments(state);

    return {
        productConfigurations,
        documents,
    };
}

export default connect(mapStateToProps, {
    updateConfiguration,
    removeConfiguration,
    addConfiguration,
})(ProductVariables);
