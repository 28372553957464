import React, { PureComponent } from 'react';

import { CYLINDER_WARP } from 'src/models/documentWarps';
import CylinderWarp from './cylinder';
import NPointWarp from './nPoint';

type WarpProps = {
    selected: boolean;
    documentId: string;
    warpType: string;
    scale: number;
    cursorChange: (cursor: string) => void;
    offset: {
        x: number;
        y: number;
    };
    container: HTMLElement;
};

export default class Warp extends PureComponent<WarpProps> {
    render() {
        const { scale, offset, cursorChange, container, documentId, warpType, selected } = this.props;
        const RenderedWarp = warpType === CYLINDER_WARP ? CylinderWarp : NPointWarp;
        return (
            <RenderedWarp
                selected={selected}
                documentId={documentId}
                container={container}
                cursorChange={cursorChange}
                scale={scale}
                offset={offset}
            />
        );
    }
}
