import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getCoamTenant, isTenantSelectable, hasCoamPermissions, getResources } from 'src/selectors';
import { setTenant } from 'src/slice';
import coamModal from './modal';

const mapStateToProps = (state: RootState) => {
    const { tenantId, tenantType } = getCoamTenant(state);
    const hasPermissions = hasCoamPermissions(state);
    const resources = getResources(state);
    const disabled = !isTenantSelectable(state);

    const forceShow = !tenantId && hasPermissions;

    let tip = '';
    if (hasPermissions && disabled) {
        tip = 'Cannot change Tenant after the scene has been saved to a tenant.';
    }

    return { resources, tenantId, tenantType, disabled, forceShow, tip };
};

const coamModalContainer = connect(mapStateToProps, { saveCoam: setTenant })(coamModal);

export default coamModalContainer;
