import auth from 'src/auth';
import { makeAuthenticatedServiceRequest } from './servicesClient';

const ENDPOINT = 'https://api.cimpress.io/auth/access-management/v1/principals/';

function extractResourceIdentifiers(permissionEntries = []) {
    return permissionEntries
        .filter((value) => value.permissions.includes('create:scenes'))
        .map((value) => value.identifier);
}

function mapCoamResponse(coamResponse) {
    const keys = Object.keys(coamResponse.data);

    const mappedCoamResponse = {};
    keys.forEach((key) => {
        const identifiers = extractResourceIdentifiers(coamResponse.data[key]);
        if (identifiers.length) {
            mappedCoamResponse[key] = identifiers;
        }
    });
    return mappedCoamResponse;
}

/**
 * Retrieves an array of merchants and fulfillers that the principal has access to.
 * @returns {object} - The promise of the request.
 */
export function getResources() {
    // eslint-disable-line import/prefer-default-export
    const { sub } = auth.getProfile();
    const url = `${ENDPOINT}${sub}/permissions`;

    return makeAuthenticatedServiceRequest({ url }).then(mapCoamResponse);
}
