import * as React from 'react';
import { PureComponent } from 'react';

import { Tooltip } from '@cimpress/react-components';

const isDisabled = (image: Img, container: HTMLElement | null) => !image.width || !image.height || !container;

interface Img {
    url?: string;
    width?: number;
    height?: number;
}

interface HeaderProps {
    name: string;
    image: Img;
    container: HTMLElement | null;
    addModeEnabled: boolean;
    zoomCanvas: (args: { scroll: number; image: Img; container: HTMLElement | null }) => void;
    centerCanvas: (args: { image: Img; container: HTMLElement | null }) => void;
    toggleAddRulerMode: () => void;
    onUndo: () => void;
    onRedo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    undoEvents: number;
    redoEvents: number;
}

export default class Header extends PureComponent<HeaderProps> {
    zoomIn = () => {
        this.zoom(1);
    };

    zoomOut = () => {
        this.zoom(-1);
    };

    fitToScreen = () => {
        const { centerCanvas, image, container } = this.props;
        if (isDisabled(image, container)) {
            return;
        }
        centerCanvas({ image, container });
    };

    zoom = (scroll: number) => {
        const { zoomCanvas, image, container } = this.props;
        if (isDisabled(image, container)) {
            return;
        }
        zoomCanvas({ scroll, image, container });
    };

    undo = () => {
        this.props.onUndo();
    };

    redo = () => {
        this.props.onRedo();
    };

    render() {
        const { name, image, container, toggleAddRulerMode, addModeEnabled, canUndo, undoEvents, canRedo, redoEvents } =
            this.props;
        const disabled = isDisabled(image, container) ? ' disabled' : '';
        const undoDisabled = !canUndo ? ' disabled' : '';
        const redoDisabled = !canRedo ? ' disabled' : '';
        const active = addModeEnabled ? ' active' : '';
        return (
            <div className='canvas-header'>
                <div className='canvas-header__name'>{name}</div>
                <div className='canvas-header__actions'>
                    <button className={`canvas-header__undo${undoDisabled}`} onClick={this.undo} disabled={!canUndo}>
                        {undoEvents > 0 && <div className='canvas-header__undo__events'>{undoEvents}</div>}
                        <img className='icon2x' src='https://static.ux.cimpress.io/assets/icons/undo-l.svg' />
                    </button>
                    <button className={`canvas-header__redo${redoDisabled}`} onClick={this.redo} disabled={!canRedo}>
                        {redoEvents > 0 && <div className='canvas-header__redo__events'>{redoEvents}</div>}
                        <img className='icon2x' src='https://static.ux.cimpress.io/assets/icons/redo-l.svg' />
                    </button>
                    <Tooltip
                        className='ruler-tooltip'
                        direction='bottom'
                        show={!!addModeEnabled}
                        contents={
                            <div>
                                <div>Click to start drawing the ruler.</div>
                                <div>Click again to finish.</div>
                                <div>Click ESC to exit drawing mode.</div>
                            </div>
                        }>
                        <button className={`canvas-header__add-ruler${disabled}${active}`} onClick={toggleAddRulerMode}>
                            <img className='icon2x' src='https://static.ux.cimpress.io/assets/icons/ruler-1-l.svg' />
                        </button>
                    </Tooltip>
                    <button className={`canvas-header__zoom-in${disabled}`} onClick={this.zoomIn}>
                        <img className='icon2x' src='https://static.ux.cimpress.io/assets/icons/zoom-in-l.svg' />
                    </button>
                    <button className={`canvas-header__zoom-out${disabled}`} onClick={this.zoomOut}>
                        <img className='icon2x' src='https://static.ux.cimpress.io/assets/icons/zoom-out-l.svg' />
                    </button>
                    <button className={`canvas-header__fit-to-screen${disabled}`} onClick={this.fitToScreen}>
                        <img className='icon2x' src='https://static.ux.cimpress.io/assets/icons/expand-all-1-l.svg' />
                    </button>
                </div>
            </div>
        );
    }
}
