import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';
import { getResizeModalOpened, getResizeModalMessage } from 'src/selectors/layers';
import { acceptResize, declineResize, cancelResizeModalUpload } from '../slice';
import ResizeModal from './resizeModal';

const mapStateToProps = (state: RootState) => ({
    opened: getResizeModalOpened(state),
    message: getResizeModalMessage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onClose: () => dispatch(cancelResizeModalUpload()),
    onAccept: () => dispatch(acceptResize()),
    onDecline: () => dispatch(declineResize()),
});

const resizeModalContainer = connect(mapStateToProps, mapDispatchToProps)(ResizeModal);

export default resizeModalContainer;
