import React, { PureComponent } from 'react';
import { Checkbox } from '@cimpress/react-components';
import InputColor from 'react-input-color';
import { getHexColor } from 'src/util/misc';

interface EngravingProps {
    id: string;
    isEngraving: boolean;
    colorEngraving: string;
    toggleEngraving: (id: string) => void;
    changeEngravingColor: (data: { id: string; color: string }) => void;
}

export default class Engraving extends PureComponent<EngravingProps> {
    render() {
        const { id, isEngraving, toggleEngraving, changeEngravingColor, colorEngraving } = this.props;
        const handleEngraving = () => {
            toggleEngraving(id);
        };
        const handleChange = (evt: { hex: string }) => {
            changeEngravingColor({ id, color: getHexColor(evt.hex) });
        };
        return (
            <div className='blending'>
                <Checkbox onChange={handleEngraving} label='Engraved Product' checked={isEngraving} />
                {isEngraving && (
                    <div className='blending__engraving-color-wrapper'>
                        <span>Engraving Color</span>
                        <InputColor initialValue={colorEngraving} onChange={handleChange} placement='top' />
                    </div>
                )}
            </div>
        );
    }
}
