import React, { PureComponent } from 'react';
import { Button, ButtonProps, Spinner } from '@cimpress/react-components';

interface SpinnerButtonProps {
    text: string;
    onClick(e: any): void;
    disabled?: boolean;
    spinning?: boolean;
    spinningText?: string;
    buttonType?: ButtonProps['variant'];
    className?: string;
    testid?: string;
}

export default class SpinnerButton extends PureComponent<SpinnerButtonProps> {
    onClick = (e: any) => {
        if (!this.props.disabled) {
            this.props.onClick(e);
        }
    };

    render() {
        const { text, spinning, disabled, spinningText, buttonType, className } = this.props;
        const classNames = `spinner-button${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}`;
        return (
            <Button data-testid={this.props.testid} variant={buttonType} className={classNames} onClick={this.onClick}>
                {!spinning && <div className='button-text'>{text}</div>}
                {spinning && <Spinner className='centerSpinner' size='medium' />}
                {spinning && !!spinningText && <div className='button-text'>{spinningText}</div>}
            </Button>
        );
    }
}
