import React, { PureComponent } from 'react';
import { Layer } from 'react-konva';

import Warp from './warpContainer';

type Props = {
    documentIds: string[];
    selectedDocumentId?: string;
    scale: number;
    cursorChange: (cursor: string) => void;
    offset: { x: number; y: number };
    container: HTMLElement;
};

export default class Warps extends PureComponent<Props> {
    render() {
        const { scale, offset, cursorChange, container, documentIds, selectedDocumentId } = this.props;
        return (
            <Layer>
                {documentIds.map((id) => (
                    <Warp
                        key={id}
                        documentId={id}
                        selected={selectedDocumentId === id}
                        container={container}
                        cursorChange={cursorChange}
                        scale={scale}
                        offset={offset}
                    />
                ))}
            </Layer>
        );
    }
}
