import React, { Component } from 'react';
import { Group } from 'react-konva';

import Border from './border';
import GrabPoints from './grabPointsContainer';
import Labels from './labels';
import Rotation from './rotation';

type Point = {
    x: number;
    y: number;
};

interface NPointWarpProps {
    container: HTMLElement;
    documentId: string;
    transformId: string;
    points: Point[];
    selectedPoint?: number;
    hidden?: boolean;
    selected: boolean;
    scale: number;
    cursorChange: (cursor: string) => void;
    offset: Point;
    onDragEnd: (params: { id: string; transformId: string; points: Point[] }) => void;
    rotationAngle?: number;
    setRotationAngle: (params: { angle: number; id: string }) => void;
    selectLayer: (documentId: string) => void;
}

interface NPointWarpState {
    dragging?: boolean;
    points: Point[];
}

export default class NPointWarp extends Component<NPointWarpProps, NPointWarpState> {
    state: NPointWarpState = {
        dragging: false,
        points: this.props.points,
    };

    setRotationAngle = (angle: number) => this.props.setRotationAngle({ angle, id: this.props.documentId });

    // We have to create our own drag move and drag end events since when react re-renders, it forgets that it was being dragged.
    onDragMove = (points: Point[]) => {
        this.setState({ points, dragging: true });
    };

    onDragEnd = () => {
        const { documentId, transformId, onDragEnd } = this.props;
        onDragEnd({ id: documentId, transformId, points: this.state.points });
        this.setState({ dragging: false });
    };

    static getDerivedStateFromProps(nextProps: NPointWarpProps, prevState: NPointWarpState): NPointWarpState | null {
        if (!prevState.dragging) {
            return { points: nextProps.points };
        }
        return null;
    }

    render() {
        const {
            scale,
            cursorChange,
            documentId,
            selectedPoint,
            offset,
            selected,
            selectLayer,
            hidden,
            container,
            rotationAngle,
        } = this.props;
        const { points } = this.state;
        if (!points || !points.length || hidden) {
            return null;
        }

        const scaledPoints = points.map((point) => ({ x: point.x * scale + offset.x, y: point.y * scale + offset.y }));

        let scaledAndExpandedPoints = scaledPoints;
        if (scaledPoints.length === 2) {
            scaledAndExpandedPoints = [
                scaledPoints[0],
                { x: scaledPoints[1].x, y: scaledPoints[0].y },
                scaledPoints[1],
                { x: scaledPoints[0].x, y: scaledPoints[1].y },
            ];
        }

        return (
            <Group>
                <Border
                    selected={selected}
                    points={points}
                    scaledPoints={scaledAndExpandedPoints}
                    scale={scale}
                    onDragMove={this.onDragMove}
                    onDragEnd={this.onDragEnd}
                    selectLayer={() => selectLayer(documentId)}
                />
                {selected && (
                    <Group>
                        {points.length > 2 && (
                            <Rotation
                                container={container}
                                scaledPoints={scaledAndExpandedPoints}
                                points={points}
                                scale={scale}
                                onDragMove={this.onDragMove}
                                onDragEnd={this.onDragEnd}
                                cursorChange={cursorChange}
                                offset={offset}
                                rotationAngle={rotationAngle!}
                                setRotationAngle={this.setRotationAngle}
                            />
                        )}
                        <GrabPoints
                            points={points}
                            scaledPoints={scaledAndExpandedPoints}
                            scale={scale}
                            onDragMove={this.onDragMove}
                            onDragEnd={this.onDragEnd}
                            cursorChange={cursorChange}
                            documentId={documentId}
                            selectedPoint={selectedPoint}
                            rotationAngle={rotationAngle!}
                        />
                        <Labels points={scaledPoints} selectedPoint={selectedPoint} />
                    </Group>
                )}
            </Group>
        );
    }
}
