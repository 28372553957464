import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getSelectedLayer, getAllDocuments } from 'src/selectors/layers';
import { DOCUMENT } from 'src/pages/editor/components/layer/layerTypes';
import warps from './warps';

const mapStateToProps = (state: RootState) => {
    const selectedLayer = getSelectedLayer(state);
    return {
        selectedDocumentId: selectedLayer.type === DOCUMENT ? selectedLayer.id : undefined,
        documentIds: getAllDocuments(state).map((doc) => doc.id),
    };
};

const warpsContainer = connect(mapStateToProps, {})(warps);

export default warpsContainer;
