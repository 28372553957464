import { createSlice } from '@reduxjs/toolkit';
import { SurfaceType } from 'src/types/surface';

type SurfaceSliceState = {
    sku: string;
    skuVersion: number | undefined;
    merchantSku: string | undefined;
    merchantVersion: number | undefined;
    lastFailedSku: string | undefined;
    isResolved: boolean;
    isReady: boolean;
    variables: Record<string, string>;
    list: SurfaceType[];
    error?: Error;
};

const initialState: SurfaceSliceState = {
    sku: '',
    skuVersion: undefined,
    merchantSku: undefined,
    merchantVersion: undefined,
    lastFailedSku: undefined,
    isResolved: false,
    isReady: false,
    variables: {},
    list: [],
};

const slice = createSlice({
    name: 'surfaces',
    initialState,
    reducers: {
        validateEditorSku: () => {},
        validateEditorSkuSuccess: (state, { payload }) => {
            state.sku = payload.sku;
            state.skuVersion = payload.skuVersion;
            state.merchantSku = payload.merchantSku;
            state.merchantVersion = payload.merchantVersion;
        },
        validateEditorSkuFailed: (state, { payload }) => {
            state.lastFailedSku = payload.sku;
            state.sku = '';
            state.skuVersion = 0;
            state.merchantSku = undefined;
            state.merchantVersion = undefined;
        },
        changeEditorSku: () => {},
        changeEditorSkuVersion: (state, { payload }) => {
            if (state.merchantSku) {
                state.merchantVersion = payload;
            } else {
                state.skuVersion = payload;
            }
        },
        clearEditorSku: (state, { payload }) => {
            state.sku = payload || '';
            state.skuVersion = undefined;
            state.merchantSku = undefined;
            state.merchantVersion = undefined;
            state.variables = {};
            state.isResolved = false;
            state.isReady = false;
            state.list = [];
        },
        changeEditorSkuSuccess: (state) => {
            state.variables = {};
            state.list = [];
            state.isReady = true;
        },
        changeEditorSkuFailed: (state, { payload }) => {
            state.error = {
                ...payload,
            };
        },
        applyVariables: (state, { payload }) => {
            state.variables = payload.variables;
            state.isResolved = payload.resolved;
        },
        retrieveSurfacesSuccess: (state, { payload }) => {
            state.list = payload;
            state.isResolved = true;
        },
        retrieveSurfacesFailed: () => {},
        unresolveVariables: (state, { payload }) => {
            state.variables = payload;
            state.isResolved = false;
        },
    },
});

export const reducer = slice.reducer;

export const {
    changeEditorSku,
    changeEditorSkuVersion,
    clearEditorSku,
    changeEditorSkuSuccess,
    changeEditorSkuFailed,
    validateEditorSku,
    validateEditorSkuSuccess,
    validateEditorSkuFailed,
    applyVariables,
    retrieveSurfacesSuccess,
    retrieveSurfacesFailed,
    unresolveVariables,
} = slice.actions;
