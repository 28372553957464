import { all, takeEvery, select, put, call } from 'redux-saga/effects';
import { RELOAD } from 'src/store/actions/actionTypes';
import {
    updateAssetRemovalFailed,
    updateAssetRemovalSuccess,
    updateBackgroundRemovalFailed,
    updateBackgroundRemovalSuccess,
    uploadBackgrounds,
} from 'src/pages/editor/components/backgrounds/slice';
import { overlayUploaded } from 'src/pages/editor/components/overlays/slice';
import { maskUploaded, textureUploaded } from 'src/pages/editor/components/documents/slice';
import { renderCanvasSuccess, renderCanvasFailed } from 'src/pages/editor/components/canvas/slice';
import {
    publishSceneSuccess,
    publishSceneFailed,
    duplicateSceneSuccess,
    duplicateSceneFailed,
    saveDraftSuccess,
    saveDraftFailed,
    createSceneFailed,
    createBulkScenesFailed,
    saveAndPublishSceneSuccess,
    saveAndPublishSceneFailed,
    createSceneFinished,
} from 'src/pages/editor/components/scene/slice';
import { loadEditorPage } from 'src/pages/editor/slice';
import {
    retrieveSurfacesSuccess,
    retrieveSurfacesFailed,
    validateEditorSkuSuccess,
    clearEditorSku,
} from 'src/pages/editor/components/surfaces/slice';
import { saveLinkVariationFailed, saveVariationFailed } from 'src/pages/editor/components/SceneVariation/slice';
import { getAllAlerts, getAllAlertsWithName } from './selectors';
import {
    renderFailed,
    publishComplete,
    publishFailed,
    saveComplete,
    saveFailed,
    bulkFailed,
    removeAlert,
    insertVariablesWarning,
    SaveVariantFailed,
    SaveLinkVariantFailed,
    ReplaceBackgroundRemovalFailed,
    ReplaceBackgroundRemovalSuccess,
} from './slice';

// Removing alerts
export function* removeAlertByName(name) {
    const alerts = yield select(getAllAlertsWithName, name);
    const removedAlerts = alerts.map((alert) => put(removeAlert(alert.id)));
    yield all(removedAlerts);
}

// Adding alerts
function* addCanvasFailed() {
    yield put(renderFailed());
}

function* addPublishSuccess() {
    yield put(publishComplete());
    yield call(removeAlertByName, 'PUBLISH_FAILED');
}

function* addPublishFailed() {
    yield put(publishFailed());
}

function* addSaveSuccess() {
    yield put(saveComplete());
    yield call(removeAlertByName, 'SAVE_FAILED');
}

function* addSaveFailed() {
    yield put(saveFailed());
}

function* addBulkFailed() {
    yield put(bulkFailed());
}

function* addVariablesWarning() {
    yield put(insertVariablesWarning());
}

function* addSaveVariantFailed() {
    yield put(SaveVariantFailed());
}

function* addSaveLinkVariantFailed() {
    yield put(SaveLinkVariantFailed());
}

function* alertRemovalBackgroundFailed() {
    yield put(ReplaceBackgroundRemovalFailed());
}

function* alertRemovalBackgroundSuccess() {
    yield put(ReplaceBackgroundRemovalSuccess());
}

function* clearRelatedFailures({ payload }) {
    const { name } = payload;
    switch (name) {
        case 'SAVE_COMPLETE':
            yield call(removeAlertByName, 'SAVE_FAILED');
            break;
        case 'PUBLISH_COMPLETE':
            yield call(removeAlertByName, 'PUBLISH_FAILED');
            break;
        case 'LINK_SERVER_SAVE_SUCCESS':
            yield call(removeAlertByName, 'LINK_FAILED');
            break;
        default:
        // none
    }
    return yield payload;
}

function* clearAllAlertsOtherThanCOAM() {
    const alerts = yield select(getAllAlerts);
    const removedAlerts = alerts
        .filter((alert) => alert.name !== 'NO_PERMISSIONS')
        .map((alert) => put(removeAlert(alert.id)));
    yield all(removedAlerts);
}

function* clearCanvasFailure() {
    yield call(removeAlertByName, 'RENDER_FAILED');
}

function* clearUploadFailed() {
    yield call(removeAlertByName, 'UPLOAD_FAILED');
}

function* clearLoadProductFailed() {
    yield call(removeAlertByName, 'LOAD_PRODUCT_FAILED');
}

function* clearLoadSceneFailed() {
    yield call(removeAlertByName, 'LOAD_SCENE_FAILED');
}

function* clearVariabledWarning() {
    yield call(removeAlertByName, 'INSERT_VARIABLES_WARNING');
    yield call(removeAlertByName, 'PRODUCT_MISSING');
}

export default function* alertsSagas() {
    return yield all([
        yield takeEvery(createSceneFinished, addPublishSuccess),
        yield takeEvery(createSceneFailed, addPublishFailed),
        yield takeEvery(saveAndPublishSceneSuccess, addPublishSuccess),
        yield takeEvery(saveAndPublishSceneFailed, addPublishFailed),
        yield takeEvery(publishSceneSuccess, addPublishSuccess),
        yield takeEvery(publishSceneFailed, addPublishFailed),
        yield takeEvery(duplicateSceneSuccess, addSaveSuccess),
        yield takeEvery(duplicateSceneFailed, addSaveFailed),
        yield takeEvery(saveDraftSuccess, addSaveSuccess),
        yield takeEvery(saveDraftFailed, addSaveFailed),
        yield takeEvery(createBulkScenesFailed, addBulkFailed),
        yield takeEvery(renderCanvasFailed, addCanvasFailed),
        yield takeEvery(retrieveSurfacesFailed, addVariablesWarning),
        yield takeEvery(removeAlert, clearRelatedFailures),
        yield takeEvery(renderCanvasSuccess, clearCanvasFailure),
        yield takeEvery(uploadBackgrounds, clearUploadFailed),
        yield takeEvery(overlayUploaded, clearUploadFailed),
        yield takeEvery(maskUploaded, clearUploadFailed),
        yield takeEvery(textureUploaded, clearUploadFailed),
        yield takeEvery(validateEditorSkuSuccess, clearLoadProductFailed),
        yield takeEvery(clearEditorSku, clearLoadProductFailed),
        yield takeEvery(retrieveSurfacesSuccess, clearVariabledWarning),
        yield takeEvery(RELOAD, clearLoadSceneFailed),
        yield takeEvery(loadEditorPage, clearAllAlertsOtherThanCOAM),
        yield takeEvery(saveVariationFailed, addSaveVariantFailed),
        yield takeEvery(saveLinkVariationFailed, addSaveLinkVariantFailed),
        yield takeEvery(updateBackgroundRemovalFailed, alertRemovalBackgroundFailed),
        yield takeEvery(updateBackgroundRemovalSuccess, alertRemovalBackgroundSuccess),
        yield takeEvery(updateAssetRemovalFailed, alertRemovalBackgroundFailed),
        yield takeEvery(updateAssetRemovalSuccess, alertRemovalBackgroundSuccess),
    ]);
}
