import { createSelector } from 'reselect';
import { RootState } from 'src/store';

const getBkgRemovalId = (state: RootState) => state.pages.editor.backgrounds.backgroundRemoval.backgroundId;

export const getSelectedBackgroundRemoval = createSelector([getBkgRemovalId], (id) => id);

const getAssetRemovalId = (state: RootState) => state.pages.editor.backgrounds.backgroundRemoval.assetId;

export const getSelectedAssetRemoval = createSelector([getAssetRemovalId], (id) => id);
