import React, { PureComponent } from 'react';
import { Group, Text } from 'react-konva';

interface Point {
    x: number;
    y: number;
}

interface LabelsProps {
    points: Point[];
    selectedPoint?: number;
}

const TEXT_POSITIVE_OFFSET = 10;
const TEXT_NEGATIVE_OFFSET = -25;
const FONT_SIZE = 20;

export default class Labels extends PureComponent<LabelsProps> {
    render() {
        const { points, selectedPoint } = this.props;
        const labels = points.map((point, index) => {
            const selected = selectedPoint === index + 1;
            const fontSize = FONT_SIZE * (selected ? 1.5 : 1);
            const fill = selected ? '#00b5e2' : '#222222';
            let xOffset;
            let yOffset;
            if (points.length === 2) {
                xOffset = index ? TEXT_POSITIVE_OFFSET : TEXT_NEGATIVE_OFFSET;
                yOffset = index ? TEXT_POSITIVE_OFFSET : TEXT_NEGATIVE_OFFSET;
            } else {
                xOffset = index % 3 !== 0 ? TEXT_POSITIVE_OFFSET : TEXT_NEGATIVE_OFFSET;
                yOffset = index >= 2 ? TEXT_POSITIVE_OFFSET : TEXT_NEGATIVE_OFFSET;
            }
            return (
                <Text
                    key={index} // eslint-disable-line react/no-array-index-key
                    x={point.x + xOffset}
                    y={point.y + yOffset}
                    text={`${index + 1}`}
                    fontSize={fontSize}
                    fontFamily='Arial'
                    fill={fill}
                    align='center'
                />
            );
        });

        return <Group>{labels}</Group>;
    }
}
