import { htmlSpecialChars } from 'src/util/url';
import { toArray } from 'src/util/converter';
import { NORMAL } from 'src/models/blendingModes';
import { BACKGROUND } from 'src/pages/editor/components/layer/layerTypes';
import { BackgroundType } from 'src/types/background';
import { XMLTree_DIPComposite, XMLTree_Image } from 'src/types/xml';

/**
 *
 * @param {{ height: string, width: string }} param0
 * @description: Function that take an object and parse it to string.
 * @example: sizeToString({ height: 20, width: 40 }) => '40,20'
 */
export function sizeToString({ height, width }: Pick<BackgroundType, 'height' | 'width'>) {
    return `${width},${height}`;
}

/**
 *
 * @param {string} size
 * @param {string = ','} splitter
 * @description: Functions that take a string and parse it to Object
 * @example: sizeToObject('1000,800') => { width, height };
 */
export function sizeToObject(size: string, splitter = ',') {
    const [width, height] = size.split(splitter);
    return { width, height };
}

/* eslint-disable no-underscore-dangle */
export function buildBackgroundObject(background: BackgroundType, size?: string) {
    return {
        src: htmlSpecialChars(background.url),
        size: size || sizeToString(background),
        type: BACKGROUND,
        name: background.name,
        mode: background.mode,
    };
}

export function xmlToBackgroundObject(xmlObject: XMLTree_DIPComposite) {
    let images = toArray(xmlObject.Image);
    // TODO: Here we are assuming any image with mode NORMAL is a background, we can do this better
    images = images.filter((a: XMLTree_Image) => a._mode === NORMAL);

    return {
        selected: 'Background 0',
        list: images.map((img: XMLTree_Image, i: number) => {
            const size = sizeToObject(img._size);
            return {
                id: `Background ${i}`,
                mode: img._mode || NORMAL,
                url: img._src,
                uploading: false,
                name: img._name,
                width: parseInt(size.width),
                height: parseInt(size.height),
            };
        }),
    };
}
