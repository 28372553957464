import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { updateDocumentReference } from 'src/pages/editor/components/canvas/slice';
import { getEditorDocImage } from 'src/selectors/canvas';
import DocRefSelector from './docRefSelector';

const mapStateToProps = (state: RootState) => {
    const existingDocRef = getEditorDocImage(state).reference;

    return { existingDocRef };
};

const docRefSelectorContainer = connect(mapStateToProps, {
    onUpdateDocRef: updateDocumentReference,
})(DocRefSelector);

export default docRefSelectorContainer;
