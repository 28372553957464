import React, { PureComponent } from 'react';
import { Select } from '@cimpress/react-components';

interface SurfaceProps {
    activeSurface?: number | string;
    surfaces?: { value: number | string; label: number | string }[];
    onSelect: (e: any) => void;
}

const MAX_PAGE = 2;

export default class Surface extends PureComponent<SurfaceProps> {
    render() {
        const { activeSurface, surfaces = [], onSelect } = this.props;
        // When no surfaces are present fill two pages with values and labels
        if (!surfaces.length) {
            for (let i = 1; i <= MAX_PAGE; i++) {
                surfaces.push({ value: i, label: i });
            }
        }
        // Build the surface label from the provided surface information
        const currentSurface = surfaces.find((s) => s.value === activeSurface);
        const activeSurfaceLabel = currentSurface ? currentSurface.label : activeSurface;

        return (
            <div className='surface'>
                <Select
                    label='Surface'
                    className='surface__select'
                    options={surfaces}
                    value={{ value: activeSurface, label: activeSurfaceLabel }}
                    onChange={onSelect}
                    isClearable={false}
                    isSearchable={false}
                />
            </div>
        );
    }
}
