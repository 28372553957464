import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { RELOAD } from 'src/store/actions/actionTypes';

import { reducer as alerts } from 'src/components/alert/slice';
import { reducer as application } from 'src/slice';
import { reducer as links } from 'src/components/links/slice';
import pages from 'src/pages/reducer';
import { reducer as scenes } from 'src/components/scenes/slice';
import { reducer as skus } from 'src/components/skus/slice';
import { reducer as sceneConfiguration } from 'src/pages/editor/components/SceneVariation/slice';

const reducers = {
    application,
    alerts,
    links,
    pages,
    scenes,
    skus,
    sceneConfiguration,
};

// A guide on how reducers work http://redux.js.org/docs/basics/Reducers.html
const appReducer = (history) => combineReducers({ router: connectRouter(history), ...reducers });

// TODO we should remove this silly reload wrapper at some point
export default function rootReducer(history) {
    // Can be used to both load a scene and to completely reset.
    // Should preserve cross-page data.
    return (state, action) => {
        let newState = state;
        if (action.type === RELOAD) {
            newState = Object.assign(action.payload, {
                scenes: state.scenes,
                application: state.application,
                alerts: state.alerts,
                sceneConfiguration: state.sceneConfiguration,
            });
        }
        return appReducer(history)(newState, action);
    };
}
