import type { RootState } from 'src/store';
import { connect } from 'react-redux';
import { getSelectedPoint, getTransformsById } from 'src/selectors/transforms';
import { editTransformPoint, selectTransformPoint } from '../../slice';
import mapPoints from './mapPoints';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const selectedPoint = getSelectedPoint(state, ownProps.id);
    const transforms = getTransformsById(state, ownProps.id);
    let transform;
    if (transforms && transforms.length) {
        transform = transforms[0];
    }
    return {
        transform,
        selectedPoint,
    };
};

const mapPointsContainer = connect(mapStateToProps, {
    onSubmit: editTransformPoint,
    onSelectPoint: selectTransformPoint,
})(mapPoints);

export default mapPointsContainer;
