import { Prompt } from 'react-router-dom';
import React from 'react';
import { connect, useStore } from 'react-redux';
import isSceneModified from 'src/selectors/pages/editor/isSceneModified';
import type { RootState } from 'src/store';

interface UnsavedChangesAlertProps {
    hasChanges: boolean;
}

function UnsavedChangesAlert({ hasChanges }: UnsavedChangesAlertProps) {
    const store = useStore<RootState>();

    return (
        <Prompt
            when={hasChanges}
            message={() => {
                const hasChanges = isSceneModified(store.getState());
                return hasChanges ? 'You have unsaved changes. If you continue, you will lose your changes.' : true;
            }}
        />
    );
}

export default connect((state: RootState) => ({
    hasChanges: isSceneModified(state),
}))(UnsavedChangesAlert);
