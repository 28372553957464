import createCachedSelector from 're-reselect';

import getDocumentById from '../layers/getDocumentById';

const getSelectedPoint = createCachedSelector(
    [getDocumentById],
    (doc) => doc.selectedPoint,
)((state, documentId) => documentId);

export default getSelectedPoint;
