import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getRulerById } from 'src/selectors/layers';
import { updateSegments } from 'src/pages/editor/components/rulers/slice';
import segments from './segments';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => ({
    // @ts-expect-error -- fix type for `getRulerById`
    segments: getRulerById(state, ownProps.id).segments,
});

const segmentsContainer = connect(mapStateToProps, { updateSegments })(segments);

export default segmentsContainer;
