import { createSelector } from 'reselect';

import getAllSkuData from 'src/selectors/skus/getAllSkuData';
import getSku from './getSku';
import { RootState } from 'src/store';

const isResolved = (state: RootState) => state.pages.editor.surfaces.isResolved;

const isLinkSetupInvalid = createSelector([getAllSkuData, getSku, isResolved], (skus, sku, resolved) => {
    const skuData = skus[sku];
    if (!skuData || !skuData.requiredVariables) {
        return false;
    }

    const { requiredVariables } = skuData;
    const missingVariables = !!requiredVariables.length && !resolved;
    return missingVariables;
});

export default isLinkSetupInvalid;
