import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import storeManager from 'src/store/storeManager';
import { AppContainer } from './app';

import 'normalize-scss';
import '../content/scss/main.scss';

const root = createRoot(document.getElementById('react-root')!);

root.render(
    <Provider store={storeManager.store}>
        <AppContainer />
    </Provider>,
);
