import { connect } from 'react-redux';
import { isApplicationLoading, isBulkMode, isPageLoading } from 'src/selectors';
import { setTenant } from 'src/slice';
import { loadEditorPage } from './slice';
import app from './app';
import { RootState } from 'src/store';
import { getShowPSDModal } from 'src/import/psd/selectors';

const mapStateToProps = (state: RootState) => ({
    loading: isPageLoading(state) || isApplicationLoading(state),
    isBulkMode: isBulkMode(state),
    isPSDOpen: getShowPSDModal(state),
});

const AppContainer = connect(mapStateToProps, { loadEditorPage, setTenant })(app);

export default AppContainer;
