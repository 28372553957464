import { apiPrefix } from 'src/settings';
import { appendQueryParameters } from 'src/util/url';
import { logError, logInfo } from 'src/loggingManager';
import { parseError } from 'src/util/misc';
import { makeAuthenticatedServiceRequest } from './servicesClient';
import { AssetResponseType, AssetType } from 'src/types/asset';

const DEFAULT_PAGE_SIZE = 10;
const BASE_URL = `https://${apiPrefix}assets.documents.cimpress.io/v3/assets`;
const DEFAULT_ASSET_TYPE = 'scene';

/** Generates a URI to a specific version of an asset */
export function generateVersionContentUri({ assetId, versionId }: { assetId: string; versionId: string }) {
    if (!assetId || !versionId) {
        return undefined;
    }
    return `${BASE_URL}/${assetId}/revisions/${versionId}/content`;
}

/** Generates a URI to the content of the published version */
export function generatePublishedContentUri({ assetId }: { assetId: string }) {
    if (!assetId) {
        return undefined;
    }
    return `${BASE_URL}/${assetId}/content`;
}

/** Loads an asset by url. */
export function loadAssetUri(url: string) {
    return makeAuthenticatedServiceRequest({ url })
        .then((response) => response.data)
        .catch((e) => {
            logError(`Load Asset url error (url=${url}): ${parseError(e)}`);
            throw e;
        });
}

// Helper to parse an asset
export function parseAsset(asset: AssetResponseType): AssetType {
    return {
        latestVersionId: asset.latestRevisionId,
        publishedVersionId: asset.publishedRevisionId,
        id: asset.id,
        name: asset.name,
        mcpSku: asset.referenceId,
        mcpSkuVersion: asset.referenceIdVersion,
        description: asset.description,
        notes: asset.notes,
        created: asset.created,
        owner: asset.owner,
        lastModified: asset.lastModified,
        modifiedBy: asset.modifiedBy,
        lastPublished: asset.lastPublished,
        publishedBy: asset.publishedBy,
        variables: asset.attributes || {},
        tenantType: asset.tenant.type,
        tenantId: asset.tenant.id,
        deleted: asset.deleted || false,
        tags: asset.tags || [],
        publishedVersions: (asset.recentPublishedRevisions || []).map((version) => ({
            id: version.revisionId,
            date: version.publishedAt,
            publisher: version.publishedBy,
        })),
    };
}

/**
 * Creates an asset
 * @param options
 * @param options.tenantType - The type of owner resource (merchant / fulfiller)
 * @param options.tenantId - The id of the resource
 * @param options.asset - The updated asset object
 * @param options.asset.assetType - The type of the asset
 * @param options.asset.name - The updated asset name
 * @param options.asset.description - The updated asset description
 * @param options.asset.referenceId - The updated sku
 * @param options.asset.attributes - The updated sku variables
 * @param options.asset.tags - string array of tags for the asset
 * @param options.asset.initialRevision - the initial version of this asset
 * @param options.asset.initialRevision.uri - the URI to the initial version's content
 * @param [options.asset.initialRevision.metadata] - the metadata for this type of asset
 * @returns The promise of the request.
 */
export function createAsset({
    tenantType,
    tenantId,
    asset,
}: {
    tenantType: string;
    tenantId: string;
    asset: {
        assetType: string;
        name: string;
        description: string;
        referenceId: string;
        attributes: Record<string, any>;
        tags: string[];
        initialRevision: { uri: string; metadata?: Record<string, any> };
    };
}): Promise<AssetType> {
    const data = {
        ...asset,
        tenant: {
            id: tenantId,
            type: tenantType,
        },
    };
    return makeAuthenticatedServiceRequest({ method: 'post', url: BASE_URL, data })
        .then((response) => {
            logInfo(`Created asset: ${response} ${JSON.stringify(response || {})}`);
            return parseAsset(response.data);
        })
        .catch((e) => {
            logError(`Creating asset failed (data=${JSON.stringify(data)}): ${parseError(e)}`);
            throw e;
        });
}

/**
 * Updates an asset
 * @param options
 * @param options.tenantType - The type of owner resource (merchant / fulfiller)
 * @param options.tenantId - The id of the resource
 * @param options.assetId - The id of the asset
 * @param options.newAsset - The updated asset object
 * @param options.newAsset.assetType - The type of the asset
 * @param options.newAsset.name - The updated asset name
 * @param options.newAsset.description - The updated asset description
 * @param options.newAsset.referenceId - The updated sku
 * @param options.newAsset.attributes - The updated sku variables
 * @param options.newAsset.latestRevisionId - The updated latest version
 * @param options.newAsset.publishedRevisionId - The updated published version
 * @param options.newAsset.tags - string array of tags for the asset
 * @param options.newAsset.deleted - if the asset is deleted or not
 * @returns The promise of the request
 */
export function updateAsset({
    tenantType,
    tenantId,
    assetId,
    newAsset,
}: {
    tenantType: string;
    tenantId: string;
    assetId: string;
    newAsset: {
        assetType: string;
        name: string;
        description: string;
        referenceId: string;
        attributes: Record<string, any>;
        latestRevisionId: string;
        publishedRevisionId: string;
        tags: string[];
        deleted: boolean;
    };
}) {
    const url = `${BASE_URL}/${assetId}`;
    const data = {
        ...newAsset,
        tenant: {
            id: tenantId,
            type: tenantType,
        },
    };
    return makeAuthenticatedServiceRequest({ method: 'put', url, data, header: { 'If-Match': '*' } }).catch((e) => {
        logError(`Updating asset failed (url=${url}) (data=${JSON.stringify(data)}): ${parseError(e)}`);
        throw e;
    });
}

/**
 * Creates an asset version
 * @param options
 * @param options.assetId - The id of the asset
 * @param options.version - The asset version object
 * @param options.version.uri - The uri to the new version's content
 * @param [options.version.metadata] - metadata for this type of asset
 * @returns The promise of the request.
 */
export function createVersion({
    assetId,
    version,
}: {
    assetId: string;
    version: { uri: string; metadata?: Record<string, any> };
}) {
    const url = `${BASE_URL}/${assetId}/revisions`;
    return makeAuthenticatedServiceRequest({ method: 'post', url, data: version }).catch((e) => {
        logError(`Creating asset version failed (url=${url}) (data=${JSON.stringify(version)}): ${parseError(e)}`);
        throw e;
    });
}

/**
 * Get a specific version of a specific asset
 * @param options
 * @param options.assetId - The id of the asset
 * @param options.versionId - The id of the version
 * @returns The promise of the request
 */
export function getVersion({ assetId, versionId }: { assetId: string; versionId: string }): Promise<any> {
    const url = `${BASE_URL}/${assetId}/revisions/${versionId}`;
    return makeAuthenticatedServiceRequest({ url })
        .then((response) => response.data)
        .catch((e) => {
            logError(`Load versioned asset failed (url=${url}): ${parseError(e)}`);
            throw e;
        });
}

/**
 * Gets all versions of an asset
 * @param options
 * @param options.assetId - The id of the asset.
 * @returns The promise of the request.
 */
export function getAllVersions({ assetId }: { assetId: string }) {
    const url = `${BASE_URL}/${assetId}/revisions`;
    return makeAuthenticatedServiceRequest({ url })
        .then((response) =>
            response.data._embedded.item.map((version: TODO) => ({
                id: version.id,
                created: version.created,
                creator: version.createdBy,
            })),
        )
        .catch((e) => {
            logError(`Getting all versions for asset failed (url=${url}): ${parseError(e)}`);
            throw e;
        });
}

/**
 * Gets an asset
 * @param options
 * @param options.assetId - The id of the asset
 * @returns The promise of the request
 */
export function getAsset({ assetId }: { assetId: string }) {
    const url = `${BASE_URL}/${assetId}`;
    return makeAuthenticatedServiceRequest({ url })
        .then((response) => parseAsset(response.data))
        .catch((e) => {
            logError(`Getting asset failed (url=${url}): ${parseError(e)}`);
            throw e;
        });
}

/**
 * Delete an asset
 * @param options
 * @param options.assetId - The ID of the asset
 */
export function deleteAsset({ assetId }: { assetId: string }) {
    const url = `${BASE_URL}/${assetId}`;
    return makeAuthenticatedServiceRequest({ method: 'delete', url, header: { 'If-Match': '*' } }).catch((e) => {
        logError(`Deleting asset failed (url=${url}): ${parseError(e)}`);
        throw e;
    });
}

/**
 * Restore an asset
 * @param options
 * @param options.assetId - The ID of the asset
 */
export function restoreAsset({ assetId }: { assetId: string }) {
    const url = `${BASE_URL}/${assetId}`;
    return makeAuthenticatedServiceRequest({
        method: 'put',
        url,
        data: { deleted: false },
        header: { 'If-Match': '*' },
    }).catch((e) => {
        logError(`Restoring asset failed (url=${url}): ${parseError(e)}`);
        throw e;
    });
}

/**
 * List assets
 * @param options
 * @param [options.url] - url to request, used for pagination/offets. If provided, all other params are ignored.
 * @param options.tenantType - The type of owner resource (merchant / fulfiller).
 * @param options.tenantId - The id of the resource.
 * @param [options.owner] - owner to query for.
 * @param [options.assetType] - the type of asset to query
 * @param [options.includeDeleted=false] - flag to show deleted assets or not.
 * @param [options.pageSize] - page size for responses.
 * @param [options.searchType='Description']
 * @param [options.searchText]
 * @param [options.searchEngine]
 * @returns The promise of the request.
 */
export function queryAssets({
    assetType = DEFAULT_ASSET_TYPE,
    tenantId,
    tenantType,
    includeDeleted = false,
    pageSize = DEFAULT_PAGE_SIZE,
    owner,
    url,
    searchText,
    searchType = 'Description',
    searchEngine = 'ElasticSearch',
}: {
    url?: string;
    tenantType: string;
    tenantId: string;
    owner?: string;
    assetType?: string;
    includeDeleted?: boolean;
    pageSize?: number;
    searchType?: string;
    searchText?: string;
    searchEngine?: string;
}) {
    let requestUrl = url;
    if (!requestUrl) {
        const queryParams = {
            assetType,
            tenantType,
            tenantId,
            pageSize,
            includeDeleted,
            ...(owner && { owner }),
            ...(searchType && searchText && { [searchType]: searchText }),
            searchEngine,
            client: 'sceneMaker',
        };

        requestUrl = appendQueryParameters(`${BASE_URL}:query`, queryParams);
    }

    return makeAuthenticatedServiceRequest({ url: requestUrl })
        .then((response) => {
            // eslint-disable-next-line no-underscore-dangle
            const assets = response.data._embedded.item.map((result: TODO) => parseAsset(result));
            // eslint-disable-next-line no-underscore-dangle
            const nextLink = response.data._links.next;
            const next = nextLink && nextLink.href;
            return { scenes: assets, next, curr: requestUrl };
        })
        .catch(() => ({ scenes: [], curr: requestUrl }));
}
