import createCachedSelector from 're-reselect';

import getAllBackgrounds from './getAllBackgrounds';
import { RootState } from 'src/store';

const getId = (state: RootState, id: string) => id;

const getBackgroundById = createCachedSelector([getAllBackgrounds, getId], (backgrounds, id) =>
    backgrounds.find((background) => background.id === id),
)((state, backgroundId) => backgroundId);

export default getBackgroundById;
