/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

export function htmlSpecialChars(unsafe: string | undefined) {
    return unsafe && unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

export function getLocationParameters() {
    const { search } = window.location;
    if (!search) {
        return {};
    }
    return search
        .substring(1)
        .split('&')
        .reduce((params: Record<string, string>, item) => {
            const [key, value] = item.split('=');
            params[key] = value; // eslint-disable-line no-param-reassign
            return params;
        }, {});
}

export function copyUrlToClipboard(url: string) {
    const textArea = document.createElement('textarea');
    textArea.value = url;
    textArea.style.position = 'fixed';
    textArea.style.left = '-1000';
    textArea.style.top = '-1000';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
}

export function downloadBlob(url: string) {
    return axios({
        url,
        responseType: 'blob',
    });
}

export function generateQueryString(parameters: Record<string, any>) {
    if (!parameters) {
        return '';
    }
    return Object.entries(parameters)
        .filter(([, v]) => v !== undefined && v !== null && v !== '')
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
}

export function appendQueryParameters(url: string, parameters: Record<string, any>) {
    const query = generateQueryString(parameters);
    if (!query) {
        return url;
    }
    return `${url}${url.includes('?') ? '&' : '?'}${query}`;
}
