import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import { isBackgroundUploading, getBackgroundUrl } from 'src/selectors/layers';
import backgroundButton from 'src/components/UploadButton';
import idGeneratorAtor from 'src/util/idGeneratorAtor';
import { uploadBackgrounds } from './slice';

const idGenerator = idGeneratorAtor('Background');

const mapStateToProps = (state: RootState) => {
    const uploaded = !!getBackgroundUrl(state);
    return {
        uploaded,
        uploading: isBackgroundUploading(state),
        label: uploaded ? 'Replace Background' : 'Upload Background',
        className: 'upload-button--background',
        accept: 'image/png, image/jpeg, image/bmp, application/x-photoshop, application/octet-stream, image/vnd.adobe.photoshop',
        multiple: true,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onUpload: (e: any) => {
        const { files } = e.target;
        if (files.length) {
            const newBackgrounds = Array.from(files).map((file, index) => ({
                id: idGenerator(),
                file,
                isFirstUpload: index === 0,
            }));
            dispatch(uploadBackgrounds(newBackgrounds));
        }
    },
});

const backgroundButtonContainer = connect(mapStateToProps, mapDispatchToProps)(backgroundButton);

export default backgroundButtonContainer;
