import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { reducer as backgrounds } from './components/backgrounds/slice';
import { reducer as canvas } from './components/canvas/slice';
import { reducer as documents } from './components/documents/slice';
import { reducer as overlays } from './components/overlays/slice';
import { reducer as rulers } from './components/rulers/slice';
import { reducer as scene } from './components/scene/slice';
import { reducer as selectedLayer } from './components/layer/slice';
import { reducer as surfaces } from './components/surfaces/slice';

const slice = createSlice({
    name: 'loader',
    initialState: null,
    reducers: {
        loadEditorPage: (
            _state,
            _action: PayloadAction<{ tenantType?: string; tenantId?: string; sceneId?: string; versionId?: string }>,
        ) => {},
    },
});

export const loadEditorPage = slice.actions.loadEditorPage;

export const reducer = combineReducers({
    backgrounds,
    canvas,
    documents,
    loader: slice.reducer,
    overlays,
    rulers,
    scene,
    selectedLayer,
    surfaces,
});
