import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import { getDocumentById } from 'src/selectors/layers';
import { getSurfaces } from 'src/selectors/pages/editor';
import { DOCUMENT, LayerType } from 'src/pages/editor/components/layer/layerTypes';
import { changePage } from '../slice';
import surfaceView from './surface';

const mapStateToProps = (state: RootState, ownProps: { id: string; type: LayerType }) => {
    const surfaces = getSurfaces(state).map((surface, index) => ({ value: index + 1, label: surface.name }));

    let activeSurface;
    if (ownProps.type === DOCUMENT) {
        activeSurface = getDocumentById(state, ownProps.id).page;
    }

    return {
        activeSurface,
        surfaces,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: { id: string }) => ({
    onSelect: (page: TODO) => dispatch(changePage({ id: ownProps.id, page: page.value })),
});

const surfaceContainer = connect(mapStateToProps, mapDispatchToProps)(surfaceView);

export default surfaceContainer;
