import React, { PureComponent } from 'react';
import { Toggle } from '@cimpress/react-components';

interface FullWrapProps {
    fullWrapEnabled?: boolean;
    id?: string;
    onToggle: (id: string) => void;
}

export default class FullWrap extends PureComponent<FullWrapProps> {
    render() {
        const { fullWrapEnabled, id, onToggle } = this.props;
        return (
            <div className='full-wrap' data-testid='transform-fullwrap-wrapper'>
                Full Wrap
                <Toggle on={fullWrapEnabled} onClick={() => onToggle(id!)} />
            </div>
        );
    }
}
