import { appendQueryParameters } from 'src/util/url';
import { makeAuthenticatedServiceRequest } from './servicesClient';
import { AxiosResponse } from 'axios';
import {
    SurfaceAreaType,
    SurfaceFoldLineType,
    SurfaceGroupType,
    SurfaceResponseAdditionalDataType,
    SurfaceResponseType,
} from 'src/types/surface';

const ENDPOINT = 'https://surface.products.cimpress.io';
const ENDPOINT_V2 = 'https://api.products.cimpress.io';

// TODO: revisit this function cause it is ugly
function parseClipArea(docAdditionalData: SurfaceResponseAdditionalDataType, type: string) {
    if (!docAdditionalData) {
        return;
    }

    if (!docAdditionalData.masks || docAdditionalData.masks.length === 0) {
        return;
    }

    for (let i = 0; i < docAdditionalData.masks.length; i++) {
        const maskElement = docAdditionalData.masks[i];

        if (!maskElement.pathType || maskElement.pathType !== type) {
            continue; // eslint-disable-line no-continue
        }

        if (!maskElement.paths || maskElement.paths.length === 0) {
            continue; // eslint-disable-line no-continue
        }

        const maskPaths: SurfaceAreaType[] = [];
        maskElement.paths.forEach((maskPath) => {
            if (!maskPath.pathPoints || maskPath.pathPoints.length !== 3) {
                if (maskElement.boundingArea && maskElement.boundingArea.type === 'Rectangle') {
                    maskPaths.push({
                        x: maskElement.boundingArea.position.x,
                        y: maskElement.boundingArea.position.y,
                        width: maskElement.boundingArea.position.width,
                        height: maskElement.boundingArea.position.height,
                        pathPoints: maskPath.pathPoints,
                        anchorX: maskPath.anchorX, // We need this value but unknown why is being replace for the boundingArea :\
                        anchorY: maskPath.anchorY,
                    });
                }
                return;
            }

            maskPaths.push({
                x: maskPath.anchorX,
                y: maskPath.anchorY,
                anchorX: maskPath.anchorX,
                anchorY: maskPath.anchorY,
                width: maskPath.pathPoints[1].endPointX - maskPath.anchorX,
                height: maskPath.pathPoints[1].endPointY - maskPath.anchorY,
                pathPoints: maskPath.pathPoints,
            });
        });

        // eslint-disable-next-line consistent-return
        return maskPaths;
    }
}

function parseFoldLines(docAdditionalData: SurfaceResponseAdditionalDataType) {
    if (!docAdditionalData || !docAdditionalData.guides || docAdditionalData.guides.length === 0) {
        return;
    }
    const fold: SurfaceFoldLineType[] = [];
    for (let i = 0; i < docAdditionalData.guides.length; i++) {
        const guideElement = docAdditionalData.guides[i];

        if (guideElement.pathType === 'FOLD' && guideElement.paths && guideElement.paths.length !== 0) {
            guideElement.paths.forEach((path) => {
                // straight fold lines
                if (
                    path.pathPoints.length === 1 &&
                    !path.pathPoints[0].firstControlPointX &&
                    !path.pathPoints[0].secondControlPointX
                ) {
                    fold.push({
                        startX: path.anchorX,
                        startY: path.anchorY,
                        endX: path.pathPoints[0].endPointX,
                        endY: path.pathPoints[0].endPointY,
                    });
                }
            });
        }
    }

    return fold; // eslint-disable-line consistent-return
}

function mapSurface(surface: SurfaceResponseType) {
    const fullBleedAreas = parseClipArea(surface.docAdditionalData, 'BLEED') || [];
    const trimAreas = parseClipArea(surface.docAdditionalData, 'TRIM') || [];
    let fullBleedArea = {};

    if (!fullBleedAreas || fullBleedAreas.length === 0) {
        fullBleedArea = {
            x: 0,
            y: 0,
            width: surface.widthCm,
            height: surface.heightCm,
        };
    } else {
        fullBleedArea = fullBleedAreas[0];
    }

    return {
        id: surface.id,
        name: surface.name,
        technology: surface.processType,
        trimArea: trimAreas.length > 0 ? trimAreas[0] : {}, // Principal TrimArea use as default
        fullBleedArea, // Principal FullBleedArea us as default
        foldLines: parseFoldLines(surface.docAdditionalData),
        width: surface.widthCm,
        height: surface.heightCm,
        trimAreas, // All trim areas use for automasking
        fullBleedAreas, // All fullBleedAreas use for automasking
    };
}

function mapSurfaceGroup(group: SurfaceGroupType) {
    return {
        id: group.id,
        surfaces: group.surfaces.map(mapSurface),
    };
}

function parseProductResponse(response: AxiosResponse | void) {
    // Validate the surface spec contains at least one surface group
    if (response && response.data && response.data.surfaceGroups.length > 0) {
        // Validate each surface group contains non-empty surfaces
        for (let i = 0; i < response.data.surfaceGroups.length; i++) {
            if (response.data.surfaceGroups[i].surfaces.length === 0) {
                throw new Error('An empty surface was found in the surface specification!');
            }
        }

        return response.data.surfaceGroups.map(mapSurfaceGroup);
    } else {
        throw new Error('No surface groups found in the surface specification!');
    }
}

/**
 * Retrieves SKU information from the surface specifications service.
 * @param sku - The product SKU.
 * @param variables - Optional product variables.
 * @returns - A promise of the product information.
 */
export function retrieveSurfaceData(sku: string, skuVersion: number | undefined, variables = {}) {
    return makeAuthenticatedServiceRequest({
        url: appendQueryParameters(
            `${ENDPOINT}/v3/calculatedSurfaceSets/${sku}${skuVersion ? `/${skuVersion}` : ''}`,
            variables,
        ),
    }).then(parseProductResponse);
}

function parseV2Body(response: AxiosResponse | void) {
    if (response && 'data' in response) {
        const location =
            response.data &&
            response.data.relatedResources &&
            response.data.relatedResources.calculatedSurfaceSet[0] &&
            response.data.relatedResources.calculatedSurfaceSet[0].definitionHref;
        if (location === undefined) {
            return Promise.resolve(undefined);
        }
        return makeAuthenticatedServiceRequest({
            url: location,
        }).then(
            (resp) => resp.data.variables,
            () => [],
        );
    }
}

function parseVariables(response: AxiosResponse, sku: string) {
    // If there is some information then this is configured as a v1 product
    if (response.data && response.data[0]) {
        return (response.data && response.data[0] && response.data[0].variables) || [];
    }
    // Try as v2 product, if fails then the product doesn't have surfaces configured
    return makeAuthenticatedServiceRequest({
        url: `${ENDPOINT_V2}/v1/products/${sku}:current`,
    }).then(parseV2Body, () => undefined);
}

/**
 * Retrieves required variable attributes from the surface specifications service.
 * @param sku - The product SKU.
 * @returns - A promise of the information.
 */
export function retrieveRequiredVariables(sku: string) {
    return makeAuthenticatedServiceRequest({
        url: `${ENDPOINT}/v3/surfaceSets?mcpSku=${sku}`,
    }).then((resp) => parseVariables(resp, sku));
}
