import { all, call, put, takeEvery, select } from 'redux-saga/effects';

import { logError } from 'src/loggingManager';
import { getSurfaceProperties } from 'src/selectors/pages/editor';
import { fetchSurfaces } from 'src/components/links/saga';
import { retrieveRulesForProduct, fetchProduct, buildSku, loadVersions } from 'src/components/skus/saga';
import { getSkuById } from 'src/selectors/skus';
import {
    applyVariables,
    changeEditorSkuSuccess,
    changeEditorSkuFailed,
    retrieveSurfacesFailed,
    retrieveSurfacesSuccess,
    changeEditorSku,
    validateEditorSku,
    validateEditorSkuSuccess,
    validateEditorSkuFailed,
    changeEditorSkuVersion,
} from './slice';

/**
 * Editor page has a weird flow for changing skus compared to the links page. Centralized that logic here.
 * @param {string} payload.sku
 */
function* changeSkuForEditor() {
    try {
        const { sku, skuVersion } = yield select(getSurfaceProperties);
        const payload = { sku, skuVersion };

        if (payload.sku) {
            yield call(fetchProduct, {
                payload: { sku: payload.sku, skuVersion: payload.skuVersion, throwAgain: true },
            });
            yield put(changeEditorSkuSuccess());
            try {
                const surfaces = yield call(fetchSurfaces, payload);
                yield put(retrieveSurfacesSuccess(surfaces));
            } catch (error) {
                yield put(retrieveSurfacesFailed());
            }
            yield call(retrieveRulesForProduct, { payload });
        } else {
            logError('Failed to change product sku in editor page: Sku is required');
            yield put(changeEditorSkuFailed(payload.sku));
        }
    } catch (error) {
        logError(`Failed to change product sku in editor page: ${error}`);
        yield put(changeEditorSkuFailed(error));
    }
}

function* validateSkuForEditor({ payload }) {
    try {
        if (payload) {
            let version;
            let hasNoVersions = payload.startsWith('VIP-');
            // In case the sku does not support versions, don't try to get the versions
            if (!hasNoVersions) {
                yield call(loadVersions, { payload });
                const product = yield select(getSkuById, payload);
                version =
                    !product.versions || product.versions.length === 0
                        ? undefined
                        : product.versions[product.versions.length - 1].version;
                hasNoVersions = product.isV1Product;
            }

            const mcp = yield call(buildSku, { sku: payload, skuVersion: version });

            // It's a PRD Product
            if (mcp.platformId !== payload) {
                yield put(
                    validateEditorSkuSuccess({
                        sku: mcp.platformId,
                        skuVersion: mcp.platformIdVersion,
                        merchantSku: payload,
                        merchantVersion: version,
                    }),
                );
            } else {
                yield put(validateEditorSkuSuccess({ sku: payload, skuVersion: version }));
            }

            // If the sku doesn't support versions, try to get the surfaces right away.
            if (hasNoVersions) {
                yield call(changeSkuForEditor);
            }
        } else {
            logError('Failed to change product sku in editor page: Sku is required');
            yield put(validateEditorSkuFailed(payload));
        }
    } catch (error) {
        logError(`Failed to change product sku in editor page: ${payload} ${error}`);
        yield put(validateEditorSkuFailed(payload));
    }
}

function* getSurfaces() {
    const { isResolved, variables, sku, skuVersion, mcpSku } = yield select(getSurfaceProperties);
    if (isResolved) {
        try {
            const surfaces = yield call(fetchSurfaces, { sku: mcpSku || sku, skuVersion, variables });
            yield put(retrieveSurfacesSuccess(surfaces));
        } catch (error) {
            logError(`Failed to get surfaces despite a resolved variable set. ${error}`);
            yield put(retrieveSurfacesFailed());
        }
    }
}

function* changeSkuEditorVersion({ payload }) {
    const { merchantSku } = yield select(getSurfaceProperties);
    if (merchantSku) {
        const mcp = yield call(buildSku, { sku: merchantSku, skuVersion: payload });

        if (mcp !== undefined) {
            yield put(
                validateEditorSkuSuccess({
                    sku: mcp.platformId,
                    skuVersion: mcp.platformIdVersion,
                    merchantSku,
                    merchantVersion: payload,
                }),
            );
        }
    }
}

export default function* editorSurfacesSagas() {
    return yield all([
        yield takeEvery(changeEditorSku, changeSkuForEditor),
        yield takeEvery(validateEditorSku, validateSkuForEditor),
        yield takeEvery(applyVariables, getSurfaces),
        yield takeEvery(changeEditorSkuVersion, changeSkuEditorVersion),
    ]);
}
