import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getCanvasImage } from 'src/selectors/canvas';
import { getDocumentById } from 'src/selectors/layers';
import { getTransformsById } from 'src/selectors/transforms';
import { dragTransformPoint } from 'src/pages/editor/components/documents/slice';
import { getSurfaceByPage } from 'src/selectors/pages/editor';
import sourcePoint from './sourcePoint';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const { points, id: transformId, type: warpType } = getTransformsById(state, ownProps.id)[0];
    const { url: previewUrl } = getCanvasImage(state);

    const activePage = getDocumentById(state, ownProps.id).page;
    const surfaceData = getSurfaceByPage(state, activePage);

    return {
        warpType,
        points,
        transformId,
        surfaceData,
        previewUrl,
    };
};

const sourcePointContainer = connect(mapStateToProps, { onApply: dragTransformPoint })(sourcePoint);

export default sourcePointContainer;
