import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'src/store';
import { setAIData, generateImagesAI, confirmImageAI, changeMode, setAIModalOpen } from 'src/slice';
import { getAIData, getAIResult, isAIGeneration, isAIMode } from 'src/selectors';
import { Alert, Button, Spinner, TextField } from '@rendering/components';
import { Modal } from '@cimpress/react-components/';
import { getAllBackgrounds } from 'src/selectors/layers';

export interface IAIGenerationProps {
    data: Record<string, string | boolean | string[] | undefined>;
    images?: string[];
    isGenerating: boolean;
    setAIData: (payload: { field: string; value: string }) => void;
    generateImages: (active: boolean) => void;
    confirmImage: (image: string) => void;
    onBack: () => void;
    onCancel: () => void;
    show: boolean;
    isFirstTime: boolean;
}

function AIGeneration(props: IAIGenerationProps) {
    const [error, setError] = React.useState<Record<string, string>>({});
    const { description, width, height } = props.data;

    const onGenerate = () => {
        props.generateImages(true);
    };
    const onTextChange = (event: React.ChangeEvent<HTMLInputElement> & { isValid: boolean }) => {
        if (!event.target.value) {
            setError((cuError) => ({ ...cuError, description: 'Description is required' }));
        } else {
            setError((cuError) => ({ ...cuError, description: '' }));
        }
        props.setAIData({ field: 'description', value: event.target.value });
    };
    const onWidthChange = (event: React.ChangeEvent<HTMLInputElement> & { isValid: boolean }) => {
        props.setAIData({ field: 'width', value: event.target.value });
        !event.target.value || parseInt(event.target.value) < 1000
            ? setError((cuError) => ({ ...cuError, width: 'Width must be greater or equal than 1000' }))
            : setError((cuError) => ({ ...cuError, width: '' }));
    };
    const onHeightChange = (event: React.ChangeEvent<HTMLInputElement> & { isValid: boolean }) => {
        props.setAIData({ field: 'height', value: event.target.value });
        !event.target.value || parseInt(event.target.value) < 800
            ? setError((cuError) => ({ ...cuError, height: 'Width must be greater or equal than 800' }))
            : setError((cuError) => ({ ...cuError, height: '' }));
    };
    const onConfirmImage = (image: string) => {
        props.confirmImage(image);
    };
    const onCancel = () => (props.isFirstTime ? props.onBack() : props.onCancel());

    const hasResults = props.images && props.images.length > 0;
    const generateIsDisabled = Object.values(error).some((i) => i !== '') || props.isGenerating || description === '';

    return (
        <div className='ai-generation'>
            <TextField
                value={description as string}
                onChange={onTextChange}
                helpText={error['description']}
                status={error['description'] ? 'error' : 'success'}
                label='What would you like to create today? you can try "A sunset at the beach"'
            />
            {props.isFirstTime && (
                <div className='ai-generation-size'>
                    <TextField
                        helpText={error['width']}
                        value={width as string}
                        onChange={onWidthChange}
                        type='number'
                        label='Width'
                        status={error['width'] ? 'error' : 'success'}
                    />
                    <TextField
                        helpText={error['height']}
                        value={height as string}
                        onChange={onHeightChange}
                        type='number'
                        label='Height'
                        status={error['height'] ? 'error' : 'success'}
                    />
                </div>
            )}
            <div className='ai-generation-actions'>
                <Button variant='default' onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={onGenerate} disabled={generateIsDisabled}>
                    Generate Image
                </Button>
            </div>

            <div className='ai-generation-result'>
                {props.isGenerating && <Spinner size='large' />}
                {hasResults && (
                    <>
                        <Alert
                            message='Click on the image you want to use to continue or change the description and generate images
                            again'
                            status='info'
                        />

                        <div className='ai-generation-images-wrapper'>
                            {props.images?.map((img, i) => (
                                <img
                                    className='ai-generation-image'
                                    key={i}
                                    src={img}
                                    alt={`Generated from description ${i}`}
                                    onClick={() => onConfirmImage(img)}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

function AIModal(props: IAIGenerationProps) {
    return (
        <Modal className='generate-scene-modal' show={props.show} title='Generate Image' size='xl'>
            <div className='generate-scene-modal__body'>
                <AIGeneration {...props} />
            </div>
        </Modal>
    );
}

const mapStateToProps = (state: RootState) => ({
    data: getAIData(state),
    images: getAIResult(state),
    isGenerating: isAIGeneration(state),
    show: isAIMode(state),
    isFirstTime: getAllBackgrounds(state).length == 0,
});

const AIGenerationContainer = connect(mapStateToProps, {
    setAIData,
    generateImages: generateImagesAI,
    confirmImage: confirmImageAI,
    onBack: () => changeMode('default'),
    onCancel: () => setAIModalOpen(false),
})(AIModal);

export default AIGenerationContainer;
