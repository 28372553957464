import type { RootState } from 'src/store';
import { connect } from 'react-redux';
import { getDocumentById } from 'src/selectors/layers';
import transformEditor from './transformEditor';
import type { LayerType } from '../../../layer/layerTypes';

const mapStateToProps = (state: RootState, ownProps: { id: string; type: LayerType }) => {
    const { warpType } = getDocumentById(state, ownProps.id);

    return {
        warpType,
    };
};

const transformEditorContainer = connect(mapStateToProps, {})(transformEditor);

export default transformEditorContainer;
