import { BLENDING_MODE_CHANGE, LAYER_TOGGLE_HIDE } from './actionTypes';

export const toggleHide = (id, type) => ({
    type: LAYER_TOGGLE_HIDE,
    payload: {
        id,
        type,
    },
});

export const changeMode = (id, type, mode) => ({
    type: BLENDING_MODE_CHANGE,
    payload: {
        mode,
        id,
        type,
    },
});
