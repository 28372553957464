import React, { PureComponent, ChangeEvent } from 'react';
import { TextField } from '@cimpress/react-components';

interface PrecisionWrapProps {
    diameter?: number;
    width?: number;
    id?: string;
    disabled?: boolean;
    updatePrecisionWrap: (data: { id?: string; width?: number; diameter?: number }) => void;
}

interface PrecisionWrapState {
    width?: number;
    validWidth?: number;
    diameter?: number;
    validDiameter?: number;
}

export default class PrecisionWrap extends PureComponent<PrecisionWrapProps, PrecisionWrapState> {
    state: PrecisionWrapState = {
        width: this.props.width,
        validWidth: this.props.width,
        diameter: this.props.diameter,
        validDiameter: this.props.diameter,
    };

    onChangeWidth = (e: ChangeEvent<HTMLInputElement>) => {
        this.onChange(e, 'width');
    };

    onChangeDiameter = (e: ChangeEvent<HTMLInputElement>) => {
        this.onChange(e, 'diameter');
    };

    onChange = (e: ChangeEvent<HTMLInputElement>, prop: 'width' | 'diameter') => {
        const value = parseFloat(e.target.value);
        const data = { [prop]: value } as { width: number } | { diameter: number };
        this.setState(data);
        if (Number.isFinite(value)) {
            const { updatePrecisionWrap, id } = this.props;
            const { width, diameter } = this.state;
            updatePrecisionWrap({ id, width, diameter, ...data });
        }
    };

    static getDerivedStateFromProps(nextProps: PrecisionWrapProps, prevState: PrecisionWrapState) {
        if (nextProps.width !== prevState.validWidth || nextProps.diameter !== prevState.validDiameter) {
            return {
                width: nextProps.width,
                diameter: nextProps.diameter,
                validWidth: nextProps.width,
                validDiameter: nextProps.diameter,
            };
        }
        return null;
    }

    render() {
        const { width, diameter } = this.state;
        const { disabled } = this.props;
        return (
            <div className='precision-wrap'>
                <TextField
                    label='Product Diameter'
                    value={diameter}
                    onChange={this.onChangeDiameter}
                    type='number'
                    onFocus={(e) => e.target.select()}
                    disabled={disabled}
                />
                <TextField
                    label='Decoration Width'
                    value={width}
                    onChange={this.onChangeWidth}
                    type='number'
                    onFocus={(e) => e.target.select()}
                    disabled={disabled}
                />
            </div>
        );
    }
}
