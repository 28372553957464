import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import navigationManager from './navigationManager';
import createRootReducer from './rootReducer';
import rootSaga from './sagas';
// import initData from './initData';

export type RootState = ReturnType<typeof store.getState>;
export type Store = typeof store;
export type AppDispatch = typeof store.dispatch;

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddlewares) =>
        getDefaultMiddlewares({ serializableCheck: false }).concat([routerMiddleware(history), sagaMiddleware]),
    devTools: true,
});

sagaMiddleware.run(rootSaga);

navigationManager(store);

export default {
    history,
    store,
};
