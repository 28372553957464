import { connect } from 'react-redux';

import UploadIcon from 'src/components/UploadIcon';
import idGeneratorAtor from 'src/util/idGeneratorAtor';
import { uploadOverlays } from './slice';

const idGenerator = idGeneratorAtor('Overlay');

const mapStateToProps = () => ({
    className: 'add-layer',
    testid: 'editor-scene-overlays-add',
    multiple: true,
});

const mapDispatchToProps = (dispatch) => ({
    onUpload: (e) => {
        const { files } = e.target;
        if (files.length) {
            const newOverlays = Array.from(files).map((file) => ({
                id: idGenerator(),
                file,
            }));
            dispatch(uploadOverlays(newOverlays));
        }
    },
});

const addOverlayContainer = connect(mapStateToProps, mapDispatchToProps)(UploadIcon);

export default addOverlayContainer;
