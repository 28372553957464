/* eslint-disable @typescript-eslint/no-explicit-any */
import { htmlSpecialChars } from 'src/util/url';
import { toArray } from 'src/util/converter';
import { getBoundingRectangle, calculateUnrotatedWidthAndHeight } from 'src/util/math';
import idGeneratorAtor from 'src/util/idGeneratorAtor';
import { DOCUMENT, INSTRUCTION, MASK } from 'src/pages/editor/components/layer/layerTypes';
import { ENGRAVING_CHANNEL, NORMAL } from 'src/models/blendingModes';
import { CYLINDER_WARP, DOCUMENT_ID, SMOOTH_WARP, TEXTURE_WARP } from 'src/models/documentWarps';
import { deflateRaw } from 'pako';
import { Document, ENGRAVING_COLOR } from 'src/pages/editor/components/documents/slice';
import { SurfaceType } from 'src/types/surface';
import {
    AutoMaskInstructionType,
    DocumentXmlObject,
    EngravingImageType,
    XMLTree_CompositeComposite,
    XMLTree_DIP,
} from 'src/types/xml';
import { PointType } from 'src/types';
import { TransformTypeField } from 'src/types/transforms';

const idGenerator = idGeneratorAtor(DOCUMENT_ID);

const BACKGROUND_DOC_PLACEHOLDER = (color: string) => ({
    documentId: 'demoEllipseDocument',
    version: '2',
    deleted: false,
    document: {
        panels: [
            {
                id: 'surface1',
                name: 'Item',
                width: '1mm',
                height: '1mm',
                shapes: [
                    {
                        type: 'rectangle',
                        zIndex: 0,
                        position: {
                            x: '0mm',
                            y: '0mm',
                            width: '1mm',
                            height: '1mm',
                        },
                        id: 'shape1',
                        color: `rgb(${color})`,
                        itemType: 'shape',
                    },
                ],
                decorationTechnology: 'offsetOrDigital',
            },
        ],
    },
    fontRepositoryUrl:
        'https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published',
});

/* eslint-disable no-underscore-dangle */
export function buildDocumentObject(doc: Document) {
    const { page } = doc;

    let size;
    if (doc.width || doc.height) {
        size = `${doc.width},${doc.height}`;
    } else {
        const { points, type } = (doc.transforms || [])[0];
        let { width, height } = calculateUnrotatedWidthAndHeight(points);
        const sourceBox = getBoundingRectangle(points.map((point) => ({ x: point.sourceX, y: point.sourceY })));
        if (sourceBox.width !== 1 || sourceBox.height !== 1) {
            width /= sourceBox.width;
            height /= sourceBox.height;
        }

        if (type === SMOOTH_WARP) {
            const length = Math.ceil(Math.max(width, height));
            size = `${length},${length}`;
        } else {
            size = `${Math.ceil(width)},${Math.ceil(height)}`;
        }
    }

    return {
        size,
        name: doc.id,
        type: DOCUMENT,
        mode: doc.mode,
        page,
        offset: 0,
    };
}

export function buildMaskObject(doc: Document, size: string) {
    const { id, maskName, maskUrl } = doc;
    if (!maskUrl) {
        return undefined;
    }

    return {
        size,
        src: htmlSpecialChars(maskUrl),
        name: maskName || `${id}-Mask`,
        type: MASK,
        mode: MASK,
        depth: 0,
    };
}

function buildInstructionsContent(docSize: PointType, surface: SurfaceType) {
    const { trimAreas, fullBleedAreas } = surface;

    const Contours = trimAreas.map((trimArea, i) => {
        const widthScaler = docSize.x / fullBleedAreas[i].width;
        const heightScaler = docSize.y / fullBleedAreas[i].height;
        const { pathPoints } = trimArea;

        // Always start in the middle of the document
        const StartPointX = trimArea.anchorX * widthScaler;
        const StartPointY = trimArea.anchorY * heightScaler;

        const calculateSegments = pathPoints.map((pathPoint) => {
            const isCubic = pathPoint.firstControlPointX !== undefined && pathPoint.secondControlPointX !== undefined;
            let extra = {};
            const type = isCubic ? 'CubicTo' : 'LineTo';
            if (isCubic) {
                extra = {
                    ControlPoint1: {
                        X: pathPoint.firstControlPointX * widthScaler,
                        Y: pathPoint.firstControlPointY * heightScaler,
                    },
                    ControlPoint2: {
                        X: pathPoint.secondControlPointX * widthScaler,
                        Y: pathPoint.secondControlPointY * heightScaler,
                    },
                };
            }
            return {
                Type: type,
                ...extra,
                EndPoint: {
                    X: pathPoint.endPointX * widthScaler,
                    Y: pathPoint.endPointY * heightScaler,
                },
            };
        });

        return {
            Closed: true,
            StartPoint: {
                X: StartPointX,
                Y: StartPointY,
            },
            ContourSegments: calculateSegments,
        };
    });
    return JSON.stringify([
        {
            Type: 'Path',
            Fill: {
                Color: {
                    R: 0,
                    G: 0,
                    B: 0,
                    A: 255,
                },
            },
            PathContours: {
                Contours,
            },
        },
    ]);
}

export function buildAutomask(doc: DocumentXmlObject, surface: SurfaceType | undefined): AutoMaskInstructionType {
    const sizeValues = doc.size.split(',');
    const docSize = {
        x: parseInt(sizeValues[0]),
        y: parseInt(sizeValues[1]),
    };
    const content = surface ? buildInstructionsContent(docSize, surface) : undefined;

    return {
        size: doc.size,
        name: `${doc.name}-Mask`,
        mode: MASK,
        depth: 0,
        type: INSTRUCTION,
        content,
    };
}

export function buildEngravingImage(size: string, color = ENGRAVING_COLOR): EngravingImageType {
    const [height, width] = size.split(',');
    const content = JSON.stringify(BACKGROUND_DOC_PLACEHOLDER(color));
    const deflatedContent = deflateRaw(content, { to: 'string' });
    const encodedContent = encodeURIComponent(btoa(deflatedContent));
    const instructionsUri = `https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=${encodedContent}`;

    return {
        size,
        src: htmlSpecialChars(
            `https://rendering.documents.cimpress.io/v1/cse/preview?width=${width}&height=${height}&` +
                `format=webp&instructions_uri=${encodeURIComponent(instructionsUri)}`,
        ),
        name: 'Silver-Engraving',
        mode: NORMAL,
        depth: 1,
    };
}

const hasMask = (composite: XMLTree_CompositeComposite) => {
    let founded = false;
    Object.keys(composite).forEach((key) => {
        if (founded) {
            return;
        }

        const ele = composite[key];
        if (key === 'Image' && ele._mode === MASK) {
            founded = true;
        } else if (typeof ele === 'object') {
            founded = hasMask(ele);
        }
    });

    return founded;
};

const hasAutomask = (composite: XMLTree_CompositeComposite) => {
    let founded = false;
    Object.keys(composite).forEach((key) => {
        if (founded) {
            return;
        }

        const ele = composite[key];
        if (key === 'Instructions' && ele._mode === MASK) {
            founded = true;
        }
    });

    return founded;
};
const sortDocuments = (a: XMLTree_CompositeComposite, b: XMLTree_CompositeComposite): number =>
    a._name < b._name ? -1 : a._name > b._name ? 1 : 0;

export function xmlToDocumentObjects(dip: XMLTree_DIP) {
    const transformIdMap: Record<string, any> = {};
    const addWarp = (name: string, warpType: TransformTypeField) =>
        toArray(dip.Transforms[name]).forEach(
            (transform: Record<string, any>) => (transformIdMap[transform._id] = warpType),
        ); // eslint-disable-line no-return-assign
    addWarp('RectangleWarp', 'RectangleWarp');
    addWarp('PerspectiveWarp', 'PerspectiveWarp');
    addWarp('TubeWarp', 'CylinderWarp');
    addWarp('PlacementWarp', 'CylinderWarp'); //TODO: Check why they are using cylinder for both tube and placement
    addWarp('SmoothWarp', 'SmoothWarp');
    addWarp('TextureWarp', 'TextureWarp');

    const composites: XMLTree_CompositeComposite[] = toArray(dip.Composite.Composite);
    const documents = composites
        .slice()
        .sort(sortDocuments) // The order matters to place documents with more depth underneath others.
        .map((composite) => {
            let doc: Document | undefined;
            let documentNode = composite.Document;
            if (!documentNode && composite.Composite) {
                documentNode =
                    composite.Composite.Document ||
                    (composite.Composite.Composite && composite.Composite.Composite.Document);
            }
            if (documentNode) {
                doc = {
                    mode: composite._mode || NORMAL,
                    warpType: transformIdMap[documentNode._xform],
                    page: parseInt(documentNode._page),
                    id: documentNode._name || idGenerator(),
                    transforms: [],
                };
                if (doc.warpType === CYLINDER_WARP || doc.warpType === TEXTURE_WARP) {
                    const size = documentNode._size.split(',');
                    Object.assign(doc, {
                        width: parseFloat(size[0]),
                        height: parseFloat(size[1]),
                    });
                }

                if (documentNode._channel === ENGRAVING_CHANNEL) {
                    doc.isEngraving = true;
                    // doc.maskHidden = true;
                }
                if (hasMask(composite) && composite.Image) {
                    doc.maskUrl = composite.Image._src;
                    doc.maskName = composite.Image._name;
                }
                if (hasAutomask(composite)) {
                    doc.automask = true;
                }
            }

            return doc;
        })
        .filter((doc) => doc !== undefined) as Document[];

    return documents;
}
