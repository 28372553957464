import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getBackgroundById, getOverlayById, getDocumentById, getRulerById } from 'src/selectors/layers';
import { BACKGROUND, OVERLAY, DOCUMENT, LayerType } from './layerTypes';
import layer from './layer';
import { changeBackgroundName } from '../backgrounds/slice';

const LOADING_TEXT = 'uploading...';

const mapStateToProps = (state: RootState, ownProps: { id: string; type: LayerType }) => {
    let title = ownProps.id;
    let hidden: boolean | undefined;
    let url: string | undefined = '';
    if (ownProps.type === BACKGROUND) {
        const backgroundDetails = getBackgroundById(state, ownProps.id)!;
        title = backgroundDetails.name || LOADING_TEXT;
        return {
            title,
            hidden,
            width: backgroundDetails.width,
            height: backgroundDetails.height,
            url: backgroundDetails.url,
        };
    }
    if (ownProps.type === OVERLAY) {
        const overlay = getOverlayById(state, ownProps.id)!;
        title = overlay.name || LOADING_TEXT;
        hidden = overlay.hidden;
        url = overlay.url;
    } else if (ownProps.type === DOCUMENT) {
        hidden = !!getDocumentById(state, ownProps.id).hidden;
    } else {
        // @ts-expect-error -- fix type for `getRulerById`
        hidden = !!getRulerById(state, ownProps.id).hidden;
    }
    return {
        title,
        hidden,
        url,
    };
};

const layerContainer = connect(mapStateToProps, {
    changeBackgroundName,
})(layer);

export default layerContainer;
