import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import SpinnerButton from 'src/components/SpinnerButton';

export default class SaveDuplicate extends PureComponent {
    render() {
        const { spinning, duplicate, disabled } = this.props;
        return (
            <div className='tools-duplicate'>
                <SpinnerButton
                    testid='editor-footer-duplicate'
                    onClick={duplicate}
                    text='Duplicate'
                    disabled={disabled}
                    spinning={spinning}
                />
            </div>
        );
    }
}

SaveDuplicate.propTypes = {
    spinning: PropTypes.bool,
    duplicate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
