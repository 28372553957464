/* eslint-disable @typescript-eslint/no-explicit-any */
import { COMPOSITE, DOCUMENT } from 'src/pages/editor/components/layer/layerTypes';

import { RECTANGLE_WARP, PERSPECTIVE_WARP, SMOOTH_WARP, TEXTURE_WARP } from 'src/models/documentWarps';
import { PERSPECTIVE_TRANSFORM, TUBE_TRANSFORM, PLACEMENT_TRANSFORM } from 'src/models/transforms';
import { getSurfaces } from 'src/selectors/pages/editor';
import { ENGRAVING_CHANNEL, MASK, NORMAL } from 'src/models/blendingModes';
import { getAllDocuments, getAllOverlays, getAllBackgrounds, getSceneSize } from 'src/selectors/layers';
import { getAllTransforms } from 'src/selectors/transforms';
import { buildTransformsArray } from './transforms';
import { buildAutomask, buildDocumentObject, buildEngravingImage, buildMaskObject } from './documents';
import { buildOverlayObject } from './overlays';
import { buildBackgroundObject, sizeToString } from './backgrounds';
import { RootState } from 'src/store';
import { BackgroundType } from 'src/types/background';
import { SurfaceType } from 'src/types/surface';
import {
    AutoMaskInstructionType,
    CompositeTreeRootType,
    CompositeTreeType,
    DocumentXmlObject,
    EngravingCompositeType,
    MaskImageType,
    XMLCompositeType,
} from 'src/types/xml';
import { PlacementTransformType, TubeTransformType } from 'src/types/transforms';
import { Document, DocumentSliceState } from 'src/pages/editor/components/documents/slice';
import { OverlaySliceState } from 'src/pages/editor/components/overlays/slice';

function handleEngraving(
    id: string,
    document: DocumentXmlObject,
    root: XMLCompositeType,
    mask?: MaskImageType,
    isEngraving?: boolean,
    engravingColor?: string,
) {
    const composite = Object.assign({}, root);
    const documentObject = Object.assign({}, document);
    let engraving;
    let compositeEngraving: EngravingCompositeType;

    if (isEngraving) {
        engraving = buildEngravingImage(root.size, engravingColor);
    }

    if (mask && engraving) {
        compositeEngraving = {
            size: root.size,
            name: `${id}-engraving-wrapper`,
            type: COMPOSITE,
            mode: documentObject.mode,
            depth: 1,
        };
        const engraveDoc = Object.assign({}, documentObject);
        engraveDoc.type = DOCUMENT;
        engraveDoc.mode = MASK;
        engraveDoc.channel = ENGRAVING_CHANNEL;
        engraveDoc.depth = 0;
        compositeEngraving.children = [engraveDoc, engraving];

        composite.children = [compositeEngraving, mask];
        documentObject.depth = 1;
    } else if (engraving) {
        documentObject.type = DOCUMENT;
        documentObject.mode = MASK;
        documentObject.channel = ENGRAVING_CHANNEL;
        documentObject.depth = 0;
        composite.mode = NORMAL;
        composite.children = [documentObject, engraving];
    } else if (mask) {
        documentObject.depth = 1;
        composite.children = [documentObject, mask];
    } else {
        composite.children = [documentObject];
    }

    return composite;
}

function documentCompositeBuilder(doc: Document, size: string, hideEnabled: boolean, surfaces: SurfaceType[]) {
    const { warpType: documentWarp, hidden, maskHidden, id, isEngraving, automask } = doc;
    const surface = surfaces.find((surf) => surf.index === doc.page);
    if (hideEnabled && hidden) {
        return null;
    }
    const documentObject: DocumentXmlObject = buildDocumentObject(doc);
    documentObject.depth = 0;

    let mask: MaskImageType | undefined = undefined;

    if (!hideEnabled || !maskHidden) {
        mask = buildMaskObject(doc, size);
    }

    const transformsData = doc.transforms || [];
    let composite: XMLCompositeType = {
        size,
        name: `${id}-wrapper`,
        type: COMPOSITE,
        mode: isEngraving ? NORMAL : documentObject.mode,
        children: [] as Record<string, any>[],
    };

    documentObject.mode = NORMAL;

    if (
        documentWarp === RECTANGLE_WARP ||
        documentWarp === PERSPECTIVE_WARP ||
        documentWarp === SMOOTH_WARP ||
        documentWarp === TEXTURE_WARP
    ) {
        documentObject.xform = transformsData[0].id;

        if (automask) {
            composite.children = [documentObject];
            const automaskInstruction: AutoMaskInstructionType = buildAutomask(documentObject, surface);
            automaskInstruction.xform = transformsData[0].id;
            composite.children.push(automaskInstruction);
        } else {
            composite = handleEngraving(id, documentObject, composite, mask, isEngraving, doc.engravingColor);
        }
    } else {
        // CYLINDER_WARP
        const compositeXform = transformsData.find((transform) => transform.type === PERSPECTIVE_TRANSFORM)!;
        const tubeTransform = transformsData.find(
            (transform) => transform.type === TUBE_TRANSFORM,
        ) as TubeTransformType;
        const placementTransform = transformsData.find(
            (transform) => transform.type === PLACEMENT_TRANSFORM,
        ) as PlacementTransformType;

        let compositeChildren;
        let engraving;
        const tubeTransformSize = tubeTransform
            ? `${tubeTransform.width},${tubeTransform.height}`
            : documentObject.size;
        const placementTransformSize = placementTransform
            ? `${placementTransform.width},${placementTransform.height}`
            : documentObject.size;

        const tempChildren: any[] = [documentObject];
        if (isEngraving) {
            documentObject.channel = ENGRAVING_CHANNEL;
            documentObject.depth = 0;
            documentObject.mode = MASK;
            documentObject.xform = placementTransform ? placementTransform.id : tubeTransform.id;
            engraving = buildEngravingImage(
                placementTransform ? placementTransformSize : tubeTransformSize,
                doc.engravingColor,
            );
            engraving.xform = placementTransform ? placementTransform.id : tubeTransform.id;
            tempChildren.push(engraving);
        } else if (automask && surface) {
            const automaskInstruction = buildAutomask(documentObject, surface);
            automaskInstruction.xform = placementTransform ? placementTransform.id : tubeTransform.id;
            tempChildren.push(automaskInstruction);
        }

        if (placementTransform) {
            documentObject.xform = placementTransform.id;
            compositeChildren = [
                {
                    size: placementTransformSize,
                    name: `${id}-inner-placement-wrapper`,
                    mode: NORMAL,
                    type: COMPOSITE,
                    xform: tubeTransform.id,
                    depth: mask ? 1 : 0,
                    children: tempChildren,
                },
            ];
        } else {
            documentObject.xform = tubeTransform.id;
            compositeChildren = tempChildren;
        }

        composite.children = [
            {
                size: tubeTransformSize,
                name: `${id}-inner-wrapper`,
                mode: NORMAL,
                type: COMPOSITE,
                xform: compositeXform.id,
                depth: mask ? 1 : 0,
                children: compositeChildren,
            },
        ];

        if (mask) {
            composite.children.push(mask);
        }
    }

    return composite;
}

function buildComposite(
    maxSize: string,
    backgrounds: BackgroundType[],
    documentsData: DocumentSliceState,
    overlaysData: OverlaySliceState,
    hideEnabled: boolean,
    surfaces: SurfaceType[],
) {
    const backgroundsItems = backgrounds.map((bkg) => buildBackgroundObject(bkg));
    const documents = documentsData
        .map((doc) => documentCompositeBuilder(doc, maxSize, hideEnabled, surfaces))
        .filter(Boolean);
    const overlays = overlaysData.map((overlay) => buildOverlayObject(overlay, maxSize, hideEnabled)).filter(Boolean);

    const children = [...overlays, ...documents, ...backgroundsItems].map((node, index) => {
        // TODO: Find a better option to allow adding depth without spamming types
        const child = node as Record<string, any>;
        if (child) {
            child.depth = index; // eslint-disable-line no-param-reassign
        }
        return child;
    });

    return {
        children,
        size: maxSize,
        mode: NORMAL,
        depth: 0,
        name: 'root',
    } as CompositeTreeRootType;
}

export function buildCompositeTree(state: RootState, background: BackgroundType, hideEnabled: boolean) {
    // eslint-disable-line import/prefer-default-export
    let backgrounds = getAllBackgrounds(state);
    const surfaces = getSurfaces(state);
    let maxSize = null;

    if (!background && backgrounds.length === 0) {
        return undefined;
    }

    if (background) {
        backgrounds = [background];
        maxSize = sizeToString(background);
    } else {
        const firstDocumentSize = getSceneSize(state);
        maxSize = sizeToString(firstDocumentSize);
    }

    const documents = getAllDocuments(state);
    const overlays = getAllOverlays(state);
    const transforms = getAllTransforms(state) || [];

    const data: CompositeTreeType = {
        root: buildComposite(maxSize, backgrounds, documents, overlays, hideEnabled, surfaces),
        transforms: buildTransformsArray(transforms, maxSize),
    };

    return data;
}
