const VARIABLE_TOKEN_REGEX = /#{(.*?)}/g;
const VARIABLE_TOKEN_REPLACE_REGEX = /#{|}/g;

export default class Attribute {
    key;

    selectedValue;

    impliedValue;

    type;

    required;

    derived;

    attributeValues;

    attributeRanges;

    validValues;

    validRanges;

    formula;

    hidden;

    overridden;

    valid;

    /**
     * Attribute Constructor
     * @param $0.key The attribute key
     * @param $0.selectedValue The current selected value for the attribute
     * @param $0.type The attribute type
     * @param $0.required Whether or not this attribute is required for selection resolution
     * @param $0.derived Arbitrary way to define whether an attribute is displayed NOTE: Not well defined
     * @param $0.attributeValues Possible values for a listOfValue type attribute
     * @param $0.attributeRanges Possible values for a listOfRanges type attribute
     * @param $0.formula Formula to calculate the attribute's value
     * @param $0.hidden Whether or not this attribute should be hidden
     * @param $0.overridden Whether or not this attribute's selection has been overridden
     */
    constructor({
        key,
        selectedValue,
        type,
        required,
        derived = false,
        attributeValues,
        attributeRanges,
        formula,
        hidden = false,
        overridden = false,
    }) {
        this.key = key;
        this.selectedValue = selectedValue;
        this.type = type;
        this.required = required;
        this.derived = derived;
        this.attributeValues = attributeValues;
        this.attributeRanges = attributeRanges;
        this.formula = formula;
        this.hidden = hidden;
        this.overridden = overridden;
        this.validValues = attributeValues;
        this.validRanges = attributeRanges;
        this.valid = true;
    }

    get selection() {
        return this.selectedValue || this.impliedValue || undefined;
    }

    clearSelection = () => {
        delete this.selectedValue;
        this.valid = true;
    };

    areRangesImplied = () =>
        this.attributeRanges &&
        this.attributeRanges.length === 1 &&
        this.attributeRanges[0].minimum === this.attributeRanges[0].maximum;

    areValuesImplied = () => this.attributeValues && this.attributeValues.length === 1;

    // TODO fix type
    isImplied = () =>
        (this.type === 'listOfValues' && this.areValuesImplied()) ||
        (this.type === 'listOfRanges' && this.areRangesImplied());
}

export function getDisplayableAttributes(ruleSet) {
    let displayableAttributes = [];
    if (ruleSet && ruleSet.whiteList) {
        const whiteList = ruleSet.whiteList.map(
            (attribute) => new Attribute({ ...attribute, key: attribute.attributeKey }),
        );

        displayableAttributes = whiteList
            .filter((attribute) => !(attribute.derived || attribute.isImplied()))
            .map((attribute) => attribute.key);
    }

    return displayableAttributes;
}

function getUniqueValues(arr) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
}

function areKeysEqual(key1, key2) {
    return key1.toLowerCase() === key2.toLowerCase();
}

function findAttributeDependenciesInWhiteList(attribute, whiteList) {
    const attributeDependencies = [];
    whiteList.forEach((whiteListAttribute) => {
        if (whiteListAttribute.attributeValueTranslations) {
            const containeredAttributes = whiteListAttribute.attributeValueTranslations.some((translation) =>
                translation.attributeSelections.find((selection) => areKeysEqual(selection.attributeKey, attribute)),
            );

            if (containeredAttributes && !attributeDependencies.includes(whiteListAttribute.attributeKey)) {
                attributeDependencies.push(whiteListAttribute.attributeKey);
            }
        }
    });

    return attributeDependencies;
}

function findAttributeDependenciesInRules(attribute, rules) {
    const attributeDependencies = [];
    rules.forEach((rule) => {
        if (rule.results.find((ruleAttribute) => areKeysEqual(ruleAttribute.attributeKey, attribute))) {
            const predicateAttributes = rule.predicates.map((ruleAttribute) => ruleAttribute.attributeKey);

            attributeDependencies.push(...predicateAttributes);
        }
    });

    return attributeDependencies;
}

function findFormulaAttributeDependencies(attributeKey, whiteList) {
    const formulaDependencies = [];

    const attribute = whiteList.find((whiteListAttribute) =>
        areKeysEqual(whiteListAttribute.attributeKey, attributeKey),
    );

    // TODO fix attribute type
    if (attribute !== undefined && attribute.type === 'formula') {
        const formulaVariables = attribute.formula.match(VARIABLE_TOKEN_REGEX);

        if (formulaVariables !== null) {
            const attributeKeys = formulaVariables.map((match) => match.replace(VARIABLE_TOKEN_REPLACE_REGEX, ''));
            formulaDependencies.push(...attributeKeys);
        }
    }

    return formulaDependencies;
}

function findAttributeDependencies(attribute, ruleSet) {
    const attributeDependencies = [];

    let dependencyStack = [attribute];
    while (dependencyStack.length > 0) {
        const currentAttribute = dependencyStack.pop();
        attributeDependencies.push(currentAttribute);

        const newDependencies = [];

        // find dependencies based on rules
        // (Only do this for derived attributes. For non-derived attributes this brings in unnecessary dependencies)
        const whiteListAttribute = ruleSet.whiteList.find((att) => areKeysEqual(att.attributeKey, currentAttribute));
        if (whiteListAttribute !== undefined && whiteListAttribute.derived) {
            newDependencies.push(...findAttributeDependenciesInRules(currentAttribute, ruleSet.rules));
        }

        // find dependencies based on containered attributes in the whiteList
        newDependencies.push(...findAttributeDependenciesInWhiteList(currentAttribute, ruleSet.whiteList));

        // Pull dependencies from formulae
        newDependencies.push(...findFormulaAttributeDependencies(currentAttribute, ruleSet.whiteList));

        dependencyStack = getUniqueValues(dependencyStack.concat(newDependencies));
        dependencyStack = dependencyStack.filter((dependency) => !attributeDependencies.includes(dependency));
    }

    return attributeDependencies;
}

function populateCustomRequired(customRequiredAttributes, ruleSet) {
    const fullCustomRequired = [];
    customRequiredAttributes.forEach((customRequiredAttribute) => {
        fullCustomRequired.push(...findAttributeDependencies(customRequiredAttribute, ruleSet));
    });

    return getUniqueValues(fullCustomRequired);
}

export function getRequiredAttributes(ruleSet, customRequiredAttributes) {
    if (!ruleSet) return [];
    const requiredAttributes =
        customRequiredAttributes !== undefined
            ? customRequiredAttributes
            : ruleSet.whiteList.filter((attribute) => attribute.required).map((attribute) => attribute.attributeKey);

    return populateCustomRequired(requiredAttributes, ruleSet);
}
