import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button, Carousel, Modal, Select, TextField, Spinner } from '@cimpress/react-components';
import { connect } from 'react-redux';
import { ENTER } from 'src/util/keycodes';
import ProductSelector from './components/productSelector';
import getSceneVariation from './selectors/getSceneVariation';
import { loadPreviews } from './slice';
import getPreviewScenes from './selectors/getPreviews';

const PreviewScene = (props) => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [purposes, setPurposes] = useState([]);
    const [customPurpose, setCustomPurpose] = useState('');
    const [zoomImage, setZoomImage] = useState(false);
    const { images, loadingPreviews } = props.previews || {};
    const [attributes, setAttributes] = useState({});
    const handleChange = (attrs) => {
        setAttributes(attrs);
    };
    const handleToggle = () => {
        setOpen((o) => !o);
    };
    const handleClick = () => {
        if (purposes.length > 0) {
            setError('');
            props.loadPreviews({ attributes, purposes });
        } else {
            setError('Please select at least one Purpose to Preview');
        }
    };
    const onZoomImage = (currentSlide) => {
        setZoomImage(images[currentSlide]);
    };
    const closeModal = () => {
        setZoomImage(undefined);
    };

    const addPurpose = (selectedOptions) => {
        if (selectedOptions.length > 0 && error) {
            setError('');
        }

        setPurposes(selectedOptions || []);
    };
    const updateCurrentSubpurpose = (e) => {
        setCustomPurpose(e.target.value);
    };
    const addCustomSubpurposes = () => {
        setPurposes((e) => [
            ...e,
            { label: `Merchandising ${customPurpose}`, value: `merchandising:${customPurpose}` },
        ]);
    };

    const renderImages = useMemo(
        () =>
            images && images.length > 0 ? (
                <Carousel size='s' showZoomOverlay={true} onCarouselClick={onZoomImage}>
                    {images.map((image) => (
                        <img key={image} src={image} alt={`Scene Preview for ${image}`} />
                    ))}
                </Carousel>
            ) : (
                <></>
            ),
        [images],
    );

    const purposeOptions = useMemo(
        () => props.purposes.filter((p) => !purposes.find((i) => i.value === p.value)),
        [purposes],
    );

    if (loadingPreviews) {
        return <Spinner isCenter={true} show={true} />;
    }

    if (!props.enable) {
        return <></>;
    }

    return (
        <Accordion title='Preview' defaultOpen={open} onHeaderClick={handleToggle}>
            <div className='wrapper-preview-scene'>
                <ProductSelector
                    attributes={attributes}
                    sku={props.sku}
                    skuVersion={props.skuVersion}
                    onChange={handleChange}
                />
                <Select
                    label='Select Purpose(s)'
                    isMulti={true}
                    value={purposes}
                    options={purposeOptions}
                    onChange={addPurpose}
                    menuPortalTarget={document.body}
                    helpText={error}
                    containerClassName={error ? 'has-error' : ''}
                />
                <TextField
                    value={customPurpose}
                    label='Add custom merchandising subpurposes'
                    onChange={updateCurrentSubpurpose}
                    onKeyDown={(e) => {
                        if (e.keyCode === ENTER) {
                            addCustomSubpurposes();
                        }
                    }}
                    rightAddon={
                        <Button onClick={addCustomSubpurposes} disabled={!customPurpose}>
                            Add
                        </Button>
                    }
                />
                <Button style={{ marginTop: '12px' }} variant='primary' onClick={handleClick}>
                    Show Preview
                </Button>
                <div className='wrapper-preview-scene-carousel'>{renderImages}</div>
                <Modal
                    className='wrapper-preview-scene-modal'
                    size='lg'
                    closeButton={true}
                    show={!!zoomImage}
                    onRequestHide={closeModal}
                    closeOnOutsideClick={true}>
                    <img src={zoomImage} alt='Preview Scene' />
                </Modal>
            </div>
        </Accordion>
    );
};

PreviewScene.propTypes = {
    sku: PropTypes.string,
    skuVersion: PropTypes.number,
    previews: PropTypes.shape({
        loadingPreviews: PropTypes.bool,
        isReady: PropTypes.bool,
        images: PropTypes.arrayOf(PropTypes.string),
        error: PropTypes.string,
    }),
    purposes: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    loadPreviews: PropTypes.func,
    enable: PropTypes.bool,
};

function mapStateToProps(state) {
    const { sku, skuVersion, sceneVariableId, scenePurposes } = getSceneVariation(state);
    const previews = getPreviewScenes(state);
    return {
        sku,
        skuVersion,
        previews,
        purposes: scenePurposes,
        enable: !!sceneVariableId,
    };
}

export default connect(mapStateToProps, {
    loadPreviews,
})(PreviewScene);
