import * as React from 'react';
import { Button, Dropdown } from '@rendering/components';
import UploadIcon, { UploadIconProps } from 'src/components/UploadIcon';

interface AddBackgroundProps extends UploadIconProps {
    onGenetare: () => void;
}

const AddBackground: React.FC<AddBackgroundProps> = (props) => {
    const onClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
    };
    return (
        <Dropdown
            title={<img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/add-2-l.svg' />}
            variant='simple'
            className='add-bkg-dropdown'
            onClick={onClick}>
            <UploadIcon {...props}>Upload File</UploadIcon>
            <Button onClick={props.onGenerate}>Generate Image</Button>
        </Dropdown>
    );
};

export default AddBackground;
