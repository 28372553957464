import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getDocumentById } from 'src/selectors/layers';
import { getSurfaceByPage } from 'src/selectors/pages/editor';
import { getTransformsById } from 'src/selectors/transforms';
import { editTransform } from 'src/pages/editor/components/documents/slice';
import { CYLINDER_WARP, TEXTURE_WARP } from 'src/models/documentWarps';
import trimButton from './trimButton';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const { id } = ownProps;

    const documentWarpType = getDocumentById(state, id).warpType;
    let transform;
    if (documentWarpType !== CYLINDER_WARP && documentWarpType !== TEXTURE_WARP) {
        transform = getTransformsById(state, id)[0];
    }

    const activePage = getDocumentById(state, id).page;
    const surfaceData = getSurfaceByPage(state, activePage);

    return {
        transform,
        surfaceData,
    };
};

const trimButtonContainer = connect(mapStateToProps, {
    trimCallback: editTransform,
})(trimButton);

export default trimButtonContainer;
