import React, { Component } from 'react';
import { Group } from 'react-konva';
import { distance, calculatePosition } from 'src/util/math';
import Grabber from '../../grabber';
import type { KonvaEventObject } from 'konva/lib/Node';
import { CylinderType } from 'src/types/transforms';

type Point = {
    x: number;
    y: number;
};

type Props = {
    container?: HTMLElement;
    scaledCylinderWarp: Omit<CylinderType, 'id' | 'fullWrap'>;
    cylinderWarp: CylinderType;
    scale: number;
    onDragMove: (warp: CylinderType) => void;
    onDragEnd: () => void;
    cursorChange: (cursor: string) => void;
    offset: Point;
};

type State = {
    startCylinderWarp: CylinderType | null;
};

export default class RadiusGrabbers extends Component<Props, State> {
    state: State = {
        startCylinderWarp: null,
    };

    onDragStart = (e: KonvaEventObject<globalThis.MouseEvent>, top: boolean, y: boolean): void => {
        const { cylinderWarp } = this.props;

        this.setState({
            startCylinderWarp: cylinderWarp,
        });

        document.onmouseup = this.onDragEnd;
        document.onmousemove = (evt) => this.onDragMove(evt, top, y);
        e.cancelBubble = true;
    };

    onDragMove = (e: MouseEvent, top: boolean, y: boolean): void => {
        const { startCylinderWarp } = this.state;
        const { onDragMove, scale, container, offset } = this.props;
        const warp = JSON.parse(JSON.stringify(startCylinderWarp));

        const containerRect = container?.getBoundingClientRect();
        const mousePosition: Point = {
            x: (e.clientX - containerRect!.left - offset.x) / scale,
            y: (e.clientY - containerRect!.top - offset.y) / scale,
        };

        if (!y && top) {
            warp.cylinderTop.radiusX = distance(warp.cylinderTop.center, mousePosition);
        } else if (!y) {
            warp.cylinderBottom.radiusX = distance(warp.cylinderBottom.center, mousePosition);
        } else {
            const ellipse = top ? warp.cylinderTop : warp.cylinderBottom;
            ellipse.radiusY = distance(ellipse.center, mousePosition);

            const ptAboveDistance = distance(calculatePosition(ellipse, 270), mousePosition);
            const ptBellowDistance = distance(calculatePosition(ellipse, 90), mousePosition);

            if (ptAboveDistance < ptBellowDistance) {
                ellipse.radiusY = -ellipse.radiusY;
            }
        }

        onDragMove(warp);
    };

    onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();
    };

    render() {
        const { scaledCylinderWarp, cursorChange } = this.props;

        const topRight = calculatePosition(scaledCylinderWarp.cylinderTop, 0);
        const bottomRight = calculatePosition(scaledCylinderWarp.cylinderBottom, 0);
        const topBottom = calculatePosition(scaledCylinderWarp.cylinderTop, 90);
        const bottomBottom = calculatePosition(scaledCylinderWarp.cylinderBottom, 90);

        return (
            <Group>
                <Grabber
                    position={topRight}
                    cursorChange={cursorChange}
                    onDragStart={(e) => this.onDragStart(e, true, false)}
                />
                <Grabber
                    position={bottomRight}
                    cursorChange={cursorChange}
                    onDragStart={(e) => this.onDragStart(e, false, false)}
                />
                <Grabber
                    position={topBottom}
                    cursorChange={cursorChange}
                    onDragStart={(e) => this.onDragStart(e, true, true)}
                />
                <Grabber
                    position={bottomBottom}
                    cursorChange={cursorChange}
                    onDragStart={(e) => this.onDragStart(e, false, true)}
                />
            </Group>
        );
    }
}
