import { createSlice } from '@reduxjs/toolkit';
import { SCENE_VARIABLE_TYPES } from './constants';

import { removeDocument } from '../documents/slice';

type SceneConfigurationSliceState = {
    showForm: boolean;
    loadingLink: string | undefined;
    links: any[];
    dataLinks: any[];
    userSettings: {
        merchandisingSubPurposes: any[];
        tags: any;
    };
    activeLink: Link;
    previews: {
        loadingPreviews: boolean;
        attributes: any;
        purposes: any;
        isReady?: boolean;
        error?: any;
        images?: any;
    };
    confirm?: boolean;
};

type Link = {
    sku: string;
    skuVersion: number;
    confirm: boolean;
    cimSku: string;
    cimSkuVersion: number;
    fixedAttributesSelection: Record<string, any>;
    productConfigurations: Record<string, any>[];
    scenePurposes: string[];
    sceneVariables: Record<string, any>[];
    tags: string[];
    error?: any;
    sceneVariableId?: any;
    sceneLinkId?: any;
    cropping: {
        enable: boolean;
        points: Array<{ x: any; y: any }>;
        ruleId: string | null;
        purpose: string | null;
        scale: number | null;
        constraint: {
            enable: boolean;
            width: number;
            height: number;
        };
    };
    view?: {
        name?: string;
        ordinal?: number;
    };
};

export const BLANK_STATE = {
    sku: '',
    skuVersion: 0,
    confirm: false,
    cimSku: '',
    cimSkuVersion: 0,
    fixedAttributesSelection: {},
    productConfigurations: [{}],
    scenePurposes: [],
    sceneVariables: [],
    tags: [],
    error: undefined,
    sceneVariableId: undefined,
    sceneLinkId: undefined,
    cropping: {
        // Object to ensure cropping functionality, this is not being save
        enable: false,
        points: [], // ImageBox coordinates set by the current canvas
        ruleId: null,
        purpose: null,
        scale: 0,
        constraint: {
            enable: false,
            width: 100,
            height: 100,
        },
    },
};

const initialState: SceneConfigurationSliceState = {
    showForm: false,
    loadingLink: undefined,
    links: [],
    dataLinks: [],
    userSettings: {
        merchandisingSubPurposes: [],
        tags: {},
    },
    activeLink: BLANK_STATE,
    previews: {
        loadingPreviews: false,
        attributes: null,
        purposes: null,
        isReady: false,
    },
};

const slice = createSlice({
    name: 'sceneConfiguration',

    initialState,

    reducers: {
        retrieveVersionsForSku: () => {},
        retrieveProductDetails: (state, { payload }) => {
            state.activeLink.sku = payload.sku;
            state.activeLink.skuVersion = payload.skuVersion;
            state.activeLink.cimSku = payload.cimSku || payload.sku;
            state.activeLink.cimSkuVersion = payload.cimSkuVersion || payload.skuVersion;
        },
        setMCPSkus: (state, { payload }) => {
            state.activeLink.cimSku = payload.cimSku;
            state.activeLink.cimSkuVersion = payload.cimSkuVersion;
        },
        updateConfiguration: (state, { payload }) => {
            const { newConfiguration, index } = payload;
            state.activeLink.productConfigurations = [
                ...state.activeLink.productConfigurations.slice(0, index),
                newConfiguration,
                ...state.activeLink.productConfigurations.slice(index + 1),
            ];
        },
        removeConfiguration: (state, { payload }) => {
            state.activeLink.productConfigurations = [
                ...state.activeLink.productConfigurations.slice(0, payload),
                ...state.activeLink.productConfigurations.slice(payload + 1),
            ];
        },
        addConfiguration: (state) => {
            state.activeLink.productConfigurations = [...state.activeLink.productConfigurations, {}];
        },
        updateScenePurpose: (state, { payload }) => {
            state.activeLink.scenePurposes = payload;
        },
        updateViewOptions: (state, { payload }) => {
            state.activeLink.view = payload;
        },
        setMerchandisingSubpurposes: (state, { payload }) => {
            if (payload && payload.subPurposes) {
                state.userSettings.merchandisingSubPurposes = payload.subPurposes.merchandising;
                state.userSettings.tags = payload.tags;
            }
        },
        updateFixedAttributesSelection: (state, { payload }) => {
            state.activeLink.fixedAttributesSelection = payload;
        },
        updateSceneVariable: (state, { payload }) => {
            if (payload) {
                state.activeLink.sceneVariables = payload;
            }
        },
        addSceneVariable: (state, { payload }) => {
            if (payload.purpose) {
                state.activeLink.sceneVariables.push({ purpose: payload.purpose, rules: [] });
                state.activeLink.error = undefined;
            } else {
                state.activeLink.error = 'Please select a valid purpose';
            }
        },
        removeSceneVariable: (state, { payload }) => {
            if (payload.purpose) {
                state.activeLink.sceneVariables = state.activeLink.sceneVariables.filter(
                    (item) => item.purpose !== payload.purpose,
                );
            }
        },
        addSceneVariableRule: (state, { payload }) => {
            const itemIndex = state.activeLink.sceneVariables.findIndex((i) => i.purpose === payload.purpose);
            const newId = state.activeLink.sceneVariables[itemIndex].rules
                ? state.activeLink.sceneVariables[itemIndex].rules.length
                : 0;
            const newRule = {
                id: newId,
                type: payload.type,
                x0: 400,
                y0: 200,
                x1: 800,
                y1: 600,
            };
            if (state.activeLink.cropping.points && state.activeLink.cropping.points.length === 2) {
                newRule.x0 = state.activeLink.cropping.points[0].x;
                newRule.y0 = state.activeLink.cropping.points[0].y;
                newRule.x1 = state.activeLink.cropping.points[1].x;
                newRule.y1 = state.activeLink.cropping.points[1].y;
            }
            if (state.activeLink.sceneVariables[itemIndex].rules) {
                state.activeLink.sceneVariables[itemIndex].rules.push(newRule);
            } else {
                state.activeLink.sceneVariables[itemIndex].rules = [newRule];
            }
        },
        removeSceneVariableRule: (state, { payload }) => {
            if (payload.purpose && payload.rule) {
                const curVariable = state.activeLink.sceneVariables.find((i) => i.purpose === payload.purpose);
                const newRules: any[] = [];
                curVariable?.rules.forEach((r: any) => {
                    if (r.id !== payload.rule.id) {
                        const newRule = Object.assign({}, r, { id: newRules.length });
                        newRules.push(newRule);
                    }
                });
                if (curVariable) {
                    curVariable.rules = newRules;
                }
            }
        },
        updateSceneVariableRule: (state, { payload }) => {
            const curVariable = state.activeLink.sceneVariables.find((i) => i.purpose === payload.purpose);
            if (curVariable) {
                const ruleIndex = curVariable.rules.findIndex((rule: any) => rule.id === payload.ruleId);
                if (ruleIndex >= 0) {
                    if (payload.type === 'type' && payload.value === SCENE_VARIABLE_TYPES.Cropping) {
                        delete curVariable.rules[ruleIndex].asset;
                        if (state.activeLink.cropping.points.length === 2) {
                            // setting croping area as initial points for cropping clip
                            curVariable.rules[ruleIndex].x0 = state.activeLink.cropping.points[0].x;
                            curVariable.rules[ruleIndex].y0 = state.activeLink.cropping.points[0].y;
                            curVariable.rules[ruleIndex].x1 = state.activeLink.cropping.points[1].x;
                            curVariable.rules[ruleIndex].y1 = state.activeLink.cropping.points[1].y;
                        } else {
                            curVariable.rules[ruleIndex].x0 = 100;
                            curVariable.rules[ruleIndex].y0 = 100;
                            curVariable.rules[ruleIndex].x1 = 600;
                            curVariable.rules[ruleIndex].y1 = 600;
                        }
                    }
                    if (payload.type === 'type' && payload.value === SCENE_VARIABLE_TYPES['Asset Change']) {
                        ['x0', 'x1', 'y0', 'y1'].forEach((each) => {
                            delete curVariable.rules[ruleIndex][each];
                        });
                    }
                    if (payload.type === 'action') {
                        delete curVariable.rules[ruleIndex].file;
                        delete curVariable.rules[ruleIndex].fillColor;
                    }
                    curVariable.rules[ruleIndex][payload.type] = payload.value;
                }
            }
        },
        updateSceneIds: (state, { payload }) => {
            if (payload.sceneVariableId) {
                state.activeLink.sceneVariableId = payload.sceneVariableId;
            }
            if (payload.sceneLinkId) {
                state.activeLink.sceneLinkId = payload.sceneLinkId;
            }
        },
        cleanSceneConfiguration: (state) => {
            state.activeLink = BLANK_STATE;
        },
        loadConfiguration: (state, { payload }) => {
            state.activeLink.sku = payload.configData.sku || '';
            state.activeLink.skuVersion = payload.configData.skuVersion || 0;
            state.activeLink.cimSku = payload.configData.sku || '';
            state.activeLink.cimSkuVersion = payload.configData.skuVersion || 0;
            state.activeLink.fixedAttributesSelection = payload.configData.fixedAttributesSelection || {};
            state.activeLink.productConfigurations = payload.configData.productConfigurations || [{}];
            state.activeLink.scenePurposes = payload.configData.scenePurposes || [];
            state.activeLink.sceneVariables = payload.configData.sceneVariables || [];
            state.activeLink.sceneVariableId = payload.configData.sceneVariableId;
            state.activeLink.sceneLinkId = payload.configData.sceneLinkId;
            state.activeLink.view = payload.configData.view;
        },
        loadLink: (state, { payload }) => {
            state.loadingLink = payload;
        },
        setLinks: (state, { payload }) => {
            state.links = payload.links;
        },
        setCurrentLink: (state, { payload }) => {
            const { cropping } = state.activeLink;
            if (payload) {
                const newState = Object.assign({}, payload, {
                    cimSku: payload.sku,
                    cropping,
                    cimSkuVersion: payload.skuVersion,
                });
                state.activeLink = newState;
                state.loadingLink = undefined;
                state.showForm = true;

                const cacheExist = state.dataLinks.find((i) => i.sceneLinkId === payload.sceneLinkId);
                if (!cacheExist) {
                    state.dataLinks.push(payload);
                }
            }
        },
        setCacheLinks: (state, { payload }) => {
            if (payload) {
                const cacheExist = state.dataLinks.find((i) => i.sceneLinkId === payload.sceneLinkId);
                if (!cacheExist) {
                    state.dataLinks.push(payload);
                }
            }
        },
        setCreateMode: (state, { payload }) => {
            if (!payload) {
                state.activeLink = BLANK_STATE;
            }
            state.showForm = !!payload;
        },
        saveVariationFailed: () => {},
        saveLinkVariationFailed: () => {},
        loadPreviews: (state, { payload }) => {
            state.previews = {
                loadingPreviews: true,
                attributes: payload.attributes,
                purposes: payload.purposes,
            };
        },
        loadedPreviews: (state, { payload }) => {
            state.previews = {
                ...state.previews,
                loadingPreviews: false,
                error: payload.error,
                isReady: !payload.error,
                images: payload.images,
            };
        },
        startCropping: (state, { payload }) => {
            state.activeLink.cropping.enable = true;
            state.activeLink.cropping.ruleId = payload.id;
            state.activeLink.cropping.purpose = payload.item.purpose;
        },
        setCroppingArea: (state, { payload }) => {
            state.activeLink.cropping.points = payload.points;
            state.activeLink.cropping.scale = payload.scale;
        },
        endCropping: (state) => {
            state.activeLink.cropping.enable = false;
        },
        saveCropping: (state, { payload }) => {
            const { purpose, ruleId } = state.activeLink.cropping;
            const foundItem = state.activeLink.sceneVariables.find(
                (i) => i.purpose === purpose && i.rules.find((r: any) => r.id === ruleId),
            );
            if (foundItem && payload.length === 2) {
                const rule = foundItem.rules.find((r: any) => r.id === ruleId);
                rule.x0 = payload[0].x;
                rule.y0 = payload[0].y;
                rule.x1 = payload[1].x;
                rule.y1 = payload[1].y;
            }
        },
        resetCroppingArea: (state) => {
            state.activeLink.cropping.ruleId = null;
            state.activeLink.cropping.purpose = null;
            state.activeLink.cropping.scale = null;
        },
        updateConstraint: (state, { payload }) => {
            state.activeLink.cropping.constraint = {
                ...state.activeLink.cropping.constraint,
                [payload.field]: payload.value,
            };
        },
        setTags: (state, { payload }) => {
            state.activeLink.tags = payload;
        },
        removeTag: (state, { payload }) => {
            state.activeLink.tags = state.activeLink.tags.filter((tag) => tag !== payload);
        },
        setConfirm: (state, { payload }) => {
            state.confirm = payload;
        },
        deleteSceneVariation: () => {},
        deleteSceneVariationSuccess: (state, { payload }) => {
            state.activeLink = BLANK_STATE;
            state.links = state.links.filter((link) => link.sceneLinkId !== payload);
            state.dataLinks = state.dataLinks.filter((link) => link.sceneLinkId !== payload);
        },
    },

    extraReducers: (builder) => {
        builder.addCase(removeDocument, (state, { payload }) => {
            state.activeLink.productConfigurations = state.activeLink.productConfigurations.filter(
                (conf) => conf.document !== payload,
            );
        });
    },
});

export const reducer = slice.reducer;
export const retrieveVersionsForSku = slice.actions.retrieveVersionsForSku;
export const retrieveProductDetails = slice.actions.retrieveProductDetails;
export const setMCPSkus = slice.actions.setMCPSkus;
export const updateConfiguration = slice.actions.updateConfiguration;
export const removeConfiguration = slice.actions.removeConfiguration;
export const addConfiguration = slice.actions.addConfiguration;
export const updateScenePurpose = slice.actions.updateScenePurpose;
export const updateFixedAttributesSelection = slice.actions.updateFixedAttributesSelection;
export const updateSceneVariable = slice.actions.updateSceneVariable;
export const addSceneVariable = slice.actions.addSceneVariable;
export const removeSceneVariable = slice.actions.removeSceneVariable;
export const addSceneVariableRule = slice.actions.addSceneVariableRule;
export const removeSceneVariableRule = slice.actions.removeSceneVariableRule;
export const updateSceneVariableRule = slice.actions.updateSceneVariableRule;
export const updateSceneIds = slice.actions.updateSceneIds;
export const loadConfiguration = slice.actions.loadConfiguration;
export const loadLink = slice.actions.loadLink;
export const setLinks = slice.actions.setLinks;
export const setCurrentLink = slice.actions.setCurrentLink;
export const setCreateMode = slice.actions.setCreateMode;
export const setMerchandisingSubpurposes = slice.actions.setMerchandisingSubpurposes;
export const saveVariationFailed = slice.actions.saveVariationFailed;
export const saveLinkVariationFailed = slice.actions.saveLinkVariationFailed;
export const cleanSceneConfiguration = slice.actions.cleanSceneConfiguration;
export const loadPreviews = slice.actions.loadPreviews;
export const loadedPreviews = slice.actions.loadedPreviews;
export const startCropping = slice.actions.startCropping;
export const endCropping = slice.actions.endCropping;
export const saveCropping = slice.actions.saveCropping;
export const updateConstraint = slice.actions.updateConstraint;
export const setCroppingArea = slice.actions.setCroppingArea;
export const resetCroppingArea = slice.actions.resetCroppingArea;
export const setTags = slice.actions.setTags;
export const removeTag = slice.actions.removeTag;
export const setCacheLinks = slice.actions.setCacheLinks;
export const setConfirm = slice.actions.setConfirm;
export const deleteSceneVariation = slice.actions.deleteSceneVariation;
export const deleteSceneVariationSuccess = slice.actions.deleteSceneVariationSuccess;
export const updateViewOptions = slice.actions.updateViewOptions;
