import { CylinderType, CylinderXMLType } from 'src/types/transforms';

export function fromState(cylinders: CylinderType[]) {
    return {
        Cylinders: cylinders.map((cylinder) => ({
            DocumentId: cylinder.id,
            CylinderTop: {
                Center: {
                    X: cylinder.cylinderTop.center.x,
                    Y: cylinder.cylinderTop.center.y,
                },
                RadiusX: cylinder.cylinderTop.radiusX,
                RadiusY: cylinder.cylinderTop.radiusY,
                RotationDegrees: cylinder.cylinderTop.rotationDegrees,
            },
            CylinderBottom: {
                Center: {
                    X: cylinder.cylinderBottom.center.x,
                    Y: cylinder.cylinderBottom.center.y,
                },
                RadiusX: cylinder.cylinderBottom.radiusX,
                RadiusY: cylinder.cylinderBottom.radiusY,
                RotationDegrees: cylinder.cylinderBottom.rotationDegrees,
            },
            DecorationAreaDimensions: {
                Height: cylinder.decorationAreaDimensions.height,
                Width: cylinder.decorationAreaDimensions.width,
            },
            ImageDimensions: {
                Height: cylinder.imageDimensions.height,
                Width: cylinder.imageDimensions.width,
            },
            ProductDiameter: cylinder.productDiameter,
            IsDecorationRotated: cylinder.isDecorationRotated,
            DecorationExtentDegreesBeta: cylinder.decorationExtentDegreesBeta,
            DecorationExtentDegreesAlpha: cylinder.decorationExtentDegreesAlpha,
        })),
    };
}

export function toState(cylinders: CylinderXMLType[]) {
    return cylinders.map((cylinder) => ({
        id: cylinder.DocumentId,
        cylinderTop: {
            center: {
                x: cylinder.CylinderTop.Center.X,
                y: cylinder.CylinderTop.Center.Y,
            },
            radiusX: cylinder.CylinderTop.RadiusX,
            radiusY: cylinder.CylinderTop.RadiusY,
            rotationDegrees: cylinder.CylinderTop.RotationDegrees,
        },
        cylinderBottom: {
            center: {
                x: cylinder.CylinderBottom.Center.X,
                y: cylinder.CylinderBottom.Center.Y,
            },
            radiusX: cylinder.CylinderBottom.RadiusX,
            radiusY: cylinder.CylinderBottom.RadiusY,
            rotationDegrees: cylinder.CylinderBottom.RotationDegrees,
        },
        decorationAreaDimensions: {
            height: cylinder.DecorationAreaDimensions.Height,
            width: cylinder.DecorationAreaDimensions.Width,
        },
        imageDimensions: {
            height: cylinder.ImageDimensions.Height,
            width: cylinder.ImageDimensions.Width,
        },
        productDiameter: cylinder.ProductDiameter,
        isDecorationRotated: cylinder.IsDecorationRotated,
        decorationExtentDegreesBeta: cylinder.DecorationExtentDegreesBeta,
        decorationExtentDegreesAlpha: cylinder.DecorationExtentDegreesAlpha,
        fullWrap: cylinder.DecorationExtentDegreesBeta === 360,
    }));
}
