import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { rotateCylinder } from 'src/pages/editor/components/documents/slice';
import { getCylinderWarpById } from 'src/selectors/layers';
import orientation from './orientation';
import { HORIZONTAL, VERTICAL } from './orientations';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const cylinderWarp = getCylinderWarpById(state, ownProps.id);

    let activeOrientation;
    if (cylinderWarp) {
        if (cylinderWarp.isDecorationRotated === false) {
            activeOrientation = VERTICAL;
        } else if (cylinderWarp.isDecorationRotated === true) {
            activeOrientation = HORIZONTAL;
        }
    }

    return {
        activeOrientation,
    };
};

const orientationContainer = connect(mapStateToProps, { onSelect: rotateCylinder })(orientation);

export default orientationContainer;
