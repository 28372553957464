import * as React from 'react';
import { connect } from 'react-redux';
import { updateViewOptions } from './slice';
import { TextField } from '@cimpress/react-components';
import getSceneVariation from './selectors/getSceneVariation';
import { RootState } from 'src/store';

interface View {
    name?: string;
    ordinal?: number;
}

interface ViewOptionsProps {
    view?: View;
    updateViewOptions: (viewOptions: View) => void;
}

function ViewOptions({ view, updateViewOptions }: ViewOptionsProps) {
    return (
        <div>
            <TextField
                label='Name'
                value={view?.name}
                onChange={(e) => updateViewOptions({ ...view, name: e.currentTarget.value })}
            />
            <TextField
                label='Ordinal'
                type='number'
                value={view?.ordinal}
                onChange={(e) => {
                    updateViewOptions({ ...view, ordinal: e.currentTarget.valueAsNumber });
                }}
            />
        </div>
    );
}

export default connect(
    (state: RootState) => {
        const { view } = getSceneVariation(state);

        return {
            view,
        };
    },
    { updateViewOptions },
)(ViewOptions);
