import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@cimpress/react-components';

export default class CoamDropdown extends PureComponent {
    render() {
        const { type, id, resources, onChange } = this.props;
        let values = ['Loading...'];
        let selectedValue = 'Loading...';

        if (resources) {
            selectedValue = `${type},${id}`;
            values = Object.entries(resources)
                .map(([key, vals]) =>
                    vals
                        .filter((value) => value !== '*')
                        .map((value) => ({
                            value: `${key},${value}`,
                            label: value,
                        })),
                )
                .reduce((acc, curr) => acc.concat(curr), []);
        }

        const onSelect = (option) => {
            // Don't cause a UI change if the selected option is the same
            if (option.value !== selectedValue) {
                const pair = option.value.split(',');
                onChange(pair[0], pair[1]);
            }
        };

        return (
            <Select
                label='Tenant'
                classNamePrefix='tenant'
                className='coam__dropdown'
                options={values}
                value={{ label: selectedValue.split(',')[1], value: selectedValue }}
                onChange={onSelect}
            />
        );
    }
}

CoamDropdown.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    resources: PropTypes.shape({
        fulfillers: PropTypes.arrayOf(PropTypes.string),
        merchants: PropTypes.arrayOf(PropTypes.string),
    }),
    onChange: PropTypes.func.isRequired,
};
