import X2JS from 'xml-json-parser';
import { toState } from 'src/services/converters/cylinderWarp';
import { TEXTURE_TRANSFORM } from 'src/models/transforms';
import { xmlToBackgroundObject } from './backgrounds';
import { xmlToDocumentObjects } from './documents';
import { xmlToOverlayObjects } from './overlays';
import { xmlToTransformObjects } from './transforms';
import xmlToMetadataObject from './metadata';
import type { XMLTree_DIP } from 'src/types/xml';

const composeComposite = (dip: XMLTree_DIP, cylinderWarp?: Record<string, any>) => {
    const backgrounds = xmlToBackgroundObject(dip.Composite);
    const overlays = xmlToOverlayObjects(dip.Composite);
    const initialDocuments = xmlToDocumentObjects(dip);
    const docs = initialDocuments.length > 1 ? [xmlToDocumentObjects(dip)] : xmlToDocumentObjects(dip);

    const documents = {
        past: docs,
        present: initialDocuments,
        future: [],
    };
    const transformsById = xmlToTransformObjects(dip);
    const metadata = xmlToMetadataObject(dip.Metadata);

    const cylinderWarps = cylinderWarp ? toState(cylinderWarp.Cylinders) : [];

    documents.present.forEach((doc) => {
        doc.transforms = transformsById[doc.id]; // eslint-disable-line no-param-reassign
        const matchingCylinder = cylinderWarps.find((cylinder) => cylinder.id === doc.id);
        if (matchingCylinder) {
            doc.cylinder = matchingCylinder; // eslint-disable-line no-param-reassign
        }

        const texture = doc.transforms.find((transform) => transform.type === TEXTURE_TRANSFORM);
        if (texture) {
            doc.textureName = texture.id.substring(1); // eslint-disable-line no-param-reassign
        }

        // check for rotation on warp
        const rotation = metadata.find(
            (data) => data.type === 'rotation' && `_${data.data.transformId}` === doc.transforms[0].id,
        );
        const engraveColor = metadata.find((data) => data.type === 'engraving' && `_${data.data.id}` === doc.id);

        if (rotation) {
            doc.rotationAngle = rotation.data.rotationAngle; // eslint-disable-line no-param-reassign
        } else {
            doc.rotationAngle = 0; // eslint-disable-line no-param-reassign
        }

        if (engraveColor) {
            doc.engravingColor = engraveColor.data.color; // eslint-disable-line no-param-reassign
        }
    });

    const stateValue = { backgrounds, overlays, documents };
    const aspectRatio = metadata.find((data) => data.type === 'aspectRatio');
    if (aspectRatio) {
        // @ts-expect-error -- we need to add types for `stateValue`
        stateValue.aspectRatio = aspectRatio.data;
    }

    return stateValue;
};

/**
 * Converts a sceneXML string into the expected state for the redux store.
 * All IDs from the sceneXML will have "_" appended to the front such that
 * it does not conflict with newly created IDs.
 * @param {string} xml - The sceneXML to load. The source of the XML doesn't matter.
 * @returns {StateObject} - The composed state to be ingested.
 */
export default function fromXml(xml: string, cylinderWarp?: Record<string, any>) {
    const objectTree = new X2JS().xml_str2json(xml);

    return composeComposite(objectTree.Dip, cylinderWarp);
}
