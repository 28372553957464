import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { loadEditorPage } from './pages/editor/slice';

import { createScene, createSceneFinished, createSceneSuccess } from './pages/editor/components/scene/slice';
import { uploadBackgrounds } from './pages/editor/components/backgrounds/slice';

type ApplicationSliceState = {
    isTenantSelectable: boolean;
    region: string;
    areSurfacesRequired: boolean;
    isPageLoading: boolean;
    isBulkMode: boolean;
    AIGeneration: {
        [key: string]: string | boolean | string[] | undefined;
        active: boolean;
        description: string;
        width: string;
        height: string;
        images: string[];
        isGenerating: boolean;
        error?: string;
    };
    isApplicationLoading: boolean;
    showCreateSceneModal: boolean;
    id: string;
    mode: 'default' | 'bulk' | 'ai';
    type: string;
    resources: any;
    settings: {
        merchandisingSubPurposes: string[];
        tags: any;
    };
};

export const INITIAL_STATE: ApplicationSliceState = {
    isTenantSelectable: true,
    region: 'auto',
    areSurfacesRequired: true,
    isPageLoading: false,
    isBulkMode: false,
    AIGeneration: {
        active: false,
        description: '',
        width: '1300',
        height: '1300',
        images: [],
        isGenerating: false,
    },
    mode: 'default',
    isApplicationLoading: false,
    showCreateSceneModal: false,
    id: '',
    type: '',
    resources: {},
    settings: {
        merchandisingSubPurposes: [],
        tags: {},
    },
};

const loadPage = (
    state: ApplicationSliceState,
    { payload }: PayloadAction<{ tenantId?: string; tenantType?: string }>,
) => {
    if (payload && payload.tenantId && payload.tenantType) {
        state.id = payload.tenantId;
        state.type = payload.tenantType;
    }
    state.isTenantSelectable = true;
    state.isPageLoading = true;
};

const slice = createSlice({
    name: 'application',
    initialState: INITIAL_STATE,

    reducers: {
        loadApplication: (state) => {
            state.isApplicationLoading = true;
        },
        completeLoadingPage: (state) => {
            state.isPageLoading = false;
        },
        completeLoadingApplication: (state) => {
            state.isApplicationLoading = false;
        },
        setAreSurfacesRequired: (state, { payload }: PayloadAction<boolean>) => {
            globalThis.localStorage?.setItem('settingNoSurfaces', JSON.stringify(!payload));
            state.areSurfacesRequired = payload;
        },
        setIsTenantSelectable: (state, { payload }: PayloadAction<boolean>) => {
            state.isTenantSelectable = payload;
        },
        setResources: (
            state,
            { payload }: PayloadAction<{ id: string; type: string; resources: any; areSurfacesRequired: boolean }>,
        ) => {
            state.id = payload.id;
            state.type = payload.type;
            state.resources = payload.resources;
            state.areSurfacesRequired = payload.areSurfacesRequired;
        },
        setTenant: (state, { payload }: PayloadAction<{ type: string; id: string }>) => {
            globalThis.localStorage?.setItem('coamSelection', `${payload.type},${payload.id}`);
            state.id = payload.id;
            state.type = payload.type;
        },
        changeMode: (state, { payload }) => {
            const isBulk = payload === 'bulk';

            state.isBulkMode = isBulk;
            state.mode = payload;
        },
        setAIModalOpen: (state, { payload }) => {
            state.AIGeneration.active = !!payload;
        },
        setAIData: (state, { payload }: PayloadAction<{ field: string; value: string | boolean | string[] }>) => {
            state.AIGeneration[payload.field] = payload.value;
        },
        generateImagesAI: (state, { payload }) => {
            state.AIGeneration.isGenerating = payload;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        confirmImageAI: (state, { payload }) => {
            state.AIGeneration.active = false;
            state.AIGeneration.description = '';
            state.AIGeneration.images = [];
        },
        toggleBulkMode: (state) => {
            state.isBulkMode = !state.isBulkMode;
        },
        toggleShowCreateSceneModal: (state) => {
            state.showCreateSceneModal = !state.showCreateSceneModal;
        },
        setUserSettings: (state, { payload }) => {
            if (payload && payload.subPurposes) {
                state.settings.merchandisingSubPurposes = payload.subPurposes.merchandising;
                state.settings.tags = payload.tags;
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase(loadEditorPage, loadPage);
        builder.addCase(uploadBackgrounds, (state) => {
            state.showCreateSceneModal = false;
        });
        builder.addCase(createSceneSuccess, (state) => {
            state.isTenantSelectable = false;
        });
        builder.addCase(createScene, (state) => {
            state.isPageLoading = true;
        });
        builder.addCase(createSceneFinished, (state) => {
            state.isPageLoading = false;
        });
    },
});

export const reducer = slice.reducer;

export const loadApplication = slice.actions.loadApplication;
export const completeLoadingPage = slice.actions.completeLoadingPage;
export const completeLoadingApplication = slice.actions.completeLoadingApplication;
export const setAreSurfacesRequired = slice.actions.setAreSurfacesRequired;
export const setIsTenantSelectable = slice.actions.setIsTenantSelectable;
export const setResources = slice.actions.setResources;
export const setTenant = slice.actions.setTenant;
export const toggleBulkMode = slice.actions.toggleBulkMode;
export const changeMode = slice.actions.changeMode;
export const setAIModalOpen = slice.actions.setAIModalOpen;
export const setAIData = slice.actions.setAIData;
export const generateImagesAI = slice.actions.generateImagesAI;
export const confirmImageAI = slice.actions.confirmImageAI;
export const toggleShowCreateSceneModal = slice.actions.toggleShowCreateSceneModal;
export const setUserSettings = slice.actions.setUserSettings;

export default slice;
