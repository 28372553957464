import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, Button } from '@cimpress/react-components';

import TooltipWrapper from 'src/components/TooltipWrapper';
import CoamDropdown from './dropdown';

export default class CoamModal extends PureComponent {
    state = {
        open: this.props.forceShow,
        selectedId: '',
        selectedType: '',
        forceShow: this.props.forceShow,
    };

    onOpen = () => {
        if (!this.props.disabled) {
            this.setState({ open: true, selectedId: '', selectedType: '' });
        }
    };

    onClose = () => {
        this.setState({ open: false });
    };

    onChange = (type, id) => {
        this.setState({ selectedType: type, selectedId: id });
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.forceShow && !prevState.forceShow) {
            return { open: true, forceShow: nextProps.forceShow };
        }
        return null;
    }

    save = () => {
        this.props.saveCoam({ type: this.state.selectedType, id: this.state.selectedId });
        this.onClose();
    };

    render() {
        const { tenantType, tenantId, disabled, tip, resources } = this.props;
        const { open, selectedId, selectedType } = this.state;

        const show = open;
        const hasTenantId = !!tenantId;

        return (
            <TooltipWrapper className='coam-tooltip' direction='bottom' contents={tip}>
                <div className='coam__wrapper'>
                    <span className='coam__label'>Tenant:</span>
                    <button className={`coam-button${disabled ? ' coam-button--disabled' : ''}`} onClick={this.onOpen}>
                        {tenantId || 'None'}
                    </button>
                </div>
                <Modal
                    className='coam-modal'
                    show={show}
                    onRequestHide={this.onClose}
                    closeButton={hasTenantId}
                    title='Choose a tenant'
                    footer={
                        <div className='coam-modal__footer'>
                            <Button className='coam-modal__close' disabled={!hasTenantId} onClick={this.onClose}>
                                Cancel
                            </Button>
                            <Button
                                variant='primary'
                                className='coam-modal__submit'
                                disabled={!selectedId}
                                onClick={this.save}>
                                Save
                            </Button>
                        </div>
                    }>
                    <div className='coam-modal__description'>
                        Before you begin, you will need to choose which tenant you are designing scenes for. We will
                        remember your choice the next time you come here. You can change this choice from the header.
                    </div>
                    <CoamDropdown
                        id={selectedId || tenantId}
                        type={selectedType || tenantType}
                        resources={resources}
                        onChange={this.onChange}
                    />
                </Modal>
            </TooltipWrapper>
        );
    }
}

CoamModal.propTypes = {
    tenantType: PropTypes.string,
    tenantId: PropTypes.string,
    resources: PropTypes.shape({
        fulfillers: PropTypes.arrayOf(PropTypes.string),
        merchants: PropTypes.arrayOf(PropTypes.string),
    }),
    saveCoam: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    forceShow: PropTypes.bool,
    tip: PropTypes.string,
};
