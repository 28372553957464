import React, { Component } from 'react';
import { Group } from 'react-konva';

import { distance, intersectionOfTwoLines } from 'src/util/math';
import Grabber from '../../grabber';
import type { KonvaEventObject } from 'konva/lib/Node';
import type { RequiredNonNullable } from 'src/types/utils';

type Point = {
    x: number;
    y: number;
};

const SNAP_DISTANCE = 5;

const DEGREE45 = Math.PI / 4;
const DEGREE135 = (3 * Math.PI) / 4;
const DEGREE225 = (5 * Math.PI) / 4;
const DEGREE315 = (7 * Math.PI) / 4;

const DEGREE90 = Math.PI / 2;

const DEGREE360 = 2 * Math.PI;

const clonePoints = (points: Point[]): Point[] => points.map((point) => ({ x: point.x, y: point.y }));

const constrainToAspectRatio = (index: number, points: Point[], aspectRatio: number): Point[] => {
    const newPoints = clonePoints(points);
    const width = newPoints[1].x - newPoints[0].x;
    const desiredHeight = width / aspectRatio;
    if (index < 2) {
        newPoints[0].y = newPoints[1].y - desiredHeight;
    } else {
        newPoints[1].y = newPoints[0].y + desiredHeight;
    }

    return newPoints;
};

const detectSnapPoint = (snapPoints: Point[], mousePosition: Point): Point | undefined => {
    let bestPoint: Point | undefined;
    let bestDistance = SNAP_DISTANCE;

    // Using distance formula find any point that meets the snap threshold
    if (snapPoints && snapPoints.length > 0) {
        snapPoints.forEach((snapPoint) => {
            const dist = distance(mousePosition, snapPoint);

            if (dist < bestDistance) {
                bestPoint = snapPoint;
                bestDistance = dist;
            }
        });
    }

    return bestPoint;
};

interface GrabPointsProps {
    points: Point[];
    scaledPoints: Point[];
    selectPoint: (data: { point: number; id: string }) => void;
    cursorChange: (cursor: string) => void;
    onDragMove: (newPoints: Point[]) => void;
    onDragEnd: () => void;
    scale: number;
    isAspectRatioConstrained?: boolean;
    aspectRatio?: number;
    selectedPoint?: number;
    snapPoints: Point[];
    documentId: string;
    rotationAngle: number;
}

interface GrabPointsState {
    clientX: number | null;
    clientY: number | null;
    dragPoint: number | null;
    startX: number | null;
    startY: number | null;
}

export default class GrabPoints extends Component<GrabPointsProps, GrabPointsState> {
    state: GrabPointsState = {
        clientX: null,
        clientY: null,
        dragPoint: null,
        startX: null,
        startY: null,
    };

    onDragStart = (index: number) => (e: KonvaEventObject<MouseEvent>) => {
        const { points, selectPoint, documentId } = this.props;
        let point = -1;
        if (points.length !== 2 || index === 0) {
            point = index + 1;
        } else if (index === 2) {
            point = 2;
        }
        selectPoint({ point, id: documentId });

        let startX;
        let startY;
        if (points.length !== 2) {
            // Perspective Warp
            startX = points[index].x;
            startY = points[index].y;
        } else {
            startX = index === 0 || index === 3 ? points[0].x : points[1].x;
            startY = index === 0 || index === 1 ? points[0].y : points[1].y;
        }

        this.setState({
            clientX: e.evt.clientX,
            clientY: e.evt.clientY,
            dragPoint: index,
            startX,
            startY,
        });

        document.onmousemove = this.onDragMove;
        document.onmouseup = this.onDragEnd;
        e.cancelBubble = true;
    };

    // We have to create our own drag move and drag end events since when react re-renders, it forgets that it was being dragged.
    onDragMove = (e: MouseEvent) => {
        const { clientX, clientY, startX, startY } = this.state as RequiredNonNullable<GrabPointsState>;
        const { points, onDragMove, scale, isAspectRatioConstrained, aspectRatio, snapPoints, rotationAngle } =
            this.props;
        let newPoints = clonePoints(points);

        let newX = startX - (clientX - e.clientX) / scale;
        let newY = startY - (clientY - e.clientY) / scale;

        let { dragPoint } = this.state as RequiredNonNullable<GrabPointsState>;

        // move single point
        if (!e.shiftKey || points.length === 2) {
            if (points.length !== 2) {
                // Perspective Warp or Smooth Warp
                const snapPoint = detectSnapPoint(snapPoints, { x: newX, y: newY });
                if (snapPoint) {
                    newX = snapPoint.x;
                    newY = snapPoint.y;
                }
                newPoints[dragPoint] = { x: newX, y: newY };
            } else {
                // Rectangle Warp
                if (!isAspectRatioConstrained || !aspectRatio) {
                    const snapPoint = detectSnapPoint(snapPoints, { x: newX, y: newY });
                    if (snapPoint) {
                        newX = snapPoint.x;
                        newY = snapPoint.y;
                    }
                }
                dragPoint % 3 === 0 ? (newPoints[0].x = newX) : (newPoints[1].x = newX);
                // If constrained, calculate the y position.
                if (isAspectRatioConstrained && aspectRatio) {
                    newPoints = constrainToAspectRatio(dragPoint, newPoints, aspectRatio);
                } else {
                    dragPoint < 2 ? (newPoints[0].y = newY) : (newPoints[1].y = newY);
                }
            }
        } else {
            // resize the entire warp
            // find all border points
            const size = Math.ceil(Math.sqrt(newPoints.length));

            const switchPointOrder = (index1: number, index2: number) => {
                const temp = { ...newPoints[index1] };
                newPoints[index1] = newPoints[index2];
                newPoints[index2] = temp;
            };

            // determine whether to use y or x coordinate. if rotation is between 315-45 degrees or 135-225 degrees,
            // use change in y to find ratio (x would be really small as angle reaches 0 or 180 degrees)
            const shouldUseYCoordinate = (angle: number) => {
                let theta = angle % DEGREE360;
                if (theta < 0) {
                    theta += DEGREE360;
                }

                return theta > DEGREE315 || theta < DEGREE45 || (theta > DEGREE135 && theta < DEGREE225);
            };

            const findRatio = (endPoint: Point, angle: number): number => {
                // find the change in position
                const newDistance = { y: endPoint.y - newY, x: endPoint.x - newX };
                const oldDistance = { y: endPoint.y - newPoints[dragPoint].y, x: endPoint.x - newPoints[dragPoint].x };

                if (shouldUseYCoordinate(angle)) {
                    return newDistance.y / oldDistance.y;
                }
                return newDistance.x / oldDistance.x;
            };

            const scalePoint = (index: number, angle: number, edgePoint: Point, ratio: number) => {
                let point = newPoints[index];
                // warp stays in shape
                const intersectionPoint = intersectionOfTwoLines(-angle, edgePoint, -angle + DEGREE90, point);
                const offset = { x: point.x - intersectionPoint.x, y: point.y - intersectionPoint.y };

                point = { x: point.x - offset.x, y: point.y - offset.y };

                // scales each point
                const length = { y: edgePoint.y - point.y, x: edgePoint.x - point.x };

                let scaleMagnitude = 1;
                if (shouldUseYCoordinate(angle)) {
                    scaleMagnitude = length.y / Math.cos(-angle) || 0;
                } else {
                    // otherwise use x
                    scaleMagnitude = length.x / Math.sin(-angle) || 0;
                }

                newPoints[index] = {
                    x: edgePoint.x - Math.sin(-angle) * scaleMagnitude * ratio + (offset.x || 0),
                    y: edgePoint.y - Math.cos(-angle) * scaleMagnitude * ratio + (offset.y || 0),
                };
            };

            const scalePointsHeight = (
                endPoint: Point,
                getEdgePointIndex: (col: number) => number,
                borderIndex = 0,
            ) => {
                const ratio = findRatio(endPoint, rotationAngle) || 1;

                // apply change in height to every col
                for (let col = borderIndex; col < size - borderIndex; col++) {
                    const edgePoint = newPoints[getEdgePointIndex(col)];

                    // points in each col
                    for (let i = col + size * borderIndex; i < newPoints.length - size * borderIndex; i += size) {
                        scalePoint(i, rotationAngle, edgePoint, ratio);
                    }
                }
            };

            const scalePointsWidth = (endPoint: Point, getEdgePointIndex: (row: number) => number, borderIndex = 0) => {
                const ratio = findRatio(endPoint, rotationAngle - DEGREE90) || 1;

                // apply change in width to every row
                for (let row = borderIndex; row < size - borderIndex; row++) {
                    const edgePoint = newPoints[getEdgePointIndex(row)];

                    // points in each row
                    for (let i = borderIndex; i < size - borderIndex; i++) {
                        const index = row * size + i;
                        scalePoint(index, rotationAngle - DEGREE90, edgePoint, ratio);
                    }
                }
            };

            // switch order of point 3 and point 4 so order format is same as smooth warp
            if (newPoints.length === 4) {
                switchPointOrder(2, 3);

                if (dragPoint === 2) {
                    dragPoint = 3;
                } else if (dragPoint === 3) {
                    dragPoint = 2;
                }
            }

            const layer = Math.floor(size / 2);

            for (let l = 0; l < layer; l++) {
                const layerOffset = l * size;
                const endPointOffset = size - 1 - l;

                // drag point is one of the top border points
                if (dragPoint >= 0 + layerOffset + l && dragPoint < size + layerOffset - l) {
                    scalePointsHeight(
                        newPoints[dragPoint + (endPointOffset - l) * size],
                        (col) => col + endPointOffset * size,
                        l,
                    );
                }

                // drag point is one of the bottom border points
                if (
                    dragPoint < newPoints.length - layerOffset - l &&
                    dragPoint >= newPoints.length - size - layerOffset + l
                ) {
                    scalePointsHeight(
                        newPoints[dragPoint - (endPointOffset - l) * size],
                        (col) => col + layerOffset,
                        l,
                    );
                }

                // drag point is one of the left border points,
                if (
                    (dragPoint - l) % size === 0 &&
                    dragPoint >= layerOffset &&
                    dragPoint < newPoints.length - layerOffset
                ) {
                    scalePointsWidth(newPoints[dragPoint + endPointOffset], (row) => row * size + endPointOffset, l);
                }

                // drag point is one of the right border points
                if (
                    (dragPoint + 1 + l) % size === 0 &&
                    dragPoint > layerOffset &&
                    dragPoint < newPoints.length - layerOffset
                ) {
                    scalePointsWidth(newPoints[dragPoint - endPointOffset], (row) => row * size + l, l);
                }
            }

            // switch back order of point 3 and point 4
            if (newPoints.length === 4) {
                switchPointOrder(2, 3);
            }
        }

        onDragMove(newPoints);
    };

    onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();
    };

    render() {
        const { scaledPoints, cursorChange, selectedPoint, points } = this.props;
        const circles = scaledPoints.map((point, index) => {
            let isSelected = false;
            if (points.length !== 2 || index === 0) {
                isSelected = selectedPoint === index + 1;
            } else if (index === 2) {
                isSelected = selectedPoint === 2;
            }
            return (
                <Grabber
                    key={index}
                    position={point}
                    cursorChange={cursorChange}
                    onDragStart={this.onDragStart(index)}
                    isSelected={isSelected}
                />
            );
        });

        return <Group>{circles}</Group>;
    }
}
