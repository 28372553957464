import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, Button, Select, TextField } from '@cimpress/react-components';
import PropTypes from 'prop-types';
import InputColor from 'react-input-color';
import { getAllBackgrounds } from 'src/selectors/layers';
import { toSelectOption, getHexColor } from 'src/util/misc';
import UploadFile from './UploadFile';
import { ASSET_ACTIONS, ASSET_ACTIONS_OPTIONS, SCENE_VARIABLE_TYPES, SCENE_VARIABLE_TYPES_OPTIONS } from './constants';
import {
    addSceneVariable,
    removeSceneVariable,
    addSceneVariableRule,
    removeSceneVariableRule,
    updateSceneVariableRule,
    startCropping,
    updateConstraint,
    endCropping,
} from './slice';
import getSceneVariation from './selectors/getSceneVariation';

// / STYLES

const sceneVariablesConfigurationStyles = {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
};
const sceneVariablesConfigurationVariableContentCroppingStyles = {
    display: 'flex',
    flexBasis: '100%',
    padding: '0 8px',
    flexDirection: 'column',
};
const sceneVariablesConfigurationVariableContentCroppingFormStyles = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px',
};
const sceneVariablesConfigurationVariableContentCroppingFormItemStyles = {
    flex: '40%',
};

const buildTitle = (title, actions) => (
    <div className='layers-header'>
        <div className='layers-header__text'>{title}</div>
        {actions}
    </div>
);

const SceneVariables = (props) => {
    const [state, setState] = useState({
        variables: [],
    });

    const addVariable = (e) => {
        e.stopPropagation();
        if (state.selectedPurpose) {
            props.addSceneVariable({ purpose: state.selectedPurpose.value });
            const newState = { ...state };
            newState.selectedPurpose = {};
            setState(newState);
        } else {
            const newState = { ...state };
            newState.error = 'Please select a valid purpose';
            setState(newState);
        }
    };

    const title = buildTitle('Purpose Variables');

    const saveVariableChange = (item, ruleId, selected, type) => {
        if (item && ruleId >= 0 && type) {
            props.updateSceneVariableRule({ purpose: item.purpose, ruleId, type, value: selected });
        }
    };

    const purposeChanged = (selected) => {
        const newState = { ...state };
        newState.selectedPurpose = selected;
        setState(newState);
    };

    const activeVariable = props.sceneVariables.find((i) => i.active);

    const removeRule = (e, item, rule) => {
        e.stopPropagation();
        if (item && rule) {
            props.removeSceneVariableRule({ purpose: item.purpose, rule });
        }
    };

    const onCroppingValueChange = (item, ruleId, field, value) => {
        props.updateSceneVariableRule({ purpose: item.purpose, ruleId, type: field, value });
    };

    const getAssetListOptions = (item) => {
        const assetAlreadyUsed = item.rules.filter((each) => !!each.asset).map((each) => each.asset.value);
        const assetListOptions = props
            .getAssetOptions()
            .filter((eachOption) => !assetAlreadyUsed.includes(eachOption.value));
        return assetListOptions;
    };

    const isCroppingAlreadySet = (item) => {
        const croppingAlreadySet = item.rules.some((each) => each.type === SCENE_VARIABLE_TYPES.Cropping);
        return croppingAlreadySet;
    };

    const variableOptions = (item) => {
        if (!getAssetListOptions(item).length) {
            // no more asset so only cropping possible
            return toSelectOption([SCENE_VARIABLE_TYPES.Cropping]);
        }
        if (isCroppingAlreadySet(item)) {
            return toSelectOption([SCENE_VARIABLE_TYPES['Asset Change']]);
        }
        return SCENE_VARIABLE_TYPES_OPTIONS;
    };

    const getLayerDimensions = (item, index) => {
        if (item && item.rules[index].asset) {
            const theLayerDetails = props.backgroundLayers.find((each) => each.id === item.rules[index].asset.value);
            if (theLayerDetails) {
                return {
                    width: theLayerDetails.width,
                    height: theLayerDetails.height,
                };
            }
            return {};
        }
        return {};
    };

    const onToggleCropping = (item, id) => {
        if (props.croppingData.enable) {
            props.endCropping();
        } else {
            props.startCropping({ item, id });
        }
    };

    const updateConstraint = (field, value) => {
        props.updateConstraint({ field, value });
    };
    const updateConstraintNumber = (field, value) => {
        try {
            const numValue = value !== '' ? parseFloat(value) : 0;
            props.updateConstraint({ field, value: numValue });
        } catch {
            console.warn('Error parsing constraint value');
        }
    };

    const renderRules = (item) =>
        item && item.rules ? (
            item.rules.map((rule) => {
                const assetListOptions = getAssetListOptions(item);
                const isAssetChange = rule.type === SCENE_VARIABLE_TYPES['Asset Change'];
                const isCropping = rule.type === SCENE_VARIABLE_TYPES.Cropping;
                const subtitle = buildTitle(
                    `Rule ${rule.id + 1}`,
                    <Button variant='anchor' onClick={(e) => removeRule(e, item, rule)}>
                        <img src='https://static.ux.cimpress.io/assets/icons/bin-1-l.svg' alt='delete' />
                    </Button>,
                );
                const isCroppingToolActive =
                    props.croppingData.ruleId === rule.id && props.croppingData.purpose === item.purpose;
                const isCroppingDisable = !isCroppingToolActive && props.croppingData.enable;
                // eslint-disable-next-line no-nested-ternary
                const croppingLabel = isCroppingToolActive ? 'End Cropping Tool' : 'Use Cropping Tool';
                return (
                    <div className='scene-variables-configuration-rules-content' key={rule.id}>
                        {subtitle}
                        <Select
                            options={variableOptions(item)}
                            menuPortalTarget={document.body}
                            label='Select a Variable'
                            className='scene-variables-configuration-rules-content_select'
                            onChange={(sel) => saveVariableChange(item, rule.id, sel.value, 'type')}
                            value={toSelectOption(rule.type)}
                        />
                        {isAssetChange && (
                            <>
                                <Select
                                    options={assetListOptions}
                                    menuPortalTarget={document.body}
                                    label='Select an Asset'
                                    onChange={(sel) => saveVariableChange(item, rule.id, sel, 'asset')}
                                    value={rule.asset}
                                />
                                <Select
                                    options={ASSET_ACTIONS_OPTIONS}
                                    menuPortalTarget={document.body}
                                    label='Select an Action'
                                    onChange={(sel) => saveVariableChange(item, rule.id, sel.value, 'action')}
                                    value={toSelectOption(rule.action)}
                                />
                                {rule.action === ASSET_ACTIONS.REPLACE && (
                                    <UploadFile
                                        onChange={(fileDetails) =>
                                            saveVariableChange(item, rule.id, fileDetails, 'file')
                                        }
                                        file={rule.file}
                                        {...getLayerDimensions(item, rule.id)}
                                    />
                                )}
                                {rule.action === ASSET_ACTIONS.FILL && (
                                    <InputColor
                                        initialValue={rule.fillColor || '#808080'}
                                        onChange={(evt) =>
                                            saveVariableChange(item, rule.id, getHexColor(evt.hex), 'fillColor')
                                        }
                                        placement='top'
                                    />
                                )}
                            </>
                        )}
                        {isCropping && (
                            <div
                                className='scene-variables-configuration-variables-content-cropping'
                                style={sceneVariablesConfigurationVariableContentCroppingStyles}>
                                <h3>Please use the canvas to select the Cropping Area or use the coordinates below</h3>
                                <div
                                    className='scene-variables-configuration-variables-content-cropping-form'
                                    style={sceneVariablesConfigurationVariableContentCroppingFormStyles}>
                                    <div style={sceneVariablesConfigurationVariableContentCroppingFormItemStyles}>
                                        <span>X0:</span>
                                        <TextField
                                            type='number'
                                            value={rule.x0}
                                            onChange={(event) =>
                                                onCroppingValueChange(item, rule.id, 'x0', event.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <span>Y0:</span>
                                        <TextField
                                            type='number'
                                            value={rule.y0}
                                            onChange={(event) =>
                                                onCroppingValueChange(item, rule.id, 'y0', event.target.value)
                                            }
                                        />
                                    </div>
                                    <div style={sceneVariablesConfigurationVariableContentCroppingFormItemStyles}>
                                        <span>X1:</span>
                                        <TextField
                                            type='number'
                                            value={rule.x1}
                                            onChange={(event) =>
                                                onCroppingValueChange(item, rule.id, 'x1', event.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <span>Y1:</span>
                                        <TextField
                                            type='number'
                                            value={rule.y1}
                                            onChange={(event) =>
                                                onCroppingValueChange(item, rule.id, 'y1', event.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='scene-variables-configuration-variables-content-cropping-constraint-wrapper'>
                                    <span>Constraint Dimensions</span>{' '}
                                    <input
                                        type='checkbox'
                                        className='scene-variables-configuration-variables-content-cropping-constraint-check'
                                        onChange={(e) => updateConstraint('enable', e.target.checked)}
                                    />
                                    <div className='scene-variables-configuration-variables-content-cropping-constraint-form'>
                                        <TextField
                                            label='Width'
                                            type='number'
                                            value={props.croppingData.constraint.width}
                                            onChange={(e) => updateConstraintNumber('width', e.target.value)}
                                        />
                                        <TextField
                                            label='Height'
                                            type='number'
                                            value={props.croppingData.constraint.height}
                                            onChange={(e) => updateConstraintNumber('height', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <Button
                                    variant='primary'
                                    disabled={isCroppingDisable}
                                    onClick={() => onToggleCropping(item, rule.id)}>
                                    {croppingLabel}
                                </Button>
                            </div>
                        )}
                    </div>
                );
            })
        ) : (
            <></>
        );

    const addRule = (item) => {
        if (item) {
            const options = variableOptions(item);
            if (options.length === 1 && options[0].value === 'Cropping') {
                props.addSceneVariableRule({ purpose: item.purpose, type: SCENE_VARIABLE_TYPES.Cropping });
            } else {
                props.addSceneVariableRule({ purpose: item.purpose, type: SCENE_VARIABLE_TYPES['Asset Change'] });
            }
        }
    };

    const isAllRuleConfigured = (item) => {
        if (isCroppingAlreadySet(item) && !getAssetListOptions(item).length) {
            return true;
        }
        return false;
    };

    const removeItem = (e, item) => {
        e.stopPropagation();
        if (item) {
            props.removeSceneVariable({ purpose: item.purpose });
        }
    };

    const availablePurposes = props.purposeOptions.filter(
        (item) => props.sceneVariables.findIndex((variable) => variable.purpose === item.value) < 0,
    );

    return (
        <Accordion title={title} defaultOpen={false}>
            <div className='scene-variables-configuration' style={sceneVariablesConfigurationStyles}>
                {!activeVariable && (
                    <div className='scene-variables-configuration-add'>
                        <Select
                            options={availablePurposes}
                            menuPortalTarget={document.body}
                            label='Select a Purpose'
                            onChange={purposeChanged}
                            value={state.selectedPurpose}
                        />
                        <Button onClick={addVariable} type='link'>
                            <img src='https://static.ux.cimpress.io/assets/icons/add-circle-1.svg' alt='Add Variable' />{' '}
                            Add Variable
                        </Button>
                        {state.error && <span className='scene-variables-configuration-error'>{state.error}</span>}
                    </div>
                )}
                <div className='list-variables'>
                    {props.sceneVariables.map((i) => {
                        const itemTitle = buildTitle(
                            i.purpose,
                            <Button variant='anchor' onClick={(e) => removeItem(e, i)}>
                                <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/bin-1-l.svg' />
                            </Button>,
                        );
                        return (
                            <Accordion key={i.purpose} title={itemTitle} defaultOpen={true}>
                                <div className='scene-variables-configuration-rules'>{renderRules(i)}</div>
                                <div className='scene-variables-configuration-add'>
                                    <Button onClick={() => addRule(i)} variant='link' disabled={isAllRuleConfigured(i)}>
                                        <img
                                            src='https://static.ux.cimpress.io/assets/icons/add-circle-1.svg'
                                            alt='Add Variable'
                                        />
                                        Add Rule
                                    </Button>
                                </div>
                            </Accordion>
                        );
                    })}
                </div>
            </div>
        </Accordion>
    );
};

SceneVariables.propTypes = {
    sceneVariables: PropTypes.arrayOf(
        PropTypes.shape({
            purpose: PropTypes.string,
            rules: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                    type: PropTypes.string,
                    action: PropTypes.string,
                    asset: PropTypes.shape({
                        value: PropTypes.string,
                        label: PropTypes.string,
                    }),
                }),
            ),
        }),
    ),
    purposeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    addSceneVariable: PropTypes.func,
    removeSceneVariable: PropTypes.func,
    addSceneVariableRule: PropTypes.func,
    removeSceneVariableRule: PropTypes.func,
    updateSceneVariableRule: PropTypes.func,
    getAssetOptions: PropTypes.func,
    backgroundLayers: PropTypes.array,
    startCropping: PropTypes.func,
    endCropping: PropTypes.func,
    updateConstraint: PropTypes.func,
    croppingData: PropTypes.object,
};

function mapStateToProps(state) {
    const { sceneVariables, cropping } = getSceneVariation(state);
    const backgroundLayers = getAllBackgrounds(state);
    return {
        sceneVariables,
        backgroundLayers,
        croppingData: cropping,
    };
}

export default connect(mapStateToProps, {
    addSceneVariable,
    removeSceneVariable,
    addSceneVariableRule,
    removeSceneVariableRule,
    updateSceneVariableRule,
    updateConstraint,
    startCropping,
    endCropping,
})(SceneVariables);
