import React, { Component } from 'react';
import { Group, Line } from 'react-konva';

import {
    angle as calculateAngle,
    midpoint,
    getBoundingRectanglePosition,
    rotatePointsAboutPoint,
    distance,
} from 'src/util/math';

import Grabber from '../../grabber';
import type { KonvaEventObject } from 'konva/lib/Node';

type Point = {
    x: number;
    y: number;
};

const DOTTED_LINE_HEIGHT = 30;

// adding 0.01 to void any divide by 0 for calculations involving divide by sin(angle) or cos(angle)
const snapRotations = [
    0,
    Math.PI / 4 + 0.01, // 45
    Math.PI / 2 + 0.01, // 90
    (3 * Math.PI) / 4 + 0.01, // 135
    Math.PI + 0.01, // 180
    (5 * Math.PI) / 4 + 0.01, // 225
    (3 * Math.PI) / 2 + 0.01, // 270
    (7 * Math.PI) / 4 + 0.01, // 315
];

type BorderProps = {
    container: HTMLElement;
    scaledPoints: Point[];
    points: Point[];
    scale: number;
    onDragMove: (points: Point[]) => void;
    onDragEnd: () => void;
    cursorChange: (cursor: string) => void;
    offset: Point;
    rotationAngle: number;
    setRotationAngle: (angle: number) => void;
};

type BorderState = {
    startPoints: Point[] | null;
    angle: number;
};

export default class Border extends Component<BorderProps, BorderState> {
    state: BorderState = {
        startPoints: null,
        angle: 0,
    };

    onDragStart = (e: KonvaEventObject<MouseEvent>) => {
        const { points } = this.props;

        this.setState({
            startPoints: points,
        });

        document.onmousemove = this.onDragMove;
        document.onmouseup = this.onDragEnd;
        e.cancelBubble = true;
    };

    onDragMove = (e: MouseEvent) => {
        const { startPoints } = this.state;
        const { onDragMove, scale, container, offset, rotationAngle } = this.props;

        // calculate mouse position on canvas
        const containerRect = container.getBoundingClientRect();
        const mousePosition = {
            x: (e.clientX - containerRect.left - offset.x) / scale,
            y: (e.clientY - containerRect.top - offset.y) / scale,
        };

        // find center and top border midpoint of warp for angle calculation
        const boundingPoints = this.getBoundingBoxWithRotation(startPoints!, rotationAngle);
        const topCenter = midpoint(boundingPoints[0], boundingPoints[1]);
        const center = midpoint(boundingPoints[0], boundingPoints[2]);

        let angle = calculateAngle(center, topCenter, mousePosition);

        if (distance(mousePosition, boundingPoints[3]) < distance(mousePosition, boundingPoints[2])) {
            angle = -angle;
        }

        let newAngle = (rotationAngle + angle) % (2 * Math.PI);
        const angleDirection = newAngle / Math.abs(newAngle) || 1;
        newAngle = Math.abs(newAngle);

        snapRotations.forEach((snapAngle) => {
            if (newAngle < snapAngle + 0.1 && newAngle > snapAngle - 0.1) {
                angle = angleDirection * (snapAngle - angleDirection * rotationAngle);
            }
        });

        const rotatedPoints = rotatePointsAboutPoint(startPoints!, angle, center.x, center.y);

        onDragMove(rotatedPoints);
        this.setState({ angle });
    };

    onDragEnd = () => {
        const { angle } = this.state;
        const { rotationAngle } = this.props;

        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();

        this.props.setRotationAngle((rotationAngle + angle) % (2 * Math.PI));
        this.setState({
            angle: 0,
        });
    };

    getBoundingBoxWithRotation = (points: Point[], angle: number) => {
        // find center of warp
        const boundingBox = getBoundingRectanglePosition(points);
        const center = midpoint(
            { x: boundingBox.left, y: boundingBox.top },
            { x: boundingBox.right, y: boundingBox.bottom },
        );

        const rotatedPoints = rotatePointsAboutPoint(points, -angle, center.x, center.y);

        const { left, top, right, bottom } = getBoundingRectanglePosition(rotatedPoints);

        const boundingPoints = [
            { x: left, y: top },
            { x: right, y: top },
            { x: right, y: bottom },
            { x: left, y: bottom },
            { x: left, y: top }, // back to top left corner for rendering close box
        ];

        return rotatePointsAboutPoint(boundingPoints, angle, center.x, center.y);
    };

    render() {
        const { cursorChange, scaledPoints, rotationAngle } = this.props;
        const { angle } = this.state;

        const boundingPoints = this.getBoundingBoxWithRotation(scaledPoints, angle + rotationAngle);

        // dotted line from top of border line to rotation grip point
        const topMidpoint = midpoint(boundingPoints[0], boundingPoints[1]);
        let dottedPoints = [
            { x: topMidpoint.x, y: topMidpoint.y - DOTTED_LINE_HEIGHT },
            { x: topMidpoint.x, y: topMidpoint.y },
        ];

        // make dotted line perpendicular to top border line
        dottedPoints = rotatePointsAboutPoint(dottedPoints, angle + rotationAngle, topMidpoint.x, topMidpoint.y);

        // mapping out points into one long number array [x1, y1, x2, y2, ... xn, yn] to render line
        const boundingLine: number[] = [];
        boundingPoints.forEach((point) => {
            boundingLine.push(point.x);
            boundingLine.push(point.y);
        });

        const dottedLine: number[] = [];
        dottedPoints.forEach((point) => {
            dottedLine.push(point.x);
            dottedLine.push(point.y);
        });

        return (
            <Group>
                <Line points={dottedLine} stroke='rgba(0, 0, 0, .3)' dash={[4, 2]} strokeWidth={1} />
                <Line points={boundingLine} stroke='rgba(0, 0, 0, .3)' dash={[4, 2]} strokeWidth={1} />
                <Grabber position={dottedPoints[0]} cursorChange={cursorChange} onDragStart={this.onDragStart} />
            </Group>
        );
    }
}
