import * as React from 'react';

import Constraints from 'src/pages/editor/components/scene/constraints';
import Warp from 'src/pages/editor/components/documents/warp';
import MaskButton from 'src/pages/editor/components/documents/maskButtonContainer';
import Surface from 'src/pages/editor/components/documents/surface';
import TransformInfo from 'src/pages/editor/components/documents/transforms/editor';
import TrimButton from 'src/pages/editor/components/scene/trim';
import { DOCUMENT, RULER, OVERLAY, type LayerType } from 'src/pages/editor/components/layer/layerTypes';
import Blending from '../blending';
import CustomTicks from '../customTicks';
import Segments from '../segments';
import Color from '../color';
import AdvancedContainer from '../advanced';
import AutomaskContainer from '../../documents/autoMask';

function renderDocInfo(id: string, type: LayerType) {
    return (
        <div className='layer-information'>
            <Surface id={id} type={type} />
            <Warp id={id} />
            <Blending id={id} type={type} />
            <MaskButton id={id} />
            <AutomaskContainer id={id} />
            <Constraints id={id} />
            <TrimButton id={id} />
            <TransformInfo id={id} type={type} />
            <AdvancedContainer id={id} type={type} />
        </div>
    );
}

function renderOverlayInfo(id: string, type: LayerType) {
    return (
        <div className='layer-information'>
            <Blending id={id} type={type} />
        </div>
    );
}

function renderRulerInfo(id: string) {
    return (
        <div className='layer-information'>
            <Segments id={id} />
            <Color id={id} />
            <CustomTicks id={id} />
        </div>
    );
}

export default class layerInformation extends React.PureComponent<{ id: string; type: LayerType }> {
    render() {
        const { id, type } = this.props;
        switch (type) {
            case DOCUMENT:
                return renderDocInfo(id, type);
            case OVERLAY:
                return renderOverlayInfo(id, type);
            case RULER:
                return renderRulerInfo(id);
            default:
                return null;
        }
    }
}
