import PropTypes from 'prop-types';
import React, { Component } from 'react';
import auth from 'src/auth';
import GenericSelector from '@cimpress-technology/generic-selector';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';
import { uniq } from 'src/util/misc';
import CheckboxSelection from './checkboxSelection';
import { getDisplayableAttributes, getRequiredAttributes } from './legacyAttributeUtil';

function computeState(props) {
    const rulesetDisplayableAttrs = getDisplayableAttributes(props.ruleSet);
    const requiredAttributes = getRequiredAttributes(props.ruleSet, props.requiredVariables);
    const displayableAttributes =
        rulesetDisplayableAttrs.length > 0 ? rulesetDisplayableAttrs : props.genericAttributes || [];
    const computedRequiredAttributes = intersection(requiredAttributes, displayableAttributes);

    // If there are no required attributes ensure the variable configuration is resolved
    if (
        props.isReady &&
        props.ruleSet &&
        Object.keys(props.variables).length === 0 &&
        computedRequiredAttributes.length === 0
    ) {
        props.onChangeVariables({ variables: {}, resolved: true });
    }

    return {
        ...props,
        displayedVariables: uniq(Object.keys(props.variables).concat(computedRequiredAttributes)),
        requiredVariables: props.requiredVariables,
        id: props.id,
        allVariables: displayableAttributes,
        computedRequiredAttributes,
        initialVariables: props.variables,
        ruleSet: props.ruleSet,
    };
}

export default class Variables extends Component {
    state = computeState(this.props);

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            !isEqual(prevState.requiredVariables, nextProps.requiredVariables) ||
            prevState.ruleSet !== nextProps.ruleSet
        ) {
            return computeState(nextProps);
        }
        return prevState;
    }

    // The attribute selector is an expensive complicated component, so don't rerender it needlessly.
    shouldComponentUpdate(nextProps, nextState) {
        return (
            !isEqual(this.props.ruleSet, nextProps.ruleSet) ||
            !isEqual(this.state, nextState) ||
            this.props.isReady !== nextProps.isReady
        );
    }

    changeVariables = (changedVariables, resolved, oldVariables) => {
        const { onChangeVariables, sku, skuVersion } = this.props;

        const variables = Object.assign({}, oldVariables, changedVariables);
        // If any variables have been removed, such should be deleted from the variables object
        Object.getOwnPropertyNames(changedVariables).forEach((name) => {
            if (
                changedVariables[name] === '' ||
                changedVariables[name] === null ||
                changedVariables[name] === undefined
            ) {
                delete variables[name];
            }
        });
        onChangeVariables({ sku, skuVersion, variables, resolved });
    };

    // When changing the display, we should make sure to nullify any variables that are removed.
    changeVariableDisplay = (displayedVariables) => {
        this.setState((prevState) => {
            const { variables } = this.props;
            const hiddenVariables = {};
            prevState.displayedVariables
                .filter((variable) => !displayedVariables.includes(variable))
                .forEach((variable) => (hiddenVariables[variable] = null)); // eslint-disable-line no-return-assign
            this.changeVariables(hiddenVariables, false, variables);
            return { displayedVariables, initialVariables: variables };
        });
    };

    onChange = (state, attributes, validationErrors) => {
        const { variables, onFailure } = this.props;

        if (validationErrors) {
            onFailure(variables);
        } else if (state) {
            // Determine the overall resolved state (all attributes resolved)
            let resolved = true;
            const displayedAttributes = state.attributes.filter((attribute) => attribute.isDisplayed);
            // Format variables for the legacy variable methods
            const formatedVariables = [];
            displayedAttributes.forEach((attribute) => {
                // if does not have a value, set space as value to keep the attributes on session
                formatedVariables[attribute.key] = attribute.resolvedValue || ' ';
                if (!attribute.resolvedValue) {
                    resolved = false;
                }
            });
            this.changeVariables(formatedVariables, resolved, variables);
        }
    };

    onLoad = (state) => {
        // At this stage all the 'displayable' attributes are considered 'all' the showable attributes
        const displayedAttributes = state.attributes.filter((attribute) => attribute.isDisplayed === true);
        const { sku, skuVersion } = this.props;
        this.props.setRules({ sku, skuVersion, attributes: displayedAttributes.map((attribute) => attribute.key) });
    };

    render() {
        const { requiredOnlyMode, ruleSet, showAllMode, sku, skuVersion, isReady } = this.props;
        const { displayedVariables, allVariables, initialVariables, computedRequiredAttributes } = this.state;

        const showAddVariables = !showAllMode && !requiredOnlyMode && !!computedRequiredAttributes && isReady;
        const showGenericSelector = isReady;
        const noneShownClass =
            !allVariables.length || (!showAllMode && !allVariables.length) ? ' variables--none-shown' : '';

        // Setup each initial variable and determine its hidden state
        const attributeConfigurations = {};
        allVariables.forEach((key) => {
            attributeConfigurations[key] = {
                initialSelection: initialVariables[key],
                isHidden: !displayedVariables.includes(key),
            };
        });

        // Loads a new product and sets initial variable selections

        const customRequiredAttributes = {
            productAttributes: computedRequiredAttributes,
        };

        // Hide errors related to not having a full configuration because we are only aiming for a subset
        const hiddenErrorConfiguration = {
            showErrorAlert: false,
        };

        const productData = sku ? { productId: sku, productVersion: skuVersion } : { product: ruleSet };

        return (
            <div className={`variables${noneShownClass}`}>
                {showGenericSelector && (
                    <div className='variables__options'>
                        <GenericSelector
                            {...productData}
                            authToken={auth.getAccessToken()}
                            onChange={this.onChange}
                            attributeConfigurations={attributeConfigurations}
                            customRequiredAttributes={customRequiredAttributes}
                            errorAlertConfiguration={hiddenErrorConfiguration}
                            isColorSwatch={true}
                            enableSerialization={false}
                            onLoad={this.onLoad}
                        />
                    </div>
                )}
                {showAddVariables && (
                    <CheckboxSelection
                        onUpdate={this.changeVariableDisplay}
                        variables={allVariables}
                        displayedVariables={displayedVariables}
                        requiredVariables={computedRequiredAttributes}
                    />
                )}
            </div>
        );
    }
}

// This disable is necessary since I am using the props in a function outside of scope, which confuses the linter.
/* eslint-disable react/no-unused-prop-types */
Variables.propTypes = {
    requiredVariables: PropTypes.arrayOf(PropTypes.string),
    ruleSet: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    variables: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    loading: PropTypes.bool, // TODO use loading
    isReady: PropTypes.bool,
    showAllMode: PropTypes.bool,
    requiredOnlyMode: PropTypes.bool,
    id: PropTypes.string,
    setRules: PropTypes.func.isRequired,
    onChangeVariables: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    sku: PropTypes.string,
    skuVersion: PropTypes.number,
};
