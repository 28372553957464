import { call, put, takeEvery, all } from 'redux-saga/effects';

import { getScene } from 'src/services/sceneClient';
import { deleteAsset, restoreAsset } from 'src/services/assetsClient';
import { logError } from 'src/loggingManager';
import {
    addScene,
    deleteSceneSuccess,
    deleteSceneFailed,
    deleteScene,
    restoreScene,
    restoreSceneSuccess,
    restoreSceneFailed,
} from './slice';

/**
 * @param {string} payload.sceneId
 */
export function* fetchSceneData({ payload }) {
    const { sceneId } = payload;
    try {
        const scene = yield call(getScene, { sceneId });
        yield put(addScene(scene));
    } catch (e) {
        logError(`Failed to fetch scene from client: ${e}`);
    }
}

function* deleteSceneOnServer({ payload }) {
    try {
        yield call(deleteAsset, { assetId: payload });
        yield put(deleteSceneSuccess(payload));
    } catch (e) {
        yield put(deleteSceneFailed(payload));
        logError(`Failed to delete scene to client: ${e}`);
    }
}

function* restoreSceneOnServer({ payload }) {
    try {
        yield call(restoreAsset, { assetId: payload });
        yield put(restoreSceneSuccess(payload));
    } catch (e) {
        yield put(restoreSceneFailed(payload));
        logError(`Failed to restore scene to client: ${e}`);
    }
}

export default function* scenesSaga() {
    return yield all([
        yield takeEvery(deleteScene, deleteSceneOnServer),
        yield takeEvery(restoreScene, restoreSceneOnServer),
    ]);
}
