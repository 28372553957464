import * as React from 'react';
import { Button } from '@cimpress/react-components';

import CustomTick, { type RemoveCustomTickFn, type UpdateCustomTickFn } from './customTick';

interface CustomTicksProps {
    id: string;
    ticks: number[];
    segments: number;
    addCustomTick: (id: string) => void;
    removeCustomTick: RemoveCustomTickFn;
    updateCustomTick: UpdateCustomTickFn;
}

export default class CustomTicks extends React.PureComponent<CustomTicksProps> {
    render() {
        const { id, ticks, segments, addCustomTick, removeCustomTick, updateCustomTick } = this.props;
        return (
            <div className='custom-ticks'>
                {ticks.map((value, index) => (
                    <CustomTick
                        id={id}
                        key={index} // eslint-disable-line react/no-array-index-key
                        value={value}
                        index={index}
                        removeCustomTick={removeCustomTick}
                        updateCustomTick={updateCustomTick}
                        segments={segments}
                    />
                ))}
                <Button onClick={() => addCustomTick(id)}>Add Custom Tick</Button>
            </div>
        );
    }
}
