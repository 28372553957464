import { createSlice } from '@reduxjs/toolkit';

import { BLENDING_MODE_CHANGE } from 'src/store/actions/actionTypes';
import { backgroundUploaded, changeBackgroundOrder } from '../backgrounds/slice';
import { setAspectRatioConstraint } from '../canvas/slice';
import {
    replaceTransforms,
    addDocument,
    removeDocument,
    maskUploaded,
    textureUploaded,
    removeMask,
    changePage,
    dragTransformPoint,
    editTransformPoint,
    editTransform,
    setWarp,
    toggleEngraving,
    changeEngravingColor,
    toggleAutomask,
} from '../documents/slice';
import { changeOverlayOrder, overlayUploaded, removeOverlay } from '../overlays/slice';
import {
    addConfiguration,
    addSceneVariable,
    addSceneVariableRule,
    removeConfiguration,
    removeSceneVariable,
    removeSceneVariableRule,
    retrieveProductDetails,
    updateConfiguration,
    updateFixedAttributesSelection,
    updateSceneVariableRule,
} from '../SceneVariation/slice';
import { changeEditorSkuSuccess, clearEditorSku } from '../surfaces/slice';

type SceneSliceState = {
    id: string;
    name: string;
    description: string;
    notes: string;
    xml: string;
    tags: string[];
    isModified: boolean;
    saving: boolean;
    publishing: boolean;
    publishedVersionId: string;
    latestVersionId: string;
    duplicating?: boolean;
    // TODO: is this still used? Is it working?
    isRetrievingSurfaces?: boolean;
};

const initialState: SceneSliceState = {
    id: '',
    name: 'My Scene',
    description: 'My Description',
    notes: '',
    xml: '',
    tags: [],
    isModified: false,
    saving: false,
    publishing: false,
    publishedVersionId: '',
    latestVersionId: '',
};

const slice = createSlice({
    name: 'scene',

    initialState,

    reducers: {
        updateName: (state, { payload }) => {
            state.name = payload;
            state.isModified = true;
        },
        updateDescription: (state, { payload }) => {
            state.description = payload;
            state.isModified = true;
        },
        updateNotes: (state, { payload }) => {
            state.notes = payload;
            state.isModified = true;
        },
        addTag: (state, { payload }) => {
            state.tags = payload;
            state.isModified = true;
        },
        removeTag: (state, { payload }) => {
            const tagIndex = state.tags.indexOf(payload);
            if (tagIndex > -1) {
                state.tags.splice(tagIndex, 1);
                state.isModified = true;
            }
        },
        updateXml: (state, { payload }) => {
            state.xml = payload;
        },
        saveDraft: (state) => {
            state.saving = true;
        },
        saveDraftSuccess: (state, { payload }) => {
            state.isModified = false;
            state.saving = false;
            state.latestVersionId = payload.latestVersionId;
        },
        saveDraftFailed: (state) => {
            state.saving = false;
        },
        saveAndPublishScene: (state) => {
            state.publishing = true;
        },
        saveAndPublishSceneSuccess: (state, { payload }) => {
            state.isModified = false;
            state.publishing = false;
            state.latestVersionId = payload.latestVersionId;
            state.publishedVersionId = payload.publishedVersionId;
        },
        saveAndPublishSceneFailed: (state) => {
            state.publishing = false;
        },
        publishScene: (state) => {
            state.publishing = true;
        },
        publishSceneSuccess: (state, { payload }) => {
            state.publishing = false;
            state.publishedVersionId = payload.publishedVersionId;
        },
        publishSceneFailed: (state) => {
            state.publishing = false;
        },
        createScene: (state) => {
            state.publishing = true;
        },
        createSceneSuccess: (state, { payload }) => {
            state.isModified = false;
            state.publishing = false;
            state.id = payload.sceneId;
            state.latestVersionId = payload.latestVersionId;
            state.publishedVersionId = payload.publishedVersionId;
        },
        // action to handle the correct shown of the notification when creating an scene
        createSceneFinished: () => {},
        createSceneFailed: (state) => {
            state.publishing = false;
        },
        createBulkScenes: (state) => {
            state.publishing = true;
        },
        createBulkScenesSuccess: (state) => {
            state.isModified = false;
            state.publishing = false;
        },
        createBulkScenesFailed: (state) => {
            state.publishing = false;
        },
        duplicateScene: (state) => {
            state.duplicating = true;
        },
        duplicateSceneSuccess: (state, { payload }) => {
            state.isModified = false;
            state.duplicating = false;
            state.id = payload.sceneId;
            state.name = payload.name;
        },
        duplicateSceneFailed: (state) => {
            state.duplicating = false;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(BLENDING_MODE_CHANGE, (state) => {
            state.isModified = true;
        });
        builder.addCase(backgroundUploaded, (state) => {
            state.isModified = true;
        });
        builder.addCase(replaceTransforms, (state) => {
            state.isModified = true;
        });
        builder.addCase(addDocument, (state) => {
            state.isModified = true;
        });
        builder.addCase(removeDocument, (state) => {
            state.isModified = true;
        });
        builder.addCase(maskUploaded, (state) => {
            state.isModified = true;
        });
        builder.addCase(textureUploaded, (state) => {
            state.isModified = true;
        });
        builder.addCase(removeMask, (state) => {
            state.isModified = true;
        });
        builder.addCase(changePage, (state) => {
            state.isModified = true;
        });
        builder.addCase(overlayUploaded, (state) => {
            state.isModified = true;
        });
        builder.addCase(removeOverlay, (state) => {
            state.isModified = true;
        });
        builder.addCase(dragTransformPoint, (state) => {
            state.isModified = true;
        });
        builder.addCase(editTransformPoint, (state) => {
            state.isModified = true;
        });
        builder.addCase(editTransform, (state) => {
            state.isModified = true;
        });
        builder.addCase(setWarp, (state) => {
            state.isModified = true;
        });
        builder.addCase(changeBackgroundOrder, (state) => {
            state.isModified = true;
        });
        builder.addCase(changeOverlayOrder, (state) => {
            state.isModified = true;
        });
        builder.addCase(changeEditorSkuSuccess, (state) => {
            state.isModified = true;
        });
        builder.addCase(clearEditorSku, (state) => {
            state.isModified = true;
        });
        builder.addCase(toggleEngraving, (state) => {
            state.isModified = true;
        });
        builder.addCase(toggleAutomask, (state) => {
            state.isModified = true;
        });
        builder.addCase(changeEngravingColor, (state) => {
            state.isModified = true;
        });
        builder.addCase(setAspectRatioConstraint, (state) => {
            state.isModified = true;
        });
        builder.addCase(retrieveProductDetails, (state) => {
            state.isModified = true;
        });
        builder.addCase(updateFixedAttributesSelection, (state) => {
            state.isModified = true;
        });
        builder.addCase(removeConfiguration, (state) => {
            state.isModified = true;
        });
        builder.addCase(addConfiguration, (state) => {
            state.isModified = true;
        });
        builder.addCase(updateConfiguration, (state) => {
            state.isModified = true;
        });
        builder.addCase(addSceneVariable, (state) => {
            state.isModified = true;
        });
        builder.addCase(addSceneVariableRule, (state) => {
            state.isModified = true;
        });
        builder.addCase(removeSceneVariable, (state) => {
            state.isModified = true;
        });
        builder.addCase(updateSceneVariableRule, (state) => {
            state.isModified = true;
        });
        builder.addCase(removeSceneVariableRule, (state) => {
            state.isModified = true;
        });
    },
});

export const reducer = slice.reducer;

export const updateName = slice.actions.updateName;
export const updateDescription = slice.actions.updateDescription;
export const updateNotes = slice.actions.updateNotes;
export const addTag = slice.actions.addTag;
export const removeTag = slice.actions.removeTag;
export const updateXml = slice.actions.updateXml;

export const saveDraft = slice.actions.saveDraft;
export const saveDraftSuccess = slice.actions.saveDraftSuccess;
export const saveDraftFailed = slice.actions.saveDraftFailed;

export const saveAndPublishScene = slice.actions.saveAndPublishScene;
export const saveAndPublishSceneSuccess = slice.actions.saveAndPublishSceneSuccess;
export const saveAndPublishSceneFailed = slice.actions.saveAndPublishSceneFailed;

export const duplicateScene = slice.actions.duplicateScene;
export const duplicateSceneSuccess = slice.actions.duplicateSceneSuccess;
export const duplicateSceneFailed = slice.actions.duplicateSceneFailed;

export const publishScene = slice.actions.publishScene;
export const publishSceneSuccess = slice.actions.publishSceneSuccess;
export const publishSceneFailed = slice.actions.publishSceneFailed;

export const createScene = slice.actions.createScene;
export const createSceneSuccess = slice.actions.createSceneSuccess;
export const createSceneFinished = slice.actions.createSceneFinished;
export const createSceneFailed = slice.actions.createSceneFailed;

export const createBulkScenes = slice.actions.createBulkScenes;
export const createBulkScenesSuccess = slice.actions.createBulkScenesSuccess;
export const createBulkScenesFailed = slice.actions.createBulkScenesFailed;
