import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getRulerById } from 'src/selectors/layers';
import { addCustomTick, removeCustomTick, updateCustomTick } from 'src/pages/editor/components/rulers/slice';
import customTicks from './customTicks';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    // @ts-expect-error -- fix type for `getRulerById`
    const { ticks, segments } = getRulerById(state, ownProps.id);
    return { ticks, segments };
};

const customTicksContainer = connect(mapStateToProps, { addCustomTick, removeCustomTick, updateCustomTick })(
    customTicks,
);

export default customTicksContainer;
