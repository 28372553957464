import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { loadLinksForSkuSuccess } from 'src/components/skus/slice';
import { LinkType } from 'src/types/link';
import { removeById } from 'src/util/state';

type Link = Omit<LinkType, 'skuVersion' | 'tenant'> & {
    id: string;
    readyToLink?: boolean;
    linkSuccess?: boolean;
    retrievingSurfaces: boolean;
};

type LinksSliceState = Link[];

const initialState: LinksSliceState = [];

function addLinks(state: LinksSliceState, { payload }: PayloadAction<{ links: LinkType[] }>) {
    const links = payload.links
        .filter((link) => !state.some((stateLink) => link.linkId === stateLink.id))
        .map((link) => ({
            id: link.linkId,
            sceneId: link.sceneId,
            linkId: link.linkId, // This id is the id that is stored on the server (may be different than internal reference id)
            sku: link.sku,
            merchantSku: link.merchantSku,
            purpose: link.purpose,
            subPurpose: link.subPurpose,
            variables: link.variables,
            surfaces: link.surfaces || [],
            tags: link.tags || [],
            retrievingSurfaces: false,
            _links: link._links,
        }));
    state.push(...links);
}

const slice = createSlice({
    name: 'links',
    initialState,

    reducers: {
        addLink: addLinks,
        deleteLink: () => {},
        deleteLinkSuccess: removeById,
        linkToServerSuccess: (state, { payload }: PayloadAction<{ linkId?: string; id: string }>) => {
            const { linkId, id } = payload;
            const matchingLink = state.find((link) => link.id === id);
            if (matchingLink) {
                if (linkId) {
                    matchingLink.linkId = linkId;
                }
                matchingLink.readyToLink = false;
                matchingLink.linkSuccess = true;
            }
        },
        linkToServerSuccessRemove: (state, { payload }: PayloadAction<string>) => {
            const matchingLink = state.find((link) => link.id === payload);
            if (matchingLink) {
                matchingLink.linkSuccess = false;
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase(loadLinksForSkuSuccess, addLinks);
    },
});

export const reducer = slice.reducer;

export const addLink = slice.actions.addLink;
export const deleteLink = slice.actions.deleteLink;
export const deleteLinkSuccess = slice.actions.deleteLinkSuccess;
export const linkToServerSuccess = slice.actions.linkToServerSuccess;
export const linkToServerSuccessRemove = slice.actions.linkToServerSuccessRemove;
