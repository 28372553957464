import * as React from 'react';
import { createMessage, validateMessage } from './webworker/message';
import { Button, Modal } from '@cimpress/react-components';

import { getCurrentPSDFile, getLayersPSD, getResolutionPSD } from './selectors';
import { AppDispatch, RootState } from 'src/store';
import {
    selectPSDLayer,
    importPSDStart,
    importPSDFinish,
    setResolution,
} from 'src/pages/editor/components/backgrounds/slice';
import { connect } from 'react-redux';
import PSDImportContent from './content';
import { LayerSelected, ResolutionType } from './types';
import { readFileAsArrayBuffer, toImageURL } from 'src/util/upload';

interface IPSDImporterProps {
    selectedLayers: LayerSelected[];
    file: File | undefined;
    resolution: ResolutionType;
    onClose: () => void;
    onLoadSuccess: (layer: LayerSelected) => void;
    onResolutionChange: (res: ResolutionType) => void;
    onUpload: (layers: string[]) => void;
}

const PSDImporter: React.FunctionComponent<IPSDImporterProps> = (props) => {
    const [layers, setLayers] = React.useState<LayerSelected[]>([]);
    const [worker, setWorker] = React.useState<Worker>();

    const { file, selectedLayers, onClose, onUpload, onLoadSuccess } = props;

    const handleWorkerCallback = React.useCallback(({ data }: MessageEvent<any>) => {
        const { type, timestamp, value } = data;
        validateMessage(data);

        if (type === 'Layer') {
            const { pixelData, ...fileData } = value;
            const newItem: LayerSelected = Object.assign(
                {
                    type,
                    key: data.key,
                    timestamp,
                },
                { ...fileData, imageUrl: toImageURL(value) },
            );
            setLayers((cur) => [...cur, newItem]);
        }
    }, []);

    React.useEffect(() => {
        if (window.Worker) {
            const newWorker = new Worker(new URL('./webworker/worker', import.meta.url), { type: 'module' });
            if (newWorker) {
                newWorker.addEventListener('message', (ev: MessageEvent<any>) => handleWorkerCallback(ev));
                newWorker.addEventListener('error', (event) => {
                    console.error('Error on Web Worker', event);
                });
                setWorker(newWorker);
            }

            return () => {
                newWorker.terminate();
            };
        }
    }, [handleWorkerCallback]);

    // We want the file to be process only once, this file will be render when file is already set
    // so, only check for worker, that should be enough to process the current file
    React.useEffect(() => {
        if (file && worker) {
            setLayers([]);
            readFileAsArrayBuffer(file).then((buffer) => {
                worker.postMessage(createMessage('ParseData', buffer), [buffer]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worker]);

    const items = React.useMemo(() => {
        return layers.filter((i) => i.imageUrl);
    }, [layers]);

    const footerEle = React.useMemo(() => {
        return (
            <div className='import-psd-modal__footer'>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant='primary' onClick={onUpload}>
                    Upload Assets
                </Button>
            </div>
        );
    }, [onClose, onUpload]);

    const onChangeResolution = (evt: React.ChangeEvent<HTMLInputElement>) => {
        props.onResolutionChange({ ...props.resolution, [evt.target.name]: parseInt(evt.target.value, 10) });
    };

    return (
        <Modal
            className='import-psd-modal'
            show={true}
            title='Import PSD Assets'
            closeButton={false}
            onRequestHide={onClose}
            size='lg'
            footer={footerEle}>
            <div className='import-psd-modal__body'>
                <PSDImportContent
                    layers={items}
                    resolution={props.resolution}
                    selectedLayers={selectedLayers}
                    onLayerSelected={onLoadSuccess}
                    onChangeInput={onChangeResolution}
                />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    const file = getCurrentPSDFile(state);
    const layers = getLayersPSD(state);
    const resolution = getResolutionPSD(state);
    return {
        file,
        selectedLayers: layers,
        resolution,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onLoadSuccess: (layer: LayerSelected) => dispatch(selectPSDLayer(layer)),
    onResolutionChange: (resolution: { width: number; height: number }) => dispatch(setResolution(resolution)),
    onUpload: () => {
        dispatch(importPSDStart());
    },
    onClose: () => dispatch(importPSDFinish()),
});

const ImportPSDModal = connect(mapStateToProps, mapDispatchToProps)(PSDImporter);

export default ImportPSDModal;
