import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getSceneSize } from 'src/selectors/layers';
import { addDocument } from './slice';
import addLayer from '../layer/addLayer';

const mapStateToProps = (state: RootState) => {
    const sceneSize = getSceneSize(state);
    return {
        addProps: sceneSize,
        testid: 'editor-scene-documents-add',
    };
};

const addDocumentContainer = connect(mapStateToProps, { onAdd: (addProps: any) => addDocument(addProps) })(addLayer);

export default addDocumentContainer;
