const onDownListeners = [];
const onUpListeners = [];

document.addEventListener(
    'keydown',
    (e) => {
        onDownListeners.forEach((listener) => listener(e));
    },
    true,
);

document.addEventListener('keyup', (e) => {
    onUpListeners.forEach((listener) => listener(e));
});

export function addOnDownListener(listener) {
    onDownListeners.push(listener);
}

export function addOnUpListener(listener) {
    onUpListeners.push(listener);
}

export function removeOnDownListener(listener) {
    const index = onDownListeners.indexOf(listener);
    onDownListeners.splice(index, 1);
}

export function removeOnUpListener(listener) {
    const index = onDownListeners.indexOf(listener);
    onUpListeners.splice(index, 1);
}
