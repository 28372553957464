import { connect } from 'react-redux';

import { getAllBackgrounds } from 'src/selectors/layers';
import { getAllSkuData } from 'src/selectors/skus';
import {
    changeEditorSku,
    changeEditorSkuVersion,
    clearEditorSku,
    validateEditorSku,
} from 'src/pages/editor/components/surfaces/slice';

import skuInput from 'src/components/product/sku';
import { getSkuData, getSurfaceProperties } from 'src/selectors/pages/editor';

function mapStateToProps(state) {
    const { sku, skuVersion, merchantSku, merchantVersion, isReady } = getSurfaceProperties(state);
    const skus = getAllSkuData(state);
    const curSkuData = getSkuData(state);

    return {
        sku,
        skuVersion,
        merchantSku,
        merchantVersion,
        allSkus: skus,
        disabled: getAllBackgrounds(state).length === 0,
        isReady,
        hasNoVersions: curSkuData && curSkuData.isV1Product,
    };
}

const skuContainer = connect(mapStateToProps, {
    onValidateSku: validateEditorSku,
    onChangeVersion: changeEditorSkuVersion,
    onChangeProduct: changeEditorSku,
    onClearProduct: clearEditorSku,
})(skuInput);

export default skuContainer;
