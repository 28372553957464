import React, { PureComponent, ReactElement } from 'react';
import { Stage, Layer, Group } from 'react-konva';
import { PERSPECTIVE_WARP, RECTANGLE_WARP, SMOOTH_WARP } from 'src/models/documentWarps';
import { Checkbox } from '@cimpress/react-components';
import { Provider, ReactReduxContext } from 'react-redux';
import Border from '../../canvas/warps/nPoint/border';
import GrabPoints from '../../canvas/warps/nPoint/grabPoints';

type Point = {
    x: number;
    y: number;
};

type MapPoint = Point & {
    sourceX: number;
    sourceY: number;
};

interface Props {
    id: string;
    points: MapPoint[];
    warpType: string;
    onDragEnd: (points: Point[]) => void;
    offset: number;
    snapCoordinates: { x: number[]; y: number[] };
    img: { src: string; width: number; height: number; scaledWidth: number; scaledHeight: number };
}

interface State {
    guideEnable: boolean;
    points?: Point[];
}

const SNAP_DISTANCE = 5;

export default class SourcePointCanvas extends PureComponent<Props, State> {
    state: State = {
        guideEnable: true,
    };

    container: HTMLDivElement | null = null;

    componentDidMount() {
        this.resizePoints();
    }

    componentDidUpdate(prevProps: Props) {
        const { img } = this.props;

        if (prevProps.img.scaledWidth !== img.scaledWidth || prevProps.img.scaledHeight !== img.scaledHeight) {
            this.resizePoints();
        }
    }

    resizePoints = () => {
        const { points, offset, warpType, img } = this.props;

        // set grab points be on document relative to currently set source points
        let newPoints: Point[] = [];
        if (warpType === RECTANGLE_WARP) {
            // map point to point for rectangular warp
            newPoints = points.map((point) => ({
                x: point.sourceX * img.scaledWidth + offset,
                y: point.sourceY * img.scaledHeight + offset,
            }));
        } else if (warpType === PERSPECTIVE_WARP) {
            // map perspective warp points to perspective warp points
            newPoints[0] = {
                x: points[0].sourceX * img.scaledWidth + offset,
                y: points[0].sourceY * img.scaledHeight + offset,
            };
            newPoints[1] = {
                x: points[2].sourceX * img.scaledWidth + offset,
                y: points[2].sourceY * img.scaledHeight + offset,
            };
        } else {
            // map perspective warp points to smooth warp points
            newPoints[0] = {
                x: points[0].sourceX * img.scaledWidth + offset,
                y: points[0].sourceY * img.scaledHeight + offset,
            };
            newPoints[1] = {
                x: points[points.length - 1].sourceX * img.scaledWidth + offset,
                y: points[points.length - 1].sourceY * img.scaledHeight + offset,
            };
        }

        this.setState({ points: newPoints });
    };

    onDragMove = (points: Point[]): void => {
        let newPoints = points;
        if (this.state.guideEnable) {
            newPoints = points.map((point) => this.snapToCoordinate(point));
        }
        this.setState({ points: newPoints });
    };

    snapToCoordinate = (point: Point): Point => {
        const { snapCoordinates } = this.props;
        snapCoordinates.x.forEach((x: number) => {
            if (Math.abs(point.x - x) < SNAP_DISTANCE) {
                point.x = x;
            }
        });
        snapCoordinates.y.forEach((y: number) => {
            if (Math.abs(point.y - y) < SNAP_DISTANCE) {
                point.y = y;
            }
        });
        return point;
    };

    onDragEnd = () => {
        const { points } = this.state;
        const { warpType } = this.props;

        if (warpType === PERSPECTIVE_WARP) {
            this.props.onDragEnd([
                points![0],
                { x: points![1].x, y: points![0].y },
                points![1],
                { x: points![0].x, y: points![1].y },
            ]);
        } else if (warpType === RECTANGLE_WARP) {
            this.props.onDragEnd(points!);
        } else if (warpType === SMOOTH_WARP) {
            const updatedPoints: Point[] = [];
            const dimension = Math.sqrt(this.props.points.length);
            const distanceBetweenColumns =
                Math.abs(this.state.points![0].x - this.state.points![1].x) / (dimension - 1);
            const distanceBetweenRows = Math.abs(this.state.points![0].y - this.state.points![1].y) / (dimension - 1);
            this.props.points.forEach((_, index) => {
                const row = Math.floor(index / dimension);
                const column = index % dimension;
                updatedPoints.push({
                    x: points![0].x + distanceBetweenColumns * column,
                    y: points![0].y + distanceBetweenRows * row,
                });
            });
            this.props.onDragEnd(updatedPoints);
        }
    };

    handleGuideChange = () => {
        this.setState((state) => ({ guideEnable: !state.guideEnable }));
    };

    render() {
        const { id, offset, img } = this.props;
        const { points } = this.state;

        let canvas: ReactElement;
        let backDrop: ReactElement;

        if (points && this.container) {
            const cursorChange = (cursor: string) => (this.container!.style.cursor = cursor);

            backDrop = (
                <img
                    className='scene-maker-editor__background'
                    src={img.src}
                    width={img.scaledWidth}
                    height={img.scaledHeight}
                    style={{ top: offset, left: offset }}
                />
            );

            let scaledAndExpandedPoints = points;
            if (points.length === 2) {
                scaledAndExpandedPoints = [
                    points[0],
                    { x: points[1].x, y: points[0].y },
                    points[1],
                    { x: points[0].x, y: points[1].y },
                ];
            }

            // Redux workaround for React Konva: https://github.com/konvajs/react-konva/issues/311#issuecomment-536634446
            // This can be removed as soon as we upgrade React Konva to 18.2.2 or higher which requires React 18
            canvas = (
                <ReactReduxContext.Consumer>
                    {({ store }) => (
                        <Stage width={img.scaledWidth + offset * 2} height={img.scaledHeight + offset * 2}>
                            <Provider store={store}>
                                <Layer>
                                    <Group>
                                        <Border
                                            selected={true}
                                            points={points}
                                            scaledPoints={scaledAndExpandedPoints}
                                            scale={1}
                                            onDragMove={this.onDragMove}
                                            onDragEnd={this.onDragEnd}
                                            selectLayer={() => {}}
                                        />
                                        <Group>
                                            <GrabPoints
                                                points={points}
                                                scaledPoints={scaledAndExpandedPoints}
                                                selectPoint={() => {}}
                                                isAspectRatioConstrained={false}
                                                aspectRatio={1}
                                                scale={1}
                                                onDragMove={this.onDragMove}
                                                onDragEnd={this.onDragEnd}
                                                cursorChange={cursorChange}
                                                documentId={id}
                                                snapPoints={[]}
                                                selectedPoint={0}
                                                rotationAngle={0}
                                            />
                                        </Group>
                                    </Group>
                                </Layer>
                            </Provider>
                        </Stage>
                    )}
                </ReactReduxContext.Consumer>
            );
        }

        return (
            <div
                ref={(ref) => {
                    this.container = ref;
                }}
                className='scene-maker-editor__sourcePoint_canvas'>
                {backDrop!}
                {canvas!}
                <Checkbox
                    style={{ marginLeft: '10px' }}
                    checked={this.state.guideEnable}
                    onChange={this.handleGuideChange}
                    label='Snap to Coordinate Enable'
                />
            </div>
        );
    }
}
