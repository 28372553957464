import React, { Component } from 'react';
import { Group, Line } from 'react-konva';
import Grabber from '../../grabber';
import { calculatePosition, toDegrees, angle as calculateAngle, distance } from 'src/util/math';
import type { KonvaEventObject } from 'konva/lib/Node';
import type { CylinderType } from 'src/types/transforms';

const PADDING = 15;

const snapRotations = [0, 45, 90, 135, 180, 225, 270, 315];

interface Props {
    container?: HTMLElement;
    scaledCylinderWarp?: Omit<CylinderType, 'id' | 'fullWrap'>;
    cylinderWarp?: CylinderType;
    scale: number;
    onDragMove: (warp: CylinderType) => void;
    onDragEnd: () => void;
    cursorChange: (cursor: string) => void;
    offset: { x: number; y: number };
}

interface State {
    startCylinderWarp?: CylinderType | null;
}

export default class Border extends Component<Props, State> {
    state = {
        startCylinderWarp: null,
    };

    onDragStart = (e: KonvaEventObject<MouseEvent>) => {
        const { cylinderWarp } = this.props;

        this.setState({
            startCylinderWarp: cylinderWarp,
        });

        document.onmousemove = this.onDragMove;
        document.onmouseup = this.onDragEnd;
        e.cancelBubble = true;
    };

    onDragMove = (e: MouseEvent) => {
        const { startCylinderWarp } = this.state;
        const warp = JSON.parse(JSON.stringify(startCylinderWarp));
        const { onDragMove, scale, container, offset } = this.props;
        const topCenter = warp.cylinderTop.center;
        const bottomCenter = warp.cylinderBottom.center;
        const tubeCenter = {
            x: (topCenter.x + bottomCenter.x) / 2,
            y: (topCenter.y + bottomCenter.y) / 2,
        };

        const containerRect = (container as HTMLElement).getBoundingClientRect();
        const mousePosition = {
            x: (e.clientX - containerRect.left - offset.x) / scale,
            y: (e.clientY - containerRect.top - offset.y) / scale,
        };

        let angle = toDegrees(calculateAngle(tubeCenter, topCenter, mousePosition));

        // rotate left or rotate right
        if (
            distance(mousePosition, calculatePosition(warp.cylinderTop, -180)) <
            distance(mousePosition, calculatePosition(warp.cylinderTop, 0))
        ) {
            angle = -angle;
        }

        // snap angle to one of the snap rotation (angle in degrees)
        const angleDirection = angle / Math.abs(angle) || 1;
        angle = Math.abs(angle);

        snapRotations.forEach((snapAngle) => {
            if (angle < snapAngle + 5 && angle > snapAngle - 5) {
                angle = snapAngle;
            }
        });

        angle *= angleDirection;

        angle = warp.cylinderTop.rotationDegrees + angle;

        // baseline tube radius
        const baselineRadius = distance(topCenter, tubeCenter);

        // circle representing the entire tube
        const tubeCircle = {
            center: tubeCenter,
            radiusX: baselineRadius,
            radiusY: baselineRadius,
            rotationDegrees: 0,
        };

        // calculate center point of "top ellipse"
        const topCenterPoint = calculatePosition(tubeCircle, angle - 90);

        // calculate center point of "bottom ellipse"
        const bottomCenterPoint = calculatePosition(tubeCircle, angle - 270);

        warp.cylinderTop.center = topCenterPoint;
        warp.cylinderTop.rotationDegrees = angle;
        warp.cylinderBottom.center = bottomCenterPoint;
        warp.cylinderBottom.rotationDegrees = angle;

        onDragMove(warp);
    };

    onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();
    };

    render() {
        const { scaledCylinderWarp, cursorChange } = this.props;
        const largerEllipse = Object.assign({}, scaledCylinderWarp?.cylinderTop);
        largerEllipse.radiusX = Math.abs(largerEllipse.radiusX) + PADDING;
        largerEllipse.radiusY = Math.abs(largerEllipse.radiusY) + PADDING;

        const rotationPoint = calculatePosition(largerEllipse, -90);

        const centerOfTopEllipse = scaledCylinderWarp?.cylinderTop.center;
        const dottedLine = [rotationPoint.x, rotationPoint.y, centerOfTopEllipse!.x, centerOfTopEllipse!.y];

        return (
            <Group>
                <Line points={dottedLine} stroke='rgba(0, 0, 0, .3)' dash={[4, 2]} strokeWidth={1} />
                <Grabber position={rotationPoint} cursorChange={cursorChange} onDragStart={this.onDragStart} />
            </Group>
        );
    }
}
