import React, { PureComponent } from 'react';
import { Spinner } from '@cimpress/react-components';

import idGeneratorAtor from 'src/util/idGeneratorAtor';
import FileUpload from './FileUpload';

const idGenerator = idGeneratorAtor('uploadButton');

interface UploadButtonProps {
    className?: string;
    label?: string;
    accept?: string;
    hide?: boolean;
    uploaded?: boolean;
    uploading?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    buttonType?: string;
    onUpload: React.ChangeEventHandler<HTMLInputElement>;
    testid?: string;
}

export default class UploadButton extends PureComponent<UploadButtonProps> {
    state = {
        uniqueId: idGenerator().replace(' ', ''),
    };

    render() {
        const {
            label,
            hide,
            uploaded,
            uploading,
            onUpload,
            className,
            buttonType,
            accept,
            disabled,
            multiple,
            testid,
        } = this.props;
        if (hide) {
            return null;
        }
        const type = buttonType || 'default';
        return (
            <div
                className={`upload-button ${className}${uploaded ? ' upload-button--uploaded' : ''}${uploading ? ' upload-button--uploading' : ''}`}>
                <label htmlFor={this.state.uniqueId} className={`btn btn-${type}${disabled ? ' disabled' : ''}`}>
                    {label}
                </label>
                <Spinner className='upload-button__spinner centerSpinner' size='medium' />
                <FileUpload
                    testid={testid}
                    id={this.state.uniqueId}
                    onChange={onUpload}
                    disabled={disabled || uploading}
                    accept={accept}
                    multiple={multiple}
                />
            </div>
        );
    }
}
