import axios from 'axios';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { TextField, Button } from '@cimpress/react-components';

import { logError } from 'src/loggingManager';

export default class DocRefSelector extends PureComponent {
    state = {
        docRef: this.props.existingDocRef,
        fail: false,
        success: false,
    };

    // eslint-disable-next-line react/no-deprecated -- FIXME: move to hooks
    componentWillReceiveProps(nextProps) {
        this.setState({ docRef: nextProps.existingDocRef });
    }

    onTypeText = (e) => {
        this.setState({ docRef: e.target.value });
    };

    updateDocRef = () => {
        this.setState({ success: false, fail: false });
        const { docRef } = this.state;

        axios({
            url: docRef,
        })
            .then((response) => {
                if (response.data.renderingInstructionsUrl) {
                    this.setState({ success: true });
                    this.props.onUpdateDocRef(response.data.renderingInstructionsUrl);
                } else {
                    throw new Error('The url is not a valid document reference!');
                }
            })
            .catch((e) => {
                this.setState({ fail: true });
                logError(`failure to retrieve docRef (url=${docRef}): ${e}`);
            });
    };

    render() {
        const { success, fail } = this.state;
        let style = undefined;
        if (success) {
            style = 'success';
        } else if (fail) {
            style = 'error';
        }
        return (
            <div className='choose-doc-ref'>
                <p className='choose-doc-ref__title'>Specify a document reference:</p>
                <div className='choose-doc-ref__wrapper'>
                    <TextField
                        data-testid='editor-sceneInformation-docRef-input'
                        className='choose-doc-ref__input'
                        value={this.state.docRef}
                        onChange={this.onTypeText}
                        placeholder='Add Url Here'
                        status={style}
                        type='url'
                    />

                    <Button
                        data-testid='editor-sceneInformation-docRef-submit'
                        className='choose-sku__submit'
                        onClick={this.updateDocRef}>
                        Update
                    </Button>
                </div>
            </div>
        );
    }
}

DocRefSelector.propTypes = {
    existingDocRef: PropTypes.string,
    onUpdateDocRef: PropTypes.func.isRequired,
};
