import { createSelector } from 'reselect';

const getCroppingData = (state) => state.sceneConfiguration.activeLink.cropping;
const getSceneVariables = (state) => state.sceneConfiguration.activeLink.sceneVariables;

const getCroppingRule = createSelector([getCroppingData, getSceneVariables], (cropping, sceneVariables) => {
    const { purpose, ruleId } = cropping;
    const foundItem = sceneVariables.find((i) => i.purpose === purpose && i.rules.find((r) => r.id === ruleId));
    if (foundItem) {
        return foundItem.rules.find((r) => r.id === ruleId);
    }

    return {};
});

export default getCroppingRule;
