import React, { PureComponent } from 'react';

interface LoadingProps {
    show: boolean;
    greyOut?: boolean;
    fullPage?: boolean;
}

export default class Loading extends PureComponent<LoadingProps> {
    render() {
        const { show, greyOut, fullPage } = this.props;
        const showGreyOut = greyOut && show ? ' loader--grey-out' : '';
        const showFullPage = fullPage && show ? ' loader--full-page' : '';
        const showLoader = show ? ' loader__spinner--show' : '';
        return (
            <div className={`loader${showGreyOut}${showFullPage}`}>
                <img className={`loader__spinner${showLoader}`} />
            </div>
        );
    }
}
