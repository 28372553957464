import { all, call, put, takeEvery, take, select } from 'redux-saga/effects';

import { getAIData, isAIMode, isApplicationLoading } from 'src/selectors';
import { noPermissions as noPermissionsAlert } from 'src/components/alert/slice';
import { getResources } from 'src/services/coamClient';
import { generateImages as createImages } from './services/imageMindClient';
import { RELOAD } from 'src/store/actions/actionTypes';
import {
    completeLoadingApplication,
    setResources,
    loadApplication,
    setTenant,
    setUserSettings,
    setAIData,
    generateImagesAI,
    confirmImageAI,
    changeMode,
    toggleShowCreateSceneModal,
    setAIModalOpen,
} from 'src/slice';
import { noSurfacesEnabled } from 'src/settings';
import { getPurposeSettings } from './services/linkClient';
import idGeneratorAtor from './util/idGeneratorAtor';
import { uploadBackgrounds } from './pages/editor/components/backgrounds/slice';
import { getAllBackgrounds } from './selectors/layers';
import { getCanvasImage } from './selectors/canvas';

const idGenerator = idGeneratorAtor('BackgroundButton');
const idAddGenerator = idGeneratorAtor('aiNewBackground');

function* initializeCoam() {
    const resources = yield call(getResources);
    if (!Object.keys(resources).length) {
        return yield put(noPermissionsAlert());
    }
    const actionPayload = {
        resources,
        areSurfacesRequired: noSurfacesEnabled ? localStorage.getItem('settingNoSurfaces') !== 'true' : true,
    };

    // Pull the last selected coam option and check that the user has permission for it.
    let tenantId;
    let tenantType;
    const coam = localStorage.getItem('coamSelection');
    if (coam) {
        [tenantType, tenantId] = coam.split(',');
    }
    if (resources[tenantType] && resources[tenantType].includes(tenantId)) {
        Object.assign(actionPayload, {
            type: tenantType,
            id: tenantId,
        });
    } else {
        // If there is at least one tenant type and it's ids aren't empty populate the display
        const firstTenantType = Object.keys(resources).find((key) => resources[key].length);
        if (firstTenantType) {
            Object.assign(actionPayload, { type: firstTenantType, id: resources[firstTenantType][0] });
        }
    }

    return yield put(setResources(actionPayload));
}

function* loadApp() {
    const preloadState = localStorage.getItem('expiredState');
    if (preloadState) {
        localStorage.removeItem('expiredState');
        yield put({ type: RELOAD, payload: JSON.parse(preloadState) });
    } else {
        yield initializeCoam();
    }
    yield put(completeLoadingApplication());
}

// This function GUARANTEES that, when this is "yield call()"ed, that the application load will be complete once it is done yielding.
export function* awaitApplicationLoad() {
    const loading = yield select(isApplicationLoading);
    if (loading) {
        yield take(completeLoadingApplication);
    }
}

function* getTenantMerchandisingSettings({ payload }) {
    if (payload && payload.type && payload.id) {
        const data = yield call(getPurposeSettings, payload.type, payload.id);
        yield put(setUserSettings(data));
    }
}

function* changeModals({ payload }) {
    const isModalOpen = yield select(isAIMode);
    yield put(setAIModalOpen(payload === 'ai'));
    if ((isModalOpen && payload !== 'ai') || (!isModalOpen && payload === 'ai')) {
        yield put(toggleShowCreateSceneModal());
    }
}

function* createSceneFromAI({ payload }) {
    if (payload) {
        const response = yield fetch(payload);
        const blob = yield response.blob();
        blob.name = `imageGeneratedByAI-${Date.now()}`;
        blob.lastModifiedDate = new Date();
        const files = yield select(getAllBackgrounds);
        const isFirstTime = files.length === 0;
        const newFiles = [
            {
                id: isFirstTime ? idGenerator() : idAddGenerator(),
                file: blob,
                isFirstUpload: isFirstTime,
            },
        ];
        yield put(uploadBackgrounds(newFiles));
    }
}

export function* generateImages({ payload }) {
    const isActive = yield select(isAIMode);
    const data = yield select(getAIData);
    const image = yield select(getCanvasImage);
    // only execute when payload is false, when generation is finished
    if (isActive && payload && data.description) {
        const width = image.width || data.width;
        const height = image.height || data.height;
        const abortController = new AbortController();
        const images = yield createImages({
            input: data.description,
            width: +width,
            height: +height,
            signal: abortController.signal,
        });
        if ('errorMessage' in images) {
            yield put(
                setAIData({
                    field: 'error',
                    value: images.errorMessage,
                }),
            );
            yield put(generateImagesAI(false));
        } else {
            yield put(
                setAIData({
                    field: 'images',
                    value: images.map((img) => img.url),
                }),
            );
            yield put(generateImagesAI(false));
        }
    }
}

export default function* applicationSagas() {
    return yield all([
        yield takeEvery(loadApplication, loadApp),
        yield takeEvery(setTenant, getTenantMerchandisingSettings),
        yield takeEvery(setResources, getTenantMerchandisingSettings),
        yield takeEvery(generateImagesAI, generateImages),
        yield takeEvery(confirmImageAI, createSceneFromAI),
        yield takeEvery(changeMode, changeModals),
    ]);
}
