import { createSelector } from 'reselect';
import { RootState } from 'src/store';

const getCanvasDocument = (state: RootState) => state.pages.editor.canvas.renderedDocument;

const getEditorDocImage = createSelector([getCanvasDocument], (canvas) => ({
    image: canvas.image,
    reference: canvas.reference,
    fileName: canvas.fileName,
}));

export default getEditorDocImage;
