// Note that there is no "attribute" API, but this is a collection of clients that work together to form this client.
import { retrieveRequiredVariables } from 'src/services/surfaceClient';
import { makeAuthenticatedServiceRequest } from './servicesClient';

export const clientId = 'L7F3LDeQMgZf9J2smpD1c10N2NaNlkP5';

const ENDPOINT = 'https://rule.products.cimpress.io';

function retrieveRuleData(sku) {
    return makeAuthenticatedServiceRequest({
        url: `${ENDPOINT}/v1/ruleSets?referenceId=${sku}`,
    }).then((response) => response && response.data && response.data[0]);
}

export function retrieveAllAndRequiredVariables(sku) {
    // eslint-disable-line import/prefer-default-export
    const rulePromise = retrieveRuleData(sku);
    const requiredVariablePromise = retrieveRequiredVariables(sku);
    return Promise.all([rulePromise, requiredVariablePromise]).then(([ruleSet, requiredVariablesRaw]) => {
        const requiredVariables = requiredVariablesRaw || [];
        // Special flag, if couldn't find required variables then flag for merchandising only
        const merchandisingOnly = requiredVariablesRaw === undefined;
        return { requiredVariables, ruleSet, merchandisingOnly };
    });
}
