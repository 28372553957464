import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getCoamTenant, isBulkMode } from 'src/selectors';
import {
    isScenePublishing,
    isSceneSavable,
    isScenePublished,
    isSceneModified,
    getSceneId,
    getLatestVersionId,
    getPublishedVersionId,
    isLinkSetupInvalid,
} from 'src/selectors/pages/editor';
import {
    saveAndPublishScene,
    publishScene,
    createScene,
    createBulkScenes,
} from 'src/pages/editor/components/scene/slice';
import publishButton from './publish';
import getSceneVariation from '../../SceneVariation/selectors/getSceneVariation';

const SELECT_TENANT_TIP = 'Select a Tenant at the top of the page to save and publish';
const INVALID_SKU_SETUP_TIP = 'Select the required attributes for your sku before continuing';

const getTipText = (state: RootState) => {
    const invalidSkuSetup = isLinkSetupInvalid(state);
    const invalidTenantSelection = !getCoamTenant(state).tenantId;

    if (invalidTenantSelection) {
        return SELECT_TENANT_TIP;
    }

    if (invalidSkuSetup) {
        return INVALID_SKU_SETUP_TIP;
    }

    return '';
};

const mapStateToProps = (state: RootState) => {
    const sceneVariable = getSceneVariation(state);
    const isSceneVariableSavable = sceneVariable.sku ? sceneVariable.scenePurposes.length > 0 : true;
    return {
        tip: getTipText(state),
        spinning: isScenePublishing(state),
        disabled:
            !isSceneSavable(state) ||
            (!isSceneModified(state) && getLatestVersionId(state) === getPublishedVersionId(state)) ||
            !isSceneVariableSavable,
        isModified: isSceneModified(state),
        isEditingScene: !!getSceneId(state),
        isPublished: isScenePublished(state),
        isBulkMode: isBulkMode(state),
    };
};

const publishContainer = connect(mapStateToProps, { saveAndPublishScene, publishScene, createScene, createBulkScenes })(
    publishButton,
);

export default publishContainer;
