import { connect } from 'react-redux';
import { isMultiBackground } from 'src/selectors/layers';
import { isSceneSavable, isSceneDuplicating, getSceneId } from 'src/selectors/pages/editor';
import { duplicateScene } from 'src/pages/editor/components/scene/slice';
import duplicateButton from './duplicate';
import { RootState } from 'src/store';

const mapStateToProps = (state: RootState) => ({
    spinning: isSceneDuplicating(state),
    disabled: isMultiBackground(state) || !isSceneSavable(state) || !getSceneId(state),
});

const duplicateButtonContainer = connect(mapStateToProps, { duplicate: () => duplicateScene() })(duplicateButton);

export default duplicateButtonContainer;
