import { AppDispatch, RootState } from 'src/store';
import { getSelectedAssetRemoval } from '../backgrounds/selectors';
import { hideAssetRemovalModal, updateAssetRemovalModal } from '../backgrounds/slice';
import { BackgroundRemovalModal } from '../backgrounds/backgroundRemovalModal';
import { connect } from 'react-redux';
import getSceneVariation from './selectors/getSceneVariation';

const mapStateToProps = (state: RootState) => {
    const backgroundId = getSelectedAssetRemoval(state);
    const data = backgroundId?.split(';');

    const sceneConfig = getSceneVariation(state);
    const variable = sceneConfig.productConfigurations.find(
        (item) => item.variableOption === data?.[0] && item.variableAttribute === data?.[1],
    );
    let item: Record<string, any> = {};
    if (variable !== undefined && Object.keys(variable).length > 0) {
        const key = data?.[2];
        if (key !== undefined) {
            item = variable?.values[key];
        }
    }
    const show = backgroundId != null;

    return {
        show,
        imageUrl: item && item.value && item.value.imageUrl ? item.value.imageUrl : '',
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    hide: () => dispatch(hideAssetRemovalModal()),
    onReplace: (backgroundRemovedUrl: string) => dispatch(updateAssetRemovalModal(backgroundRemovedUrl)),
});

const AssetRemovalContainer = connect(mapStateToProps, mapDispatchToProps)(BackgroundRemovalModal);

export default AssetRemovalContainer;
