import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getSkuData, getSurfaceProperties, isRetrievingSurfaces } from 'src/selectors/pages/editor';

import product from './product';

const mapStateToProps = (state: RootState) => {
    const { isReady, list } = getSurfaceProperties(state);
    const { lastModified, productName } = getSkuData(state) || {};
    const activeSurfaceFullBleedArea = (list[0] && list[0].fullBleedArea) || {};
    const { width: firstSurfaceWidth, height: firstSurfaceHeight } = activeSurfaceFullBleedArea;
    const retrievingSurfaces = isRetrievingSurfaces(state);

    return {
        lastModified,
        productName,
        firstSurfaceWidth,
        firstSurfaceHeight,
        retrievingSurfaces,
        isReady,
    };
};

const productContainer = connect(mapStateToProps, {})(product);

export default productContainer;
