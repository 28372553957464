export const NORMAL = 'normal';
export const MULTIPLY = 'multiply';
export const MASK = 'mask';
export const OVERLAY = 'overlay';
export const SCREEN = 'screen';

export type BlendingMode = (typeof blendingModes)[number];

export const blendingModes = [NORMAL, MULTIPLY, MASK, OVERLAY, SCREEN] as const;

export const documentModes = [
    { value: NORMAL, label: NORMAL },
    { value: MULTIPLY, label: MULTIPLY },
];

export const overlayModes = [
    { value: OVERLAY, label: OVERLAY },
    { value: SCREEN, label: SCREEN },
];

export const ENGRAVING_CHANNEL = 'Engraving';
