import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getSurfaceProperties, getSkuData } from 'src/selectors/pages/editor';
import Variables from 'src/components/product/variables/variables';
import { setRules } from 'src/components/skus/slice';
import VariablesV2 from 'src/components/product/variables/variablesv2';
import { applyVariables, unresolveVariables } from './slice';

const mapStateToProps = (state: RootState) => {
    const { options, loadingRules, ruleSet, requiredVariables, genericAttributes } = getSkuData(state) || {};
    const { sku, skuVersion, variables, isReady, merchantSku, merchantVersion } = getSurfaceProperties(state);
    return {
        variables,
        options,
        ruleSet,
        loading: loadingRules,
        isReady,
        sku: sku || merchantSku,
        skuVersion: skuVersion || merchantVersion,
        // Props required by the RuleSet GenericSelector
        requiredVariables: requiredVariables || [],
        genericAttributes,
    };
};

const variablesContainer = connect(mapStateToProps, {
    onChangeVariables: applyVariables,
    onFailure: unresolveVariables,
    setRules,
})(Variables);

export const VariablesContainerV2 = connect(mapStateToProps, {
    onChangeVariables: applyVariables,
    onFailure: unresolveVariables,
})(VariablesV2);

export default variablesContainer;
