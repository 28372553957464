import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-konva';

let localPoints = [];
export default function Border(props) {
    const { points, width, height, offset, color, rectanglePoints } = props;

    const reducedPoints = useMemo(
        () => rectanglePoints.reduce((array, point) => array.concat([point.x, point.y]), []),
        [rectanglePoints],
    );

    const onDragMove = (e, initialPosition) => {
        const deltaX = initialPosition.x - e.clientX;
        const deltaY = initialPosition.y - e.clientY;

        const newPoints = points.map((point) => ({ x: point.x - deltaX, y: point.y - deltaY }));

        if (width) {
            // ensure point never goes outside of width bounds
            const boxWidth = newPoints[1].x - newPoints[0].x;
            const rightBound = width + offset.x;

            if (newPoints[1].x > rightBound) {
                newPoints[0].x = rightBound - boxWidth;
                newPoints[1].x = rightBound;
            } else if (newPoints[0].x < offset.x) {
                newPoints[0].x = offset.x;
                newPoints[1].x = offset.x + boxWidth;
            }
        }

        if (height) {
            // ensure point never goes outside of height bounds
            const boxHeight = newPoints[1].y - newPoints[0].y;
            const bottomBound = height + offset.y;
            if (newPoints[1].y > bottomBound) {
                newPoints[0].y = bottomBound - boxHeight;
                newPoints[1].y = bottomBound;
            } else if (newPoints[0].y < offset.y) {
                newPoints[0].y = offset.y;
                newPoints[1].y = offset.y + boxHeight;
            }
        }

        localPoints = newPoints;
        props.onDragMove(newPoints);
    };

    const onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        props.onDragEnd(localPoints);
    };

    const onDragStart = (e) => {
        const initialPosition = {
            x: e.evt.clientX,
            y: e.evt.clientY,
        };

        document.onmousemove = (evt) => onDragMove(evt, initialPosition);
        document.onmouseup = onDragEnd;

        e.cancelBubble = true;
        props.onDragStart();
    };

    return (
        <Line
            points={reducedPoints}
            stroke={color || 'black'}
            strokeWidth={1}
            closed={true}
            onMouseDown={onDragStart}
        />
    );
}

Border.propTypes = {
    points: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })),
    rectanglePoints: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })),
    onDragStart: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragEnd: PropTypes.func,
    offset: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};
