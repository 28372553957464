import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

import { logError } from 'src/loggingManager';
import { authClientId } from './settings';

const isDisabled = import.meta.env.NODE_ENV === 'test';

let auth: centralizedAuth | undefined = undefined;

if (!isDisabled) {
    auth = new centralizedAuth({
        // eslint-disable-line new-cap
        clientID: authClientId,
        redirectRoute: '/',
        nextUri: window.location.pathname + window.location.search,
        emitInitialTokenExpired: false,
    });
}

function setListeners() {
    auth?.on('tokenExpired', () => auth?.login({ nextUri: window.location.pathname, forceLogin: true }));
}

function initializeAuth(): Promise<void> {
    if (isDisabled) {
        return Promise.resolve();
    }

    return auth!
        .ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
        .then((isAuthenticated) => {
            if (isAuthenticated) {
                setListeners();
            }
        })
        .catch((error) => {
            logError(`Failed to ensure authentication: ${error}`);
        });
}

export default Object.assign({}, auth, { initializeAuth });
