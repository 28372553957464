import {
    RECTANGLE_TRANSFORM,
    PERSPECTIVE_TRANSFORM,
    TUBE_TRANSFORM,
    PLACEMENT_TRANSFORM,
    SMOOTH_TRANSFORM,
    TEXTURE_TRANSFORM,
} from 'src/models/transforms';
import {
    InputGeneratePlacementTransform,
    InputGenerateSmoothTransform,
    InputGenerateTextureTransform,
    InputGenerateTransform,
    InputGenerateTubeTransform,
    PerspectiveTransformType,
    PlacementTransformType,
    RectangleTransformType,
    SmoothTransformType,
    TextureTransformType,
    TransformPointType,
    TubeTransformType,
} from 'src/types/transforms';
import idGeneratorAtor from 'src/util/idGeneratorAtor';

const idGenerator = idGeneratorAtor('Transform');
const MARGIN_PERCENTAGE = 0.2;
const DEFAULT_LOWSHIFT = 20;
const DEFAULT_HIGHSHIFT = 60;

// Helpers used by document actions
const createPoint = (x: number, y: number, sourceX: number, sourceY: number): TransformPointType => ({
    x,
    y,
    sourceX,
    sourceY,
});

export const generateRectangleTransform = ({
    width,
    height,
    x1,
    y1,
    x2,
    y2,
    id,
}: InputGenerateTransform): RectangleTransformType => {
    const points = [
        createPoint(x1 || width * MARGIN_PERCENTAGE, y1 || height * MARGIN_PERCENTAGE, 0, 0),
        createPoint(x2 || width * (1 - MARGIN_PERCENTAGE), y2 || height * (1 - MARGIN_PERCENTAGE), 1, 1),
    ];

    return {
        id: id || idGenerator(),
        type: RECTANGLE_TRANSFORM,
        points,
        dragPoints: points,
    };
};

export const generatePerspectiveTransformWithPoints = ({
    points,
    id,
}: {
    points: TransformPointType[];
    id?: string;
}): PerspectiveTransformType => ({
    id: id || idGenerator(),
    type: PERSPECTIVE_TRANSFORM,
    points,
    dragPoints: points,
});

export const generatePerspectiveTransform = ({ width, height, x1, y1, x2, y2, id }: InputGenerateTransform) => {
    const points = [
        createPoint(x1 || width * MARGIN_PERCENTAGE, y1 || height * MARGIN_PERCENTAGE, 0, 0),
        createPoint(x2 || width * (1 - MARGIN_PERCENTAGE), y1 || height * MARGIN_PERCENTAGE, 1, 0),
        createPoint(x2 || width * (1 - MARGIN_PERCENTAGE), y2 || height * (1 - MARGIN_PERCENTAGE), 1, 1),
        createPoint(x1 || width * MARGIN_PERCENTAGE, y2 || height * (1 - MARGIN_PERCENTAGE), 0, 1),
    ];
    return generatePerspectiveTransformWithPoints({ points, id });
};

export const generatePlacementTransform = ({
    width,
    height,
    rotate,
    x1,
    y1,
    id,
}: InputGeneratePlacementTransform): PlacementTransformType => ({
    id: id || idGenerator(),
    rotate: rotate || 0,
    type: PLACEMENT_TRANSFORM,
    width,
    height,
    points: [createPoint(x1 || width / 2, y1 || height / 2, 0.5, 0.5)],
});

export const generateTubeTransform = ({
    width,
    height,
    lowShift = DEFAULT_LOWSHIFT,
    highShift = DEFAULT_HIGHSHIFT,
    points,
    orientation = 'v',
    id,
}: InputGenerateTubeTransform): TubeTransformType => ({
    id: id || idGenerator(),
    type: TUBE_TRANSFORM,
    width,
    height,
    points: points || [
        createPoint(width * MARGIN_PERCENTAGE, height * MARGIN_PERCENTAGE, 0, 0),
        createPoint(width * (1 - MARGIN_PERCENTAGE), height * (1 - MARGIN_PERCENTAGE), 1, 1),
    ],
    lowShift,
    highShift,
    orientation,
});

export const generateSmoothTransform = ({
    width,
    height,
    size,
    id,
    startingX,
    startingY,
}: InputGenerateSmoothTransform): SmoothTransformType => {
    function calculatePoint(dimension: number, source: number, offset = 0) {
        if (offset > 0) {
            return dimension * source + offset;
        }
        if (source === 0) {
            return dimension * MARGIN_PERCENTAGE;
        }
        if (source === 1) {
            return dimension * (1 - MARGIN_PERCENTAGE);
        }

        return dimension * (source * (1 - 2 * MARGIN_PERCENTAGE) + MARGIN_PERCENTAGE);
    }

    const points = [];

    const length = size > 0 ? size : 4;
    // generate points based on dimension size
    for (let y = 0; y < length; y++) {
        for (let x = 0; x < length; x++) {
            const sourceX = x / (length - 1);
            const sourceY = y / (length - 1);

            const pointX = calculatePoint(width, sourceX, startingX);
            const pointY = calculatePoint(height, sourceY, startingY);

            points.push(createPoint(pointX, pointY, sourceX, sourceY));
        }
    }

    return {
        id: id || idGenerator(),
        type: SMOOTH_TRANSFORM,
        points,
        dragPoints: points,
    };
};

export const generateTextureTransform = ({
    width,
    height,
    id,
    src,
}: InputGenerateTextureTransform): TextureTransformType => ({
    id: id || idGenerator(),
    type: TEXTURE_TRANSFORM,
    points: [],
    src:
        src ||
        `https://assets.documents.cimpress.io/v3/scenes/texture/identity:generate?width=${width}&amp;height=${height}`,
});
