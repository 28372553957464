import { connect } from 'react-redux';

import { dragTransformPoint, setRotationAngle } from 'src/pages/editor/components/documents/slice';
import { selectLayer } from 'src/pages/editor/components/layer/slice';
import { DOCUMENT } from 'src/pages/editor/components/layer/layerTypes';
import { getSelectedPoint, getTransformsById, getRotationAngle } from 'src/selectors/transforms';
import { getDocumentById } from 'src/selectors/layers';
import nPointWarp from './nPointWarp';
import { RootState } from 'src/store';

const mapStateToProps = (state: RootState, ownProps: { documentId: string }) => {
    const selectedPoint = getSelectedPoint(state, ownProps.documentId);
    const { points, id: transformId } = getTransformsById(state, ownProps.documentId)[0];
    const { hidden } = getDocumentById(state, ownProps.documentId);
    const rotationAngle = getRotationAngle(state, ownProps.documentId);

    return {
        selectedPoint,
        transformId,
        points,
        hidden,
        rotationAngle,
    };
};

const nPointWarpContainer = connect(mapStateToProps, {
    onDragEnd: dragTransformPoint,
    selectLayer: (documentId: string) => selectLayer({ id: documentId, type: DOCUMENT }),
    setRotationAngle,
})(nPointWarp);

export default nPointWarpContainer;
