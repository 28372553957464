import React from 'react';
import { Modal } from '@cimpress/react-components';
import NoBackgroundPanel from '../noBackgroundPanel';

interface GenerateSceneModalProps {
    mode: string;
    changeMode: (mode: string) => void;
    onAIGeneration: (active: boolean) => void;
    showModal?: boolean;
}

const generateSceneModal = (props: GenerateSceneModalProps) => {
    if (!props.showModal) {
        return null;
    }

    const { mode, changeMode, onAIGeneration } = props;

    const onAIStep = () => {
        onAIGeneration(true);
    };

    return (
        <Modal className='generate-scene-modal' show={props.showModal} title='Create Scene' size='xl'>
            <div className='generate-scene-modal__body'>
                <NoBackgroundPanel mode={mode} onItemClick={changeMode} onAIGeneration={onAIStep} />
            </div>
        </Modal>
    );
};

export default generateSceneModal;
