import React, { PureComponent, Fragment } from 'react';

import UploadButton from 'src/components/UploadButton';

interface UploadAndDeleteButtonProps {
    testid?: string;
    className?: string;
    fileName?: string;
    hidden?: boolean;
    disabled?: boolean;
    onHide?(...args: unknown[]): unknown;
    onDelete?(...args: unknown[]): unknown;
    onUpload: React.ChangeEventHandler<HTMLInputElement>;
}

export default class UploadAndDeleteButton extends PureComponent<UploadAndDeleteButtonProps> {
    render() {
        const { fileName, onDelete, onHide, hidden, testid } = this.props;
        return (
            <div className={`upload-and-delete ${this.props.className || ''}`}>
                <UploadButton testid={testid} {...this.props} />
                {fileName && (
                    <Fragment>
                        <div className='upload-and-delete__file-name'>
                            <span>{fileName}</span>
                        </div>
                        {onHide && (
                            <button className='upload-and-delete__hide' onClick={onHide}>
                                <img
                                    className={`iconLg ${hidden ? '' : 'colorOceanBase'}`}
                                    src='https://static.ux.cimpress.io/assets/icons/view-2-l.svg'
                                />
                            </button>
                        )}
                        {onDelete && (
                            <button className='upload-and-delete__delete' onClick={onDelete}>
                                <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/bin-1-l.svg' />
                            </button>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}
