import type { KonvaEventObject } from 'konva/lib/Node';
import React, { PureComponent, MouseEvent } from 'react';
import { Circle } from 'react-konva';

const RADIUS = 5;
const SELECTED_RADIUS = 7;
const WHITE_START = 0.5;
const SELECTED_WHITE_START = 0.36;
const SELECTED_BLUE_START = 0.71;
const STROKE_WIDTH = 12; // This allows an area outside of the circle to be grabbable

interface Point {
    x: number;
    y: number;
}

interface GrabberProps {
    position: Point;
    onDragStart: (e: KonvaEventObject<globalThis.MouseEvent>) => void;
    cursorChange: (cursor: string) => void;
    color?: string;
    isSelected?: boolean;
}

// The grabber uses fillRadial in order to have both a transparent grabbable stroke and a small white stroke.
export default class Grabber extends PureComponent<GrabberProps> {
    render() {
        const { position, onDragStart, cursorChange, color = 'red', isSelected = false } = this.props;
        let radius = RADIUS;
        let gradientStops = [0, color, WHITE_START, color, 1, 'white'];
        if (isSelected) {
            radius = SELECTED_RADIUS;
            gradientStops = [0, color, SELECTED_WHITE_START, color, SELECTED_BLUE_START, 'white', 1, '#00b5e2'];
        }
        return (
            <Circle
                x={position.x}
                y={position.y}
                radius={radius}
                stroke='transparent'
                fillRadialGradientStartPoint={{ x: 0, y: 0 }}
                fillRadialGradientStartRadius={0}
                fillRadialGradientEndPoint={{ x: 0, y: 0 }}
                fillRadialGradientEndRadius={radius}
                fillRadialGradientColorStops={gradientStops}
                strokeWidth={STROKE_WIDTH}
                onMouseEnter={() => cursorChange('pointer')}
                onMouseLeave={() => cursorChange('default')}
                onMouseDown={(e) => {
                    onDragStart(e);
                    e.cancelBubble = true;
                }}
            />
        );
    }
}
