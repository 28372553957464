import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import { getDocumentById } from 'src/selectors/layers';
import uploadAndDeleteButton from 'src/components/UploadAndDeleteButton';
import { uploadMask, removeMask, hideMask } from './slice';
import React from 'react';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const selectedDocument = getDocumentById(state, ownProps.id);
    const uploaded = !!selectedDocument.maskUrl;
    const fileName = selectedDocument.maskName;

    return {
        testid: 'editor-scene-upload-mask-input',
        fileName,
        uploaded,
        uploading: selectedDocument.maskUploading,
        hidden: selectedDocument.maskHidden,
        label: uploaded ? 'Replace Mask' : 'Upload Mask',
        className: 'document-mask__upload-button',
        accept: 'image/png, image/jpeg, image/bmp',
        disabled: !!selectedDocument.automask,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: { id: string }) => ({
    onUpload: (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];
        if (file) {
            dispatch(uploadMask({ file, id: ownProps.id }));
        }
    },
    onDelete: () => dispatch(removeMask(ownProps.id)),
    onHide: () => dispatch(hideMask(ownProps.id)),
});

const maskButtonContainer = connect(mapStateToProps, mapDispatchToProps)(uploadAndDeleteButton);

export default maskButtonContainer;
