import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import { RULER } from 'src/pages/editor/components/layer/layerTypes';
import { getRulerById, getSelectedLayer } from 'src/selectors/layers';
import { updatePosition } from 'src/pages/editor/components/rulers/slice';
import { selectLayer } from 'src/pages/editor/components/layer/slice';
import rulerView from './ruler';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => ({
    selected: getSelectedLayer(state).id === ownProps.id,
    ...(getRulerById(state, ownProps.id) || {}),
});

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: { id: string }) => ({
    selectRuler: () => dispatch(selectLayer({ id: ownProps.id, type: RULER })),
    updatePosition: (data: TODO) => dispatch(updatePosition({ id: ownProps.id, ...data })),
});

const rulerContainer = connect(mapStateToProps, mapDispatchToProps)(rulerView);

export default rulerContainer;
