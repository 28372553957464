import { connect } from 'react-redux';
import { getSceneNotes } from 'src/selectors/pages/editor';
import { updateNotes } from 'src/pages/editor/components/scene/slice';
import { isBulkMode } from 'src/selectors';
import notes from './notes';
import { RootState } from 'src/store';
import React from 'react';

const mapStateToProps = (state: RootState) => ({
    note: getSceneNotes(state),
    isBulkMode: isBulkMode(state),
});

const noteContainer = connect(mapStateToProps, {
    onChangeNotes: (e: React.ChangeEvent<HTMLTextAreaElement>) => updateNotes(e.target.value || ''),
})(notes);

export default noteContainer;
