import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import auth from 'src/auth';
import { CancelablePromise } from 'src/types/upload';

type RequestConfig = Pick<AxiosRequestConfig, 'url' | 'method' | 'data' | 'responseType'> & {
    header?: any;
    includeToken?: boolean;
};

function makeAxiosRequest<T>({
    url,
    method,
    data,
    header,
    includeToken,
    responseType,
}: RequestConfig): CancelablePromise<AxiosResponse<T>> {
    const headers = {
        'Content-Type': 'application/json',
        ...header,
    };

    if (includeToken) {
        headers.Authorization = `Bearer ${auth.getAccessToken()}`;
    }

    const { CancelToken } = axios;
    let cancel;

    const axiosRequest = axios({
        url,
        method,
        data,
        headers,
        cancelToken: new CancelToken((c) => {
            cancel = c;
        }),
        responseType,
    });

    const wrapped: CancelablePromise<AxiosResponse> = new Promise((resolve, reject) =>
        axiosRequest
            .then((response) => resolve(response))
            .catch((response) => {
                if (!axios.isCancel(response)) {
                    reject(response);
                }
            }),
    );

    wrapped.cancel = cancel;

    return wrapped;
}

type AuthenticatedRequestConfig = Pick<RequestConfig, 'url' | 'method' | 'data' | 'header'>;

export function makeAuthenticatedServiceRequest<T = any>({
    url,
    method = 'get',
    data,
    header,
}: AuthenticatedRequestConfig): CancelablePromise<AxiosResponse<T>> {
    if (!auth.isLoggedIn()) {
        return Promise.reject(new Error('User is not logged in'));
    }

    return makeAxiosRequest({ url, method, data, header, includeToken: true });
}

type PublicRequestConfig = Pick<RequestConfig, 'url' | 'method' | 'data' | 'responseType' | 'header'>;

export function makePublicServiceRequest<T = any>({
    url,
    method = 'get',
    data,
    responseType,
    header,
}: PublicRequestConfig): CancelablePromise<AxiosResponse<T>> {
    return makeAxiosRequest({ url, method, data, responseType, header });
}
