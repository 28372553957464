import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Layer, Group, Rect } from 'react-konva';
import { connect } from 'react-redux';
import { translateCanvasPoints } from 'src/util/misc';
import Border from './border';
import GrabPoints from './grabPoints';
import getCroppingRule from '../selectors/getCroppingRule';
import getSceneVariation from '../selectors/getSceneVariation';
import { saveCropping, setCroppingArea } from '../slice';

const OFFSET = 1;
const COLOR = '#b1b1b1';

const usePrevious = (color) => {
    const prev = useRef();
    useEffect(() => {
        prev.current = color;
    }, [color]);
    return prev.current;
};

function Cropping(props) {
    const {
        canvasHeight,
        canvasWidth,
        savePointsState,
        croppingPoints,
        cropEnable,
        offset,
        saveCroppingState,
        scale,
        image,
        constraint,
    } = props;
    const points = useMemo(
        () => [
            { x: croppingPoints.x0, y: croppingPoints.y0 },
            { x: croppingPoints.x1, y: croppingPoints.y1 },
        ],
        [croppingPoints],
    );
    const [localPoints, setLocalPoints] = useState(points);
    const prevData = usePrevious({ scale, offset });
    const prevOrigin = usePrevious({ scale, offset, canvasHeight, canvasWidth });
    const constraintFactor = constraint.enable ? constraint.width / constraint.height : 1;

    useEffect(() => {
        // scale points to match display size
        const offsetX = Math.max(offset.x, 0);
        const offsetY = Math.max(offset.y, 0);

        const initialData = [
            { x: offsetX, y: offsetY },
            { x: canvasWidth - offsetX, y: canvasHeight - offsetY },
        ];

        if (JSON.stringify({ scale, offset, canvasHeight, canvasWidth }) !== JSON.stringify(prevOrigin)) {
            saveCroppingState({ points: initialData, scale });
        }
    }, [scale, offset, canvasHeight, canvasWidth]);

    useEffect(() => {
        if (prevData && scale !== prevData.scale) {
            const newPoints = translateCanvasPoints({
                points: localPoints,
                scale: prevData.scale,
                offset: prevData.offset,
                target: { scale, offset },
            });
            savePointsState(newPoints); // save points to state
        }
    }, [scale, offset, canvasHeight, canvasWidth]);

    useEffect(() => {
        if (JSON.stringify(localPoints) !== JSON.stringify(points)) {
            setLocalPoints(points);
        }
    }, [points]);

    const onDragStart = (_curPoints) => {};

    const onDragMove = (curPoints) => {
        if (constraint.enable) {
            const height = (curPoints[1].x - curPoints[0].x) / constraintFactor;
            curPoints[1].y = curPoints[0].y + height;
        }
        setLocalPoints(curPoints);
    };

    const onDragEnd = (curPoints) => {
        savePointsState(curPoints); // save points to state
    };

    const clip = (ctx) => {
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(props.canvasWidth + OFFSET * 2, 0);
        ctx.lineTo(props.canvasWidth + OFFSET * 2, props.canvasHeight + OFFSET * 2);
        ctx.lineTo(0, props.canvasHeight + OFFSET * 2);
        ctx.moveTo(localPoints[0].x, localPoints[1].y);
        ctx.lineTo(localPoints[1].x, localPoints[1].y);
        ctx.lineTo(localPoints[1].x, localPoints[0].y);
        ctx.lineTo(localPoints[0].x, localPoints[0].y);
        ctx.closePath();
    };

    const fixX = localPoints[0].x === localPoints[1].x ? 20 : 0;
    const fixY = localPoints[0].y === localPoints[1].y ? 20 : 0;

    // Calculate the points, restricting the points into the canvas area and avoiding situations where the position of 2 or more points are in the same location
    // ie: if P1 and P3 are in the same position, there will be a line and won't be able to grab a point, same happens with P2 and P4
    // eslint-disable-next-line react-hooks/rules-of-hooks -- FIXME
    const rectanglePoints = useMemo(
        () => [
            {
                x: Math.min(Math.max(localPoints[0].x, 10), canvasWidth),
                y: Math.min(Math.max(localPoints[0].y, 10), canvasHeight),
            },
            {
                x: Math.min(Math.max(+localPoints[1].x + fixX, 50), canvasWidth),
                y: Math.min(Math.max(localPoints[0].y, 10), canvasHeight),
            },
            {
                x: Math.min(Math.max(+localPoints[1].x + fixX, 10), canvasWidth),
                y: Math.min(Math.max(+localPoints[1].y + fixY, 50), canvasHeight),
            },
            {
                x: Math.min(Math.max(localPoints[0].x, 50), canvasWidth),
                y: Math.min(Math.max(+localPoints[1].y + fixY, 50), canvasHeight),
            },
        ],
        [localPoints],
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks -- FIXME
    const borderPoints = useMemo(
        () => [
            {
                x: Math.min(Math.max(localPoints[0].x, 10), canvasWidth),
                y: Math.min(Math.max(localPoints[0].y, 10), canvasHeight),
            },
            {
                x: Math.min(Math.max(+localPoints[1].x + fixX, 50), canvasWidth),
                y: Math.min(Math.max(+localPoints[1].y + fixY, 50), canvasHeight),
            },
        ],
        [localPoints],
    );

    if (!cropEnable || localPoints.length === 0 || localPoints[0].x === undefined) {
        return <></>;
    }

    return (
        <Layer width={canvasWidth + OFFSET} height={canvasHeight + OFFSET}>
            <Group>
                <Group clipFunc={clip}>
                    <Rect
                        x={offset.x}
                        y={offset.y}
                        width={image.width * scale}
                        height={image.height * scale}
                        fill={'black'}
                        opacity={0.7}
                    />
                </Group>
                <Border
                    points={borderPoints}
                    rectanglePoints={rectanglePoints}
                    onDragStart={onDragStart}
                    onDragMove={onDragMove}
                    onDragEnd={onDragEnd}
                    width={image.width * scale}
                    height={image.height * scale}
                    offset={offset}
                    color={COLOR}
                />
                <Group>
                    <GrabPoints
                        points={rectanglePoints}
                        onDragStart={onDragStart}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                        width={canvasWidth}
                        height={canvasHeight}
                        offset={OFFSET}
                        color={COLOR}
                    />
                </Group>
            </Group>
        </Layer>
    );
}

Cropping.propTypes = {
    canvasWidth: PropTypes.number,
    canvasHeight: PropTypes.number,
    scale: PropTypes.number,
    offset: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    croppingPoints: PropTypes.shape({
        x0: PropTypes.number,
        y0: PropTypes.number,
        x1: PropTypes.number,
        y1: PropTypes.number,
    }),
    savePointsState: PropTypes.func,
    saveCroppingState: PropTypes.func,
    cropEnable: PropTypes.bool,
    constraint: PropTypes.shape({
        enable: PropTypes.bool,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    image: PropTypes.shape({ width: PropTypes.number, height: PropTypes.number }),
};

const mapStateToProps = (state) => {
    const sceneVariation = getSceneVariation(state);
    const croppingPoints = getCroppingRule(state);

    return {
        cropEnable: sceneVariation.cropping.enable,
        constraint: sceneVariation.cropping.constraint,
        croppingPoints,
    };
};

const croppingContainer = connect(mapStateToProps, {
    savePointsState: saveCropping,
    saveCroppingState: setCroppingArea,
})(Cropping);

export default croppingContainer;
