import { createSelector } from 'reselect';

import { uniq } from 'src/util/misc';
import getActiveSceneXml from './getSceneXml';

const getActiveScenePages = createSelector([getActiveSceneXml], (xml) => {
    if (!xml) {
        return [];
    }
    const matches = xml.match(/page="\d+"/g) || [];
    return uniq(
        matches.map((match) => {
            const temp = match.match(/\d+/);
            if (temp) {
                parseInt(temp[0]);
            }
        }),
    );
});

export default getActiveScenePages;
