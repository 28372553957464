import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { GlobalStyles } from '@cimpress/react-components';

import Editor from 'src/pages/editor';
import AlertList from 'src/components/alert';
import Header from 'src/components/header';
import Loading from 'src/components/Loading';
import auth from 'src/auth';
import storeManager, { RootState } from 'src/store/storeManager';

import identifyFullStoryUser from 'src/identifyFullStoryUser';
import initializeApplication from 'src/initializeApplication';
import * as FullStory from '@fullstory/browser';
import { devMode } from './settings';
import { connect } from 'react-redux';
import { isPageLoading, isApplicationLoading } from './selectors';

FullStory.init({ orgId: '97CT5', devMode });

function App({ loading }: { loading: boolean }) {
    useEffect(() => {
        auth.initializeAuth()
            .then(() => identifyFullStoryUser())
            .then(() => initializeApplication(storeManager.store));
    }, []);

    return (
        <>
            <GlobalStyles />
            <ConnectedRouter history={storeManager.history}>
                <div className='scene-maker'>
                    <Loading show={loading} greyOut={true} fullPage={true} />
                    <Header />
                    <AlertList />
                    {auth.isLoggedIn() && (
                        <Switch>
                            <Route path='/editor/:tenantType?/:tenantId?/:id?/:versionId?' component={Editor} />
                            <Redirect to='/editor' />
                        </Switch>
                    )}
                </div>
            </ConnectedRouter>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    loading: isPageLoading(state) || isApplicationLoading(state),
});

export const AppContainer = connect(mapStateToProps, {})(App);
