import { createSelector } from 'reselect';
import { RootState } from 'src/store';

const getModal = (state: RootState) => state.pages.editor.backgrounds.isResizeModalShown.show;

export const getResizeModalOpened = createSelector([getModal], (isModalOpened) => isModalOpened);

const getModalMessage = (state: RootState) => state.pages.editor.backgrounds.isResizeModalShown.message;

export const getResizeModalMessage = createSelector([getModalMessage], (message) => message);
