import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { updatePrecisionWrap } from 'src/pages/editor/components/documents/slice';
import { getCylinderWarpById } from 'src/selectors/layers';
import precisionWrap from './precisionWrap';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const cylinder = getCylinderWarpById(state, ownProps.id);
    if (!cylinder) return {};

    return {
        width: cylinder.decorationAreaDimensions.width,
        diameter: cylinder.productDiameter,
        disabled: !!cylinder.fullWrap,
    };
};

const precisionWrapContainer = connect(mapStateToProps, { updatePrecisionWrap })(precisionWrap);

export default precisionWrapContainer;
