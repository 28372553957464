import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Tooltip } from '@cimpress/react-components';
import GenericSelector from '@cimpress-technology/generic-selector';
import auth from 'src/auth';
import { isEqual } from 'lodash';

const ProductSelector = (props) => {
    const [attributesTooltipOpen, setAttributesToolTipOpen] = useState(false);
    const [displayabledAttributes, setDisplayabledAttributes] = useState([]);
    const keyAttributes = useMemo(() => Object.keys(props.attributes), [props.attributes]);
    const [interimSelections, setInterimSelections] = useState(keyAttributes);

    const checkboxToggle = (attributeKey) => {
        const index = interimSelections.findIndex((each) => each === attributeKey);
        if (index >= 0) {
            setInterimSelections([...interimSelections.slice(0, index), ...interimSelections.slice(index + 1)]);
        } else {
            setInterimSelections([...interimSelections, attributeKey]);
        }
    };
    const closeAttributeSelector = () => {
        if (!isEqual(interimSelections, keyAttributes)) {
            const updates = {};
            interimSelections.forEach((each) => {
                updates[each] = props.attributes[each];
            });
            props.onChange && props.onChange(updates);
        }
        setAttributesToolTipOpen(false);
    };
    const attributeConfigurations = useMemo(() => {
        const attributes = {};
        displayabledAttributes.forEach((key) => {
            attributes[key] = {
                initialSelection: props.attributes[key],
                isHidden: !keyAttributes.includes(key),
            };
        });
        return attributes;
    }, [keyAttributes, displayabledAttributes, props.attributes]);

    const onLoad = (state) => {
        const temp = state.attributes.filter((attr) => attr.isDisplayed).map((attri) => attri.key);
        setDisplayabledAttributes(temp);
    };
    const handleAttributeChanges = (changes) => {
        const updates = {};
        keyAttributes.forEach((each) => {
            const attributeDetails = changes.attributes.find((eachAttribute) => eachAttribute.key === each);
            if (attributeDetails) {
                updates[each] = attributeDetails.resolvedValue;
            }
        });
        props.onChange && props.onChange(updates);
    };

    return (
        <div>
            <GenericSelector
                authToken={auth.getAccessToken()}
                productId={props.sku}
                productVersion={props.skuVersion}
                attributeConfigurations={attributeConfigurations}
                errorAlertConfiguration={{ showErrorAlert: false }}
                onLoad={onLoad}
                isColorSwatch={true}
                onChange={handleAttributeChanges}
            />
            <Tooltip
                tooltipStyle={{ maxWidth: '800px' }}
                direction='bottom'
                show={attributesTooltipOpen}
                variety='popover'
                contents={
                    <div style={{ maxWidth: '500px' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {displayabledAttributes?.map((key) => (
                                <Checkbox
                                    data-testid={`variable-selector_${key}`}
                                    key={key}
                                    label={key}
                                    style={{ width: '33%' }}
                                    checked={interimSelections.includes(key)}
                                    onChange={() => checkboxToggle(key)}
                                />
                            ))}
                        </div>
                        <Button
                            data-testid='variable-selector_close_button'
                            variant='primary'
                            className='variables__confirm-button'
                            onClick={closeAttributeSelector}>
                            Ok
                        </Button>
                    </div>
                }
            />
            <Button
                data-testid='variable-selector_add_remove_button'
                variant='link'
                onClick={() => {
                    setAttributesToolTipOpen(!attributesTooltipOpen);
                }}>
                Add/Remove Attributes
            </Button>
        </div>
    );
};

ProductSelector.propTypes = {
    sku: PropTypes.string,
    skuVersion: PropTypes.number,
    attributes: PropTypes.object,
    onChange: PropTypes.func,
};

export default ProductSelector;
