import { createSlice } from '@reduxjs/toolkit';

type SkusSliceState = Record<
    string,
    {
        fetching: boolean;
        doesNotExist: boolean;
        loadingRules: boolean;
        rulesLoaded: boolean;
        ruleSet: any;
        loadingLinks: boolean;
        noLinks: boolean;
        loadingVersions: boolean;
        versions: string[];
        requiredVariables: string[];
        options?: Record<string, any>;
        isV1Product: boolean;
        genericAttributes?: any;
        productName?: string;
        lastModified?: Date;
        merchandisingOnly?: boolean;
    }
>;

const initialState: SkusSliceState = {};

const generateKey = (sku: string, skuVersion?: number) => (skuVersion ? `${sku}-${skuVersion}` : sku);

const slice = createSlice({
    name: 'skus',
    initialState,
    reducers: {
        fetchSku: (state, { payload }) => {
            const { sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey] = {
                    ...state[skuKey],
                    fetching: true,
                };
            } else {
                state[skuKey] = {
                    fetching: true,
                    doesNotExist: false,
                    loadingRules: false,
                    rulesLoaded: false,
                    ruleSet: undefined,
                    loadingLinks: false,
                    noLinks: false,
                    loadingVersions: false,
                    versions: [],
                    requiredVariables: [],
                    options: [],
                    isV1Product: false,
                    genericAttributes: null,
                };
            }
        },
        fetchSkuSuccess: (state, { payload }) => {
            const { sku, skuVersion, productName, lastModified, options } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].productName = productName;
                state[skuKey].lastModified = lastModified;
                state[skuKey].options = options;
                state[skuKey].fetching = false;
            }
        },
        fetchSkuFailed: (state, { payload }) => {
            const { sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].doesNotExist = true;
                state[skuKey].fetching = false;
            }
        },
        setRules: (state, { payload }) => {
            const { sku, skuVersion, attributes } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].genericAttributes = attributes;
            }
        },
        retrieveRules: (state, { payload }) => {
            const { sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].loadingRules = true;
            }
        },
        retrieveRulesSuccess: (state, { payload }) => {
            const { sku, skuVersion, requiredVariables, ruleSet, merchandisingOnly } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].loadingRules = false;
                state[skuKey].rulesLoaded = true;
                state[skuKey].requiredVariables = requiredVariables;
                state[skuKey].ruleSet = ruleSet;
                state[skuKey].merchandisingOnly = merchandisingOnly;
            }
        },
        retrieveRulesFailed: (state, { payload }) => {
            const { sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].loadingRules = false;
            }
        },
        loadLinksForSku: (state, { payload }) => {
            const { sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].loadingLinks = true;
            }
        },
        loadLinksForSkuSuccess: (state, { payload }) => {
            const { links, sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].loadingLinks = false;
                if (!links.length) {
                    state[skuKey].noLinks = true;
                }
            }
        },
        loadLinksForSkuFailed: (state, { payload }) => {
            const { sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (state[skuKey]) {
                state[skuKey].loadingLinks = false;
            }
        },
        fetchVersions: (state, { payload }) => {
            const { sku, skuVersion } = payload;
            const skuKey = generateKey(sku, skuVersion);
            if (!state[skuKey]) {
                state[skuKey] = {
                    fetching: false,
                    doesNotExist: false,
                    loadingRules: false,
                    rulesLoaded: false,
                    ruleSet: undefined,
                    loadingLinks: false,
                    noLinks: false,
                    loadingVersions: true,
                    versions: [],
                    requiredVariables: [],
                    isV1Product: false,
                };
            }
        },
        fetchVersionsForSkuSuccess: (state, { payload }) => {
            const { versions, sku, isV1Product } = payload;
            const skuKey = generateKey(sku);
            if (state[skuKey]) {
                state[skuKey].loadingVersions = false;
                state[skuKey].isV1Product = !!isV1Product;
                if (versions.length > 0) {
                    state[skuKey].versions = versions;
                }
            }
        },
        fetchVersionsForSkuFailed: (state, { payload }) => {
            if (state[payload]) {
                state[payload].loadingVersions = false;
                state[payload].doesNotExist = false;
            }
        },
    },
});

export const reducer = slice.reducer;

export const fetchSku = slice.actions.fetchSku;
export const fetchSkuSuccess = slice.actions.fetchSkuSuccess;
export const fetchSkuFailed = slice.actions.fetchSkuFailed;
export const setRules = slice.actions.setRules;
export const retrieveRules = slice.actions.retrieveRules;
export const retrieveRulesSuccess = slice.actions.retrieveRulesSuccess;
export const retrieveRulesFailed = slice.actions.retrieveRulesFailed;
export const loadLinksForSku = slice.actions.loadLinksForSku;
export const loadLinksForSkuSuccess = slice.actions.loadLinksForSkuSuccess;
export const loadLinksForSkuFailed = slice.actions.loadLinksForSkuFailed;
export const fetchVersions = slice.actions.fetchVersions;
export const fetchVersionsForSkuSuccess = slice.actions.fetchVersionsForSkuSuccess;
export const fetchVersionsForSkuFailed = slice.actions.fetchVersionsForSkuFailed;
