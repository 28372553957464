import { Select } from '@cimpress/react-components';
import PropTypes, { string } from 'prop-types';
import React, { PureComponent } from 'react';

export default class Constraints extends PureComponent {
    render() {
        const { currentValue, hide, onToggleAspectRatioConstraint, options } = this.props;
        if (hide) {
            return null;
        }

        return (
            <div className='product-ar-constraint'>
                <Select
                    label='Constrain to Product Aspect Ratio by'
                    value={currentValue}
                    options={options}
                    onChange={onToggleAspectRatioConstraint}
                />
            </div>
        );
    }
}

Constraints.propTypes = {
    currentValue: PropTypes.string,
    hide: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({ value: string, label: string })),
    onToggleAspectRatioConstraint: PropTypes.func.isRequired,
};
