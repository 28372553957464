/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Select } from '@cimpress/react-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toSelectOption } from 'src/util/misc';
import ConfigurationSku from './ConfigurationSku';
import { deleteSceneVariation, loadLink, setCreateMode } from './slice';
import getLinks from './selectors/getSceneLinks';
import getShowForm from './selectors/getCreateMode';
import getSceneVariation from './selectors/getSceneVariation';
import ConfirmModal from '../confirm';

const SceneVariation = (props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const linkOptions = props.links.map((link) =>
        toSelectOption(`${link.sku}-${link.skuVersion} ${link.linkId}`, link.linkId),
    );

    const createNew = (show) => props.setCreateMode(show);
    const loadData = (selected) => {
        if (selected) {
            props.loadLink(selected.value);
        }
    };

    useEffect(() => {
        if (!props.links || props.links.length === 0) {
            props.setCreateMode(true);
        }
    }, [props.links]);

    const loadLinks = useMemo(() => {
        if (!props.showForm && props.links && props.links.length > 0) {
            return (
                <div style={{ padding: '8px' }}>
                    <h3>Load a Configuration</h3>
                    <Select options={linkOptions} label='Select a Configuration' onChange={loadData} />

                    <Button variant='primary' onClick={() => createNew(true)}>
                        Create New Configuration
                    </Button>
                </div>
            );
        }
        if (props.links && props.links.length > 0) {
            return (
                <Button style={{ padding: '8px' }} variant='anchor' onClick={() => createNew(false)}>
                    &lt; Go Back
                </Button>
            );
        }
        return <></>;
    }, [props.links, props.showForm]);

    const confirmDelete = (evt) => {
        evt.stopPropagation();
        setShowConfirm(true);
    };

    const title = (
        <div className='layers-header'>
            <div className='layers-header__text'>Scene Configuration</div>
            {props.linkId && (
                <button onClick={confirmDelete}>
                    <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/bin-1-l.svg' />
                </button>
            )}
        </div>
    );

    const handleDelete = (confirmation) => {
        if (confirmation) {
            const { sceneVariableId, sceneId, linkId, deleteScene } = props;
            deleteScene({ sceneId, linkId, sceneVariableId });
        }

        setShowConfirm(false);
    };

    return (
        <div className='layers-panel scene-variation'>
            {loadLinks}
            {props.showForm && (
                <Accordion title={title} defaultOpen={true}>
                    <div className='sku-configuration'>
                        <ConfigurationSku />
                    </div>
                </Accordion>
            )}
            <ConfirmModal
                title='Do you want to Delete the Configuration?'
                showModal={showConfirm}
                onConfirm={handleDelete}
                status='danger'>
                <>
                    <p>
                        This will delete all the links attached to the Scene Variation and any data related to this
                        Scene Variation. Are you sure you want to Continue?
                    </p>
                </>
            </ConfirmModal>
        </div>
    );
};

SceneVariation.propTypes = {
    showForm: PropTypes.bool,
    linkId: PropTypes.string,
    sceneId: PropTypes.string,
    sceneVariableId: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            sku: PropTypes.string,
            skuVersion: PropTypes.number,
            cimSku: PropTypes.string,
            cimSkuVersion: PropTypes.string,
            fixedAttributesSelection: PropTypes.array,
            productConfigurations: PropTypes.array,
            scenePurposes: PropTypes.array,
            sceneVariables: PropTypes.array,
            error: PropTypes.string,
            sceneVariableId: PropTypes.string,
            sceneLinkId: PropTypes.string,
        }),
    ),
    loadLink: PropTypes.func,
    setCreateMode: PropTypes.func,
    deleteScene: PropTypes.func,
};

function mapStateToProps(state) {
    const { sceneLinkId, sceneId, sceneVariableId } = getSceneVariation(state);
    const links = getLinks(state);
    const showForm = getShowForm(state);
    return {
        links,
        showForm,
        linkId: sceneLinkId,
        sceneVariableId,
        sceneId,
    };
}

export default connect(mapStateToProps, {
    loadLink,
    setCreateMode,
    deleteScene: deleteSceneVariation,
})(SceneVariation);
