import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Layer, Rect } from 'react-konva';

import { ESC } from 'src/util/keycodes';
import { addOnDownListener, removeOnDownListener } from 'src/keyboardManager';
import idGeneratorAtor from 'src/util/idGeneratorAtor';
import Ruler from './rulerContainer';

export default class Rulers extends PureComponent {
    idGenerator = idGeneratorAtor('ruler');

    state = {
        drawing: false,
    };

    componentDidMount() {
        addOnDownListener(this.onKeyDown);
    }

    componentWillUnmount() {
        removeOnDownListener(this.onKeyDown);
    }

    onKeyDown = (e) => {
        const { addModeEnabled, toggleAddRulerMode, cursorChange } = this.props;
        if (addModeEnabled && e.keyCode === ESC) {
            toggleAddRulerMode();
            cursorChange('default');
            if (this.state.drawing) {
                // Force the currently drawing ruler to be placed
                this.onMouseDown();
            }
        }
    };

    onMouseDown = (e) => {
        if (e) {
            e.cancelBubble = true;
        }
        const { container, scale, offset, addRuler } = this.props;
        const { drawing, point1, point2 } = this.state;
        if (drawing) {
            addRuler({ id: this.idGenerator(), point1, point2 });
            this.setState({ drawing: false });
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('touchmove', this.onMouseMove);
        } else {
            const canvasRect = container.getBoundingClientRect();
            this.setState({
                drawing: true,
                point1: {
                    x: (e.evt.clientX - canvasRect.left - offset.x) / scale,
                    y: (e.evt.clientY - canvasRect.top - offset.y) / scale,
                },
                point2: {
                    x: (e.evt.clientX - canvasRect.left - offset.x) / scale,
                    y: (e.evt.clientY - canvasRect.top - offset.y) / scale,
                },
                clientX: e.evt.clientX,
                clientY: e.evt.clientY,
            });
            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('touchmove', this.onMouseMove);
        }
    };

    onMouseMove = (e) => {
        const { scale } = this.props;
        const { point1, clientX, clientY } = this.state;
        const newPoint = { x: point1.x - (clientX - e.clientX) / scale, y: point1.y - (clientY - e.clientY) / scale };
        this.setState({ point2: newPoint });
    };

    render() {
        const { canvasWidth, canvasHeight, cursorChange, container, addModeEnabled, scale, offset, rulerIds } =
            this.props;
        const { drawing, point1, point2 } = this.state;

        return (
            <Layer>
                {rulerIds.map((id) => (
                    <Ruler
                        key={id}
                        id={id}
                        offset={offset}
                        scale={scale}
                        cursorChange={cursorChange}
                        container={container}
                    />
                ))}
                {drawing && addModeEnabled && (
                    <Ruler
                        id='drawing'
                        drawing={true}
                        point1={point1}
                        point2={point2}
                        segments={10}
                        offset={offset}
                        scale={scale}
                        cursorChange={cursorChange}
                        container={container}
                    />
                )}
                {addModeEnabled && (
                    <Rect
                        width={canvasWidth}
                        height={canvasHeight}
                        onMouseDown={this.onMouseDown}
                        fill='rgba(0, 0, 0, 0.2)'
                        onMouseEnter={() => cursorChange('crosshair')}
                        onMouseLeave={() => cursorChange('default')}
                    />
                )}
            </Layer>
        );
    }
}

Rulers.propTypes = {
    container: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    canvasWidth: PropTypes.number,
    canvasHeight: PropTypes.number,
    scale: PropTypes.number.isRequired,
    offset: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
    addModeEnabled: PropTypes.bool,
    toggleAddRulerMode: PropTypes.func.isRequired,
    cursorChange: PropTypes.func.isRequired,
    addRuler: PropTypes.func.isRequired,
    rulerIds: PropTypes.arrayOf(PropTypes.string),
};
