import React from 'react';
import { PropTypes } from 'prop-types';
import { Accordion } from '@cimpress/react-components';

import SceneTags from 'src/pages/editor/components/save/sceneTags';
import Variables, { VariablesContainerV2 } from '../surfaces/variables';
import DocRefSelector from '../scene/docRef';
import ImageSelector from '../scene/image';
import Product from '../scene/product';
import Properties from '../save/properties';
import Notes from '../save/notes';

const InformationPanel = (props) => {
    // Variables use RuleSet Logic to calculated attributes
    // VariablesV2 relay on GenericSelector API's and Product data to calculated attributes
    // VariablesV2 is recommended when having a version otherwise Variables is the best option
    const VariablesComp = props.useV2Component ? <VariablesContainerV2 /> : <Variables />;
    return (
        <div className='information-panel'>
            <Accordion defaultOpen={true} title='Name and Description'>
                <Properties />
            </Accordion>
            <Accordion defaultOpen={true} title='Tags'>
                <SceneTags />
            </Accordion>
            <Accordion defaultOpen={true} title='Product Information'>
                <div className='information-panel__product'>
                    <React.StrictMode>
                        <Product />
                        {VariablesComp}
                        <DocRefSelector />
                        <ImageSelector />
                    </React.StrictMode>
                </div>
            </Accordion>
            <Accordion defaultOpen={true} title='Note'>
                <Notes />
            </Accordion>
        </div>
    );
};

InformationPanel.propTypes = {
    useV2Component: PropTypes.bool,
};

export default InformationPanel;
