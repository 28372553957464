import React from 'react';

type AddLayerProps = {
    addProps?: any;
    onAdd: (props: any) => void;
    testid?: string;
};

export default function AddLayer({ addProps, onAdd, testid }: AddLayerProps) {
    function onClick(e: React.MouseEvent<HTMLButtonElement>) {
        onAdd(addProps);
        e.stopPropagation();
    }

    return (
        <button data-testid={testid} className='upload-icon add-layer' onClick={onClick}>
            <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/add-2-l.svg' />
        </button>
    );
}
