import * as React from 'react';
import { PureComponent } from 'react';
import { Accordion } from '@cimpress/react-components';
import DraggableList from 'src/components/DraggableList';
import AddBackground from '../backgrounds/addBackgroundContainer';
import AddDocument from '../documents/addDocumentContainer';
import AddOverlay from '../overlays/addOverlayContainer';
import Layer from './layerContainer';
import { type LayerType } from './layerTypes';

type Layer = { id: string; type: LayerType };
type LayerList = Layer[];

const addTitle = (title: string, AddButton: any | null) => (
    <div className='layers-header'>
        <div className='layers-header__text'>{title}</div>
        {AddButton && <AddButton />}
    </div>
);

const renderItem = ({
    layer,
    selected,
    onClick,
    onDelete,
    onReplace,
    toggleHide,
    hasDragDrop,
    onDuplicate,
    onProtected,
    onBgRemove,
}: {
    layer: Layer;
    selected: boolean;
    onClick?: (id: string, type: LayerType) => void;
    onDelete?: (id: string) => void;
    onReplace?: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    toggleHide?: (id: string, type: LayerType) => void;
    hasDragDrop?: boolean;
    onDuplicate?: (id: string) => void;
    onProtected?: () => void;
    onBgRemove?: (id: string) => void;
}) => (
    <Layer
        key={layer.id}
        selected={selected}
        {...layer}
        onClick={onClick}
        onDelete={onDelete}
        onReplace={onReplace}
        toggleHide={toggleHide}
        hasDragDrop={hasDragDrop}
        onDuplicate={onDuplicate}
        onProtected={onProtected}
        onBgRemove={onBgRemove}
    />
);

interface LayersProps {
    backgrounds: LayerList;
    documents: LayerList;
    overlays: LayerList;
    tools: LayerList;
    selectedLayer: { id?: string; type?: LayerType };
    disabled: boolean;
    selectedBackground: string;
    onSelectBackground: (id: string, type: LayerType) => void;
    onSelectLayer: (id: string, type: LayerType) => void;
    onDeselectLayer: (id: string, type: LayerType) => void;
    onBackgroundDelete: (id: string) => void;
    onOverlayDelete: (id: string) => void;
    onDocumentDelete: (id: string) => void;
    onOverlayReplace: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    onBackgroundReplace: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    onRulerDelete: (id: string) => void;
    onBackgroundChangeOrder: (payload: TODO) => void;
    onOverlayChangeOrder: (payload: TODO) => void;
    toggleHide: (id: string, type: LayerType) => void;
    toggleBulkMode: (payload: TODO) => void;
    isBulkMode?: boolean;
    onDocumentDuplicate?: (id: string) => void;
    onProtected?: () => void;
    onBgRemove?: (id: string) => void;
}

const onDnDEvent = (result: any, fn: (payload: { startIndex: number; endIndex: number }) => void) => {
    const payload = {
        startIndex: result.source.index,
        endIndex: result.destination.index,
    };

    if (payload.startIndex !== payload.endIndex) {
        fn(payload);
    }
};
export default class Layers extends PureComponent<LayersProps> {
    renderLayers(
        array: LayerList,
        onDelete: (id: string) => void,
        onReplace?: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void,
        allowDragDrop?: boolean,
        onDuplicate?: (id: string) => void,
    ) {
        const {
            onSelectLayer,
            onDeselectLayer,
            selectedLayer,
            toggleHide,
            isBulkMode,
            onOverlayChangeOrder,
            onProtected,
        } = this.props;
        const hasDragDrop = !isBulkMode && allowDragDrop;
        const items = array.map((layer) => {
            const selected = layer.id === selectedLayer.id && layer.type === selectedLayer.type;
            const onClick = selected ? onDeselectLayer : onSelectLayer;
            return renderItem({
                layer,
                selected,
                onClick,
                onDelete,
                onReplace,
                toggleHide,
                hasDragDrop,
                onDuplicate,
                onProtected,
            });
        });
        return !hasDragDrop ? (
            items
        ) : (
            <DraggableList items={items} id='overlays' onDragEnd={(evt) => onDnDEvent(evt, onOverlayChangeOrder)} />
        );
    }

    renderBackgrounds() {
        const {
            backgrounds,
            onBackgroundDelete,
            onBackgroundReplace,
            onSelectBackground,
            selectedBackground,
            isBulkMode,
            onBackgroundChangeOrder,
            onProtected,
            onBgRemove,
        } = this.props;
        const items = backgrounds.map((layer) => {
            const selected = layer.id === selectedBackground;
            const onClick = !selected ? onSelectBackground : undefined;
            const onDelete = backgrounds.length > 1 ? onBackgroundDelete : undefined;
            const onReplace = onBackgroundReplace;
            const hasDragDrop = !isBulkMode;
            const toggleHide = undefined;

            return renderItem({
                layer,
                selected,
                onClick,
                onDelete,
                onReplace,
                toggleHide,
                hasDragDrop,
                onProtected,
                onBgRemove,
            });
        });

        return isBulkMode ? (
            items
        ) : (
            <DraggableList
                items={items}
                id='backgrounds'
                onDragEnd={(evt) => onDnDEvent(evt, onBackgroundChangeOrder)}
            />
        );
    }

    render() {
        const {
            onDocumentDelete,
            onOverlayDelete,
            onOverlayReplace,
            onRulerDelete,
            documents,
            overlays,
            tools,
            disabled,
            isBulkMode,
            onDocumentDuplicate,
        } = this.props;

        const backgroundTitle = addTitle('Backgrounds', !isBulkMode ? AddBackground : null);

        return (
            <div className='layers-panel'>
                {!disabled && (
                    <React.Fragment>
                        <Accordion className='accordion--background' title={backgroundTitle} defaultOpen={true}>
                            {this.renderBackgrounds()}
                        </Accordion>
                        <Accordion
                            className='accordion--overlays'
                            title={addTitle('Overlays', AddOverlay)}
                            defaultOpen={true}
                            data-testid='overlays'>
                            {this.renderLayers(overlays, onOverlayDelete, onOverlayReplace, true)}
                        </Accordion>
                        <Accordion
                            className='accordion--documents'
                            title={addTitle('Customizable Areas', AddDocument)}
                            defaultOpen={true}>
                            {this.renderLayers(documents, onDocumentDelete, undefined, undefined, onDocumentDuplicate)}
                        </Accordion>
                        <Accordion className='accordion--tools' title='Design Tools' defaultOpen={true}>
                            {this.renderLayers(tools, onRulerDelete)}
                        </Accordion>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
