import React from 'react';
import PropTypes from 'prop-types';
import BackgroundButton from './backgrounds/backgroundButtonContainer';
import AIImage from '../../../../content/images/ai.svg';

const noBackgroundPanel = ({ mode, onItemClick, onAIGeneration }) => {
    const isAIGeneration = mode === 'ai';
    return (
        <React.Fragment>
            <p>
                The first step to creating a new scene is uploading a background image. A good background image is
                usually a photograph of a blank product. After uploading the background image you can customize the area
                where a customer&apos;s design will be rendered.
            </p>
            <p> Please choose down below on which mode do you want to start working:</p>
            <ul className='scene-mode-selector'>
                <li className={mode === 'default' ? 'selected' : ''}>
                    <button onClick={() => onItemClick('default')}>
                        <img src='https://static.ux.cimpress.io/assets/icons/picture-1-l.svg' alt='Normal Mode' />
                        <h3>Normal Mode</h3>
                    </button>
                </li>
                <li className={mode === 'bulk' ? 'selected' : ''}>
                    <button onClick={() => onItemClick('bulk')}>
                        <img src='https://static.ux.cimpress.io/assets/icons/picture-3-l.svg' alt='Bulk Mode' />
                        <h3>Bulk Mode</h3>
                    </button>
                </li>
                <li className={mode === 'ai' ? 'selected' : ''}>
                    <button onClick={() => onItemClick('ai')}>
                        <img src={AIImage} alt='Normal Mode' />
                        <h3>AI Generation</h3>
                    </button>
                </li>
            </ul>
            {!isAIGeneration && <BackgroundButton buttonType='primary' />}
            {isAIGeneration && (
                <button className='btn btn-primary' onClick={onAIGeneration}>
                    Continue
                </button>
            )}
        </React.Fragment>
    );
};

noBackgroundPanel.propTypes = {
    mode: PropTypes.string,
    onItemClick: PropTypes.func,
    onAIGeneration: PropTypes.func,
};

export default noBackgroundPanel;
