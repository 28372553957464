import { createSlice } from '@reduxjs/toolkit';

import { type LayerType, DOCUMENT, RULER } from 'src/pages/editor/components/layer/layerTypes';
import { addDocument, removeDocument } from '../documents/slice';
import { addRuler, removeRuler } from '../rulers/slice';

type SelectedLayerState = {
    id?: string;
    type?: LayerType;
};

const initialState: SelectedLayerState = {};

function onRemove(state: SelectedLayerState, { payload }: any) {
    if (payload.id === state.id) {
        state.id = undefined;
        state.type = undefined;
    }
}

const slice = createSlice({
    name: 'selectedLayer',
    initialState,

    reducers: {
        selectLayer: (state, { payload }) => payload,
        deselectLayer: (state) => {
            state.id = undefined;
            state.type = undefined;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(addRuler, (state, { payload }) => {
            state.id = payload.id;
            state.type = RULER;
        });

        builder.addCase(addDocument, (state, { payload }) => {
            state.id = payload.id;
            state.type = DOCUMENT;
        });

        builder.addCase(removeDocument, onRemove);
        builder.addCase(removeRuler, onRemove);
    },
});

export const reducer = slice.reducer;

export const selectLayer = slice.actions.selectLayer;
export const deselectLayer = slice.actions.deselectLayer;
