import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { removeAlert } from './slice';
import { getAllAlerts } from './selectors';
import alertList from './alertList';

const mapStateToProps = (state: RootState) => ({
    alerts: getAllAlerts(state),
});

const alertListContainer = connect(mapStateToProps, { onDismiss: removeAlert })(alertList);

export default alertListContainer;
