import type { RootState } from 'src/store';
import { connect } from 'react-redux';
import { getDocumentById } from 'src/selectors/layers';
import { getSurfaceProperties } from 'src/selectors/pages/editor';
import Automask from './automask';
import { toggleAutomask } from '../slice';

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const doc = getDocumentById(state, ownProps.id) || {};
    const surfaceProps = getSurfaceProperties(state);
    return {
        active: !!doc.automask,
        enable: !!surfaceProps.isReady,
    };
};

const AutomaskContainer = connect(mapStateToProps, {
    toggle: toggleAutomask,
})(Automask);

export default AutomaskContainer;
