import { all } from 'redux-saga/effects';
import editorPage from 'src/pages/editor/saga';
import alerts from 'src/components/alert/saga';
import application from 'src/saga';
import scenes from 'src/components/scenes/saga';
import links from 'src/components/links/saga';

export default function* allSagas() {
    return yield all([editorPage(), alerts(), application(), links(), scenes()]);
}
