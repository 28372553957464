import { connect } from 'react-redux';

import { getAllRulers, isAddRulerEnabled } from 'src/selectors/layers';
import { addRuler } from './slice';
import { toggleAddMode } from '../canvas/slice';
import rulers from './rulers';
import { RootState } from 'src/store';

const mapStateToProps = (state: RootState) => ({
    rulerIds: getAllRulers(state).map((ruler) => ruler.id),
    addModeEnabled: isAddRulerEnabled(state),
});

const rulersContainer = connect(mapStateToProps, {
    addRuler,
    toggleAddRulerMode: toggleAddMode,
})(rulers);

export default rulersContainer;
