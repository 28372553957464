/* eslint-disable @typescript-eslint/no-explicit-any */
import { PERSPECTIVE_WARP, SMOOTH_WARP } from 'src/models/documentWarps';
import { DocumentSliceState, ENGRAVING_COLOR } from 'src/pages/editor/components/documents/slice';
import { DOCUMENT, COMPOSITE, INSTRUCTION } from 'src/pages/editor/components/layer/layerTypes';
import { buildCompositeTree } from './composites';
import { RootState } from 'src/store';
import { BackgroundType } from 'src/types/background';
import { CompositeTreeRootType } from 'src/types/xml';

const NO_CONTENT = '';

const DipParameters = {
    'xmlns:xsd': 'http://www.w3.org/2001/XMLSchema',
    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
    version: '0',
};

const nodeToXml = (name: string, attributes: Record<string, any>, content: string) => {
    const xmlAttributes = Object.keys(attributes).reduce(
        (accAttribute, key) => `${accAttribute} ${key}="${attributes[key]}"`,
        NO_CONTENT,
    );
    return `<${name}${xmlAttributes}>${content}</${name}>`;
};

const buildDocumentNode = (attributes: Record<string, any>) => nodeToXml('Document', attributes, NO_CONTENT);

const buildImageNode = (attributes: Record<string, any>) => nodeToXml('Image', attributes, NO_CONTENT);

// This can be recursive
const buildCompositeNode = (attributes: CompositeTreeRootType) => {
    const { children, ...compositeData } = attributes;
    const xmlChildren = children?.reduce((accChildren, child) => {
        const { type, ...childData } = child;

        let xmlChild = NO_CONTENT;
        switch (type) {
            case COMPOSITE:
                xmlChild = buildCompositeNode(childData);
                break;
            case DOCUMENT:
                xmlChild = buildDocumentNode(childData);
                break;
            case INSTRUCTION:
                // eslint-disable-next-line no-case-declarations
                const { content = '', ...attrs } = childData;
                xmlChild = nodeToXml('Instructions', attrs, content);
                break;
            default:
                xmlChild = buildImageNode(childData);
                break;
        }

        return accChildren + xmlChild;
    }, NO_CONTENT);

    return nodeToXml('Composite', compositeData, xmlChildren);
};

const buildTransformNode = (transforms: Record<string, any>[]) => {
    const xmlTransforms = transforms.reduce((accTransform, transform) => {
        const { type, points, ...data } = transform;

        const xmlPoints = points.reduce(
            (accPoint: string, point: Record<string, any>) => accPoint + nodeToXml('MapPoint', point, NO_CONTENT),
            NO_CONTENT,
        );

        return accTransform + nodeToXml(type, data, xmlPoints);
    }, NO_CONTENT);

    return nodeToXml('Transforms', {}, xmlTransforms);
};

const buildMetadataNode = (documents: DocumentSliceState, aspectRatioConstrainedType: string) => {
    let warpData = documents.reduce((accDocument, document) => {
        let currDoc = '';
        const { rotationAngle, warpType, transforms: docTransforms, isEngraving, engravingColor, id } = document;

        if ((warpType === SMOOTH_WARP || warpType === PERSPECTIVE_WARP) && docTransforms && docTransforms[0]) {
            const transformId = docTransforms[0].id;
            currDoc += nodeToXml(
                'MetadataItem ',
                { type: 'rotation' },
                JSON.stringify({ rotationAngle: rotationAngle || 0, transformId }),
            );
        }

        if (isEngraving) {
            currDoc += nodeToXml(
                'MetadataItem ',
                { type: 'engraving' },
                JSON.stringify({ color: engravingColor || ENGRAVING_COLOR, id }),
            );
        }

        return (accDocument || '') + currDoc;
    }, NO_CONTENT);

    if (aspectRatioConstrainedType) {
        warpData += nodeToXml('MetadataItem ', { type: 'aspectRatio' }, aspectRatioConstrainedType);
    }

    return nodeToXml('Metadata', {}, warpData);
};

/**
 * Editor page has a weird flow for changing skus compared to the links page. Centralized that logic here.
 * @param {Object} state The entire state tree
 * @param {Object} [backgroundId] id of the background that is to be built. If not supplied, it will choose the currently selected background
 * @param {boolean} hideEnabled If true, the xml will omit any layers that are marked as hidden. Otherwise, all layers will be converted.
 */
export default function currentStateToXml({
    state,
    background,
    hideEnabled = false,
}: {
    state: RootState;
    background: BackgroundType;
    hideEnabled?: boolean;
}) {
    const tree = buildCompositeTree(state, background, hideEnabled);
    if (!tree) {
        return undefined;
    }
    const transforms = buildTransformNode(tree.transforms);
    const layers = buildCompositeNode(tree.root);
    const metadata = buildMetadataNode(
        state.pages.editor.documents.present,
        state.pages.editor.canvas.aspectRatioConstrainedType,
    );
    return nodeToXml('Dip', DipParameters, `${transforms}${layers}${metadata}`);
}
