import React, { FC } from 'react';
import { Modal, Button } from '@cimpress/react-components';
import { Content } from '@rendering/components';

interface ResizeModalProps {
    opened: boolean;
    onClose: () => void;
    onAccept: () => void;
    onDecline: () => void;
    message: keyof typeof RESIZE_MODAL_MESSAGE;
}

export const RESIZE_MODAL_MESSAGE = {
    scene: 'Your image has a different size of the current Canvas. We currently only support images with the same dimensions. Would you like to resize your image down to Canvas size?',
    large: 'Your image is larger than 2000px. Large images result in slower load times. Would you like to scale your image down to 2000px?',
};

const ResizeModal: FC<ResizeModalProps> = ({ opened, onClose, onAccept, onDecline, message = 'scene' }) => {
    return (
        <Modal
            className='resize-image-modal'
            show={opened}
            title='Resize Image'
            onRequestHide={onClose}
            footer={
                <Content isFlex={true} gap='8px'>
                    <Button onClick={onDecline}>Keep Original</Button>
                    <Button variant='primary' onClick={onAccept}>
                        Resize
                    </Button>
                </Content>
            }>
            <p>{RESIZE_MODAL_MESSAGE[message]}</p>
        </Modal>
    );
};

export default ResizeModal;
