import { createSlice } from '@reduxjs/toolkit';

import { changeItemOrder, removeById } from 'src/util/state';
import { blendingModeChange, layerToggleHide } from 'src/store/actions/actionTypes';
import { OVERLAY } from 'src/pages/editor/components/layer/layerTypes';
import { BlendingMode } from 'src/models/blendingModes';

export type Overlay = {
    id: string;
    uploading: boolean;
    url?: string;
    name?: string;
    mode?: BlendingMode;
    hidden?: boolean;
    // TODO: are the properties below set somewhere?
    width?: number;
    height?: number;
    depth?: number;
};

export type OverlaySliceState = Overlay[];

const initialState: OverlaySliceState = [];

const slice = createSlice({
    name: 'overlays',
    initialState,

    reducers: {
        uploadOverlays: (state, { payload }: { payload: Array<{ id: string }> }) => {
            payload.forEach((overlay) => {
                state.push({
                    uploading: true,
                    id: overlay.id,
                });
            });
        },
        overlayUploaded: (state, { payload }) => {
            const { id, url, name, mode } = payload;
            const overlayIndex = state.findIndex((overlay) => overlay.id === id);
            if (overlayIndex > -1) {
                state[overlayIndex] = { id, url, name, mode, uploading: false };
            }
        },
        uploadAndReplaceOverlay: (state, { payload }) => {
            const overlayToReplace = state.find((overlay) => overlay.id === payload.id);
            if (overlayToReplace) {
                overlayToReplace.uploading = true;
            }
        },
        replaceFailed: (state, { payload }) => {
            const overlayThatFailed = state.find((overlay) => overlay.id === payload);
            if (overlayThatFailed) {
                overlayThatFailed.uploading = false;
            }
        },
        removeOverlay: removeById,
        changeOverlayOrder: changeItemOrder,
    },

    extraReducers: (builder) => {
        builder.addCase(blendingModeChange, (state, { payload }) => {
            const { id, type, mode } = payload;
            if (type === OVERLAY) {
                const overlay = state.find((over) => over.id === id);
                if (overlay) {
                    overlay.mode = mode;
                }
            }
        });

        builder.addCase(layerToggleHide, (state, { payload }) => {
            const { id, type } = payload;
            if (type === OVERLAY) {
                const overlay = state.find((over) => over.id === id);
                if (overlay) {
                    overlay.hidden = !overlay.hidden;
                }
            }
        });
    },
});

export const reducer = slice.reducer;

export const uploadOverlays = slice.actions.uploadOverlays;
export const overlayUploaded = slice.actions.overlayUploaded;
export const uploadAndReplaceOverlay = slice.actions.uploadAndReplaceOverlay;
export const replaceOverlayFailed = slice.actions.replaceFailed;
export const removeOverlay = slice.actions.removeOverlay;
export const changeOverlayOrder = slice.actions.changeOverlayOrder;
