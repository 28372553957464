import PropTypes from 'prop-types';
import React from 'react';

import TooltipWrapper from 'src/components/TooltipWrapper';
import SpinnerButton from 'src/components/SpinnerButton';

export default function publish({
    tip,
    disabled,
    spinning,
    isModified,
    isPublished,
    isEditingScene,
    isBulkMode,
    publishScene,
    saveAndPublishScene,
    createScene,
    createBulkScenes,
}) {
    let text = 'Publish';
    let onClick = () => publishScene();

    if (isBulkMode) {
        text = 'Publish all backgrounds';
        onClick = () => createBulkScenes();
    } else if (!isEditingScene) {
        text = 'Create scene';
        onClick = () => createScene();
    } else if (isModified) {
        text = 'Save and publish';
        onClick = () => saveAndPublishScene();
    } else if (isPublished) {
        text = 'Published';
    }

    return (
        <div className='tools-publish'>
            <TooltipWrapper direction='top' contents={tip}>
                <SpinnerButton
                    testid='publish-button'
                    buttonType='primary'
                    onClick={onClick}
                    text={text}
                    disabled={disabled || !!tip}
                    spinning={spinning}
                />
            </TooltipWrapper>
        </div>
    );
}

publish.propTypes = {
    disabled: PropTypes.bool.isRequired,
    spinning: PropTypes.bool.isRequired,
    isModified: PropTypes.bool.isRequired,
    isEditingScene: PropTypes.bool.isRequired,
    isPublished: PropTypes.bool.isRequired,
    isBulkMode: PropTypes.bool.isRequired,
    tip: PropTypes.string.isRequired,
    saveAndPublishScene: PropTypes.func.isRequired,
    createScene: PropTypes.func.isRequired,
    publishScene: PropTypes.func.isRequired,
    createBulkScenes: PropTypes.func.isRequired,
};
