import type { KonvaEventObject } from 'konva/lib/Node';
import React, { Component } from 'react';
import { Group, Line, Ellipse } from 'react-konva';
import type { CylinderType } from 'src/types/transforms';

import { calculatePosition } from 'src/util/math';

interface CylinderBorderProps {
    scaledCylinderWarp: Omit<CylinderType, 'id' | 'fullWrap'>;
    cylinderWarp: CylinderType;
    selected: boolean;
    scale: number;
    selectLayer: () => void;
    onDragMove: (cylinderWarp: CylinderType) => void;
    onDragEnd: () => void;
}

interface CylinderBorderState {
    startCylinderWarp: CylinderType | null;
    clientX: number | null;
    clientY: number | null;
}

export default class CylinderBorder extends Component<CylinderBorderProps, CylinderBorderState> {
    state: CylinderBorderState = {
        startCylinderWarp: null,
        clientX: null,
        clientY: null,
    };

    onMouseDown = (e: KonvaEventObject<globalThis.MouseEvent>) => {
        e.cancelBubble = true;
        if (this.props.selected) {
            this.onDragStart(e);
        } else {
            this.props.selectLayer();
        }
    };

    onDragStart = (e: KonvaEventObject<globalThis.MouseEvent>) => {
        const { cylinderWarp } = this.props;

        this.setState({
            startCylinderWarp: cylinderWarp,
            clientX: e.evt.clientX,
            clientY: e.evt.clientY,
        });

        document.onmousemove = this.onDragMove;
        document.onmouseup = this.onDragEnd;
    };

    onDragMove = (e: MouseEvent) => {
        const { clientX, clientY, startCylinderWarp } = this.state;
        const { onDragMove, scale } = this.props;
        const deltaX = (clientX! - e.clientX) / scale;
        const deltaY = (clientY! - e.clientY) / scale;
        const newCylinderWarp: CylinderType = JSON.parse(JSON.stringify(startCylinderWarp));
        newCylinderWarp.cylinderTop.center.x -= deltaX;
        newCylinderWarp.cylinderBottom.center.x -= deltaX;
        newCylinderWarp.cylinderTop.center.y -= deltaY;
        newCylinderWarp.cylinderBottom.center.y -= deltaY;

        onDragMove(newCylinderWarp);
    };

    onDragEnd = () => {
        document.onmousemove = () => {};
        document.onmouseup = () => {};

        this.props.onDragEnd();
    };

    render() {
        const { scaledCylinderWarp, selected } = this.props;
        const topLeftPosition = calculatePosition(scaledCylinderWarp.cylinderTop, -180);
        const bottomLeftPosition = calculatePosition(scaledCylinderWarp.cylinderBottom, -180);
        const leftLine = [topLeftPosition.x, topLeftPosition.y, bottomLeftPosition.x, bottomLeftPosition.y];

        const topRightPosition = calculatePosition(scaledCylinderWarp.cylinderTop, 0);
        const bottomRightPosition = calculatePosition(scaledCylinderWarp.cylinderBottom, 0);
        const rightLine = [topRightPosition.x, topRightPosition.y, bottomRightPosition.x, bottomRightPosition.y];

        const draggableBox = [topLeftPosition.x, topLeftPosition.y]
            .concat(rightLine)
            .concat([bottomLeftPosition.x, bottomLeftPosition.y]);

        const color = selected ? 'red' : 'transparent';

        return (
            <Group>
                <Line points={leftLine} stroke={color} strokeWidth={1} />
                <Line points={rightLine} stroke={color} strokeWidth={1} />
                <Line
                    points={draggableBox}
                    stroke='transparent'
                    strokeWidth={1}
                    closed={true}
                    onMouseDown={this.onMouseDown}
                />
                <Ellipse
                    x={scaledCylinderWarp.cylinderTop.center.x}
                    y={scaledCylinderWarp.cylinderTop.center.y}
                    radiusX={scaledCylinderWarp.cylinderTop.radiusX}
                    radiusY={scaledCylinderWarp.cylinderTop.radiusY}
                    rotation={scaledCylinderWarp.cylinderTop.rotationDegrees}
                    stroke={color}
                    strokeWidth={1}
                    onMouseDown={this.onMouseDown}
                />
                <Ellipse
                    x={scaledCylinderWarp.cylinderBottom.center.x}
                    y={scaledCylinderWarp.cylinderBottom.center.y}
                    radiusX={scaledCylinderWarp.cylinderBottom.radiusX}
                    radiusY={scaledCylinderWarp.cylinderBottom.radiusY}
                    rotation={scaledCylinderWarp.cylinderBottom.rotationDegrees}
                    stroke={color}
                    strokeWidth={1}
                    onMouseDown={this.onMouseDown}
                />
            </Group>
        );
    }
}
