import { apiPrefix } from 'src/settings';
import { logError, logInfo } from 'src/loggingManager';
import { parseError } from 'src/util/misc';
import { makeAuthenticatedServiceRequest, makePublicServiceRequest } from './servicesClient';
import { createAsset, createVersion, updateAsset, getAsset, getVersion } from './assetsClient';
import { ImportedSceneResponseType, SceneResponseType, SceneType, VersionSceneResponseType } from 'src/types/scene';
import { TransformTypes } from 'src/types/transforms';
import { AssetType } from 'src/types/asset';

const SCENE_ENDPOINT = `https://${apiPrefix}scenes.documents.cimpress.io`;

type UpdateSceneParams = {
    tenantType: string;
    tenantId: string;
    sceneId: string;
    scene: SceneType;
};

type InputSceneParams = Omit<UpdateSceneParams, 'sceneId'>;

function mapSceneToAsset(scene: SceneType) {
    const initialRevision = scene.initialVersion && {
        uri: scene.initialVersion.indexUri,
        metadata: {
            transformations: scene.initialVersion.transformations,
        },
    };
    return {
        name: scene.name,
        description: scene.description,
        notes: scene.notes,
        referenceId: scene.mcpSku,
        referenceIdVersion: scene.mcpSkuVersion,
        attributes: scene.attributes,
        assetType: 'scene',
        tags: scene.tags,
        latestRevisionId: scene.latestVersionId,
        publishedRevisionId: scene.publishedVersionId,
        deleted: scene.deleted,
        initialRevision,
    };
}

/**
 * Creates a scene.
 * @param options
 * @param options.tenantType - The type of owner resource (merchant / fulfiller).
 * @param options.tenantId - The id of the resource.
 * @param options.scene - The object containing all scene properties.
 * @param options.scene.name - Name of the scene.
 * @param options.scene.description - Description of the scene.
 * @param options.scene.notes - Notes of the scene.
 * @param options.scene.tags - Optional array of tags for the scene.
 * @param options.scene.mcpSku - Optional sku associated with the scene for searching.
 * @param options.scene.mcpSkuVersion - Optional sku version associated with the scene for searching.
 * @param options.scene.initialVersion - The optional initial version for the scene.
 * @param options.scene.initialVersion.indexUri - The link to the initial scene uri.
 * @param options.scene.initialVersion.transformations - Optional transformation data if this scene contains cylinders.
 * @returns - The promise of the request.
 */
export function createScene({ tenantType, tenantId, scene }: InputSceneParams) {
    const asset = mapSceneToAsset(scene);
    asset.initialRevision = {
        uri: scene.initialVersion.indexUri,
        metadata: {
            transformations: scene.initialVersion.transformations,
        },
    };

    return createAsset({ asset, tenantType, tenantId }).then((newScene) => {
        logInfo(`Created scene: ${newScene} ${JSON.stringify(newScene || {})}`);
        return newScene;
    });
}

type CreateSceneVersionParams = {
    sceneId: string;
    version: {
        indexUri: string;
        transformations?: TransformTypes[];
    };
};

/** Creates a scene version.
 * @param options
 * @param options.sceneId - The id of the scene.
 * @param options.version - The scene version object.
 * @param options.version.indexUri - The link to the new scene uri.
 * @param options.version.transformations - Optional transformation data if this scene contains cylinders.
 * @returns - The promise of the request.
 */
export function createSceneVersion({ sceneId, version }: CreateSceneVersionParams) {
    const mappedVersion = {
        uri: version.indexUri,
        metadata: {
            transformations: version.transformations,
        },
    };

    return createVersion({ assetId: sceneId, version: mappedVersion });
}

/**
 * Gets a scene.
 * @param options
 * @param options.sceneId - The id of the scene.
 * @returns - The promise of the request.
 */
export function getScene({ sceneId }: { sceneId: string }) {
    return getAsset({ assetId: sceneId }).then((asset) =>
        getVersion({ assetId: sceneId, versionId: asset.latestVersionId || '' }).then((version) => {
            if (Object.keys(version.metadata).length > 0 || Array.isArray(version.metadata)) {
                // eslint-disable-next-line no-param-reassign
                asset.cylinderWarp = version.metadata.transformations
                    ? version.metadata.transformations
                    : version.metadata;
            }
            return asset;
        }),
    );
}

/**
 * Updates a scene.
 * @param options
 * @param options.tenantType - The type of owner resource (merchant / fulfiller).
 * @param options.tenantId - The id of the resource.
 * @param options.sceneId - The id of the scene.
 * @param options.scene - The updated scene object.
 * @param options.scene.name - The updated scene name.
 * @param options.scene.mcpSku - The updated sku.
 * @param options.scene.description - The updated scene description.
 * @param options.scene.latestVersionId - The updated latest version.
 * @param options.scene.publishedVersionId - The updated published version.
 * @param options.scene.tags - string array of tags for the scene
 * @param options.scene.deleted - if the string is deleted or not
 * @returns - The promise of the request.
 */
export function updateScene({ tenantType, tenantId, sceneId, scene }: UpdateSceneParams) {
    const asset = mapSceneToAsset(scene);
    return updateAsset({ tenantType, tenantId, assetId: sceneId, newAsset: asset });
}

function mapImportedSceneResponse(response: { data: SceneResponseType } | void): ImportedSceneResponseType | undefined {
    if (response && response.data) {
        const scene = response.data;
        return {
            ...scene,
            PublishedRevisionId: scene.PublishedCompositionId,
            RecentPublishedRevisions: scene.RecentPublishedCompositions,
            ReferenceId: scene.McpSku,
            LatestRevisionId: scene.LatestCompositionId,
        };
    }
}

// Helper to parse an asset
export function parseScene(asset: ImportedSceneResponseType | undefined): AssetType | undefined {
    if (!asset) return undefined;

    return {
        latestVersionId: asset.LatestCompositionId,
        publishedVersionId: asset.PublishedCompositionId,
        id: asset.Id,
        name: asset.Name,
        mcpSku: undefined,
        description: undefined,
        created: asset.Created,
        owner: asset.Owner,
        lastModified: asset.LastModified,
        modifiedBy: asset.ModifiedBy,
        lastPublished: asset.LastPublished,
        publishedBy: asset.PublishedBy,
        variables: {},
        tenantType: asset.Tenant.Type,
        tenantId: asset.Tenant.Id,
        deleted: asset.Deleted || false,
        tags: [],
        publishedVersions: (asset.RecentPublishedCompositions || []).map((version: VersionSceneResponseType) => ({
            id: version.CompositionId,
            date: version.PublishedAt,
            publisher: version.PublishedBy,
        })),
    };
}

/**
 * Imports scene.
 * @param options
 * @param options.zip - Zip File containing the scene.
 * @returns - The promise of the request.
 */
export function importScene({ zip, tenantType, tenantId }: { zip: File; tenantType: string; tenantId: string }) {
    const url = `${SCENE_ENDPOINT}/v2/${tenantType}/${tenantId}/sceneimport`;
    const data = new FormData();
    data.append('file', zip, zip.name);
    return makeAuthenticatedServiceRequest({
        url,
        method: 'post',
        data,
        header: {
            // Reset default `Content-Type` header to `undefined` to allow Axios to set the correct `Content-Type` header
            'Content-Type': undefined,
        },
    })
        .then(mapImportedSceneResponse)
        .then(parseScene)
        .catch((e) => {
            logError(`Failure to import scene! ${parseError(e)}`);
            throw e;
        });
}

/**
 * Clean scene texture (uv map) image
 * @param options
 * @param options.texture - texture image file
 * @returns The promise of the request.
 */
export function cleanTexture({ texture }: { texture: File }) {
    const url = `${SCENE_ENDPOINT}/v3/scenes/texture:clean`;
    const data = new FormData();
    data.append('file', texture, texture.name);

    return makePublicServiceRequest({
        url,
        method: 'post',
        data,
        responseType: 'arraybuffer',
        header: {
            // Reset default `Content-Type` header to `undefined` to allow Axios to set the correct `Content-Type` header
            'Content-Type': undefined,
        },
    })
        .then((cleanedTexture) => new File([cleanedTexture.data], texture.name, { type: 'image/png' }))
        .catch((e) => {
            logError(`Failure to clean texture image! ${parseError(e)}`);
            throw e;
        });
}

/**
 * get texture (uv map) image
 * @param options
 * @param options.width - width of identity texture image
 * @param options.height - height of identity texture image file
 * @returns The promise of the request.
 */
export function getTextureIdentity({ width, height }: { width: number; height: number }) {
    const url = `${SCENE_ENDPOINT}/v3/scenes/texture/identity:generate?width=${width}&height=${height}`;

    return makePublicServiceRequest({ url, method: 'get', responseType: 'arraybuffer' })
        .then((identityTexture) => new File([identityTexture.data], 'identityTexture', { type: 'image/png' }))
        .catch((e) => {
            logError(`Failure to get identity texture image of ${width}x${height} size! ${parseError(e)}`);
            throw e;
        });
}
