import createCachedSelector from 're-reselect';

import getSurfaces from './getSurfaces';
import { RootState } from 'src/store';

const bindByPage = (_state: RootState, page: number) => page;

const getSurfaceByPage = createCachedSelector(
    [getSurfaces, bindByPage],
    (surfaces, page) => surfaces[page - 1],
)((_state, page) => page);

export default getSurfaceByPage;
