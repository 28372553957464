import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

const specialCharacters = {
    CTRL: 'ctrl',
    ALT: 'alt',
    SHIFT: 'shift',
};

const keyState = {
    y: false,
    z: false,
};

const checkCombinations = (keyEvent, keys) => {
    let isValid = false;
    for (let i = 0; i < keys.length; i++) {
        const curKey = keys[i];
        if (keyState[curKey] === false) {
            isValid = false;
            break;
        }

        if (specialCharacters[curKey]) {
            if (keyEvent[`${curKey}Key`] === false) {
                isValid = false;
                break;
            }
        }
        isValid = true;
    }
    return isValid;
};

function Shortcuts(props) {
    const config = [
        { fn: props.onUndo, keys: [specialCharacters.CTRL, 'z'] },
        { fn: props.onRedo, keys: [specialCharacters.CTRL, 'y'] },
    ];

    const onKeyDown = (keyDownEvent) => {
        // keyDownEvent.preventDefault();

        keyState[keyDownEvent.key] = true;

        const filtered = config.filter((shortcut) => shortcut.keys.includes(keyDownEvent.key));
        filtered.forEach((shortcut) => {
            if (checkCombinations(keyDownEvent, shortcut.keys, keyState)) {
                shortcut.fn();
            }
        });
    };

    const onKeyUp = (keyUpEvent) => {
        // Prevent default key actions, if desired
        keyUpEvent.preventDefault();

        // Track down key release
        keyState[keyUpEvent.key] = false;
    };

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, []);
    return <></>;
}

Shortcuts.propTypes = {
    onUndo: PropTypes.func,
    onRedo: PropTypes.func,
};

const mapStateToProps = () => ({});
const ShortcutsContainer = connect(mapStateToProps, (dispatch) => ({
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo()),
}))(Shortcuts);

export default ShortcutsContainer;
