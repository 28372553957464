import { createSelector } from 'reselect';
import { RootState } from 'src/store';

const getPSDFile = (state: RootState) => state.pages.editor.backgrounds.importPSD.file;

export const getCurrentPSDFile = createSelector([getPSDFile], (file) => file);

const getPSDShow = (state: RootState) => state.pages.editor.backgrounds.importPSD.show;

export const getShowPSDModal = createSelector([getPSDShow], (show) => show);

const getPSDLayers = (state: RootState) => state.pages.editor.backgrounds.importPSD.layers;

export const getLayersPSD = createSelector([getPSDLayers], (layers) => layers);

const getPSDResolution = (state: RootState) => state.pages.editor.backgrounds.importPSD.resolution;

export const getResolutionPSD = createSelector([getPSDResolution], (resolution) => resolution);
