import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { getCoamTenant, getRegionSetting } from 'src/selectors';

import {
    getSceneName,
    getSceneDescription,
    getTags,
    getSceneId,
    getLatestVersionId,
    getPublishedVersionId,
    getSurfaceProperties,
    getSceneNotes,
    getSurfaces,
} from 'src/selectors/pages/editor';
import { getAllCylinderWarps, getAllBackgrounds, getAllDocuments } from 'src/selectors/layers';
import { createScene as createSceneOnServer, updateScene, createSceneVersion } from 'src/services/sceneClient';
import { uploadSceneXml, generateUploadsUrl } from 'src/services/uploadsClient';
import { createLink, getLinksForAsset } from 'src/services/linkClient';
import { fromState } from 'src/services/converters/cylinderWarp';
import { logError, logInfo } from 'src/loggingManager';
import currentStateToXml from 'src/util/xmlConverter/toXml';
import { deleteAsset } from 'src/services/assetsClient';
import {
    publishScene,
    publishSceneSuccess,
    publishSceneFailed,
    saveDraft,
    saveDraftSuccess,
    saveDraftFailed,
    duplicateScene,
    duplicateSceneSuccess,
    duplicateSceneFailed,
    saveAndPublishScene,
    saveAndPublishSceneSuccess,
    saveAndPublishSceneFailed,
    createScene,
    createSceneSuccess,
    createSceneFailed,
    createBulkScenes,
    createBulkScenesSuccess,
    createBulkScenesFailed,
    createSceneFinished,
} from './slice';
import {
    saveSceneVariationAsset,
    getSceneVariationLink,
    updateSceneVariation,
    updateLinkVariation,
    mapPurposes,
} from '../SceneVariation/service';
import getSceneVariation from '../SceneVariation/selectors/getSceneVariation';
import getSceneData from '../SceneVariation/selectors/getSceneData';
import {
    cleanSceneConfiguration,
    saveLinkVariationFailed,
    saveVariationFailed,
    setLinks,
    updateSceneIds,
} from '../SceneVariation/slice';

// Used by all saving functionality to get and build the scene data from the state tree.
function* getScene(background) {
    const { sku, skuVersion, merchantSku, merchantVersion, variables } = yield select(getSurfaceProperties);
    const name = yield select(getSceneName);
    const description = yield select(getSceneDescription);
    const tags = yield select(getTags);
    const notes = yield select(getSceneNotes);
    try {
        const state = yield select();
        const xml = currentStateToXml({ state, background });
        const region = yield select(getRegionSetting);

        const uploadId = yield call(uploadSceneXml, xml, region);

        const initialVersion = { indexUri: generateUploadsUrl(region, uploadId) };
        const cylinders = yield select(getAllCylinderWarps);
        if (cylinders.length) {
            initialVersion.transformations = fromState(cylinders);
        }
        return {
            name,
            description,
            notes,
            tags,
            mcpSku: merchantSku || sku,
            mcpSkuVersion: merchantVersion || skuVersion,
            attributes: variables,
            initialVersion,
        };
    } catch (error) {
        logError(`Error creating scene version for saving: ${JSON.stringify(background || {})} ${error}`);
        // Everything that uses this has a try/catch around this. Let them handle the error on their own.
        throw error;
    }
}

function* createUsingBackground(background) {
    const scene = yield call(getScene, background);
    scene.name = background.name; // We name our bulk scenes after the background file name
    const { tenantId, tenantType } = yield select(getCoamTenant);
    return yield call(createSceneOnServer, { tenantType, tenantId, scene });
}

function* createMany() {
    const backgrounds = yield select(getAllBackgrounds);
    try {
        yield all(backgrounds.map(createUsingBackground));
        yield put(createBulkScenesSuccess());
        yield put(push('/'));
    } catch (error) {
        logError(`Failure to bulk create scenes: ${error}`);
        yield put(createBulkScenesFailed());
    }
}

// Used by both saveAndPublish and saveAsDraft
function* saveSceneVariation() {
    const scene = yield select(getSceneData);
    // only save when there is something to save
    if (!scene.sku) {
        return;
    }

    const { tenantId, tenantType } = yield select(getCoamTenant);
    const tenant = { id: tenantId, type: tenantType };
    scene.tenant = tenant;

    const sceneVariationData = yield select(getSceneVariation);
    if (!sceneVariationData) return;

    const documents = yield select(getAllDocuments);
    const surfaces = yield select(getSurfaces);
    const subReferences = [];
    documents.forEach((doc) => {
        const tempSurface = surfaces[doc.page - 1];
        if (!subReferences.includes(tempSurface)) {
            subReferences.push(tempSurface);
        }
    });
    let savedSceneVariation;

    try {
        // Get Link
        if (sceneVariationData.sceneVariableId) {
            const currentLink = yield call(getSceneVariationLink, {
                variableConfigurationId: sceneVariationData.sceneVariableId,
                sceneId: scene.id,
            });
            if (currentLink.variableConfigurationId) {
                // update link if possible
                // create new version for config variation
                yield call(updateSceneVariation, {
                    link: currentLink,
                    scene,
                    configData: sceneVariationData,
                    subReferences,
                    tenant,
                });
            } else {
                // Create Configuration
                savedSceneVariation = yield call(saveSceneVariationAsset, { scene, configData: sceneVariationData });
                // update link
                yield call(updateLinkVariation, {
                    assetId: scene.id,
                    linkId: currentLink.id,
                    variableConfigurationId: savedSceneVariation.id,
                    configData: sceneVariationData,
                    tenant,
                    subReferences,
                });
            }
        } else {
            // Create Configuration
            savedSceneVariation = yield call(saveSceneVariationAsset, { scene, configData: sceneVariationData });
            if (!savedSceneVariation) {
                logError('Failed to save scene Variation');
                throw new Error('Failed to save scene Variation');
            }

            yield put(updateSceneIds({ sceneVariableId: savedSceneVariation.id }));
            // Create Link
            const linkData = {
                variableConfigurationId: savedSceneVariation.id,
                referenceId: scene.sku,
                referenceIdVersion: scene.skuVersion,
                subReferenceIds: subReferences.map((ref) => (ref && ref.id) || ''),
                attributes: scene.attributes,
                linkPurposes: mapPurposes(sceneVariationData.scenePurposes),
                tags: sceneVariationData.tags,
                tenant,
            };

            const saveLink = yield call(createLink, { assetId: scene.id, linkInput: linkData });
            yield put(updateSceneIds({ sceneLinkId: saveLink.data.id }));
        }
        // Reload all links from database
        const links = yield call(getLinksForAsset, { assetId: scene.id });
        const sceneVariationConfigs = links.filter((link) => !!link.variableConfigurationId);
        yield put(setLinks({ links: sceneVariationConfigs }));
    } catch (error) {
        logError(`Failed to save scene Variation: ${error}`);
        if (
            (error.response &&
                error.response.data.message ===
                    'You already have an asset linked with this mcp product and purpose!') ||
            (error.message === 'Request failed with status code 409' && error.config.url.indexOf('/links'))
        ) {
            yield put(saveLinkVariationFailed());
        } else if (!savedSceneVariation) {
            yield put(saveVariationFailed());
        }

        if (!sceneVariationData.sceneVariableId && savedSceneVariation) {
            try {
                yield call(deleteAsset, { assetId: savedSceneVariation.id });
                yield put(cleanSceneConfiguration());
            } catch (delError) {
                logError(`Failed to delete scene Variation: ${delError}`);
            }
        }
        throw error;
    }
}

function* create() {
    const { tenantId, tenantType } = yield select(getCoamTenant);
    try {
        const scene = yield call(getScene);
        const result = yield call(createSceneOnServer, { tenantType, tenantId, scene });
        logInfo(JSON.stringify(result));
        yield put(
            createSceneSuccess({
                sceneId: result.id,
                latestVersionId: result.latestVersionId,
                publishedVersionId: result.publishedVersionId,
            }),
        );

        // If we don't catch it, the save doesn't work after this
        try {
            yield call(saveSceneVariation);
        } catch (er) {
            logError(`Error creating scene variation: ${er}`);
        }
        yield put(push(`/editor/${tenantType}/${tenantId}/${result.id}`));
        yield put(createSceneFinished());
    } catch (error) {
        logError(`Error creating scene: ${error}`);
        yield put(createSceneFailed());
        throw error;
    }
}

// Used by both saveAndPublish and saveAsDraft
function* saveScene({ draft = false }) {
    const { tenantId, tenantType } = yield select(getCoamTenant);
    const sceneId = yield select(getSceneId);
    try {
        const scene = yield call(getScene);
        if (draft) {
            scene.publishedVersionId = yield select(getPublishedVersionId);
        }
        const { initialVersion, ...sceneData } = scene;
        const newVersion = yield call(createSceneVersion, { sceneId, version: initialVersion });
        // If a publishedVersionId is passed in, we want to preserve that, as this is saving a draft.
        const updatedScene = Object.assign({ publishedVersionId: newVersion.data.id }, sceneData, {
            latestVersionId: newVersion.data.id,
        });
        yield call(updateScene, { tenantType, tenantId, sceneId, scene: updatedScene });
        return {
            sceneId,
            publishedVersionId: updatedScene.publishedVersionId,
            latestVersionId: updatedScene.latestVersionId,
        };
    } catch (error) {
        logError(`Failed to save scene: ${error}`);
        throw error;
    }
}

function* saveAndPublish() {
    try {
        const result = yield call(saveScene, { draft: false });
        yield call(saveSceneVariation);
        yield put(saveAndPublishSceneSuccess(result));
    } catch (error) {
        logError(`Failed to save and publish scene: ${error}`);
        yield put(saveAndPublishSceneFailed());
    }
}

function* saveAsDraft() {
    try {
        const result = yield call(saveScene, { draft: true });
        yield call(saveSceneVariation);
        yield put(saveDraftSuccess(result));
    } catch (error) {
        logError(`Failed to save as draft: ${error}`);
        yield put(saveDraftFailed());
    }
}

function* publish() {
    const { tenantId, tenantType } = yield select(getCoamTenant);
    const versionId = yield select(getLatestVersionId);
    const sceneId = yield select(getSceneId);
    try {
        yield call(updateScene, { tenantType, tenantId, sceneId, scene: { publishedVersionId: versionId } });
        yield put(publishSceneSuccess({ sceneId, publishedVersionId: versionId }));
    } catch (error) {
        logError(`Failed to publish scene: ${sceneId} ${versionId} ${error}`);
        yield put(publishSceneFailed());
        throw error;
    }
}

function* duplicate() {
    const { tenantId, tenantType } = yield select(getCoamTenant);
    const sceneId = yield select(getSceneId);
    try {
        const scene = yield call(getScene);
        scene.name += ' COPY';
        const newScene = yield call(createSceneOnServer, { tenantType, tenantId, scene });
        yield put(duplicateSceneSuccess({ name: scene.name, sceneId: newScene.id }));
    } catch (error) {
        logError(`Error duplicating scene: ${sceneId} ${error}`);
        yield put(duplicateSceneFailed());
    }
}

export default function* editorSceneSagas() {
    return yield all([
        yield takeEvery(publishScene, publish),
        yield takeEvery(saveAndPublishScene, saveAndPublish),
        yield takeEvery(createScene, create),
        yield takeEvery(createBulkScenes, createMany),
        yield takeEvery(saveDraft, saveAsDraft),
        yield takeEvery(duplicateScene, duplicate),
    ]);
}
