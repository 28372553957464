import { PayloadAction } from '@reduxjs/toolkit';
import { ItemType } from 'src/types/index';

export function removeById(state: ItemType[], { payload }: PayloadAction<string | number>) {
    const index = state.findIndex((item) => item.id === payload);
    if (index > -1) {
        state.splice(index, 1);
    }
}

type RangeType = {
    startIndex: number;
    endIndex: number;
};

export function changeItemOrder(state: ItemType[], { payload }: PayloadAction<RangeType>) {
    const [removed] = state.splice(payload.startIndex, 1);
    state.splice(payload.endIndex, 0, removed);
}
