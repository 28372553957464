import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@cimpress/react-components';
import { VersionSelector } from '@rendering/components';

import { ENTER } from 'src/util/keycodes';

// Statuses
const DEFAULT = 'default';
const NO_SKU = 'noSku';

function getErrorMessage(skuData, currentStatus) {
    if (currentStatus === NO_SKU) {
        return 'Sku required!';
    }
    if (skuData && skuData.doesNotExist) {
        return 'No product with this MCP SKU could be found. SKUs are case sensitive.';
    }
    if (skuData && skuData.noLinks) {
        return 'SKU exists, but no asset links found for the given MCP SKU. Please try another SKU.';
    }
    return undefined;
}

function getStyle(skuData, errorMessage) {
    if (errorMessage) {
        return 'error';
    }
    if (skuData && skuData.rulesLoaded) {
        return 'success';
    }
    return undefined;
}

export default class SkuInput extends Component {
    state = {
        currentStatus: DEFAULT,
        sku: this.props.sku || '',
        skuVersion: this.props.skuVersion,
        merchantSku: this.props.merchantSku,
        merchantVersion: this.props.merchantVersion,
        versions:
            this.props.allSkus[this.props.sku] && this.props.allSkus[this.props.sku].versions
                ? this.props.allSkus[this.props.sku].versions
                : [],
        verifiedSku: this.props.sku || '',
        skuSelected: this.props.isReady,
        hasNoVersions: this.props.hasNoVersions,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { sku, skuVersion, merchantSku, merchantVersion, isReady, hasNoVersions } = nextProps;
        const hasChangedSku = sku !== prevState.verifiedSku;
        const hasChangedVersion = skuVersion !== prevState.skuVersion;
        const hasChangedMerchantSku = merchantSku !== prevState.merchantSku;
        const hasChangedMerchantVersion = merchantVersion !== prevState.merchantVersion;
        const hasReadyChange = isReady !== prevState.isReady;
        const hasNoVersionsChange = hasNoVersions !== prevState.hasNoVersions;

        if (
            hasChangedSku ||
            hasChangedVersion ||
            hasChangedMerchantSku ||
            hasChangedMerchantVersion ||
            hasReadyChange ||
            hasNoVersionsChange
        ) {
            const curSku = nextProps.merchantSku || nextProps.sku;
            const versions =
                nextProps.allSkus[curSku] && nextProps.allSkus[curSku].versions
                    ? nextProps.allSkus[curSku].versions
                    : [];
            return {
                ...prevState,
                isReady,
                sku,
                skuVersion,
                merchantSku,
                merchantVersion,
                verifiedSku: sku,
                versions,
                hasNoVersions,
                skuSelected: isReady,
            };
        }
        return null;
    }

    onChange = (e) => {
        if (this.state.versions.length > 0) {
            this.props.onClearProduct(e.target.value);
        } else {
            const sku = e.target.value.trim();
            this.setState({ currentStatus: DEFAULT, sku });
        }
    };

    onClear = () => {
        this.setState({ currentStatus: DEFAULT, sku: '', versions: [], skuVersion: undefined, skuSelected: false });
        this.props.onClearProduct();
    };

    onKeyDown = (e) => {
        if (e.keyCode === ENTER) {
            this.getProductVersions();
        }
    };

    onHandleAction = () => {
        const { sku, skuVersion, merchantVersion } = this.state;
        if (sku && (skuVersion >= 0 || merchantVersion >= 0)) {
            this.getProductData();
        } else {
            this.getProductVersions();
        }
    };

    getProductData = () => {
        const { sku, skuVersion } = this.state;
        if (!sku) {
            this.setState({ currentStatus: NO_SKU });
            return;
        }
        this.setState({ skuSelected: true });

        this.props.onChangeProduct({ sku, skuVersion });
    };

    getProductVersions = () => {
        const { sku, hasNoVersions } = this.state;
        if (!sku) {
            this.setState({ currentStatus: NO_SKU });
            return;
        }
        // If has no versions, mark the current sku as selected.
        if (hasNoVersions) {
            this.setState({ skuSelected: true });
        }

        this.props.onValidateSku(sku);
    };

    onChangeVersion = (selected) => {
        this.props.onChangeVersion(+selected);
    };

    render() {
        const { required, disabled, allSkus } = this.props;
        const { currentStatus, sku, skuVersion, versions, skuSelected, merchantSku, merchantVersion, hasNoVersions } =
            this.state;
        const skuData = allSkus[sku];
        const loading = !!skuData && (skuData.fetching || skuData.loadingLinks);
        const mappedVersions = versions.map((v) => {
            const statusLabel = {
                retired: ' (retired)',
                error: ' (error)',
            };
            const status = v.status?.toLowerCase() || '';
            return {
                value: `${v.version}`,
                label: `${v.version}${v.current ? ' (current)' : ''}${statusLabel[status] ?? ''}`,
                status,
                isCurrent: !!v.current,
            };
        });
        const message = getErrorMessage(skuData, currentStatus);
        const style = getStyle(skuData, message);
        const btnText = (sku && (skuVersion >= 0 || merchantVersion >= 0)) || hasNoVersions ? 'Add' : 'Verify';
        const disableInput = skuSelected;
        return (
            <div className='choose-sku'>
                <div className={'choose-sku__wrapper'}>
                    <TextField
                        id='editor-sceneInformation-product-sku-input'
                        className='choose-sku__input'
                        value={merchantSku || sku}
                        required={required}
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        label='Product SKU'
                        status={style}
                        type='text'
                        disabled={disableInput}
                    />
                    <VersionSelector
                        versions={mappedVersions}
                        value={merchantVersion || skuVersion}
                        onVersionSelected={this.onChangeVersion}
                        disabled={disableInput}
                    />
                    {!skuSelected && (
                        <Button
                            data-testid='choose-sku-submit-btn'
                            className='choose-sku__submit'
                            disabled={disabled || loading}
                            onClick={this.onHandleAction}>
                            {btnText}
                        </Button>
                    )}
                </div>
                {skuSelected && (
                    <Button
                        data-testid='choose-sku-remove-btn'
                        variant='anchor'
                        onClick={this.onClear}
                        style={{ textDecoration: 'underline', fontWeight: 'bold', margin: '4px' }}>
                        Change Product Info
                    </Button>
                )}
                {message && <div className='link__error'>{message}</div>}
            </div>
        );
    }
}

SkuInput.propTypes = {
    required: PropTypes.bool,
    sku: PropTypes.string,
    skuVersion: PropTypes.number,
    merchantSku: PropTypes.string,
    merchantVersion: PropTypes.number,
    allSkus: PropTypes.objectOf(
        PropTypes.shape({
            fetching: PropTypes.bool.isRequired,
            doesNotExist: PropTypes.bool.isRequired,
            noLinks: PropTypes.bool.isRequired,
            loadingLinks: PropTypes.bool,
            productName: PropTypes.string,
            versions: PropTypes.arrayOf(
                PropTypes.shape({
                    productId: PropTypes.string,
                    version: PropTypes.number,
                    status: PropTypes.string,
                    current: PropTypes.bool,
                }),
            ),
        }),
    ).isRequired,
    onChangeProduct: PropTypes.func.isRequired,
    onChangeVersion: PropTypes.func.isRequired,
    onClearProduct: PropTypes.func.isRequired,
    onValidateSku: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isReady: PropTypes.bool,
    hasNoVersions: PropTypes.bool,
};
