import { XMLTree_Metadata, XMLTree_MetadataMetadataItem } from 'src/types/xml';
import { toArray } from 'src/util/converter';

/* eslint-disable no-underscore-dangle */
export default function xmlToMetadataObject(metadata: XMLTree_Metadata) {
    const metadataObject: Record<string, any>[] = [];

    if (typeof metadata === 'string') {
        return metadataObject;
    }

    const metadataItems: XMLTree_MetadataMetadataItem[] = toArray(metadata.MetadataItem);
    metadataItems.forEach((item) => {
        let data = item.__text;
        try {
            // try parsing data into json
            data = JSON.parse(data);
        } catch {
            // nothing, leave data as string
        }

        metadataObject.push({ type: item._type, data });
    });

    return metadataObject;
}
