import { createSlice } from '@reduxjs/toolkit';
import undoable from 'redux-undo';

import { removeById } from 'src/util/state';
import { ALL_DOCUMENTS, blendingModeChange, layerToggleHide } from 'src/store/actions/actionTypes';
import { DOCUMENT } from 'src/pages/editor/components/layer/layerTypes';
import { MULTIPLY } from 'src/models/blendingModes';
import { TUBE_TRANSFORM } from 'src/models/transforms';
import { HORIZONTAL } from 'src/pages/editor/components/layer/orientation/orientations';
import { RECTANGLE_WARP, PERSPECTIVE_WARP, CYLINDER_WARP, SMOOTH_WARP, TEXTURE_WARP } from 'src/models/documentWarps';
import {
    generateRectangleTransform,
    generatePerspectiveTransform,
    generateTubeTransform,
    generateSmoothTransform,
    generateTextureTransform,
} from 'src/util/transformGenerators';
import { getAngleFromDiameterAndWidth, getWidthFromDiameterAndAngle } from 'src/util/math';
import { getDocumentNextId, getTransformNextId } from 'src/util/idGeneratorAtor';
import { backgroundUploaded } from '../backgrounds/slice';
import { CylinderType, TransformTypes } from 'src/types/transforms';

type InputTransform = {
    id: string;
    warpType: string;
    width: number;
    height: number;
    size?: number;
};

export type Document = {
    page: number;
    mode: string;
    warpType: string;
    id: string;
    selectedPoint?: number;
    rotationAngle?: number;
    automask?: boolean;
    isProtected?: boolean;
    transforms: TransformTypes[];
    hidden?: boolean;
    maskUploading?: boolean;
    maskUrl?: string;
    maskHidden?: boolean;
    maskName?: string;
    cylinder?: CylinderType;
    width?: number;
    height?: number;
    textureName?: string;
    textureUploading?: boolean;
    isEngraving?: boolean;
    engravingColor?: string;
};

export type DocumentSliceState = Document[];

export const ENGRAVING_COLOR = '#808080';

const getTransforms = ({ warpType, width, height, size, id }: InputTransform) => {
    switch (warpType) {
        case RECTANGLE_WARP:
            return [generateRectangleTransform({ width, height, id })];
        case PERSPECTIVE_WARP:
            return [generatePerspectiveTransform({ width, height, id })];
        case CYLINDER_WARP:
            return [
                generatePerspectiveTransform({ width, height, id: `${id}-perspective` }),
                generateTubeTransform({ width, height, id: `${id}-tube` }),
            ];
        case SMOOTH_WARP:
            return [generateSmoothTransform({ width, height, size: size!, id })];
        case TEXTURE_WARP:
            return [generateTextureTransform({ width, height, id })];
        default:
            throw new Error('The warpType should be specified for a given document!');
    }
};

const initialState: DocumentSliceState = [];

const slice = createSlice({
    name: 'documents',
    initialState,

    reducers: {
        addDocument: (state, { payload }) => {
            const { width, height, id } = payload;
            const nextId = getDocumentNextId(state);
            const nextTransformId = getTransformNextId(nextId);
            state.push({
                page: 1,
                mode: MULTIPLY,
                warpType: RECTANGLE_WARP,
                transforms: getTransforms({ warpType: RECTANGLE_WARP, width, height, id: nextTransformId }),
                id: id || nextId,
                selectedPoint: 1,
                rotationAngle: 0,
                automask: false,
                isProtected: false,
            });
        },
        removeDocument: removeById,
        uploadMask: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload.id);
            if (documentMatch) {
                documentMatch.maskUploading = true;
            }
        },
        duplicateDocument: (state, { payload }) => {
            const currentDocument = state.find((each) => each.id === payload)!;
            const documentId = `${payload}_${state.length}`;
            const duplicatedDocument = {
                ...currentDocument,
                id: documentId,
                transforms: currentDocument.transforms.map((eachTransform) => ({
                    ...eachTransform,
                    id: `${eachTransform.id}_${documentId}`,
                })),
            };
            state.push(duplicatedDocument);
        },
        maskUploaded: (state, { payload }) => {
            const { id, url, fileName } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.maskUrl = url;
                documentMatch.maskName = fileName;
                documentMatch.maskUploading = false;
            }
        },
        removeMask: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload);
            if (documentMatch) {
                documentMatch.maskUrl = undefined;
                documentMatch.maskUploading = false;
                documentMatch.maskName = undefined;
                documentMatch.maskHidden = false;
            }
        },
        hideMask: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload);
            if (documentMatch) {
                documentMatch.maskHidden = !documentMatch.maskHidden;
            }
        },
        changePage: (state, { payload }) => {
            const { id, page } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.page = page;
            }
        },
        setWarp: (state, { payload }) => {
            const { id, warpType, width, height, size } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                const nextTransformId = getTransformNextId(documentMatch.id);
                const oldWarp = documentMatch.warpType;
                const oldTransform = documentMatch.transforms[0];
                let transforms;
                if ((oldWarp === RECTANGLE_WARP || oldWarp === SMOOTH_WARP) && warpType === PERSPECTIVE_WARP) {
                    const topLeftPoint = oldTransform.points[0];
                    const bottomRightPoint = oldTransform.points[oldTransform.points.length - 1];
                    transforms = [
                        generatePerspectiveTransform({
                            width,
                            height,
                            x1: topLeftPoint.x,
                            y1: topLeftPoint.y,
                            x2: bottomRightPoint.x,
                            y2: bottomRightPoint.y,
                            id: nextTransformId,
                        }),
                    ];
                } else if (
                    [RECTANGLE_WARP, SMOOTH_WARP, PERSPECTIVE_WARP].includes(oldWarp) &&
                    warpType === SMOOTH_WARP
                ) {
                    const topLeftPoint = oldTransform.points[0];
                    const bottomRight =
                        oldWarp === PERSPECTIVE_WARP
                            ? oldTransform.points[2]
                            : oldTransform.points[oldTransform.points.length - 1];
                    const currentWidth = bottomRight.x - topLeftPoint.x;
                    const currentHeight = bottomRight.y - topLeftPoint.y;
                    transforms = [
                        generateSmoothTransform({
                            width: currentWidth,
                            height: currentHeight,
                            size,
                            id: nextTransformId,
                            startingX: topLeftPoint.x,
                            startingY: topLeftPoint.y,
                        }),
                    ];
                } else if (oldWarp === SMOOTH_WARP && warpType === RECTANGLE_WARP) {
                    const topLeftPoint = oldTransform.points[0];
                    const bottomRight = oldTransform.points[oldTransform.points.length - 1];
                    transforms = [
                        generateRectangleTransform({
                            width,
                            height,
                            x1: topLeftPoint.x,
                            y1: topLeftPoint.y,
                            x2: bottomRight.x,
                            y2: bottomRight.y,
                            id: nextTransformId,
                        }),
                    ];
                } else if (oldWarp === PERSPECTIVE_WARP && warpType === RECTANGLE_WARP) {
                    transforms = [
                        generateRectangleTransform({
                            width,
                            height,
                            x1: oldTransform.points[0].x,
                            y1: oldTransform.points[0].y,
                            x2: oldTransform.points[2].x,
                            y2: oldTransform.points[2].y,
                            id: nextTransformId,
                        }),
                    ];
                } else {
                    transforms = getTransforms({ warpType, width, height, size, id: nextTransformId });
                }
                if (warpType !== CYLINDER_WARP) {
                    documentMatch.cylinder = undefined;
                }
                documentMatch.width = undefined;
                documentMatch.height = undefined;
                documentMatch.transforms = transforms;
                documentMatch.warpType = warpType;
                documentMatch.selectedPoint = 1;
                documentMatch.textureName = undefined;

                if (warpType === TEXTURE_WARP) {
                    documentMatch.width = width;
                    documentMatch.height = height;
                }
            }
        },
        editTransform: (state, { payload }: { payload: { id: string; transforms: TransformTypes[] } }) => {
            const { id, transforms } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.transforms.forEach((transform, index) => {
                    const payloadTransform = transforms.find((p) => p.id === transform.id);
                    if (payloadTransform) {
                        documentMatch.transforms[index] = payloadTransform;
                    }
                });
            }
        },
        replaceTransforms: (state, { payload }) => {
            const { id, transforms, width, height } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.transforms = transforms;
                documentMatch.width = width;
                documentMatch.height = height;
            }
        },
        dragTransformPoint: (state, { payload }) => {
            const { id, points } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.transforms.forEach((transform) => {
                    transform.points.forEach((point, index) => {
                        Object.assign(point, points[index]);
                    });
                });
            }
        },
        editTransformPoint: (state, { payload }) => {
            const { id, point, index, transformId } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            const targetTransform = documentMatch?.transforms.find((transform) => transform.id === transformId);
            if (targetTransform) {
                Object.assign(targetTransform.points[index], point);
            }
        },
        selectTransformPoint: (state, { payload }) => {
            const { id, point } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch && documentMatch.transforms[0].points.length >= point) {
                documentMatch.selectedPoint = point;
            }
        },
        updateCylinder: (state, { payload }) => {
            const { id, cylinder } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.cylinder = cylinder;
            }
        },
        rotateCylinder: (state, { payload }) => {
            const { id, orientation } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch && documentMatch.cylinder) {
                const isDecorationRotated = orientation === HORIZONTAL;
                documentMatch.cylinder.isDecorationRotated = isDecorationRotated;
            }
        },
        toggleFullWrap: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload);
            if (documentMatch && documentMatch.cylinder) {
                const { fullWrap, productDiameter } = documentMatch.cylinder;
                const decorationExtentDegreesBeta = fullWrap ? 90 : 360;
                const newDecoSize = getWidthFromDiameterAndAngle(productDiameter, decorationExtentDegreesBeta);

                documentMatch.cylinder.fullWrap = !fullWrap;
                documentMatch.cylinder.decorationExtentDegreesBeta = decorationExtentDegreesBeta;
                documentMatch.cylinder.decorationAreaDimensions = { width: newDecoSize, height: newDecoSize };
            }
        },
        updatePrecisionWrap: (state, { payload }) => {
            const { id, width, diameter } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch && documentMatch.cylinder) {
                documentMatch.cylinder.decorationAreaDimensions = { width, height: width };
                documentMatch.cylinder.productDiameter = diameter;
                if (width > 0 && diameter > 0) {
                    documentMatch.cylinder.decorationExtentDegreesBeta = getAngleFromDiameterAndWidth(diameter, width);
                }
            }
        },
        setRotationAngle: (state, { payload }) => {
            const { id, angle } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.rotationAngle = angle;
            }
        },
        uploadTexture: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload.id);
            if (documentMatch) {
                documentMatch.textureUploading = true;
            }
        },
        textureUploaded: (state, { payload }) => {
            const { id, url, fileName } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.textureName = fileName;
                documentMatch.textureUploading = false;
                documentMatch.transforms = [
                    generateTextureTransform({
                        width: documentMatch.width!,
                        height: documentMatch.height!,
                        id: fileName,
                        src: url,
                    }),
                ];
            }
        },
        removeTexture: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload);
            if (documentMatch) {
                documentMatch.textureName = undefined;
                documentMatch.textureUploading = false;
                documentMatch.transforms = [
                    generateTextureTransform({ width: documentMatch.width!, height: documentMatch.height! }),
                ];
            }
        },
        toggleEngraving: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload);
            if (documentMatch) {
                documentMatch.isEngraving = !documentMatch.isEngraving;
                documentMatch.engravingColor = documentMatch.engravingColor || ENGRAVING_COLOR;
            }
        },
        changeEngravingColor: (state, { payload }) => {
            const documentMatch = state.find((doc) => doc.id === payload.id);
            if (documentMatch) {
                documentMatch.engravingColor = payload.color || ENGRAVING_COLOR;
            }
        },
        toggleAutomask: (state, { payload }) => {
            if (payload === ALL_DOCUMENTS) {
                state.forEach((doc) => {
                    doc.automask = false;
                });
            } else {
                const documentMatch = state.find((doc) => doc.id === payload);
                if (documentMatch) {
                    documentMatch.automask = !documentMatch.automask;
                }
            }
        },
        cleanDisabledDocuments: (state) => {
            state.forEach((doc) => {
                doc.isProtected = false;
            });
        },
        disabledEditDocument: (state, { payload }) => {
            const docs = state.find((doc) => doc.id === payload);
            if (docs) {
                docs.isProtected = true;
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase(blendingModeChange, (state, { payload }) => {
            const { id, type, mode } = payload;
            if (type === DOCUMENT) {
                const documentMatch = state.find((doc) => doc.id === id);
                if (documentMatch) {
                    documentMatch.mode = mode;
                }
            }
        });

        builder.addCase(layerToggleHide, (state, { payload }) => {
            const { id, type } = payload;
            if (type === DOCUMENT) {
                const documentMatch = state.find((doc) => doc.id === id);
                if (documentMatch) {
                    documentMatch.hidden = !documentMatch.hidden;
                }
            }
        });

        // TODO: is this working? Background ID is being used for the document ID.
        builder.addCase(backgroundUploaded, (state, { payload }) => {
            const { id, width, height } = payload;
            const documentMatch = state.find((doc) => doc.id === id);
            if (documentMatch) {
                documentMatch.transforms.forEach((transform) => {
                    if (transform.type !== TUBE_TRANSFORM) {
                        // Tube transforms should not be resized
                        // @ts-expect-error -- rectangle and other transforms don't have width and height, we check if this code is working at all
                        transform.width = width;
                        // @ts-expect-error -- rectangle and other transforms don't have width and height, we check if this code is working at all
                        transform.height = height;
                    }
                });
            }
        });
    },
});

export const reducer = undoable(slice.reducer, {
    limit: 17, // set a limit for the size of the history to 15. First action is avoid it and 17 is not inclusive.
});

export const addDocument = slice.actions.addDocument;
export const removeDocument = slice.actions.removeDocument;
export const duplicateDocument = slice.actions.duplicateDocument;
export const uploadMask = slice.actions.uploadMask;
export const maskUploaded = slice.actions.maskUploaded;
export const removeMask = slice.actions.removeMask;
export const hideMask = slice.actions.hideMask;
export const changePage = slice.actions.changePage;
export const setWarp = slice.actions.setWarp;
export const editTransform = slice.actions.editTransform;
export const replaceTransforms = slice.actions.replaceTransforms;
export const dragTransformPoint = slice.actions.dragTransformPoint;
export const editTransformPoint = slice.actions.editTransformPoint;
export const selectTransformPoint = slice.actions.selectTransformPoint;
export const updateCylinder = slice.actions.updateCylinder;
export const rotateCylinder = slice.actions.rotateCylinder;
export const toggleFullWrap = slice.actions.toggleFullWrap;
export const updatePrecisionWrap = slice.actions.updatePrecisionWrap;
export const setRotationAngle = slice.actions.setRotationAngle;
export const uploadTexture = slice.actions.uploadTexture;
export const textureUploaded = slice.actions.textureUploaded;
export const removeTexture = slice.actions.removeTexture;
export const toggleEngraving = slice.actions.toggleEngraving;
export const changeEngravingColor = slice.actions.changeEngravingColor;
export const toggleAutomask = slice.actions.toggleAutomask;
export const disabledEditDocument = slice.actions.disabledEditDocument;
export const cleanDisabledDocuments = slice.actions.cleanDisabledDocuments;
