import { Button, Modal } from '@cimpress/react-components';
import PropTypes from 'prop-types';
import React from 'react';

function ConfirmModal({ showModal, onConfirm, children, title = 'Are you sure?', status = 'info' }) {
    return (
        <Modal className='confirm-modal-scene-maker' show={showModal} title={title}>
            <div className='confirm-modal-scene-maker__body'>{children}</div>
            <div className='confirm-modal-scene-maker__footer'>
                <Button variant='primary' className={`btn-${status}`} onClick={() => onConfirm(true)}>
                    Continue
                </Button>
                <Button onClick={() => onConfirm(false)}>Cancel</Button>
            </div>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    title: PropTypes.string,
    status: PropTypes.oneOf(['success', 'info', 'danger', 'warning']),
    showModal: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default ConfirmModal;
