import { Button, Modal } from '@cimpress/react-components';
import React, { useState } from 'react';
import {
    RECTANGLE_WARP,
    PERSPECTIVE_WARP,
    CYLINDER_WARP,
    SMOOTH_WARP,
    TEXTURE_WARP,
    type DocumentWarp,
} from 'src/models/documentWarps';

const getIconName = (iconType: string) => {
    switch (iconType) {
        case RECTANGLE_WARP:
            return 'https://static.ux.cimpress.io/assets/icons/design-warp-rectangle-l.svg';
        case PERSPECTIVE_WARP:
            return 'https://static.ux.cimpress.io/assets/icons/design-warp-perspective-l.svg';
        case CYLINDER_WARP:
            return 'https://static.ux.cimpress.io/assets/icons/design-warp-cylinder-l.svg';
        case SMOOTH_WARP:
            return 'https://static.ux.cimpress.io/assets/icons/design-warp-perspective-l.svg';
        case TEXTURE_WARP:
            return '/images/textureWarp.svg';
        default:
            return '';
    }
};

const smoothWarpSizes = [4, 5, 6];

type WarpProps = {
    selected?: DocumentWarp;
    warps: { value: DocumentWarp; label: string }[];
    onClick: (value: DocumentWarp, width: number, height: number, size?: number) => void;
    width?: number;
    height?: number;
    size?: number;
    isDefault?: boolean;
};

const Warp: React.FC<WarpProps> = ({ width, height, warps, selected, size, isDefault, onClick }) => {
    const [openWarning, setOpenWarning] = useState(false);
    const [smoothWarpSize, setSmoothWarpSize] = useState(4);

    const options = warps.map((warp) => {
        const iconName = getIconName(warp.value);
        const warpSelected = warp.value === selected;
        return (
            <li
                key={warp.value}
                data-testid={`editor-warps-${warp.value}`}
                className={`select-warp__option ${warpSelected ? 'select-warp__option--selected' : ''}`}
                onClick={() => onClick(warp.value, width!, height!)}>
                <div className='select-warp__icon'>
                    <img className={`iconLg ${warpSelected ? 'colorWhite' : ''}`} src={iconName} />
                </div>
                <p className='select-warp__label'>{warp.label}</p>
            </li>
        );
    });

    const smoothWarpDimension = smoothWarpSizes.map((warpSize) => {
        const sizeSelected = size === warpSize;
        return (
            <li
                className={`select-warp-size__option ${sizeSelected ? 'select-warp-size__option--selected' : ''}`}
                key={warpSize}
                onClick={() => {
                    if (!isDefault) {
                        setOpenWarning(true);
                        setSmoothWarpSize(warpSize);
                    } else {
                        setSmoothWarpSize(warpSize);
                        onClick(selected!, width!, height!, warpSize);
                    }
                }}>
                <p className='select-warp-size__label'>{`${warpSize} x ${warpSize}`}</p>
            </li>
        );
    });

    return (
        <div className='select-warp'>
            <div className='select-warp__title'>Choose surface warp type</div>
            <ul className='select-warp__options'>{options}</ul>
            {selected === SMOOTH_WARP && (
                <>
                    <div className='select-warp-size__title'>Choose smooth warp dimension</div>
                    <ul className='select-warp__options'>{smoothWarpDimension}</ul>
                </>
            )}
            <Modal
                show={openWarning}
                onRequestHide={() => setOpenWarning(false)}
                closeButton={true}
                title='Changing Smooth Warp Dimension'
                status='warning'>
                <div style={{ marginBottom: '20px' }}>
                    Your smooth warp design will not be preserved when switching to new dimensions. Do you still want to
                    switch to a new dimension?
                </div>
                <Button onClick={() => setOpenWarning(false)} style={{ marginRight: '10px' }}>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    onClick={() => {
                        onClick(selected!, width!, height!, smoothWarpSize);
                        setOpenWarning(false);
                    }}>
                    Yes
                </Button>
            </Modal>
        </div>
    );
};

export default Warp;
