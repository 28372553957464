import createCachedSelector from 're-reselect';

import getAllOverlays from './getAllOverlays';
import { RootState } from 'src/store';

const getId = (state: RootState, id: string) => id;

const getOverlayById = createCachedSelector([getAllOverlays, getId], (overlays, id) =>
    overlays.find((overlay) => overlay.id === id),
)((state, overlayId) => overlayId);

export default getOverlayById;
