import React, { Component } from 'react';

import idGeneratorAtor from 'src/util/idGeneratorAtor';
import FileUpload from './FileUpload';

const idGenerator = idGeneratorAtor('uploadIcon');

export interface UploadIconProps {
    testid?: string;
    accept?: string;
    className?: string;
    children?: React.ReactNode;
    multiple?: boolean;
    onUpload: React.ChangeEventHandler<HTMLInputElement>;
    onGenerate?: () => void;
}

export default class UploadIcon extends Component<UploadIconProps> {
    state = {
        uniqueId: idGenerator().replace(' ', ''),
    };

    render() {
        const {
            children,
            onUpload,
            className,
            multiple,
            testid,
            accept = 'image/png, image/jpeg, image/bmp',
        } = this.props;
        return (
            <button className={`upload-icon${className ? ` ${className}` : ''}`}>
                <label
                    htmlFor={this.state.uniqueId}
                    className='upload-icon__label'
                    onClick={(e) => e.stopPropagation()}>
                    {children || (
                        <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/add-2-l.svg' />
                    )}
                </label>
                <FileUpload
                    id={this.state.uniqueId}
                    testid={testid}
                    className='hidden-input'
                    onChange={onUpload}
                    onClick={(e) => e.stopPropagation()}
                    accept={accept}
                    multiple={multiple}
                />
            </button>
        );
    }
}
