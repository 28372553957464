import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import InputColor from 'react-input-color';
import { connect } from 'react-redux';
import { Select, Accordion, Button } from '@cimpress/react-components';
import { toSelectOption, getHexColor } from 'src/util/misc';
import { getAllBackgrounds } from 'src/selectors/layers';
import {
    ASSET_ACTIONS_OPTIONS,
    BLENDING_MODES_OPTIONS,
    PRODUCT_VARIABLE_TYPES,
    PRODUCT_VARIABLE_TYPES_OPTIONS,
    ASSET_ACTIONS,
} from './constants';
import UploadFile from './UploadFile';
import { showAssetRemovalModal } from '../backgrounds/slice';
import AssetRemovalContainer from './assetRemoveModal';
import { downloadImagesToFileSystem } from 'src/util/upload';

const DEFAULTOPTIONS = [{ value: 'Use Default', label: 'Use Default' }];
const actionOptions = DEFAULTOPTIONS.concat(ASSET_ACTIONS_OPTIONS);

const EachProductVariable = (props) => {
    const { configuration } = props;
    const isRequiredValuesChosen = (variableAttribute, variableOption, layer) => {
        if (!variableAttribute) {
            return false;
        }
        if (variableOption === PRODUCT_VARIABLE_TYPES.product && !layer) {
            return false;
        }
        return true;
    };
    const [nextStage, setNextStage] = useState(
        isRequiredValuesChosen(configuration.variableAttribute, configuration.variableOption, configuration.layer),
    );
    const options = useMemo(() => {
        if (!configuration.variableOption) {
            return [];
        }
        return toSelectOption(props.attributesOptions);
    }, [configuration.variableOption, props.attributesOptions]);
    const onOptionsSelect = (key, newValue) => {
        props.updateConfiguration({
            newConfiguration: {
                ...configuration,
                [key]: newValue,
            },
            index: props.index,
        });
    };
    const onVariableOptionChange = (chosenVariableOption) => {
        props.updateConfiguration({
            newConfiguration: {
                ...configuration,
                variableOption: chosenVariableOption.value,
                layer: null,
                document: null,
            },
            index: props.index,
        });
    };
    const allLayers = useMemo(() => {
        if (!configuration.variableAttribute || !configuration.variableOption) {
            return [];
        }
        return props.assetOptions;
    }, [configuration.variableAttribute, configuration.variableOption, props.assetOptions]);
    const documentOptions = useMemo(() => {
        if (!configuration.variableAttribute || !configuration.variableOption) {
            return [];
        }
        return props.getDocumentOptions(configuration.variableAttribute);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuration.variableAttribute, configuration.variableOption, props.getDocumentOptions]);
    const getSelectedVariableAttributeOptions = () => {
        let attributeDetails;
        // Try getting values from options if has version, otherwise try ruleset information
        if (props.productDetails.options && props.productDetails.options.length > 0) {
            attributeDetails =
                props.productDetails.options &&
                props.productDetails.options.find((each) => each.name === configuration.variableAttribute);
        } else {
            attributeDetails =
                props.productDetails.ruleSet &&
                props.productDetails.ruleSet.whiteList &&
                props.productDetails.ruleSet.whiteList.find(
                    (each) => each.attributeKey === configuration.variableAttribute,
                );
        }

        if (!attributeDetails) {
            return [];
        }

        if (attributeDetails.unitOfMeasure === 'hexcode') {
            return attributeDetails.values.map((item) => {
                if (item.range && item.range.minimum && !Number.isNaN(item.range.minimum)) {
                    return { value: `#${Number.parseInt(item.range.minimum).toString(16)}` };
                }
                if (item.value) {
                    const parsedNum = Number.parseInt(item.value);
                    if (!Number.isNaN(parsedNum)) {
                        return { value: `#${parsedNum.toString(16)}` };
                    }
                }
                return { value: item.value || item };
            });
        }

        return attributeDetails.values || attributeDetails.attributeValues;
    };
    const canGoToNextStep = useMemo(() => {
        const { variableAttribute, variableOption, layer } = configuration;
        return isRequiredValuesChosen(variableAttribute, variableOption, layer);
    }, [configuration]);
    const continueNextStep = () => {
        if (canGoToNextStep) {
            setNextStage(true);
            const defaultValues = {};
            getSelectedVariableAttributeOptions().forEach((eachOption) => {
                defaultValues[eachOption.value] =
                    configuration.variableOption === PRODUCT_VARIABLE_TYPES.product
                        ? { action: 'Use Default' }
                        : 'Normal';
            });
            props.updateConfiguration({
                newConfiguration: {
                    ...configuration,
                    values: defaultValues,
                },
                index: props.index,
            });
        }
    };
    const deleteConfiguration = () => {
        props.removeConfiguration(props.index);
    };

    const onDownload = () => {
        const files = Object.keys(props.configuration.values)
            .map((key) => props.configuration.values[key])
            .filter((confgurationValue) => confgurationValue.value && confgurationValue.value.imageUrl)
            .map((confgurationValue) => ({
                url: confgurationValue.value.imageUrl,
                fileName: confgurationValue.value.name,
            }));

        downloadImagesToFileSystem(
            files,
            `${props.configuration.variableOption}-${props.configuration.variableAttribute}`,
        );
    };

    const getLayerTitle = () => {
        const { variableAttribute, variableOption, layer, document } = configuration;
        let text = 'Please configure attribute';
        if (nextStage && variableAttribute && variableOption) {
            text = `${variableOption} - ${variableAttribute}`;
            if (layer) {
                text += ` - ${layer.label}`;
            } else if (document) {
                text += ` - ${document}`;
            }
        }
        return (
            <div className='layers-header'>
                <div className='layers-header__text truncate_label' title={text}>
                    {text}
                </div>
                <button onClick={onDownload} style={{ marginRight: 8 }}>
                    <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/download-3-l.svg' />
                </button>
                <button onClick={deleteConfiguration}>
                    <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/bin-1-f.svg' />
                </button>
            </div>
        );
    };
    const onActionChange = (valueKey, chosenOption) => {
        props.updateConfiguration({
            newConfiguration: {
                ...configuration,
                values: {
                    ...configuration.values,
                    [valueKey]: {
                        action: chosenOption.value,
                    },
                },
            },
            index: props.index,
        });
    };
    const onBlendingModeChange = (valueKey, chosenOption) => {
        props.updateConfiguration({
            newConfiguration: {
                ...configuration,
                values: {
                    ...configuration.values,
                    [valueKey]: chosenOption.value,
                },
            },
            index: props.index,
        });
    };

    const onValueChange = (valueKey, value) => {
        let details = { ...configuration.values[valueKey] };
        if (!value) {
            details = { action: 'Use Default' };
        } else {
            details.value = value;
        }
        props.updateConfiguration({
            newConfiguration: {
                ...configuration,
                values: {
                    ...configuration.values,
                    [valueKey]: details,
                },
            },
            index: props.index,
        });
    };

    const onRemoveBackground = (valueKey) => {
        const id = `${configuration.variableOption};${configuration.variableAttribute};${valueKey}`;
        props.showModal(id);
    };

    const layerDimensions = useMemo(() => {
        if (configuration.layer) {
            const theLayerDetails = props.backgroundLayers.find((each) => each.id === configuration.layer.value);
            if (theLayerDetails) {
                return {
                    width: theLayerDetails.width,
                    height: theLayerDetails.height,
                };
            }
            return {};
        }
        return {};
    }, [configuration.layer, props.backgroundLayers]);

    return (
        <Accordion className='product-variable-options-list' defaultOpen={true} title={getLayerTitle()}>
            <div className='accordion-body-wrapper'>
                {!nextStage && (
                    <>
                        <Select
                            options={PRODUCT_VARIABLE_TYPES_OPTIONS}
                            value={toSelectOption(configuration.variableOption)}
                            onChange={onVariableOptionChange}
                            label='Select Variable Type'
                        />
                        <Select
                            options={options}
                            value={toSelectOption(configuration.variableAttribute)}
                            onChange={(chosenOption) => onOptionsSelect('variableAttribute', chosenOption.value)}
                            isClearable={false}
                            isSearchable={false}
                            label='Select Attribute'
                            menuPortalTarget={document.body}
                        />
                        {configuration.variableOption === PRODUCT_VARIABLE_TYPES.product && (
                            <Select
                                options={allLayers}
                                value={configuration.layer}
                                onChange={(chosenOption) => onOptionsSelect('layer', chosenOption)}
                                isClearable={false}
                                isSearchable={false}
                                label='Select Layer'
                                menuPortalTarget={document.body}
                            />
                        )}
                        {configuration.variableOption === PRODUCT_VARIABLE_TYPES.blending && (
                            <Select
                                options={documentOptions}
                                value={toSelectOption(configuration.document)}
                                onChange={(chosenOption) => onOptionsSelect('document', chosenOption.value)}
                                isClearable={false}
                                isSearchable={false}
                                label='Select Document'
                                menuPortalTarget={document.body}
                            />
                        )}
                        <Button onClick={continueNextStep}>OK</Button>
                    </>
                )}
                {nextStage &&
                    Object.keys(configuration.values || {}).map((eachValueKey) => (
                        <div key={eachValueKey} className='attribute-value-row'>
                            <div className='attribute-value-option'>
                                {eachValueKey}
                                {eachValueKey.startsWith('#') && (
                                    <div className='color' style={{ background: eachValueKey }} />
                                )}
                            </div>
                            {configuration.variableOption === PRODUCT_VARIABLE_TYPES.blending && (
                                <Select
                                    options={BLENDING_MODES_OPTIONS}
                                    label='Select'
                                    value={toSelectOption(configuration.values[eachValueKey])}
                                    onChange={(chosenOption) => onBlendingModeChange(eachValueKey, chosenOption)}
                                />
                            )}
                            {configuration.variableOption === PRODUCT_VARIABLE_TYPES.product && (
                                <>
                                    <Select
                                        options={actionOptions}
                                        label='Select'
                                        value={toSelectOption(configuration.values[eachValueKey].action)}
                                        onChange={(chosenOption) => onActionChange(eachValueKey, chosenOption)}
                                        menuPortalTarget={document.body}
                                    />
                                    {configuration.values[eachValueKey].action === ASSET_ACTIONS.REPLACE && (
                                        <UploadFile
                                            onChange={(fileDetails) => onValueChange(eachValueKey, fileDetails)}
                                            onBgRemove={() => onRemoveBackground(eachValueKey)}
                                            file={configuration.values[eachValueKey].value}
                                            {...layerDimensions}
                                        />
                                    )}
                                    {configuration.values[eachValueKey].action === ASSET_ACTIONS.FILL && (
                                        <InputColor
                                            initialValue={configuration.values[eachValueKey].value || '#808080'}
                                            onChange={(evt) => onValueChange(eachValueKey, getHexColor(evt.hex))}
                                            placement='top'
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    ))}
            </div>
            <AssetRemovalContainer />
        </Accordion>
    );
};

const optionShape = PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
});

EachProductVariable.propTypes = {
    productDetails: PropTypes.shape({
        ruleSet: PropTypes.any,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                values: PropTypes.array,
            }),
        ),
    }),
    configuration: PropTypes.shape({
        layer: PropTypes.shape(optionShape),
        variableAttribute: PropTypes.string,
        variableOption: PropTypes.string,
        document: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.any),
    }),
    updateConfiguration: PropTypes.func.isRequired,
    removeConfiguration: PropTypes.func.isRequired,
    assetOptions: PropTypes.arrayOf(optionShape),
    getDocumentOptions: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    attributesOptions: PropTypes.arrayOf(PropTypes.string),
    backgroundLayers: PropTypes.array,
};

function mapStateToProps(state) {
    const backgroundLayers = getAllBackgrounds(state);

    return {
        backgroundLayers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        showModal: (id) => dispatch(showAssetRemovalModal(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EachProductVariable);
