import * as React from 'react';
import { Alert, Spinner, TextField } from '@rendering/components';
import PSDImportItem from './item';
import { LayerSelected, ResolutionType } from './types';

interface IPSDImportContentProps {
    layers: LayerSelected[];
    resolution: ResolutionType;
    selectedLayers: LayerSelected[];
    onLayerSelected: (item: LayerSelected) => void;
    onChangeInput: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const PSDImportContent: React.FunctionComponent<IPSDImportContentProps> = ({
    layers,
    resolution,
    selectedLayers,
    onLayerSelected,
    onChangeInput,
}) => {
    const onSelectedItem = (id: string) => {
        const existItem = selectedLayers.find((i) => i.id === id);
        if (!existItem) {
            const item = layers.find((i) => i.id === id);
            if (item) {
                onLayerSelected(item);
            }
        } else {
            onLayerSelected(existItem);
        }
    };
    const isWaiting = React.useMemo(() => {
        if (layers.length === 0) {
            return (
                <div>
                    <Spinner size='large' />
                    <p>We are getting the assets from your PSD file. Please wait!</p>
                </div>
            );
        }

        return null;
    }, [layers]);

    return (
        <div className='import-psd-modal__content'>
            {isWaiting}
            <Alert
                status='warning'
                message='All the images will be uploaded with one unique resolution, you can change that resolution to your need down below.'
            />
            <h3>Resolution</h3>
            <div className='import-psd-modal__size'>
                <TextField type='number' label='Width' name='width' value={resolution.width} onChange={onChangeInput} />
                <TextField
                    type='number'
                    label='Height'
                    name='height'
                    value={resolution.height}
                    onChange={onChangeInput}
                />
            </div>
            {layers.length > 0 && <h3>Please click on the assets you want to upload</h3>}
            <div className='import-psd-modal__items'>
                {layers.map((layer) => (
                    <PSDImportItem
                        key={layer.id}
                        id={layer.id}
                        url={layer.imageUrl!}
                        name={layer.name}
                        dimension={`${layer.height}h x ${layer.width}w`}
                        selected={!!selectedLayers.find((i) => i.id === layer.id)}
                        onClick={onSelectedItem}
                    />
                ))}
            </div>
        </div>
    );
};

export default PSDImportContent;
