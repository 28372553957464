import { all, call, put, takeEvery, select, take, race } from 'redux-saga/effects';

import { uploadFailed, imageResized } from 'src/components/alert/slice';
import { getRegionSetting } from 'src/selectors';
import { SCREEN } from 'src/models/blendingModes';
import { uploadImageAndGetUrl, getImageSize, toSafeUploadName } from 'src/util/upload';
import { logError } from 'src/loggingManager';
import { uploadOverlays, overlayUploaded, removeOverlay, uploadAndReplaceOverlay, replaceOverlayFailed } from './slice';
import { showResizeModal, acceptResize, declineResize, cancelResizeModalUpload } from '../backgrounds/slice';

const BACKGROUND_WARNING_THRESHOLD = 2000;
const BACKGROUND_MAX_THRESHOLD = 6000;

function imageOverMaxDimension(height, width, maxDimension) {
    return width > maxDimension || height > maxDimension;
}

function calculateResizedOverlayDimensions(height, width, maxDimension) {
    let newHeight = height;
    let newWidth = width;
    if (height > width && height > maxDimension) {
        newHeight = maxDimension;
        newWidth = Math.round((maxDimension / height) * width);
    } else if (width > height && width > maxDimension) {
        newWidth = maxDimension;
        newHeight = Math.round((maxDimension / width) * height);
    } else if (height === width && height > maxDimension) {
        newHeight = maxDimension;
        newWidth = maxDimension;
    }

    return { height: newHeight, width: newWidth };
}

function* checkImageSize(height, width) {
    let resizeImage = false;
    let resizedTooLarge = false;
    if (imageOverMaxDimension(height, width, BACKGROUND_MAX_THRESHOLD)) {
        resizeImage = true;
        resizedTooLarge = true;
    } else if (imageOverMaxDimension(height, width, BACKGROUND_WARNING_THRESHOLD)) {
        yield put(showResizeModal());
        const { acceptedResize, declinedResize, cancel } = yield race({
            acceptedResize: take(acceptResize),
            declinedResize: take(declineResize),
            cancel: take(cancelResizeModalUpload),
        });

        if (cancel) {
            return [null, null];
        }

        if (acceptedResize) {
            resizeImage = true;
        } else if (declinedResize) {
            resizeImage = false;
        }
    }

    return [resizeImage, resizedTooLarge];
}

function* uploadOverlay(file, id) {
    let { height, width } = yield call(getImageSize, file);
    let url;

    const [resizeImage, resizedTooLarge] = yield checkImageSize(height, width);

    if (resizeImage === null) {
        // "Cancel" was clicked
        return;
    }

    try {
        const region = yield select(getRegionSetting);

        let resizeParams;
        if (resizeImage) {
            resizeParams = calculateResizedOverlayDimensions(height, width, BACKGROUND_WARNING_THRESHOLD);
            height = resizeParams.height;
            width = resizeParams.width;
        }

        url = yield call(uploadImageAndGetUrl, file, region, resizeParams);
    } catch (error) {
        yield put(uploadFailed(`Overlay: ${error}`));
        throw error;
    }

    if (resizedTooLarge) {
        yield put(imageResized());
    }

    yield put(
        overlayUploaded({
            id,
            url,
            name: toSafeUploadName(file.name),
            mode: SCREEN,
        }),
    );
}

/**
 * @param {File} payload.file
 * @param {string} payload.id
 */
function* addOverlay({ file, id }) {
    try {
        yield uploadOverlay(file, id);
    } catch (error) {
        logError(
            `unable to add overlay, there was an error while uploading/adding: ${error} ${file.name} ${file.size} ${file.type}`,
        );
        yield put(removeOverlay(id));
    }
}

/**
 * @param {File} payload.file
 * @param {string} payload.id
 */
function* replaceOverlay({ payload }) {
    const { file, id } = payload;
    try {
        yield uploadOverlay(file, id);
    } catch (error) {
        logError(
            `unable to replace overlay, there was an error while uploading/updating: ${error} ${file.name} ${file.size} ${file.type}`,
        );
        yield put(replaceOverlayFailed(id));
    }
}

/**
 * @param {[UploadedOverlay]} payload
 * @param {File} UploadedOverlay.file
 * @param {string} UploadedBackground.id
 */
function* addOverlays({ payload }) {
    return yield all(payload.map((overlay) => call(addOverlay, overlay)));
}

export default function* overlaysSagas() {
    return yield all([
        yield takeEvery(uploadOverlays, addOverlays),
        yield takeEvery(uploadAndReplaceOverlay, replaceOverlay),
    ]);
}
