import React, { Fragment, PureComponent } from 'react';

import Orientation from 'src/pages/editor/components/layer/orientation';
import FullWrap from 'src/pages/editor/components/layer/fullWrap';
import PrecisionWrap from 'src/pages/editor/components/layer/precisionWrap';
import SourcePointButton from 'src/pages/editor/components/layer/sourcePoint';
import { CYLINDER_WARP, TEXTURE_WARP } from 'src/models/documentWarps';
import Texture from 'src/pages/editor/components/layer/texture';

interface TransformInfoProps {
    id: string;
    warpType: string;
}

export default class TransformInfo extends PureComponent<TransformInfoProps> {
    render() {
        const { id, warpType } = this.props;
        return (
            <div className='transform-information-body'>
                {warpType === CYLINDER_WARP && (
                    <Fragment>
                        <Orientation id={id} />
                        <FullWrap id={id} />
                        <PrecisionWrap id={id} />
                    </Fragment>
                )}
                {warpType === TEXTURE_WARP && <Texture id={id} />}
                {warpType !== CYLINDER_WARP && warpType !== TEXTURE_WARP && (
                    <Fragment>
                        <SourcePointButton id={id} />
                    </Fragment>
                )}
            </div>
        );
    }
}
