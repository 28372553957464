import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@cimpress/react-components';
import { connect } from 'react-redux';
import { getAllSkuData } from 'src/selectors/skus';
import {
    retrieveVersionsForSku,
    retrieveProductDetails,
    cleanSceneConfiguration,
} from 'src/pages/editor/components/SceneVariation/slice';
import { VersionSelector } from '@rendering/components';
import { getSurfaceProperties } from 'src/selectors/pages/editor';
import AttributesSelection from './AttributesSelection';
import getSceneVariation from './selectors/getSceneVariation';

const SkuInput = (props) => {
    const [sku, setSku] = useState('');
    const [skuVersion, setSkuVersion] = useState(0);
    const [showConfig, setShowConfig] = useState(false);
    const { sku: sku2, skuVersion: version, merchantSku, merchantVersion } = props.surfaces;

    useEffect(() => {
        if (props.configuredSku) {
            setSku(props.configuredSku);
            setSkuVersion(props.configuredSkuVersion);
            setShowConfig(true);
        } else if (props.surfaces && props.surfaces.isReady) {
            props.retrieveProductDetails({
                sku: merchantSku || sku2,
                skuVersion: merchantVersion || version,
                cimSku: merchantSku || sku2,
                cimSkuVersion: merchantVersion || version,
            });
            setShowConfig(true);
        }
    }, [props.surfaces, props.configuredSku, props.configuredSkuVersion]);

    const onChange = (event) => {
        setSku(event.target.value.trim());
        setSkuVersion(0);
        setShowConfig(false);
    };

    const getProductVersions = () => {
        if (sku) {
            props.retrieveVersionsForSku(sku);
        }
    };

    const onChangeVersion = (newVersion) => {
        setShowConfig(false);
        setSkuVersion(newVersion);
    };

    const onReset = () => {
        setSku('');
        setSkuVersion('');
        props.reset();
    };

    const productDetails = useMemo(() => {
        let details;
        if (props.cimSkuVersion) {
            details = props.allSkus[`${props.cimSku}-${props.cimSkuVersion}`] || {};
        } else {
            details = props.allSkus[props.cimSku] || {};
        }
        return details.ruleSet || details.options ? details : null;
    }, [props.allSkus, props.cimSku, props.cimSkuVersion]);

    const versions = useMemo(() => {
        if (sku) {
            const data = (props.allSkus[sku] || props.allSkus[`${sku}-${skuVersion}`] || {}).versions || [];
            return data.map((v) => {
                const status = v.status?.toLowerCase() || '';
                const statusLabel = {
                    retired: ' (retired)',
                    error: ' (error)',
                };
                return {
                    value: `${v.version}`,
                    label: `${v.version}${v.current ? ' (current)' : ''}${statusLabel[status] ?? ''}`,
                    status,
                    isCurrent: !!v.current,
                };
            });
        }
        return [];
    }, [props.allSkus, sku, skuVersion, productDetails]);

    const btnText = useMemo(() => (sku && versions.length ? 'Load' : 'Verify'), [sku, versions]);

    const onHandleAction = (event) => {
        event.preventDefault();
        if (sku && versions.length) {
            setShowConfig(true);
            props.retrieveProductDetails({ sku, skuVersion });
        } else {
            getProductVersions();
        }
    };

    const hasData = sku && productDetails && showConfig;

    return (
        <div>
            <form className='sku-input' onSubmit={onHandleAction}>
                <TextField
                    className='choose-sku__input'
                    value={sku}
                    onChange={onChange}
                    disabled={!!props.sceneVariableId}
                    label='Product SKU'
                    type='text'
                />
                {versions && (
                    // TODO: widthUnit has to be removed from DOM attributes for this component
                    <VersionSelector
                        versions={versions}
                        value={skuVersion}
                        disabled={!!props.sceneVariableId}
                        onVersionSelected={onChangeVersion}
                    />
                )}
                <Button disabled={props.allSkus[sku]?.fetching || !!props.sceneVariableId}>{btnText}</Button>
                {hasData && !props.sceneVariableId && (
                    <div className='sku-input__extra'>
                        <Button variant='anchor' onClick={onReset}>
                            Change Product Info
                        </Button>
                    </div>
                )}
            </form>
            {hasData && <AttributesSelection productDetails={productDetails} sku={sku} skuVersion={skuVersion} />}
        </div>
    );
};

SkuInput.propTypes = {
    allSkus: PropTypes.objectOf(
        PropTypes.shape({
            fetching: PropTypes.bool.isRequired,
            doesNotExist: PropTypes.bool.isRequired,
            noLinks: PropTypes.bool.isRequired,
            loadingLinks: PropTypes.bool,
            productName: PropTypes.string,
            loadingVersions: PropTypes.bool,
            versions: PropTypes.arrayOf(
                PropTypes.shape({
                    productId: PropTypes.string,
                    version: PropTypes.number,
                    status: PropTypes.string,
                    current: PropTypes.bool,
                }),
            ),
            loadingRules: PropTypes.bool,
            requiredVariables: PropTypes.array,
            ruleSet: PropTypes.any,
        }),
    ).isRequired,
    surfaces: PropTypes.shape({
        sku: PropTypes.string,
        skuVersion: PropTypes.number,
        merchantSku: PropTypes.string,
        merchantVersion: PropTypes.number,
        variables: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
        list: PropTypes.arrayOf(PropTypes.object),
        isReady: PropTypes.bool,
        isResolved: PropTypes.bool,
    }),
    retrieveVersionsForSku: PropTypes.func.isRequired,
    retrieveProductDetails: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    configuredSku: PropTypes.string,
    configuredSkuVersion: PropTypes.number,
    cimSku: PropTypes.string,
    cimSkuVersion: PropTypes.number,
    sceneVariableId: PropTypes.string,
};

function mapStateToProps(state) {
    const skus = getAllSkuData(state);
    const {
        sku: configuredSku,
        skuVersion: configuredSkuVersion,
        cimSku,
        cimSkuVersion,
        sceneVariableId,
    } = getSceneVariation(state);

    return {
        allSkus: skus,
        surfaces: getSurfaceProperties(state),
        configuredSku,
        configuredSkuVersion,
        cimSku,
        cimSkuVersion,
        sceneVariableId,
    };
}

const skuConfiguration = connect(mapStateToProps, {
    retrieveVersionsForSku,
    retrieveProductDetails,
    reset: cleanSceneConfiguration,
})(SkuInput);

export default skuConfiguration;
