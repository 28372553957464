import { deflateRaw } from 'pako';

export function buildTransientDocument(image: string) {
    return {
        version: '2',
        document: {
            panels: [
                {
                    id: 'test',
                    name: 'test',
                    width: '1mm',
                    height: '1mm',
                    images: [
                        {
                            id: '1',
                            printUrl: image,
                            previewUrl: image,
                            position: {
                                x: '0mm',
                                y: '0mm',
                                width: '1mm',
                                height: '1mm',
                            },
                        },
                    ],
                },
            ],
        },
        fontRepositoryUrl:
            'https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published',
    };
}

export function compressDocument(cimDoc: string) {
    const deflatedContent = deflateRaw(JSON.stringify(cimDoc), { to: 'string' });
    const encodedContent = encodeURIComponent(btoa(deflatedContent));
    return encodedContent;
}

export function buildURL(compressed: string) {
    return `https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=${compressed}`;
}
