import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import uploadAndDeleteButton from 'src/components/UploadAndDeleteButton';
import { getEditorDocImage } from 'src/selectors/canvas';
import { uploadDocumentImage, removeDocumentImage } from 'src/pages/editor/components/canvas/slice';

const mapStateToProps = (state: RootState) => {
    const { image, fileName, reference } = getEditorDocImage(state);

    return {
        fileName: fileName || (reference ? 'Document Reference' : ''),
        uploaded: !!image,
        uploading: false,
        label: image ? 'Replace Image' : 'Upload Image',
        className: 'image-selector--mask',
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onUpload: (e: TODO) => {
        const file = e.target.files[0];
        if (file) {
            dispatch(uploadDocumentImage(file));
        }
    },
    onDelete: () => {
        dispatch(removeDocumentImage());
    },
});

const imageSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(uploadAndDeleteButton);

export default imageSelectorContainer;
