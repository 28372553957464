import React, { Component } from 'react';
import { Button, Modal, TextField } from '@cimpress/react-components';
import { getTextureIdentity } from 'src/services/sceneClient';

import TextureButton from './textureButtonContainer';

interface TextureProps {
    id: string;
    width?: number;
    height?: number;
}

interface TextureState {
    isOpen: boolean;
    width?: number;
    height?: number;
}

export default class Texture extends Component<TextureProps, TextureState> {
    constructor(props: TextureProps) {
        super(props);

        this.state = {
            isOpen: false,
            width: props.width,
            height: props.height,
        };
    }

    download = () => {
        const { width, height } = this.state;
        if (width == null || height == null || !Number.isFinite(width) || !Number.isFinite(height)) return;

        getTextureIdentity({ width, height }).then((identityImage) => {
            const dataUrl = URL.createObjectURL(identityImage);

            const tempIdentityElement = document.createElement('a');
            tempIdentityElement.href = dataUrl;
            tempIdentityElement.download = `textureIdentity_${width}x${height}`;
            document.body.appendChild(tempIdentityElement);
            tempIdentityElement.click();
            document.body.removeChild(tempIdentityElement);
            this.toggleModal();
        });
    };

    toggleModal = () => {
        this.setState((state) => ({ isOpen: !state.isOpen }));
    };

    render() {
        const { id } = this.props;
        const { isOpen, width, height } = this.state;
        return (
            <div>
                <Button
                    className='texture-scene-download-identity'
                    style={{ marginBottom: '10px' }}
                    onClick={this.toggleModal}>
                    Generate Identity Texture
                </Button>
                <TextureButton id={id} />
                <Modal
                    className='texture-scene-modal'
                    show={isOpen}
                    title='Generate Identity Texture'
                    closeButton={true}>
                    <div className='texture-scene-modal__body'>
                        <TextField
                            data-testid='texture_modal_width'
                            value={width}
                            onChange={(e) => this.setState({ width: e.target.valueAsNumber })}
                            label='Width'
                            type='text'
                        />
                        <TextField
                            data-testid='texture_modal_height'
                            value={height}
                            onChange={(e) => this.setState({ height: e.target.valueAsNumber })}
                            label='Height'
                            type='text'
                        />
                    </div>
                    <div className='texture-scene-modal__footer'>
                        <Button className='texture-scene-modal__footer-cancel' onClick={this.toggleModal}>
                            Cancel
                        </Button>
                        <Button
                            className='texture-scene-modal__footer-download'
                            variant='primary'
                            onClick={this.download}>
                            Download
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}
