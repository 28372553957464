import { all, call, put, takeEvery, select } from 'redux-saga/effects';

import { getRegionSetting } from 'src/selectors';
import { uploadImageAndGetSize } from 'src/util/upload';
import { uploadDocumentImage, uploadDocumentImageSuccess, uploadDocumentImageFailed } from './slice';

function* uploadImageForAllDocuments({ payload }) {
    const region = yield select(getRegionSetting);
    try {
        const { url } = yield call(uploadImageAndGetSize, payload, region);
        yield put(uploadDocumentImageSuccess({ url, fileName: payload.name }));
    } catch (error) {
        yield put(uploadDocumentImageFailed());
    }
}

export default function* canvasSaga() {
    return yield all([yield takeEvery(uploadDocumentImage, uploadImageForAllDocuments)]);
}
