import { connect } from 'react-redux';

import { getDocumentById, getOverlayById } from 'src/selectors/layers';
import { DOCUMENT, BACKGROUND, OVERLAY, LayerType } from 'src/pages/editor/components/layer/layerTypes';
import { toggleEngraving, changeEngravingColor, ENGRAVING_COLOR } from '../../documents/slice';
import advancedView from './view.jsx';
import { RootState } from 'src/store/storeManager.js';

function getLayerFromStore(state: RootState, id: string, type: LayerType) {
    switch (type) {
        case OVERLAY:
            return getOverlayById(state, id);
        case DOCUMENT:
        default:
            return getDocumentById(state, id);
    }
}

const mapStateToProps = (state: RootState, ownProps: { id: string; type: LayerType }) => {
    const { id, type } = ownProps;
    let isEngraving;
    let colorEngraving = ENGRAVING_COLOR;
    if (id && type && type !== BACKGROUND) {
        const layer = getLayerFromStore(state, id, type);
        if (layer) {
            // @ts-expect-error -- `isEngaving` is not a property of `Overlay`
            isEngraving = layer.isEngraving || false;
            // @ts-expect-error -- `engravingColor` is not a property of `Overlay`
            colorEngraving = layer.engravingColor || ENGRAVING_COLOR;
        }
    }

    return {
        isEngraving,
        colorEngraving,
    };
};

const advancedContainer = connect(mapStateToProps, {
    toggleEngraving,
    changeEngravingColor,
})(advancedView);

export default advancedContainer;
