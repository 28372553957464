import { devMode } from 'src/settings';

export function logInfo(message: string) {
    if (devMode) {
        console.log(message); // eslint-disable-line no-console
    }

    // TODO: Update or remove fully story (no longer functional and causing errors)
    // logToFullStory(message);
}

export function logError(message: string) {
    if (devMode) {
        console.error(message); // eslint-disable-line no-console
    }

    // TODO: Update or remove fully story (no longer functional and causing errors)
    // logToFullStory(message);
}
