export type LayerType =
    | typeof BACKGROUND
    | typeof DOCUMENT
    | typeof INSTRUCTION
    | typeof MASK
    | typeof OVERLAY
    | typeof RULER
    | typeof COMPOSITE;

export const BACKGROUND = 'background';
export const DOCUMENT = 'document';
export const INSTRUCTION = 'instruction';
export const MASK = 'mask';
export const OVERLAY = 'overlay';
export const RULER = 'ruler';
export const COMPOSITE = 'composite';
