import PropTypes from 'prop-types';
import React, { Component } from 'react';
import auth from 'src/auth';
import GenericSelector, { ResourceType } from '@cimpress-technology/generic-selector';
import CheckboxSelection from './checkboxSelection';

export default class VariablesV2 extends Component {
    state = {
        allVariables: [],
        displayedVariables: [],
        initialVariables: [],
    };

    changeVariables = (changedVariables, resolved, oldVariables) => {
        const { onChangeVariables, sku, skuVersion } = this.props;

        const variables = Object.assign({}, oldVariables, changedVariables);
        // If any variables have been removed, such should be deleted from the variables object
        Object.getOwnPropertyNames(changedVariables).forEach((name) => {
            if (
                changedVariables[name] === '' ||
                changedVariables[name] === null ||
                changedVariables[name] === undefined
            ) {
                delete variables[name];
            }
        });
        onChangeVariables({ sku, skuVersion, variables, resolved });
    };

    // When changing the display, we should make sure to nullify any variables that are removed.
    changeVariableDisplay = (displayedVariables) => {
        this.setState((prevState) => {
            const { variables } = this.props;
            const hiddenVariables = {};
            prevState.displayedVariables
                .filter((variable) => !displayedVariables.includes(variable))
                .forEach((variable) => (hiddenVariables[variable] = null)); // eslint-disable-line no-return-assign
            this.changeVariables(hiddenVariables, false, variables);
            const newDisplayed = prevState.allVariables.filter((i) => displayedVariables.includes(i.key));
            return { displayedVariables: newDisplayed };
        });
    };

    onChange = (compState, attributes, validationErrors) => {
        const { variables, onFailure } = this.props;

        if (validationErrors) {
            onFailure(variables);
        } else if (compState) {
            // Determine the overall resolved state (all attributes resolved)
            let resolved = true;
            const displayedAttributes = compState.attributes.filter((attribute) => attribute.isDisplayed);
            // Format variables for the legacy variable methods
            const formatedVariables = [];
            displayedAttributes.forEach((attribute) => {
                formatedVariables[attribute.key] = attribute.resolvedValue || '';
                if (!attribute.resolvedValue) {
                    resolved = false;
                }
            });
            this.changeVariables(formatedVariables, resolved, variables);
        }
    };

    onLoad = (compState) => {
        const displayedAttributes = compState.attributes.filter((attribute) => attribute.isDisplayed === true);
        this.setState({
            displayedVariables: displayedAttributes,
            initialVariables: displayedAttributes,
            allVariables: compState.attributes,
        });
    };

    render() {
        const { requiredOnlyMode, showAllMode, sku, skuVersion, isReady, variables, options = [] } = this.props;
        const { displayedVariables, initialVariables } = this.state;

        const showAddVariables = !showAllMode && !requiredOnlyMode && isReady;
        const showGenericSelector = isReady;
        const noneShownClass =
            !options || !options.length || (!showAllMode && !options.length) ? ' variables--none-shown' : '';

        // Setup each initial variable and determine its hidden state
        const requiredAttributes = initialVariables.map((i) => i.key); // Minimum set to resolve surface
        const displayedAttributes = Array.from(
            new Set([...displayedVariables.map((i) => i.key), ...Object.keys(variables).map((i) => i)]),
        ); // Current visible attributes
        const allAttributes = options.map((i) => i.name); // All the attributes coming from the product information
        const customConfiguration = {}; // Current combination of attributes to display
        displayedAttributes.forEach((attr) => {
            customConfiguration[attr] = {
                isHidden: false,
            };
            if (variables[attr]) {
                customConfiguration[attr].initialSelection = variables[attr];
            }
        });

        const productData = sku ? { productId: sku, productVersion: skuVersion } : {};

        return (
            <div className={`variables${noneShownClass}`}>
                {showGenericSelector && (
                    <div className='variables__options'>
                        <GenericSelector
                            {...productData}
                            authToken={auth.getAccessToken()}
                            onChange={this.onChange}
                            attributeConfigurations={customConfiguration}
                            // errorAlertConfiguration={hiddenErrorConfiguration}
                            isColorSwatch={true}
                            onLoad={this.onLoad}
                            enableSerialization={false}
                            selectionResource={ResourceType.SURFACE}
                            selectWithProductAttributes={false}
                        />
                    </div>
                )}
                {showAddVariables && (
                    <CheckboxSelection
                        onUpdate={this.changeVariableDisplay}
                        variables={allAttributes}
                        displayedVariables={displayedAttributes}
                        requiredVariables={requiredAttributes}
                    />
                )}
            </div>
        );
    }
}

// This disable is necessary since I am using the props in a function outside of scope, which confuses the linter.
/* eslint-disable react/no-unused-prop-types */
VariablesV2.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
            valeus: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string,
                    value: PropTypes.any,
                }),
            ),
        }),
    ),
    variables: PropTypes.object,
    loading: PropTypes.bool,
    isReady: PropTypes.bool,
    showAllMode: PropTypes.bool,
    requiredOnlyMode: PropTypes.bool,
    id: PropTypes.string,
    onChangeVariables: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    sku: PropTypes.string,
    skuVersion: PropTypes.number,
};
