import React, { PureComponent, createRef, RefObject } from 'react';

import { Tooltip } from '@cimpress/react-components';
import LayerInformation from './information';
import { BACKGROUND, type LayerType } from './layerTypes';
import UploadIcon from 'src/components/UploadIcon';
import { downloadImageToFileSystem } from 'src/util/upload';

type LayerProps = {
    id: string;
    type: LayerType;
    isProtected?: boolean;
    selected: boolean;
    hidden?: boolean;
    title: string;
    width?: number;
    height?: number;
    className?: string;
    onClick?: (id: string, type: LayerType) => void;
    onDelete?: (id: string) => void;
    onReplace?: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    changeBackgroundName?: (args: { id: string; name: string }) => void;
    toggleHide?: (id: string, type: LayerType) => void;
    hasDragDrop?: boolean;
    onDuplicate?: (id: string) => void;
    onProtected?: () => void;
    onBgRemove?: (id: string) => void;
    url?: string;
};

type LayerState = {
    editMode: boolean;
};

const onClickButton = (e: React.MouseEvent, func?: () => void) => {
    func && func();
    e.stopPropagation();
};

export default class Layer extends PureComponent<LayerProps, LayerState> {
    defaultProps = {
        hasDragDrop: false,
        onClick: () => {},
        onDelete: undefined,
        onReplace: undefined,
        toggleHide: () => {},
        className: '',
    };

    inputRef: RefObject<HTMLInputElement> = createRef();

    state: LayerState = {
        editMode: false,
    };

    click = () => {
        const { onClick, id, type } = this.props;
        onClick && onClick(id, type);
    };

    replace = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onReplace, id } = this.props;
        onReplace?.(e, id);
    };

    hide = () => {
        const { toggleHide, id, type } = this.props;
        toggleHide?.(id, type);
    };

    copy = () => {
        const { onClick, id, type, onDuplicate, selected } = this.props;
        onDuplicate?.(id);
        if (selected && onClick) {
            onClick(id, type);
        }
    };

    toggleEdit = () => {
        if (this.props.isProtected) {
            this.props.onProtected?.();
            return;
        }

        this.setState(
            (state) => ({ editMode: !state.editMode }),
            () => {
                if (this.state.editMode) {
                    this.inputRef.current?.focus();
                }
            },
        );
    };

    changeName = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode === 13) {
            const { id, changeBackgroundName } = this.props;
            const name = (evt.target as HTMLInputElement).value;
            this.setState(
                (state) => ({ editMode: !state.editMode }),
                () => {
                    changeBackgroundName?.({ id, name });
                },
            );
        }
    };

    getTitleText = () => {
        const { title, width, height } = this.props;
        let titleText = title;
        if (height && width) {
            titleText += ` (${width} x ${height})`;
        }
        return titleText;
    };

    render() {
        const {
            onDelete,
            onReplace,
            toggleHide,
            title,
            className,
            selected,
            hidden,
            id,
            type,
            hasDragDrop,
            onDuplicate,
            isProtected,
            onBgRemove,
            url,
        } = this.props;
        const dndIcon = <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/drag-drop-l.svg' />;

        return (
            <div
                className={`layer ${selected ? 'layer--selected' : ''} ${className || ''} ${isProtected ? 'protected' : ''}`}>
                <div className='layer__header' onClick={this.click} onDoubleClick={this.toggleEdit}>
                    {hasDragDrop && !this.state.editMode && dndIcon}
                    <p className='layer__title'>
                        {!this.state.editMode && this.getTitleText()}
                        {this.state.editMode && (
                            <input
                                ref={this.inputRef}
                                className='layer__title__input'
                                type='text'
                                onBlur={this.toggleEdit}
                                onKeyDown={this.changeName}
                                defaultValue={title}
                            />
                        )}
                    </p>

                    <div className='layer__actions'>
                        {url && (
                            <Tooltip contents='Download image'>
                                <button
                                    className='layer__download'
                                    type='button'
                                    onClick={(e) =>
                                        onClickButton(e, () => {
                                            downloadImageToFileSystem(url, title);
                                        })
                                    }>
                                    <img
                                        className='iconLg'
                                        src='https://static.ux.cimpress.io/assets/icons/download-3-l.svg'
                                    />
                                </button>
                            </Tooltip>
                        )}
                        {onBgRemove && (
                            <Tooltip contents='Background Removal'>
                                <button
                                    className='layer__bg-remove'
                                    type='button'
                                    onClick={(e) => onClickButton(e, () => onBgRemove(id))}>
                                    <img className='iconLg' src='/images/bg-remove-icon.svg' />
                                </button>
                            </Tooltip>
                        )}
                        {!!toggleHide && (
                            <button className='layer__hide' onClick={(e) => onClickButton(e, this.hide)}>
                                <img
                                    className={`iconLg ${hidden ? '' : 'colorOceanBase'}`}
                                    src='https://static.ux.cimpress.io/assets/icons/view-2-l.svg'
                                />
                            </button>
                        )}
                        {!isProtected && !!onReplace && (
                            <UploadIcon className='layer__replace' onUpload={this.replace}>
                                <img
                                    className='iconLg'
                                    src='https://static.ux.cimpress.io/assets/icons/file-picture-edit-l.svg'
                                />
                            </UploadIcon>
                        )}
                        {!!onDuplicate && (
                            <button className='layer__duplicate' onClick={(e) => onClickButton(e, this.copy)}>
                                <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/copy-3-l.svg' />
                            </button>
                        )}
                        {!isProtected && !!onDelete && (
                            <button className='layer__delete' onClick={(e) => onClickButton(e, () => onDelete(id))}>
                                <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/bin-1-l.svg' />
                            </button>
                        )}
                        {isProtected && (
                            <Tooltip contents='Protected Asset'>
                                <div className='protected-icon' />
                            </Tooltip>
                        )}
                    </div>
                </div>
                {selected && type !== BACKGROUND && (
                    <div className='layer__body'>
                        <LayerInformation id={id} type={type} />
                    </div>
                )}
            </div>
        );
    }
}
