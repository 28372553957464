import React, { PureComponent, ChangeEvent } from 'react';
import { TextField } from '@cimpress/react-components';

export type UpdateCustomTickFn = (data: { id: string; index: number; value: number }) => void;
export type RemoveCustomTickFn = (data: { id: string; index: number }) => void;

interface CustomTickProps {
    segments: number;
    value: number;
    id: string;
    index: number;
    updateCustomTick: UpdateCustomTickFn;
    removeCustomTick: RemoveCustomTickFn;
}

interface CustomTickState {
    currentValue?: number;
    validValue?: number;
    status: 'error' | undefined;
}

const isError = (value: number, segments: number) =>
    !(Number.isFinite(value) && value >= 0 && value <= segments) ? 'error' : undefined;

export default class CustomTick extends PureComponent<CustomTickProps, CustomTickState> {
    state: CustomTickState = {
        currentValue: this.props.value,
        validValue: this.props.value,
        status: isError(this.props.value, this.props.segments),
    };

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { updateCustomTick, id, segments, index } = this.props;
        const value = parseFloat(e.target.value);
        const error = isError(value, segments);
        this.setState({ status: error, currentValue: value });
        if (!error) {
            updateCustomTick({ id, index, value });
        }
    };

    onDelete = () => {
        this.props.removeCustomTick({ id: this.props.id, index: this.props.index });
    };

    static getDerivedStateFromProps(nextProps: CustomTickProps, prevState: CustomTickState): CustomTickState | null {
        if (nextProps.value !== prevState.validValue) {
            return {
                currentValue: nextProps.value,
                validValue: nextProps.value,
                status: isError(nextProps.value, nextProps.segments),
            };
        }
        return { status: isError(prevState.currentValue!, nextProps.segments) };
    }

    render() {
        const { currentValue, status } = this.state;
        return (
            <div className='custom-tick'>
                <TextField
                    className='custom-tick__field'
                    label='Tick'
                    value={currentValue || (currentValue === 0 ? 0 : '')}
                    onChange={this.onChange}
                    status={status}
                    type='number'
                    onFocus={(e) => e.target.select()}
                />
                <div className='custom-tick__delete' onClick={this.onDelete}>
                    <img
                        className='icon2x colorOceanBase'
                        src='https://static.ux.cimpress.io/assets/icons/bin-1-f.svg'
                    />
                </div>
            </div>
        );
    }
}
