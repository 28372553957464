import { createSelector } from 'reselect';
import { RootState } from './store';

const getPageLoadingValue = (state: RootState) => state.application.isPageLoading;
export const isPageLoading = createSelector([getPageLoadingValue], (loading) => loading);

const getAppLoadingValue = (state: RootState) => state.application.isApplicationLoading;
export const isApplicationLoading = createSelector([getAppLoadingValue], (loading) => loading);

const getShowModalCreateScene = (state: RootState) => state.application.showCreateSceneModal;
export const isCreateSceneModalOpen = createSelector([getShowModalCreateScene], (open) => open);

const getResourceData = (state: RootState) => state.application.resources;
export const getResources = createSelector([getResourceData], (resources) => resources || {});

const getRegion = (state: RootState) => state.application.region;
export const getRegionSetting = createSelector([getRegion], (region) => region);

const getAreSurfacesRequired = (state: RootState) => state.application.areSurfacesRequired;
export const areSurfacesRequired = createSelector([getAreSurfacesRequired], (required) => required);

const getServiceId = (state: RootState) => state.application.id;
const getServiceType = (state: RootState) => state.application.type;
export const getCoamTenant = createSelector([getServiceId, getServiceType], (tenantId, tenantType) => ({
    tenantId,
    tenantType,
}));

export const hasCoamPermissions = createSelector([getResources], (resources) => Object.keys(resources).length !== 0);

const getSelectable = (state: RootState) => state.application.isTenantSelectable;
export const isTenantSelectable = createSelector(
    [getSelectable, hasCoamPermissions],
    (seletable, permissions) => seletable && permissions,
);

const getBulkMode = (state: RootState) => state.application.isBulkMode;
export const isBulkMode = createSelector([getBulkMode], (isBulk) => isBulk);

const getMode = (state: RootState) => state.application.mode;
export const getGenerationMode = createSelector([getMode], (mode) => mode);

const getAIFields = (state: RootState) => state.application.AIGeneration;
export const getAIData = createSelector([getAIFields], (data) => data);

const getImages = (state: RootState) => state.application.AIGeneration.images;
export const getAIResult = createSelector([getImages], (images) => images);

const getAIMode = (state: RootState) => state.application.AIGeneration.active;
export const isAIMode = createSelector([getAIMode], (active) => active);

const getAIGeneration = (state: RootState) => state.application.AIGeneration.isGenerating;
export const isAIGeneration = createSelector([getAIGeneration], (generating) => generating);
