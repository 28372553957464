import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import isEqual from 'lodash/isEqual';
import { Checkbox, Tooltip, Button } from '@cimpress/react-components';

import { removeFromArray } from 'src/util/misc';

export default class Variables extends PureComponent {
    state = {
        open: false,
        checkedVariables: this.props.displayedVariables || [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { variables, displayedVariables, requiredVariables } = nextProps;
        if (
            !isEqual(variables, prevState.variables) ||
            !isEqual(displayedVariables, prevState.displayedVariables) ||
            !isEqual(requiredVariables, prevState.requiredVariables)
        ) {
            return {
                checkedVariables: displayedVariables,
                variables,
                displayedVariables,
                requiredVariables,
            };
        }
        return null;
    }

    toggleVariable = (variable) => {
        this.setState((prevState) => {
            if (prevState.checkedVariables.includes(variable)) {
                return { checkedVariables: removeFromArray(prevState.checkedVariables, variable) };
            }
            return { checkedVariables: prevState.checkedVariables.concat([variable]) };
        });
    };

    updateDisplay = () => {
        this.setState({ open: false });
        this.props.onUpdate(this.state.checkedVariables);
    };

    toggleOpen = () => {
        this.setState((prevState) => ({ open: !prevState.open }));
    };

    render() {
        const { variables, requiredVariables } = this.props;
        const { checkedVariables, open } = this.state;

        return (
            <div className='variables__add'>
                <Tooltip
                    direction='top'
                    show={open}
                    variety='popover'
                    contents={
                        <div className='variables__popover-content'>
                            {variables.map((variable) => (
                                <Checkbox
                                    key={variable}
                                    label={variable}
                                    disabled={requiredVariables.includes(variable)}
                                    onChange={() => this.toggleVariable(variable)}
                                    checked={checkedVariables.includes(variable)}
                                />
                            ))}
                            <Button
                                variant='primary'
                                className='variables__confirm-button'
                                onClick={this.updateDisplay}>
                                Add Attributes
                            </Button>
                        </div>
                    }>
                    <Button variant='link' className='variables__add-button' onClick={this.toggleOpen}>
                        Add/Remove Attributes
                    </Button>
                </Tooltip>
            </div>
        );
    }
}

Variables.propTypes = {
    variables: PropTypes.arrayOf(PropTypes.string),
    displayedVariables: PropTypes.arrayOf(PropTypes.string),
    requiredVariables: PropTypes.arrayOf(PropTypes.string),
    onUpdate: PropTypes.func.isRequired,
};
