/* eslint-disable */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Tag, TextField } from '@cimpress/react-components';
import { ENTER } from 'src/util/keycodes';

export default class SceneTags extends PureComponent {
    state = {
        currentTag: '',
        isDuplicateTag: false,
    };

    updateCurrentTag = (e) =>
        this.setState({
            currentTag: e.target.value,
            isDuplicateTag: false,
        });

    addSceneTag = () => {
        const { currentTag } = this.state;
        const { tags, addTag } = this.props;
        const tagSet = currentTag
            .split(',')
            .filter((tag) => tag)
            .map((tag) => tag.trim());

        if (tagSet.every((item) => tags.indexOf(item) > -1)) {
            this.setState({
                isDuplicateTag: true,
            });
        } else {
            const uniqueTagSet = Array.from(new Set([...tagSet, ...tags]));
            addTag(uniqueTagSet);
        }
        this.setState({
            currentTag: '',
        });
    };

    onPreSetTagClick = (tag) => {
        const { tags, removeTag, addTag } = this.props;
        if (tags.includes(tag)) {
            removeTag(tag);
        } else {
            const uniqueTagSet = Array.from(new Set([...tags, tag]));
            addTag(uniqueTagSet);
        }
    };

    render() {
        const { tags, removeTag, predefinedTags } = this.props;
        const { currentTag, isDuplicateTag } = this.state;

        return (
            <div className='save-properties'>
                <div className='save-properties__input scene-tags__list'>
                    {tags &&
                        tags.map((tag) => <Tag value={tag} key={tag} removable={true} onRemoveClick={removeTag} />)}
                </div>
                <div className='save-properties'>
                    <TextField
                        className='save-properties__input'
                        data-testid='editor-sceneInformation-tags-input'
                        value={currentTag}
                        label='Add a tag'
                        onChange={this.updateCurrentTag}
                        onKeyDown={(e) => {
                            if (e.keyCode === ENTER) {
                                this.addSceneTag();
                            }
                        }}
                        rightAddon={
                            <Button
                                data-testid='editor-sceneInformation-tags-submit'
                                className='scene-tag__add-button'
                                onClick={this.addSceneTag}
                                disabled={!currentTag}>
                                Add
                            </Button>
                        }
                        status={isDuplicateTag ? 'error' : undefined}
                        helpText={isDuplicateTag ? 'This tag has already been added to the scene.' : ''}
                    />
                    <div className='save-properties__default-tags'>
                        {predefinedTags?.map((tag) => (
                            <div key={tag} style={{ cursor: 'pointer' }} onClick={() => this.onPreSetTagClick(tag)}>
                                <Tag
                                    data-testid={`link-tags_${tag}`}
                                    className={tags.includes(tag) ? 'selected' : 'unselected'}
                                    key={tag}
                                    value={tag}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

SceneTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
    predefinedTags: PropTypes.arrayOf(PropTypes.string),
    addTag: PropTypes.func.isRequired,
    removeTag: PropTypes.func.isRequired,
};
