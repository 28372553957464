import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

import {
    getAllDocuments,
    getAllOverlays,
    getSelectedLayer,
    getAllRulers,
    getAllBackgrounds,
    getSelectedBackgroundId,
} from 'src/selectors/layers';
import { selectLayer, deselectLayer } from 'src/pages/editor/components/layer/slice';
import { toggleHide } from 'src/store/actions/layers';
import { removeRuler } from 'src/pages/editor/components/rulers/slice';
import { removeDocument, duplicateDocument } from 'src/pages/editor/components/documents/slice';
import { isBulkMode } from 'src/selectors';
import { toggleBulkMode } from 'src/slice';
import { documentProtected } from 'src/components/alert/slice';
import { changeOverlayOrder, removeOverlay, uploadAndReplaceOverlay } from '../overlays/slice';
import {
    removeBackground,
    uploadAndReplaceBackground,
    selectBackground,
    changeBackgroundOrder,
    showBackgroundRemovalModal,
} from '../backgrounds/slice';
import layers from './layers';
import { BACKGROUND, DOCUMENT, LayerType, OVERLAY, RULER } from './layerTypes';

const getAllOfLayerType = (layerGroup: TODO, type: LayerType) =>
    layerGroup.map((doc: TODO) => ({
        id: doc.id,
        title: doc.id,
        type,
        isProtected: doc.isProtected,
    }));

const mapStateToProps = (state: RootState) => {
    const overlays = getAllOverlays(state).map((overlay) => overlay);
    const documents = getAllDocuments(state).map((doc) => doc);
    const rulers = getAllRulers(state).map((ruler) => ruler);
    const backgrounds = getAllBackgrounds(state);
    return {
        selectedLayer: getSelectedLayer(state),
        selectedBackground: getSelectedBackgroundId(state),
        documents: getAllOfLayerType(documents, DOCUMENT),
        backgrounds: getAllOfLayerType(backgrounds, BACKGROUND),
        tools: getAllOfLayerType(rulers, RULER),
        overlays: getAllOfLayerType(overlays, OVERLAY),
        disabled: backgrounds.length === 0,
        isBulkMode: isBulkMode(state),
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onSelectLayer: (id: string, type: LayerType) => dispatch(selectLayer({ id, type })),
    onSelectBackground: (id: string) => dispatch(selectBackground(id)),
    toggleHide: (id: string, type: LayerType) => dispatch(toggleHide(id, type)),
    onDeselectLayer: () => dispatch(deselectLayer()),
    onBackgroundDelete: (id: string) => dispatch(removeBackground(id)),
    onOverlayDelete: (id: string) => dispatch(removeOverlay(id)),
    onDocumentDelete: (id: string) => dispatch(removeDocument(id)),
    onRulerDelete: (id: string) => dispatch(removeRuler(id)),
    onDocumentDuplicate: (id: string) => dispatch(duplicateDocument(id)),
    onOverlayReplace: (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files![0];
        if (file) {
            dispatch(uploadAndReplaceOverlay({ file, id }));
        }
    },
    onBackgroundReplace: (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files![0];
        if (file) {
            dispatch(uploadAndReplaceBackground({ file, id }));
        }
    },
    onBackgroundChangeOrder: (payload: TODO) => dispatch(changeBackgroundOrder(payload)),
    onOverlayChangeOrder: (payload: TODO) => dispatch(changeOverlayOrder(payload)),
    toggleBulkMode: (payload: TODO) => dispatch(toggleBulkMode(payload)),
    onProtected: () => dispatch(documentProtected()),
    onBgRemove: (id: string) => dispatch(showBackgroundRemovalModal({ backgroundId: id })),
});

const layersContainer = connect(mapStateToProps, mapDispatchToProps)(layers);

export default layersContainer;
