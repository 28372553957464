import React, { PureComponent } from 'react';
import { TextField } from '@cimpress/react-components';

const isError = (value: number) => (!Number.isFinite(value) ? 'error' : undefined);

interface NumberInputProps {
    value: number;
    label: string;
    onSubmit(value: number): void;
}

interface NumberInputState {
    currentValue?: number;
    validValue?: number;
    error?: 'error';
}

export default class numberInput extends PureComponent<NumberInputProps> {
    state: NumberInputState = {
        currentValue: this.props.value,
        validValue: this.props.value,
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onSubmit } = this.props;
        const value = parseFloat(e.target.value);
        const error = isError(value);
        this.setState({ currentValue: value });
        if (!error) {
            onSubmit(value);
        }
    };

    static getDerivedStateFromProps(nextProps: NumberInputProps, prevState: NumberInputState): NumberInputState {
        const { value } = nextProps;
        if (value !== prevState.validValue) {
            return { currentValue: value, validValue: value, error: isError(value) };
        }
        return { error: isError(prevState.currentValue!) };
    }

    render() {
        const { label } = this.props;
        const { currentValue, error } = this.state;
        return (
            <TextField
                className='number-input'
                label={label}
                value={currentValue || (currentValue === 0 ? 0 : '')}
                required={true}
                onChange={this.onChange}
                status={error}
                type='number'
                onFocus={(e) => e.target.select()}
            />
        );
    }
}
