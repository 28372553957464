import React from 'react';

import Duplicate from './save/duplicate';
import Publish from './save/publish';
import NewVersion from './save/newVersion';
import { Button } from '@cimpress/react-components';

export default function footer() {
    return (
        <div className='navigation-footer'>
            <div className='navigation-footer__left'>
                <Button href='https://pam.cimpress.io/?assetType=scene'>Back to home</Button>
            </div>
            <div className='navigation-footer__right'>
                <Duplicate />
                <NewVersion />
                <Publish />
            </div>
        </div>
    );
}
