import type { AppDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';
import { getDocumentById, getSceneSize } from 'src/selectors/layers';
import { setWarp, setRotationAngle } from 'src/pages/editor/components/documents/slice';
import {
    RECTANGLE_WARP,
    PERSPECTIVE_WARP,
    CYLINDER_WARP,
    SMOOTH_WARP,
    TEXTURE_WARP,
    type DocumentWarp,
} from 'src/models/documentWarps';
import warp from './warp';

const warps: Array<{ value: DocumentWarp; label: string }> = [
    {
        value: RECTANGLE_WARP,
        label: 'Rectangle',
    },
    {
        value: PERSPECTIVE_WARP,
        label: 'Perspective',
    },
    {
        value: CYLINDER_WARP,
        label: 'Cylindrical',
    },
    {
        value: SMOOTH_WARP,
        label: 'Smooth',
    },
    {
        value: TEXTURE_WARP,
        label: 'UV',
    },
];

const mapStateToProps = (state: RootState, ownProps: { id: string }) => {
    const { width, height } = getSceneSize(state);
    const doc = getDocumentById(state, ownProps.id);
    const transform = doc.transforms[0];

    let size;
    if (
        doc.warpType === SMOOTH_WARP &&
        doc.transforms &&
        doc.transforms[0] &&
        doc.transforms[0].points &&
        doc.transforms[0].points.length
    ) {
        size = Math.ceil(Math.sqrt(doc.transforms[0].points.length));
    }

    let isDefault = true;
    if ('dragPoints' in transform && transform.dragPoints && transform.dragPoints.length > 0) {
        for (let i = 0; i < transform.dragPoints.length; i++) {
            if (
                transform.dragPoints[i].x !== transform.points[i].x ||
                transform.dragPoints[i].y !== transform.points[i].y
            ) {
                isDefault = false;
                break;
            }
        }
    } else {
        isDefault = false;
    }

    return {
        selected: doc.warpType as DocumentWarp,
        warps,
        width,
        height,
        size,
        isDefault,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: { id: string }) => ({
    onClick: (warpType: DocumentWarp, width: TODO, height: TODO, size: TODO) => {
        dispatch(setRotationAngle({ angle: 0, id: ownProps.id }));
        dispatch(setWarp({ warpType, id: ownProps.id, width, height, size }));
    },
});

const warpContainer = connect(mapStateToProps, mapDispatchToProps)(warp);

export default warpContainer;
