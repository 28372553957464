import React, { Fragment } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';

type AlertsSliceState = Array<{
    id: number;
    name: string;
    message: React.ReactNode;
    type: string;
    timeout?: number;
    dismissible?: boolean;
    preventDuplicateKey?: string;
}>;

const initialState: AlertsSliceState = [];

const SUCCESS = 'success';
const FAILURE = 'danger';
const WARNING = 'warning';

const slice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        addAlert: (state, { payload }) => {
            if (payload.preventDuplicateKey) {
                const dupAlert = state.some((alert) => alert.preventDuplicateKey === payload.preventDuplicateKey);
                if (dupAlert) {
                    return;
                }
            }
            state.push({ ...payload, id: Date.now() });
        },
        removeAlert: (state, { payload }) => {
            const index = state.findIndex((alert) => alert.id === payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
    },
});

export const addAlert = slice.actions.addAlert;
export const removeAlert = slice.actions.removeAlert;

export const reducer = slice.reducer;

// Catalog

export function urlCopied() {
    return addAlert({
        name: 'URL_COPIED',
        dismissible: true,
        message: <b>Scene url has been copied to clipboard!</b>,
        timeout: 10,
        type: SUCCESS,
    });
}

// Editor

export function productIdMissing() {
    return addAlert({
        name: 'PRODUCT_MISSING',
        dismissible: true,
        message: (
            <>
                <b>Product SKU missing</b>
                <p>Setup the Product SKU for a better experience</p>
            </>
        ),
        timeout: 4000,
        type: WARNING,
        preventDuplicateKey: 'PRODUCT_MISSING',
    });
}

export function saveComplete() {
    return addAlert({
        name: 'SAVE_COMPLETE',
        dismissible: true,
        message: <b>Scene has been saved!</b>,
        timeout: 10,
        type: SUCCESS,
    });
}

export function saveFailed() {
    return addAlert({
        name: 'SAVE_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Saving Scene Failed!</b> Please try again
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function publishComplete() {
    return addAlert({
        name: 'PUBLISH_COMPLETE',
        dismissible: true,
        message: <b>Scene has been published!</b>,
        timeout: 10,
        type: SUCCESS,
    });
}

export function publishFailed() {
    return addAlert({
        name: 'PUBLISH_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Publishing Scene Failed!</b> Please try again
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function bulkFailed() {
    return addAlert({
        name: 'BULK_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Bulk scene creation failed!</b> At least one failed, but a few may have been created. If this is
                unexpected, <Link to='/contact'>please contact support.</Link>
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function uploadFailed(text: string) {
    return addAlert({
        name: 'UPLOAD_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Failed to upload image!</b> {text}
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function backgroundResized() {
    return addAlert({
        name: 'BACKGROUND_RESIZED_WARNING',
        dismissible: true,
        message: (
            <b>
                Uploaded background image size was too big. Images larger than 6000px are automatically resized to
                2000px.
            </b>
        ),
        timeout: 15,
        type: WARNING,
    });
}

export function imageResized() {
    return addAlert({
        name: 'IMAGE_RESIZED_WARNING',
        dismissible: true,
        message: <b>Uploaded image size was too big. Images larger than 6000px are automatically resized to 2000px.</b>,
        timeout: 15,
        type: WARNING,
    });
}

export function loadProductFailed(sceneId: string) {
    return addAlert({
        name: 'LOAD_PRODUCT_FAILED',
        dismissible: false,
        message: (
            <Fragment>
                <b>Loading Product Failed! Scene ID: {sceneId}</b> Either loading the product data, or the surface data
                of the product failed. If the product has been changed since creating the scene, this could lead to
                failure. The scene will now be loaded without any product data. If this is unexpected, or you need help,{' '}
                <Link to='/contact'>please contact support.</Link>
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function SceneNotFound() {
    return addAlert({
        name: 'SCENE_NOT_FOUND',
        dismissible: false,
        message: (
            <Fragment>
                <b>Scene Not Found!</b> We could not find the scene you are trying to see, please check the tenant
                information or try with another scene ID. If this is not the case,{' '}
                <Link to='/contact'>please contact support.</Link>
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function loadSceneFailed() {
    return addAlert({
        name: 'LOAD_SCENE_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Loading Scene Failed!</b> This is likely an old scene not created using Scene Maker, which is
                presently not supported by scene maker. If this is not the case,{' '}
                <Link to='/contact'>please contact support.</Link>
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function onSearchError(errorMessage: string) {
    // TODO: save errorMessage on logger? as an event
    return addAlert({
        name: 'ASSET_SEARCH_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <h4>
                    <b>Asset Search Failed!</b>
                </h4>
                <span>
                    We found a problem searching for your asset, this could be due to connection issues or maintainance
                    in our server, if none of these is your situation{' '}
                    <Link to='/contact'> please contact support.</Link>
                    {errorMessage && (
                        <p>
                            <b>Error Detail:</b> {errorMessage}
                        </p>
                    )}
                </span>
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function renderFailed() {
    return addAlert({
        name: 'RENDER_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Scene render failed!</b> You wont be able to save until it renders properly. If this is unexpected or
                you need help, <Link to='/contact'>please contact support.</Link>
            </Fragment>
        ),
        preventDuplicateKey: 'renderFailed',
        type: FAILURE,
    });
}

export function insertVariablesWarning() {
    return addAlert({
        name: 'INSERT_VARIABLES_WARNING',
        dismissible: true,
        message: (
            <Fragment>
                <b>Sku successfully added!</b> There are required attributes that need to be added for this scene to
                render with this sku. Add the attributes beneath the sku selection to continue.
            </Fragment>
        ),
        timeout: 15,
        preventDuplicateKey: 'insertVariables',
        type: WARNING,
    });
}

export function documentProtected() {
    return addAlert({
        name: 'DOCUMENT_PROTECTED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Document Protected</b> This Asset is part of your Variable Scene Configuration, if you want to change
                the name please delete the configuration first.
            </Fragment>
        ),
        timeout: 15,
        preventDuplicateKey: 'documentProtected',
        type: WARNING,
    });
}

// etc

export function noPermissions() {
    const url = 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/189661282/Before+Using+Scene+Maker';
    return addAlert({
        name: 'NO_PERMISSIONS',
        dismissible: false,
        message: (
            <Fragment>
                <b>You have no COAM permissions!</b> You will not be able to save any scenes that you create.{' '}
                <a className='alert-link' id='scene-href' href={url} target='_blank' rel='noopener noreferrer'>
                    Please refer to our documentation here to get started.
                </a>
            </Fragment>
        ),
        preventDuplicateKey: 'noPermissions',
        type: FAILURE,
    });
}

export function backgroundLowQuality() {
    return addAlert({
        name: 'BACKGROUND_LOW_RESOLUTION',
        dismissible: true,
        message: (
            <Fragment>
                <b>Warning: Low Resolution Image</b> Your image is less than 700px. This might cause poor quality
                previews
            </Fragment>
        ),
        timeout: 15,
        type: WARNING,
    });
}

export function SaveVariantFailed() {
    return addAlert({
        name: 'SAVE_VARIANT_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Saving Scene Configuration Failed!</b> This could happen because your configuration might have
                corrupt information, please contact support
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function SaveLinkVariantFailed() {
    return addAlert({
        name: 'SAVE_LINK_VARIANT_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Saving Scene Link Failed!</b> There is already a Link to an Asset with the same configuration you are
                trying, please change your configuration and try again.
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function ParseCroppingCoordinatesFailed() {
    return addAlert({
        name: 'PARSE_CROPPING_COORDINATES_FAILED',
        dismissible: true,
        // eslint-disable-next-line max-len
        message: (
            <Fragment>
                <b>Parsing Cropping Coordinates Failed!</b> We could not transform the cropping coordinates into our
                system, if you save the scene, previews might look different of what you see in your canvas.
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function alertDeleteSceneVariationSuccess() {
    return addAlert({
        name: 'SCENEVARIATION_DELETE_SUCCESS',
        dismissible: true,
        // eslint-disable-next-line max-len
        message: (
            <Fragment>
                <b>Delete Scene Variation Successful!</b> We delete the Scene Variation and all links attached to it.
            </Fragment>
        ),
        type: SUCCESS,
    });
}

export function alertDeleteSceneVariationFailed() {
    return addAlert({
        name: 'SCENEVARIATION_DELETE_FAILED',
        dismissible: true,
        // eslint-disable-next-line max-len
        message: (
            <Fragment>
                <b>Delete Scene Variation Failed!</b> We could not delete the Scene Variation, please contact support.
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function ReplaceBackgroundRemovalFailed() {
    return addAlert({
        name: 'REPLACE_BACKGROUND_REMOVAL_FAILED',
        dismissible: true,
        message: (
            <Fragment>
                <b>Removed Background Failed!</b> Something happens while we were trying to remove the background of
                your asset. Please contact support.
            </Fragment>
        ),
        type: FAILURE,
    });
}

export function ReplaceBackgroundRemovalSuccess() {
    return addAlert({
        name: 'REPLACE_BACKGROUND_REMOVAL_SUCCESS',
        dismissible: true,
        message: (
            <Fragment>
                <b>Removed Background Success!</b> Your asset has been changed successfully.
            </Fragment>
        ),
        type: SUCCESS,
    });
}
