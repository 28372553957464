import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@cimpress/react-components';
import { uploadImageAndGetUrl, getImageSize, downloadImageToFileSystem } from 'src/util/upload';
import { uploadFailed } from 'src/components/alert/slice';

const UploadFile = (props) => {
    const refInput = useRef();
    const openFile = () => refInput.current && refInput.current.click();

    const canFileBeUploaded = async (file) => {
        const { width, height } = await getImageSize(file);
        if (props.width !== width || props.height !== height) {
            throw new Error('Width or height of uploaded image does not match the layer you are trying to replace');
        }
        return true;
    };

    const handleChange = async (e) => {
        try {
            if ((e.target.files || []).length) {
                let canUpload = true;
                const fileChosen = e.target.files[0];
                if (props.height && props.width) {
                    canUpload = await canFileBeUploaded(fileChosen);
                }
                if (canUpload) {
                    const { name } = fileChosen;
                    const imageUrl = await uploadImageAndGetUrl(fileChosen, 'auto');
                    props.onChange &&
                        props.onChange({
                            name,
                            imageUrl,
                        });
                } else {
                    props.uploadFailed();
                }
            }
        } catch (error) {
            props.uploadFailed(error.message || '');
        }
    };
    const removeFile = () => {
        refInput.current.value = null;
        props.onChange && props.onChange(null);
    };

    const onDownload = () => {
        downloadImageToFileSystem(props.file.imageUrl, props.file.name);
    };

    const tooltipContent = props.file ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <img src={props.file.imageUrl} alt='preview' />
            <Button variant='anchor' style={{ backgroundColor: '#ffa23f', color: 'black' }} onClick={props.onBgRemove}>
                <img src='/images/bg-remove-icon.svg' alt='Remove background' /> Remove Background
            </Button>
        </div>
    ) : (
        <></>
    );
    return (
        <div className='upload-file'>
            {props.file ? (
                <div className='upload-file-label'>
                    <Tooltip contents={tooltipContent}>
                        <span title={props.file.name}>{props.file.name}</span>
                    </Tooltip>
                    <Button variant='anchor' onClick={onDownload} style={{ marginRight: 8 }}>
                        <img src='https://static.ux.cimpress.io/assets/icons/download-3-l.svg' alt='Download' />
                    </Button>
                    <Button variant='anchor' onClick={removeFile}>
                        <img src='https://static.ux.cimpress.io/assets/icons/bin-1-l.svg' alt='remove background' />
                    </Button>
                </div>
            ) : (
                <Button variant='primary' onClick={openFile}>
                    Upload file
                </Button>
            )}
            <input
                ref={refInput}
                type='file'
                accept='image/png, image/jpeg, image/bmp'
                hidden={true}
                onChange={handleChange}
            />
        </div>
    );
};

UploadFile.propTypes = {
    file: PropTypes.objectOf(
        PropTypes.shape({
            name: PropTypes.string,
            imageUrl: PropTypes.string,
        }),
    ),
    onChange: PropTypes.func,
    onBgRemove: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    uploadFailed: PropTypes.func,
};

export default connect(null, {
    uploadFailed,
})(UploadFile);
