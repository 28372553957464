import createCachedSelector from 're-reselect';

import getDocumentById from './getDocumentById';

const getCylinderWarpById = createCachedSelector(
    [getDocumentById],
    (doc) => doc?.cylinder,
)((_state, documentId) => documentId);

export default getCylinderWarpById;
