import { createSelector } from 'reselect';

import getAllBackgrounds from './getAllBackgrounds';
import getSelectedBackgroundId from './getSelectedBackgroundId';

const getSelectedBackground = createSelector([getAllBackgrounds, getSelectedBackgroundId], (backgrounds, id) =>
    backgrounds.find((background) => background.id === id),
);

export default getSelectedBackground;
