/* eslint-disable @typescript-eslint/no-explicit-any */
import { toArray } from 'src/util/converter';
import {
    RECTANGLE_TRANSFORM,
    PERSPECTIVE_TRANSFORM,
    TUBE_TRANSFORM,
    PLACEMENT_TRANSFORM,
    SMOOTH_TRANSFORM,
    TEXTURE_TRANSFORM,
} from 'src/models/transforms';
import {
    PlacementTransformType,
    TextureTransformType,
    TransformPointType,
    TransformTypeField,
    TransformTypes,
    TubeTransformType,
} from 'src/types/transforms';
import {
    XMLTree_CompositeComposite,
    XMLTree_DIP,
    XMLTree_Document,
    XMLTree_MapPoint,
    XMLTree_TextureWarp,
    XMLTree_TubeWarp,
    XMLTree_WarpTypes,
} from 'src/types/xml';

/* eslint-disable no-underscore-dangle */
export function buildTransformObject(transformData: TransformTypes, backgroundSize: string) {
    let size = backgroundSize;
    if ('width' in transformData && 'height' in transformData) {
        size = `${transformData.width},${transformData.height}`;
    }

    const transform = {
        id: transformData.id,
        type: transformData.type,
        size,
        points: ('point' in transformData ? [transformData.point as TransformPointType] : transformData.points).map(
            (point: TransformPointType) => ({
                source: `${point.sourceX},${point.sourceY}`,
                target: `${point.x},${point.y}`,
            }),
        ),
    };
    if (transform.type === TEXTURE_TRANSFORM) {
        Object.assign(transform, {
            src: (transformData as TextureTransformType).src,
        });
    }
    if (transform.type === TUBE_TRANSFORM) {
        const temp = transformData as TubeTransformType;
        Object.assign(transform, {
            orientation: temp.orientation,
            lowshift: temp.lowShift,
            highshift: temp.highShift,
        });
    }
    if (transform.type === PLACEMENT_TRANSFORM) {
        Object.assign(transform, {
            rotate: (transformData as PlacementTransformType).rotate,
        });
    }
    return transform;
}

export function buildTransformsArray(transforms: TransformTypes[], backgroundSize: string) {
    return transforms.map((transform) => buildTransformObject(transform, backgroundSize));
}

export function xmlToTransformObjects(dip: XMLTree_DIP) {
    const rootTransforms = dip.Transforms;

    const convertTransform = (warps: XMLTree_WarpTypes[], type: TransformTypeField) =>
        warps.map((warp: XMLTree_WarpTypes) => {
            const mapPoints = toArray(warp.MapPoint);
            const transform = {
                id: warp._id,
                type,
                points: mapPoints.map((point: XMLTree_MapPoint) => {
                    const source = point._source.split(',');
                    const target = point._target.split(',');
                    return {
                        sourceX: parseFloat(source[0]),
                        sourceY: parseFloat(source[1]),
                        x: parseFloat(target[0]),
                        y: parseFloat(target[1]),
                    };
                }),
            };
            if (type === TEXTURE_TRANSFORM) {
                const src = (warp as XMLTree_TextureWarp)._src;
                Object.assign(transform, { src });
            }
            if (type === TUBE_TRANSFORM) {
                const size = warp._size.split(',');
                Object.assign(transform, {
                    width: parseFloat(size[0]),
                    height: parseFloat(size[1]),
                });
            }
            if ('_orientation' in warp) {
                const warpTemp = warp as XMLTree_TubeWarp;
                Object.assign(transform, {
                    lowShift: warpTemp._lowshift,
                    highShift: warpTemp._highshift,
                    orientation: warpTemp._orientation,
                });
            }
            if ('_rotate' in warp) {
                const size = warp._size.split(',');
                Object.assign(transform, {
                    rotate: warp._rotate,
                    width: parseFloat(size[0]),
                    height: parseFloat(size[1]),
                });
            }
            return transform;
        });

    const rectangleWarps = toArray(rootTransforms.RectangleWarp);
    const perspectiveWarps = toArray(rootTransforms.PerspectiveWarp);
    const tubeWarps = toArray(rootTransforms.TubeWarp);
    const placementWarps = toArray(rootTransforms.PlacementWarp);
    const smoothWarps = toArray(rootTransforms.SmoothWarp);
    const textureWarps = toArray(rootTransforms.TextureWarp);

    const transforms = convertTransform(rectangleWarps, RECTANGLE_TRANSFORM)
        .concat(convertTransform(perspectiveWarps, PERSPECTIVE_TRANSFORM))
        .concat(convertTransform(tubeWarps, TUBE_TRANSFORM))
        .concat(convertTransform(placementWarps, PLACEMENT_TRANSFORM))
        .concat(convertTransform(smoothWarps, SMOOTH_TRANSFORM))
        .concat(convertTransform(textureWarps, TEXTURE_TRANSFORM));

    const composites = toArray(dip.Composite.Composite);

    const transformsById: Record<string, any> = {};

    const addTransform = (node: XMLTree_CompositeComposite | XMLTree_Document) => {
        const id = node._name.replace('-inner-wrapper', '').replace('-inner-placement-wrapper', '');
        if (!transformsById[id]) {
            transformsById[id] = transforms.filter((transform) => transform.id === node._xform);
        } else {
            transformsById[id] = transformsById[id].concat(
                transforms.filter((transform) => transform.id === node._xform),
            );
        }
    };

    composites.forEach((composite: XMLTree_CompositeComposite) => {
        let doc = composite.Document;
        if (!doc && composite.Composite) {
            doc = composite.Composite.Document;
            addTransform(composite.Composite);
            if (!doc && composite.Composite.Composite) {
                doc = composite.Composite.Composite.Document;
                addTransform(composite.Composite.Composite);
            }
        }
        addTransform(doc);
    });

    return transformsById;
}
