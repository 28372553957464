import createCachedSelector from 're-reselect';

import getAllDocuments from './getAllDocuments';
import { RootState } from 'src/store';

const getId = (_state: RootState, id: string) => id;

const getDocumentById = createCachedSelector(
    [getAllDocuments, getId],
    // TODO: remove empty object fallback
    (documents, id) => documents.find((doc) => doc.id === id) || ({} as (typeof documents)[number]),
)((_state, documentId) => documentId);

export default getDocumentById;
