import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import SpinnerButton from 'src/components/SpinnerButton';

export default class SaveNewVersion extends PureComponent {
    render() {
        const { text, spinning, saveAsNewVersion, disabled } = this.props;
        return (
            <div className='tools-save'>
                <SpinnerButton
                    testid='editor-footer-save-new-version'
                    onClick={saveAsNewVersion}
                    text={text}
                    disabled={disabled}
                    spinning={spinning}
                />
            </div>
        );
    }
}

SaveNewVersion.propTypes = {
    disabled: PropTypes.bool,
    spinning: PropTypes.bool,
    text: PropTypes.string,
    saveAsNewVersion: PropTypes.func.isRequired,
};
