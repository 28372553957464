import { connect } from 'react-redux';
import type { RootState } from 'src/store';
import { CYLINDER_WARP } from 'src/models/documentWarps';
import { selectTransformPoint } from 'src/pages/editor/components/documents/slice';
import { aspectRatioConstrainedType } from 'src/selectors/canvas';
import { getSurfaces } from 'src/selectors/pages/editor';
import { getTransformsById } from 'src/selectors/transforms';
import { getAllDocuments, getDocumentById } from 'src/selectors/layers';
import grabPoints from './grabPoints';
import { CONSTRAINED_TRIM_TYPE } from '../../slice';

const mapStateToProps = (state: RootState, ownProps: { documentId: string }) => {
    const ARConstrainedType = aspectRatioConstrainedType(state);
    const isARConstrained = ARConstrainedType !== '';
    const { page } = getDocumentById(state, ownProps.documentId);
    const activeSurfaceData = getSurfaces(state).find((surface) => surface.index === page);

    let aspectRatio: number | undefined;
    if (activeSurfaceData && isARConstrained) {
        const { fullBleedArea, trimArea } = activeSurfaceData;
        aspectRatio =
            ARConstrainedType === CONSTRAINED_TRIM_TYPE
                ? trimArea.width / trimArea.height
                : fullBleedArea.width / fullBleedArea.height;
    }

    // Get neighboring documents that aren't cylinder warps
    const neighborDocuments = getAllDocuments(state)
        .filter((d) => d.id !== ownProps.documentId && d.warpType !== CYLINDER_WARP)
        .map((d) => d.id);

    let snapPoints: { x: number; y: number }[] = [];
    if (neighborDocuments && neighborDocuments.length > 0) {
        // Map all of the transforms of neighbor documents into points
        // For rectangle warps expand them into 4 points
        snapPoints = neighborDocuments
            .map((documentId) => {
                const { points } = getTransformsById(state, documentId)[0];

                if (points.length === 2) {
                    return [
                        points[0],
                        { x: points[1].x, y: points[0].y },
                        points[1],
                        { x: points[0].x, y: points[1].y },
                    ];
                }
                return points;
            })
            .reduce((prev, curr) => prev.concat(curr), []);
    }

    return {
        aspectRatio,
        snapPoints,
        isAspectRatioConstrained: isARConstrained,
    };
};

const grabPointsContainer = connect(mapStateToProps, { selectPoint: selectTransformPoint })(grabPoints);

export default grabPointsContainer;
