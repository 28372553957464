import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { TextField } from '@cimpress/react-components';

export default class Properties extends PureComponent {
    render() {
        const { name, description, onChangeName, onChangeDescription, noBackgrounds, isBulkMode } = this.props;
        return (
            <div className='save-properties'>
                <TextField
                    className='save-properties__input'
                    data-testid='editor-sceneInformation-name'
                    value={isBulkMode ? 'Disabled due to bulk mode' : name}
                    onChange={onChangeName}
                    label='Name of your Scene'
                    type='text'
                    disabled={noBackgrounds || isBulkMode}
                />
                <TextField
                    className='save-properties__input'
                    data-testid='editor-sceneInformation-description'
                    value={description}
                    onChange={onChangeDescription}
                    label='Description'
                    type='text'
                    disabled={noBackgrounds}
                />
            </div>
        );
    }
}

Properties.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    noBackgrounds: PropTypes.bool.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onChangeDescription: PropTypes.func.isRequired,
    isBulkMode: PropTypes.bool.isRequired,
};
