/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { logError } from 'src/loggingManager';
import { uploadImage, generateUploadsUrl } from 'src/services/uploadsClient';
import { downloadBlob } from 'src/util/url';
import { CancelablePromise } from 'src/types/upload';
import { CANCEL } from 'redux-saga';

export function downloadImage(url: string): CancelablePromise<HTMLImageElement> {
    const { CancelToken } = axios;
    const cancelTokenSource = CancelToken.source();

    const promise: Promise<HTMLImageElement> = new Promise((resolve, reject) =>
        axios({
            url,
            cancelToken: cancelTokenSource.token,
        })
            .then(() => {
                const imageObj = new Image();
                imageObj.onload = function onload() {
                    resolve(this as HTMLImageElement);
                };
                imageObj.src = url;
            })
            .catch((response) => {
                if (!axios.isCancel(response)) {
                    reject(response.stack);
                }
                reject(response);
            }),
    );

    const cancelablePromise = promise as CancelablePromise<HTMLImageElement>;
    cancelablePromise.cancel = cancelTokenSource.cancel;
    // @ts-expect-error -- support Redux Saga cancellation
    cancelablePromise[CANCEL] = cancelTokenSource.cancel;

    return cancelablePromise;
}

export async function downloadImageToFileSystem(url: string, fileName: string): Promise<void> {
    const response = await fetch(url);

    if (!response.ok) {
        throw new Error('Response not OK');
    }

    const image = await response.blob();

    saveAs(image, fileName);
}

export async function downloadImagesToFileSystem(
    images: { url: string; fileName: string }[],
    zipName: string,
): Promise<void> {
    const downloadedImages = await Promise.all(
        images.map((image) => fetch(image.url).then((response) => response.blob())),
    );
    const zip = new JSZip();

    downloadedImages.forEach((downloadedImage, index) => {
        zip.file(images[index].fileName, downloadedImage);
    });

    const toDownload = await zip.generateAsync({ type: 'blob' });

    saveAs(toDownload, `${zipName}.zip`);
}

export function uploadImageAndGetUrl(
    file: File,
    region = 'auto',
    resizeParams?: Record<string, string | number>,
): Promise<string> {
    if (!file.type.match(/image\/(jpeg|png|bmp)/)) {
        return Promise.reject('Must choose an image to upload');
    }
    return uploadImage(region, file)
        .then((response) => generateUploadsUrl(region, response.data[0].uploadId, resizeParams))
        .catch((error) => {
            logError(`Failure to upload image: ${error.message}`);
            throw error;
        });
}

export function getImageSize(file: File): Promise<{ width: number; height: number }> {
    const fr = new FileReader();

    return new Promise((resolve, reject) => {
        fr.onload = () => {
            const temp = fr.result as string;
            if (!temp || temp.search(/data:image\/(jpeg|png|bmp)/) < 0) {
                reject('Must choose an image to upload');
            }
            const img = new Image();

            img.onload = () => {
                const { height, width } = img;
                resolve({ height, width });
            };

            img.src = temp;
        };

        fr.readAsDataURL(file);
    });
}

// Uploads a new scene background.
export function uploadImageAndGetSize(file: File, region = 'auto') {
    return new Promise((resolve, reject) => {
        // Create a reader so we can parse the width/height of the upload.
        const fr = new FileReader();

        // Setup our onload callback for the file reader.
        fr.onload = () => {
            const temp = fr.result as string;
            if (!temp || temp.search(/data:image\/(jpeg|png|bmp)/) < 0) {
                reject('Must choose an image to upload');
            }
            // Create an image object so we can extract the width/height from it.
            const img = new Image();

            // When the image loads, get the width/height and upload the file.
            img.onload = () => {
                uploadImage(region, file)
                    .then((response) => {
                        const { uploadId } = response.data[0];
                        const url = generateUploadsUrl(region, uploadId);
                        resolve({ url, img });
                    })
                    .catch((e) => {
                        logError(`UploadImageAndGetSize error: ${e}`);
                        reject(
                            'Uploads service failed to retrieve the image. Details can be found in the developer console and the network tab.',
                        );
                    });
            };

            img.src = temp;
        };

        fr.readAsDataURL(file);
    });
}

type DataCollectionType = {
    filename: string;
    blob: any;
};
export function toZip(sceneXml: string) {
    const srcDataCollection: DataCollectionType[] = [];
    let requestsCompleted = 0;

    const images = sceneXml.match(/src="[^"]*"/g);
    const imagePromises: Promise<void>[] = [];

    let newXml = sceneXml;
    images?.forEach((match) => {
        const imageUrl = match.split('"')[1];

        const promise = downloadBlob(imageUrl)
            .then((response) => {
                const extension = response.data.type.split('/')[1];
                const filename = `${requestsCompleted}.${extension}`;
                const newSrc = `images/${filename}`;

                srcDataCollection.push({
                    filename,
                    blob: response.data,
                });

                newXml = newXml.replace(match, `src="${newSrc}"`);
                requestsCompleted++;
            })
            .catch((e) => {
                logError(`error downloading image '${imageUrl}' ${e}`);
                throw e;
            });
        imagePromises.push(promise);
    });

    return Promise.all(imagePromises).then(() => {
        const zip = new JSZip();
        zip.file('index.xml', newXml);

        const imgFolder = zip.folder('images');
        srcDataCollection.forEach((srcData) => {
            imgFolder?.file(srcData.filename, srcData.blob, { base64: true });
        });

        return zip.generateAsync({ type: 'blob' });
    });
}

export function saveAsZip(xml: string, filename = 'scene.zip') {
    toZip(xml).then((zip) => {
        saveAs(zip, filename);
    });
}

export function toSafeUploadName(filename: string) {
    return filename.replace(/[&/\\#,+()$~%'":*?<>{}]/gi, '_');
}

export function isPSD(file: File) {
    const ext = file.name.substring(file.name.length - 3);
    const isType = ['application/x-photoshop', 'application/octet-stream', 'image/vnd.adobe.photoshop'].includes(
        file.type,
    );
    return ext === 'psd' || isType;
}

export function dataURLtoFile(dataUrl: string, filename: string) {
    const arr = dataUrl.split(',');
    if (!!arr && arr.length > 0) {
        const mime = arr[0].match(/:(.*?);/)?.[1] ?? 'jpeg';
        const extension = mime.split('/')[1];
        let newFilename = filename;
        if (filename.indexOf(extension) < 0) {
            newFilename = filename.substring(0, filename.lastIndexOf('.')) + '.' + extension;
        }
        const bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], newFilename, { type: mime });
    }
}

export const readFileAsArrayBuffer = (file: File) => {
    if (file.arrayBuffer) {
        return file.arrayBuffer();
    } else {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        return new Promise<ArrayBuffer>((resolve) => {
            reader.addEventListener('load', (event) => {
                if (event.target) {
                    resolve(event.target.result as ArrayBuffer);
                } else {
                    throw new Error('Loaded file but event.target is null');
                }
            });
        });
    }
};

export const toImageURL = (data: { pixelData: Uint8ClampedArray; width: number; height: number }) => {
    const canvasEl = document.createElement('canvas');
    const context = canvasEl.getContext('2d') as CanvasRenderingContext2D;

    const { width, height, pixelData: rgba } = data;
    const imageData = context.createImageData(width, height);

    canvasEl.width = width;
    canvasEl.height = height;

    imageData.data.set(rgba);
    context.putImageData(imageData, 0, 0);
    const imageUrl = canvasEl.toDataURL();

    return imageUrl;
};
