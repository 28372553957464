import { call, put, all, takeEvery, select } from 'redux-saga/effects';

import { retrieveSurfaceData } from 'src/services/surfaceClient';
import {
    getLinksForAsset,
    queryLinks,
    deleteLink as deleteLinkFromServer,
    generateLinkUri,
} from 'src/services/linkClient';
import { getCoamTenant } from 'src/selectors';
import { logError } from 'src/loggingManager';
import { loadLinksForSku, loadLinksForSkuFailed, loadLinksForSkuSuccess } from 'src/components/skus/slice';
import { addLink, deleteLink, deleteLinkSuccess } from './slice';

/**
 * @param {string} payload.sku
 */
export function* fetchLinksForSku({ payload }) {
    const { tenantId, tenantType } = yield select(getCoamTenant);
    const { sku, variables, purpose } = payload;
    yield put(loadLinksForSku(sku));
    try {
        const links = yield call(queryLinks, { sku, variables, purpose, tenantId, tenantType });
        return yield put(loadLinksForSkuSuccess({ links, sku }));
    } catch (error) {
        logError(`Failed to query links from a sku: ${sku} ${error}`);
        yield put(loadLinksForSkuFailed({ sku }));
        if (payload.throwAgain) {
            throw error;
        }
        return {};
    }
}

/**
 * @param {string} payload.sceneId
 */
export function* fetchLinksForScene({ payload }) {
    const { sceneId } = payload;
    const links = yield call(getLinksForAsset, { assetId: sceneId });
    if (links.length) {
        yield put(addLink({ links }));
    }
    return links;
}

/**
 * @param {[string]} payload.sceneIds
 */
export function* fetchLinksForScenes({ payload }) {
    const { sceneIds } = payload;
    const { tenantId, tenantType } = yield select(getCoamTenant);
    return yield all(
        sceneIds.map((sceneId) => call(fetchLinksForScene, { payload: { tenantId, tenantType, sceneId } })),
    );
}

/**
 * @param {string} sku
 * @param {Object} variables // optional
 */
export function* fetchSurfaces({ sku, skuVersion, variables }) {
    try {
        const surfaceData = yield call(retrieveSurfaceData, sku, skuVersion, variables);
        // Assume one surface group for now
        const surfaces = surfaceData[0].surfaces.map((surface, i) => ({
            index: i + 1,
            id: surface.id,
            name: surface.name,
            fullBleedArea: surface.fullBleedArea,
            trimArea: surface.trimArea,
            foldLines: surface.foldLines,
            fullBleedAreas: [].concat(surface.fullBleedAreas || surface.fullBleedArea),
            trimAreas: [].concat(surface.trimAreas || surface.trimArea),
        }));

        return surfaces;
    } catch (error) {
        logError(`failed to retrieve surfaces: ${sku} ${JSON.stringify(variables)} ${error}`);
        throw error;
    }
}

function* deleteAssetLink({ payload }) {
    const { id, sceneId, linkId } = payload;
    try {
        if (linkId) {
            const uri = generateLinkUri({ assetId: sceneId, linkId });
            yield call(deleteLinkFromServer, { linkUri: uri });
        }
        yield put(deleteLinkSuccess(id));
    } catch (error) {
        logError(`failed deleting link: ${error}`);
    }
}

export default function* linksSaga() {
    return yield all([yield takeEvery(deleteLink, deleteAssetLink)]);
}
